<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>
        <div class="act-search-bar">
            <app-search-bar (searchValue)="onSearchInput($event)" [value]="'WinOls'"
                            [newInputValue]="allMasterService.searchString()"></app-search-bar>
            <div class="filtering-buttons">
                <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i
                        class="bi bi-funnel"></i>
                    @if (filterCount() > 0) {
                        <p class="count-filter">{{ filterCount() }}</p>
                    }</button>
            </div>
        </div>
    </div>

    @if (allMasterService.openAdvanceSettings()) {
        <div>
            <app-master-filters [displayedColumns]="allMasterService.displayedColumns()"
                                (masterFiltersClosed)="onAdvancedSettingClosed($event)"
                                (selectedFilters)="selectFilters($event)"
                                (filterCount)="updateFilterCount($event)">

            </app-master-filters>
        </div>
    }

    @defer (when !allMasterService.isLoading()) {

        @if (!isTableEmpty()) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="allMasterService.onScroll()"
            >
                <app-master-records-table [allMasters]="allMasterService.allMasters()"
                                          [sortDirectionValues]="allMasterService.sortDirectionValues()"
                                          [expandedMaster]="allMasterService.expandedMaster()"
                                          [displayedTableHeaders]="allMasterService.displayedTableHeaders()"
                                          [displayedColumns]="allMasterService.displayedColumns()"
                                          [allVersions]="tuningService.allVersions()"
                                          [displayedVersionColumns]="allMasterService.displayedVersionColumns()"
                                          (editItemDetails)="editItemDetails($event)"
                                          (deleteOriginalItem)="deleteOriginalItems($event)"
                                          (downloadOriginalFile)="downloadFileFromTable($event)"
                                          (deleteTuningRecord)="deleteTuningRecordFromTable($event)"
                                          (downloadTuningRecord)="downloadTuningRecordFromTable($event)"
                                          (toggleExpandedRow)="toggleExpandedRow($event)"
                                          (changeSortDirection)="changeSortDirection($event)"
                ></app-master-records-table>

            </div>
        } @else {
            <div class="empty-table-wrapper">
                <div class="empty-table">NO RESULTS</div>
            </div>
        }


    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }


</section>

