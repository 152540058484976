<div class="table-container">
  <table>
    <tr class="header">
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </table>
  <div class="content-container">
    <span>No results</span>
  </div>
</div>
