<div class="search-bar-container">
    <div class="search-bar-form">
        <form [formGroup]="searchPureOriginalForm">
            <span><i class="bi bi-search"></i></span>
            <input formControlName="searchString" type="text" placeholder="Search masters / pure originals"/>
        </form>
    </div>
    @if (searchPureOriginalForm.controls['searchString'].value.toLowerCase().includes('po')) {
        <div class="list-of-masters" [ngClass]="pureOriginalService.searchedResults().length > 0 ? 'show-list' : ''">
            <div class="search-title">
                <span>Top 5 Results</span>
            </div>
            <div class="list-of-master-wrapper">
                <li *ngFor="let element of pureOriginalService.searchedResults()"
                    (click)="openPureOriginalDetails(+element.id)">{{ element.winOlsDbName }}
                </li>
            </div>
        </div>
    } @else {
        <div class="list-of-masters" [ngClass]="allMasterService.searchedResults().length > 0 ? 'show-list' : ''">
            <div class="search-title">
                <span>Top 5 Results</span>
            </div>
            <div class="list-of-master-wrapper">
                <li *ngFor="let element of allMasterService.searchedResults()"
                    (click)="openPureOriginalMatchingDetails(element.id)">{{ element.winOLSReference }}
                </li>
            </div>
        </div>
    }
</div>


