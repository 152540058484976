<section class="dashboard">
    <ng-container *ngIf="isAdmin() && isLoggedIn() ">
        <div class="main-container">
            <div class="statistics-wrapper">
                <app-statistic-cards></app-statistic-cards>
            </div>
            <div class="win-ols-statistics-wrapper">
                <app-win-ols-cards></app-win-ols-cards>
            </div>
            <div class="pure-matching-cards">
                <app-requested-tunings></app-requested-tunings>
                <app-claimed-files-card></app-claimed-files-card>
            </div>
            <div class="second-row-wrapper">
                <div class="first-row-container">
                    <app-uploading-actions></app-uploading-actions>
                </div>
                <div class="second-row-container">
                    <div class="master-bar-container">
                        <app-search-pure-originals></app-search-pure-originals>
                    </div>
                    <div class="upload-file-container">
                        <app-pure-originals-upload></app-pure-originals-upload>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="isModerator() && isLoggedIn()">

        <div class="moderator-container-wrapper">
            <div class="pure-matching-cards">
                <app-requested-tunings></app-requested-tunings>
                <app-claimed-files-card></app-claimed-files-card>
            </div>
            <div class="main-container-moderator">
                <div class="uploading-file-container">
                    <app-uploading-actions></app-uploading-actions>
                </div>
                <div class="search-bar-basic-user">
                    <app-search-pure-originals></app-search-pure-originals>
                    <div class="upload-file-container">
                        <app-pure-originals-upload></app-pure-originals-upload>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="isUser() && isLoggedIn()">


        <div class="main-container-basic-user">
            <div class="uploading-file-container">
                <app-uploading-actions></app-uploading-actions>
            </div>
            <div class="search-bar-basic-user">
                <app-search-pure-originals></app-search-pure-originals>
            </div>
        </div>

    </ng-container>

    <div class="main-display-todo" [ngClass]="todoOpened ? 'side-todo' : '' ">
        <button class="todo-toggle" (click)="toggleTodoList()">TODO</button>
        <app-todolist></app-todolist>
    </div>

</section>

