import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {PureOriginalResultsService} from "../../service/PureOriginalResults.service";

@Component({
  selector: 'app-requested-tunings',
  templateUrl: './requested-tunings.html',
  styleUrls: ['./requested-tunings.css']
})
export class RequestedTunings implements OnInit {

  constructor(private router: Router, public pureOriginalResultsService: PureOriginalResultsService) {

  }

  ngOnInit() {
    this.pureOriginalResultsService.getNumberOfPartialTunings();
    this.pureOriginalResultsService.getMostRequestedPartialTunings();
    this.pureOriginalResultsService.getMostRequestedMissingTunings();
  }


  openMostRequestedPartialTunings() {

    this.router.navigate(['/app/requested-tunings/requested-tunings-tables'],
      {queryParams: {type: 'partial'}});
  };

  openMostRequestedMissingTunings() {
    this.router.navigate(['/app/requested-tunings/requested-tunings-tables'],
      {queryParams: {type: 'missing'}}
    );
  }
}
