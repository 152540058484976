<section class="main">
    <div class="table-title">
        <div class="head-button-container">
            <div class="head-button">
                <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
            </div>
            <div class="head-button">
                <button class="r-btn" (click)="backToMainTable()"><i class="icon bi bi-arrow-left"></i></button>
            </div>
            <div class="master-details-title">
                <i class="bi bi-sliders"></i>{{ ecuProducer }} ~ {{ ecuBuild }} ~ {{ versionName }} ~ {{ dcf }}
            </div>
        </div>

        <div class="act-search-bar">
            <app-search-bar></app-search-bar>
        </div>
    </div>

    <div class="table-container"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         (scrolled)="onScroll()" *ngIf="pureOriginalsMatchingDetails.length > 0">
        <table>
            <tr class="header">
                <th *ngFor="let column of displayedColumns">{{ column | shorten: 45 }}</th>
                <th class="actions-header">Actions</th>
            </tr>
            <tr *ngFor="let element of pureOriginalsMatchingDetails; let i = index" class="body-columns">

                <td *ngFor="let column of displayedColumns">{{ element[column] | shorten: 1000 }}</td>
                <td class="actions">

                    <button class="table-buttons"
                            (click)="downloadVersionFromPureOriginals(+element.pureOriginalMatchingDetailsId, element.versionWinOLSReference)">
                        <i
                                class="bi bi-file-earmark-arrow-down"></i>
                    </button>
                    <button class="table-buttons car-button"
                            (click)="openPureOriginalDetails(+element.pureOriginalBaseId, element.tuningName)"><i
                            class="bi bi-car-front-fill"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
    <div *ngIf="pureOriginalsMatchingDetails.length < 1">
        <app-skeleton-table></app-skeleton-table>
    </div>
</section>
