<div class="main">
    <div class="main-container-wrapper">
        <div class="button-container">
            <button (click)="toggleInputRow()" class="add-row-btn"><i class="bi bi-plus-lg"></i>Row</button>
        </div>
        @defer (when !ecuProducersService.isLoading()) {
            <div class="table-container">

                <table>
                    <tr class="header">
                        <th>ID</th>
                        <th>Correct Ecu Producer</th>
                        <th>Accepted Ecu Producer</th>
                        <th>Actions</th>
                    </tr>

                    @if (ecuProducersService.showNewInputRow()) {
                        <tr>
                            <td>-</td>
                            <td>
                                <mat-form-field class="background-input">
                                    <mat-label>Correct Ecu Producer</mat-label>
                                    <input type="text" matInput [formControl]="correctEcuProducer"/>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="background-input">
                                    <mat-label>Accepted Ecu Producer</mat-label>
                                    <input type="text" matInput [formControl]="acceptedEcuProducer"/>
                                </mat-form-field>
                            </td>
                            <td>
                                @if (!ecuProducersService.isWaitingForResponse()) {
                                    <button (click)="saveNewRow()" class="row-btn"><i class="bi bi-check-lg"></i>
                                    </button>
                                    <button (click)="ecuProducersService.closeRow()" class="row-btn"><i
                                            class="bi bi-x-lg"></i></button>
                                } @else {
                                    <div class="spinner-wrapper">
                                        <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                    </div>
                                }

                            </td>

                        </tr>
                    }


                    @for (element of ecuProducersService.correctEcuProducers(); track $index) {

                        <tr class="body-columns">

                            <td>{{ element.id }}</td>

                            <td>
                                @if (ecuProducersService.ecuProducerIndex() !== $index) {
                                    <span class="tag" (dblclick)="toggleEditView($index, element)">
                                        {{ element.correctEcuProducer }}</span>

                                } @else {

                                    <mat-form-field class="background-input">
                                        <input matInput type="text" [value]="element.correctEcuProducer"
                                               [formControl]="correctEcuProducer"
                                               (keyup)="saveEditedItem($event, element.correctEcuProducer)"
                                               (keyup.enter)="editEcuProducer(element.id,element)"/>
                                    </mat-form-field>
                                }
                            </td>


                            <td>
                                @if (ecuProducersService.ecuProducerIndex() !== $index) {
                                    <span class="tag" (dblclick)="toggleEditView($index, element)">
                                        {{ element.acceptedEcuProducer }}</span>

                                } @else {

                                    <mat-form-field class="background-input">
                                        <input matInput type="text" [value]="element.acceptedEcuProducer"
                                               [formControl]="acceptedEcuProducer"
                                               (keyup)="saveEditedItem($event, element.acceptedEcuProducer)"
                                               (keyup.enter)="editEcuProducer(element.id, element)"/>
                                    </mat-form-field>
                                }
                            </td>
                            <td class="actions">
                                @if (ecuProducersService.ecuProducerIndex() !== $index) {
                                    <button class="table-buttons" (click)="deleteItemFromDatabase(element.id)"><i
                                            class="bi bi-trash"></i></button>
                                    <button class="table-buttons" [disabled]="!element.id"
                                            (click)="toggleEditView($index, element)"><i
                                            class="bi bi-pencil"></i>
                                    </button>
                                } @else {
                                    @if (!ecuProducersService.isWaitingForResponse()) {
                                        <button class="table-buttons"
                                                (click)="editEcuProducer(element.id, element)"><i
                                                class="bi bi-check-lg"></i></button>
                                        <button class="table-buttons" (click)="ecuProducersService.closeEditedItem()"><i
                                                class="bi bi-x-lg"></i>
                                        </button>
                                    } @else {
                                        <div class="spinner-wrapper">
                                            <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                        </div>
                                    }

                                }
                            </td>
                        </tr>
                    } @empty {
                        <ng-container>
                            <tr>
                                <td>
                                    -
                                </td>
                                <td style="min-width: 100%">
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    }

                </table>

            </div>
        } @placeholder (minimum 150ms) {
            <div class="skeleton-wrapper">
                <app-skeleton-table></app-skeleton-table>
            </div>
        }
    </div>
</div>

