import {
    Component, computed, ElementRef,
    EventEmitter,
    HostListener,
    inject,
    input,
    OnInit,
    Output, signal
} from '@angular/core';
import {PureOriginalService} from "../../../service/pure-original.service";
import {CarImageService} from "../../../service/CarImage.service";
import {PureOriginalsDetailsModel} from "../../../models/PureOriginalsDetails-model";
import {PureOriginalUserModel} from "../../../models/PureOriginalUser-model";
import {UpdatePureOriginalModel} from "../../../models/UpdatePureOriginal-model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../service/Notification.service";


@Component({
    selector: 'app-pure-originals-info',
    templateUrl: './pure-originals-info.component.html',
    styleUrls: ['./pure-originals-info.component.css']
})
export class PureOriginalsInfoComponent implements OnInit {

    @Output() public isFileModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    pureOriginalId = input<number>();
    buttonIsDisabled = input<boolean>();
    public isInputChanged = signal<boolean>(false);
    public isVisibleButton = signal<boolean>(false)
    private previousFormValues: any;
    error = input<string>();
    @Output() pureOriginalUser = new EventEmitter<PureOriginalUserModel>
    public pureOriginalService = inject(PureOriginalService);
    public carImageService = inject(CarImageService);
    public elRef = inject(ElementRef);
    public notificationService = inject(NotificationService);

    public pureOriginalDetailObject = computed(() => {
        return this.pureOriginalService.pureOriginalDetails();
    })

    pureOriginalDetailsFormGroup = new FormGroup(
        {
            id: new FormControl(''),
            vehicleProducer: new FormControl(''),
            vehicleSeries: new FormControl(''),
            vehicleModel: new FormControl(''),
            vehicleModelYear: new FormControl(''),
            vehicleBuild: new FormControl(''),
            ecuBuild: new FormControl(''),
            ecuProducer: new FormControl(''),
            ecuUse: new FormControl(''),
            engineDisplacement: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
            engineMaxTorque: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
            engineProducer: new FormControl(''),
            engineType: new FormControl(''),
            engineTransmission: new FormControl(''),
            outputPs: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
            outputKw: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
            vehicleType: new FormControl(''),
            ecuSoftwareVersionVersion: new FormControl(''),
            engineEmissionStandard: new FormControl(''),
            resellerProjectDetails: new FormControl(''),
            vehicleVin: new FormControl(''),
            vehicleCharacteristic: new FormControl(''),
            ecuStgNr: new FormControl(''),
            resellerProjectType: new FormControl(''),
            resellerCredits: new FormControl(''),
        }
    )

    public editFlags = {
        id:false,
        vehicleProducer: false,
        vehicleSeries: false,
        vehicleModel: false,
        vehicleModelYear: false,
        vehicleBuild: false,
        ecuBuild: false,
        ecuProducer: false,
        ecuUse: false,
        engineDisplacement: false,
        engineMaxTorque: false,
        engineProducer: false,
        engineType: false,
        engineTransmission: false,
        outputPs: false,
        outputKw: false,
        vehicleType: false,
        ecuSoftwareVersionVersion: false,
        engineEmissionStandard: false,
        resellerProjectDetails: false,
        vehicleVin: false,
        vehicleCharacteristic: false,
        ecuStgNr: false,
        resellerProjectType: false,
        resellerCredits: false,

};

    ngOnInit() {
        this.restartPureOriginalInfoDetails();
        this.initializeValueChangeSubscriptions();
        this.getPureOriginalsDetails();
    }

    restartPureOriginalInfoDetails() {
        this.pureOriginalService.pureOriginalDetails.set({} as PureOriginalsDetailsModel);
        this.carImageService.image = null
    }


    editFormValue(pureOriginalDetails: PureOriginalsDetailsModel) {
        this.pureOriginalDetailsFormGroup.setValue({
            id: pureOriginalDetails.id,
            vehicleProducer: pureOriginalDetails.vehicleProducer,
            vehicleSeries: pureOriginalDetails.vehicleSeries,
            vehicleModel: pureOriginalDetails.vehicleModel,
            vehicleModelYear: pureOriginalDetails.vehicleModelYear,
            vehicleBuild: pureOriginalDetails.vehicleBuild,
            ecuBuild: pureOriginalDetails.ecuBuild,
            ecuProducer:pureOriginalDetails.ecuProducer,
            ecuUse: pureOriginalDetails.ecuUse,
            engineDisplacement: pureOriginalDetails.engineDisplacement,
            engineMaxTorque: pureOriginalDetails.engineMaxTorque,
            engineProducer: pureOriginalDetails.engineProducer,
            engineType: pureOriginalDetails.engineType,
            engineTransmission: pureOriginalDetails.engineTransmission,
            outputPs: pureOriginalDetails.outputPs,
            outputKw: pureOriginalDetails.outputKw,
            vehicleType: pureOriginalDetails.vehicleType,
            ecuSoftwareVersionVersion: pureOriginalDetails.ecuSoftwareVersionVersion,
            engineEmissionStandard: pureOriginalDetails.engineEmissionStandard,
            resellerProjectDetails: pureOriginalDetails.resellerProjectDetails,
            vehicleVin: pureOriginalDetails.vehicleVin,
            vehicleCharacteristic: pureOriginalDetails.vehicleCharacteristic,
            ecuStgNr: pureOriginalDetails.ecuStgNr,
            resellerProjectType: pureOriginalDetails.resellerProjectType,
            resellerCredits: pureOriginalDetails.resellerCredits,

    });
        this.previousFormValues = this.pureOriginalDetailsFormGroup.value;
    }

    initializeValueChangeSubscriptions() {
        Object.keys(this.pureOriginalDetailsFormGroup.controls).forEach(key => {
            const control = this.pureOriginalDetailsFormGroup.get(key);
            control.valueChanges.subscribe(() => {
                this.isInputChanged.set(true);
            });
        });
    }

    getPureOriginalsDetails() {
        this.pureOriginalService.getPureOriginalDetails(this.pureOriginalId()).then((response) => {
            this.editFormValue(response as PureOriginalsDetailsModel);
            if (response.pureOriginalUserRecords !== null) {
                this.pureOriginalUser.emit(response.pureOriginalUserRecords)
            }
        },error => {
            if (error.status === 404) {
                this.pureOriginalService.errorMessage.set('File not found!');
            }})
    }

    openFileUploadModal() {
        this.isFileModalOpened.emit(true);
    }

    downloadVersionFile(id: number, winOls: string, tableName: string) {
        this.pureOriginalService.downloadFileFromDatabase(id, winOls, tableName)
    }

    isDownloadInProgress(id: number) {
        return this.pureOriginalService.isDownloadInProgress(id);
    }

    deletePureOriginalItem(id: number, tableName: string, pureOriginalDetailsOpened: boolean) {
        this.pureOriginalService.deletePureOriginalItem(id, tableName, pureOriginalDetailsOpened);
    }

    repeatPureOriginalsProcess(id: number) {
        this.pureOriginalService.repeatPureOriginalsProcess(id);
    }

    async editPureOriginalItem(id: string, editItem: PureOriginalsDetailsModel) {
        const response = await this.pureOriginalService.updatePureOriginalDetails(id, editItem);
        this.editFormValue(response as PureOriginalsDetailsModel);
    }


    async submitForm() {
        if (this.pureOriginalDetailsFormGroup.valid) {
            const formValues = this.pureOriginalDetailsFormGroup.value as UpdatePureOriginalModel;
            const id = this.pureOriginalDetailsFormGroup.get('id')?.value;
            try {
                const response = await this.pureOriginalService.editPureOriginalDetailsOnClick(+id, formValues);
                this.editFormValue(response as PureOriginalsDetailsModel);
            } catch (error) {
                this.cancelForm();
            }
            this.closeAllEdits();
        } else {
            return;
        }
    }



    toggleEdit(field: string | null, $event: any) {
        $event.stopPropagation();
        if (field === null) {
            this.closeAllEdits();
        } else {
            this.isVisibleButton.set(true);
            this.editFlags[field] = !this.editFlags[field];
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    closeAllEdits() {
        this.isVisibleButton.set(false);
        Object.keys(this.editFlags).forEach(key => {
            this.editFlags[key] = false;
        });
    }

    cancelForm() {
        this.pureOriginalDetailsFormGroup.reset(this.previousFormValues);
        this.closeAllEdits()
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
        const element = this.elRef.nativeElement.querySelector('.matching-item-text');
        if (element && !element.contains(event.target as Node)) {
            this.closeAllEdits();
        }
    }

    uploadIni(event: any) {
        const file = event.target.files[0];
        this.pureOriginalService.uploadIniFile(file, this.pureOriginalId())
            .then(response => this.editFormValue(response))
            .catch(error => {
                console.error('Error uploading INI file:', error);
            });
    }

}
export class CustomPureOriginalsUploadFileValidations {
    message: string;
    hasError: boolean;
}


