import {Component} from '@angular/core';
import {AuthService} from "../service/auth.service";
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class DashboardComponent {

  public todoOpened = false;

  constructor(private authService: AuthService) {}

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
  isUser () {
    return this.authService.isUser();
  }

  isModerator () {
    return this.authService.isModerator();
  }
  toggleTodoList() {
    this.todoOpened = !this.todoOpened;

  }

}
