import {CarImageServiceModel} from "../models/CarImageService-model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: "root"})
export class CarImageService {

    private apiUrl = "https://cdn.imagin.studio";
    private customerKey = "dyno-chiptuningfiles";
    public image: any;

    constructor(private httpClient: HttpClient) {
    }

    getImage(carDTO: CarImageServiceModel) {

        const params = {
            make: carDTO.make,
            modelFamily: carDTO.modelFamily,
            modelYear: carDTO.modelYear,
            modelRange: carDTO.modelRange,
            width: carDTO.width
        }

        // return "No Car image";
        return this.httpClient.get(`${this.apiUrl}/getImage?customer=${this.customerKey}`, {
            params,
            responseType: 'blob',
            observe: 'response'
        }).subscribe((response) => {
            this.createImageFromBlob(response.body);
        })

    }


    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.image = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

}
