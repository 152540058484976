import {Component, HostListener, inject, OnInit, signal} from '@angular/core';
import { WinOlsTransactionService } from "../../service/winols-transaction.service";
import { CommonModule } from '@angular/common';
import {NgbProgressbar} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-win-ols-cards',
  standalone: true,
  imports: [CommonModule, NgbProgressbar],
  templateUrl: './win-ols-cards.component.html',
  styleUrls: ['./win-ols-cards.component.css']
})
export class WinOlsCardsComponent implements OnInit {
  status = signal<string>('')
  router = inject(Router);
  route = inject(ActivatedRoute);

  winOlsTransactionService = inject(WinOlsTransactionService);

  ngOnInit() {

  }

  viewDetails(status?: string) {
    this.router.navigate(['/app/winOls-transactions'], {
      queryParams: {
        startDate: this.winOlsTransactionService.startDate.toISOString(),
        endDate: this.winOlsTransactionService.endDate.toISOString(),
        status: status,
      }, relativeTo: this.route
    });
  }

  @HostListener('window:resize', ['$event'])
  isMobile(): boolean {
    return window.innerWidth < 768
  }

}

