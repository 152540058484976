<div class="system-info-wrapper">

    @defer () {
        <div class="system-info-cards-wrapper">
            @for (data of statisticService.systemInfoData(); track $index) {
                <div class="system-info-container">
                    <div class="cpu-container">
                        <app-cpu-details-component [cpuUsage]="data.cpuUsage"
                                                   [cpuCores]="data.numberOfCpuCores"></app-cpu-details-component>
                    </div>
                    <div class="all-info-wrapper">
                        <div class="all-info-container-title">
                            <span class="server-name-title">Server Name</span>
                            <h2 class="server-card-title">{{ data.serverName }}<span class="status-bar-online"
                                                                                     *ngIf="data.online"></span><span
                                    class="status-bar-offline" *ngIf="!data.online"></span></h2>
                        </div>
                        <div class="all-info-container">
                            <app-disk-space-component [totalDiskSpace]="data.totalDiskSpace"
                                                      [freeDiskSpace]="data.freeDiskSpace"></app-disk-space-component>
                            <app-heap-server-memory-component [maxHeapMemory]="data.maxHeapMemory"
                                                              [freeHeapMemory]="data.freeHeapMemory"></app-heap-server-memory-component>
                        </div>
                    </div>
                </div>
            }
        </div>

    } @placeholder (minimum 150ms) {
        <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
    }
</div>
