<div class="server-stats-container">

    <!--  FIRST CONTAINER-->

    <span class="server-name">Server: -</span>

    <div class="progress-bar-container">
        <div class="heap-progress-bar-wrapper">
            <div class="heap-progress-bar-title"><span class="usedHeapMemory memory-size">- gb</span><span
                    class="property-title">Heap Memory</span><span class="maxHeapMemory memory-size">- gb</span></div>
            <div class="heap-progress-bar-custom" style="--width: 0; --pseudoEl: 'var(--primary-clr)'"
                 [ngStyle]="{'--width' : 0, 'background-color' : 'var(--primary-clr)'}">
            </div>
        </div>

        <div class="heap-progress-bar-wrapper">
            <div class="heap-progress-bar-title"><span class="usedHeapMemory memory-size">- gb</span><span
                    class="property-title">Heap Memory</span><span class="maxHeapMemory memory-size">- gb</span></div>
            <div class="heap-progress-bar-custom" style="--width: 0; --pseudoEl: 'var(--primary-clr)'"
                 [ngStyle]="{'--width' : 0, 'background-color' : 'var(--primary-clr)'}">
            </div>
        </div>
    </div>


    <span class="server-name">Server: -</span>

    <div class="progress-bar-container">

        <div class="progress-bar-wrapper">
            <div class="progress-bar-title"><span class="usedDiskSpace memory-size"></span><span class="property-title"><span>Cpu Cores</span> <span>0%</span></span><span
                    class="totalDiskSpace memory-size"></span>
            </div>
            <div class="progress-bar-custom" style="--width: 0 ; --pseudoEl: 'var(--primary-clr)'"
                 [ngStyle]="{'--width' : 0, 'background-color' : 'var(--primary-clr)'}">
            </div>
        </div>

        <div class="progress-bar-wrapper">
            <div class="progress-bar-title"><span class="usedDiskSpace memory-size"></span><span class="property-title"><span>Cpu Cores</span> <span>0%</span></span><span
                    class="totalDiskSpace memory-size"></span>
            </div>
            <div class="progress-bar-custom" style="--width: 0 ; --pseudoEl: 'var(--primary-clr)'"
                 [ngStyle]="{'--width' : 0, 'background-color' : 'var(--primary-clr)'}">
            </div>
        </div>
    </div>


</div>
