
<div>

    <div class="title" mat-dialog-title>
        <h2>Add new Ecu Producer</h2>
    </div>
    <div class="underline"></div>


    <mat-dialog-content [formGroup]="addNewEcuProducerForm" (keydown.enter)="saveNewRow()">
        <mat-form-field class="input-with-button">
            <mat-label>Correct Ecu Producer Names</mat-label>
            <input matInput type="text" formControlName="correctEcuProducer"/>
            <mat-error *ngIf="addNewEcuProducerForm?.controls['correctEcuProducer'].hasError('required')">
                Please enter a correct Ecu Producer name.
            </mat-error>
            <mat-error *ngIf="addNewEcuProducerForm?.controls['correctEcuProducer']?.hasError('ecuProducerExists')">
                EcuProducer already exists in Database.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="input-with-button">
            <mat-label>Accepted Ecu Producer Names</mat-label>
            <input matInput type="text" formControlName="acceptedEcuProducer"/>
            <mat-error *ngIf="addNewEcuProducerForm.controls['acceptedEcuProducer'].hasError('required')">
                Please enter a accepted Ecu Producer.
            </mat-error>
        </mat-form-field>
        <div mat-dialog-actions class="button-actions">
            <button mat-raised-button class="dialog-save-btn"
                    (click)="saveNewRow()">Save
            </button>
            <button mat-raised-button class="dialog-btn" (click)="canceledOption()">Close</button>
        </div>
    </mat-dialog-content>

</div>
