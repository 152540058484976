export class ResellerRecordsModel {
  public resellerid: string;
  public originalid: string;
  public uploadedby: string;
  public dateuploaded: string;
  public filename: string;
  public status: string;
  public clientname: string;
  public clientnumber: string;
  public clientlicenceplace: string;
  public vehicletype: string;
  public vehicleproducer: string;
  public vehicleseries: string;
  public vehiclemodel: string;
  public vehiclemodelyear: string;
  public ecuuse: string;
  public ecuproducer: string;
  public ecubuild: string;
  public ecuprodnr: string;
  public ecusoftwareversion: string;
  public enginename: string;
  public enginedisplacement: string;
  public vin: string;
  public vehiclebuild: string;
  public vehiclecharacteristic: string;
  public ecustgnr : string;
  public ecuprocessor: string;
  public engineproducer: string;
  public enginetype: string;
  public enginemaxtorque: string;
  public engineemission: string;
  public enginegear: string;
  public projectcreatedby: string;
  public projectcreatedbydate: string;
  public projectchangedby: string;
  public projectchangedbydate: string;
  public projectfile: string;
  public projectfolder: string;
  public projectfoldercloud: string;
  public projectsize: string;
  public resellercredits: string;
  public resellerprojecttype: string;
  public outputps: string;
  public outputkw : string;
  public enginetransmission: string;
  public readinghardware: string;
  public resellerprojectdetails: string;
  public winolsreference: string;

  constructor() {
    this.resellerid = "resellerid";
    this.status = "status";
    this.originalid = "originalid";
    this.uploadedby = "uploadedby";
    this.dateuploaded = "dateuploaded";
    this.filename = "filename";
    this.clientname = "clientname";
    this.clientnumber = "clientnumber";
    this.clientlicenceplace = "clientlicenceplace";
    this.vehicletype = "vehicletype";
    this.vehicleproducer = "vehicleproducer";
    this.vehicleseries = "vehicleseries";
    this.vehiclemodel = "vehiclemodel";
    this.vehiclemodelyear = "vehiclemodelyear";
    this.ecuuse = "ecuuse";
    this.ecuproducer = "ecuproducer";
    this.ecubuild = "ecubuild";
    this.ecuprodnr = "ecuprodnr";
    this.ecusoftwareversion = "ecusoftwareversion";
    this.enginename = "enginename";
    this.enginedisplacement = "enginedisplacement";
    this.vin = "vin";
    this.vehiclebuild = "vehiclebuild";
    this.vehiclecharacteristic = "vehiclecharacteristic";
    this.ecustgnr = "ecustgnr";
    this.ecuprocessor = "ecuprocessor";
    this.engineproducer = "engineproducer";
    this.enginetype = "enginetype";
    this.enginemaxtorque = "enginemaxtorque";
    this.engineemission = "engineemission";
    this.enginegear = "enginegear";
    this.projectcreatedby = "projectcreatedby";
    this.projectcreatedbydate = "projectcreatedbydate";
    this.projectchangedby = "projectchangedby";
    this.projectchangedbydate = "projectchangedbydate";
    this.projectfile = "projectfile";
    this.projectfolder = "projectfolder";
    this.projectfoldercloud = "projectfoldercloud";
    this.projectsize = "projectsize";
    this.resellercredits = "resellercredits";
    this.resellerprojecttype = "resellerprojecttype";
    this.outputps = "outputps";
    this.outputkw = "outputkw";
    this.enginetransmission = "enginetransmission";
    this.readinghardware = "readinghardware";
    this.resellerprojectdetails = "resellerprojectdetails";
    this.winolsreference = "winolsreference";
  }
}
