<div mat-dialog-title class="title">
    <h2>History of {{ data['text'] }} Master item</h2>
</div>
<div class="underline"></div>
<mat-dialog-content class="dialog-content">
    <div class="table-container">
        <table>
            <tr class="header">
                <th>First Name</th>
                <th>Last Name</th>
                <th>Status</th>
                <th>Work Time</th>
                <th>Version Name</th>
                <th>Version Comment</th>
            </tr>


            @for (element of data.content; track $index) {
                <tr class="body-columns">
                    <td>
                        {{ element.firstName }}
                    </td>
                    <td>
                        {{ element.lastName }}
                    </td>
                    <td>
                        {{ element.status }}
                    </td>
                    <td>
                        {{ element.workTime }}
                    </td>
                    <td>
                        {{ element.versionName }}
                    </td>

                    <td>
                        {{ element.versionComment }}
                    </td>

                </tr>
            }
        </table>
    </div>
</mat-dialog-content>
