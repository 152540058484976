import {Component, EventEmitter, Inject, OnInit, Output, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UpdatePureOriginalModel} from "../../models/UpdatePureOriginal-model";
import {Clipboard} from "@angular/cdk/clipboard";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AddDialogComponent} from "../../helpers/add-dialog/add-dialog.component";
import {CorrectEcuProducersModel, EcuBuildNameModel} from "../../models/EcuBuildName-model";
import {AddEcuProducerDialogComponent} from "../../helpers/add-ecu-producer-dialog/add-ecu-producer-dialog.component";
import {PureOriginalService} from "../../service/pure-original.service";

@Component({
    selector: 'app-edit-pure-original-dialog',
    templateUrl: './edit-pure-original-dialog.component.html',
    styleUrls: ['./edit-pure-original-dialog.component.css']
})
export class EditPureOriginalDialogComponent implements OnInit {
    private contentToShow: UpdatePureOriginalModel;
    public prevPureOriginalFormState: string;
    public ecuBuild: EcuBuildNameModel;
    public ecuProducer: CorrectEcuProducersModel;
    public searchQuery: string = '';

    @Output() pureOriginalEcuBuildChangeEmitter = new EventEmitter<string>();

    @Output() pureOriginalEcuProducerChangeEmitter = new EventEmitter<string>();

    constructor(private pureOriginalService: PureOriginalService, private clipboard: Clipboard, private dialog: MatDialog, public dialogRef: MatDialogRef<EditPureOriginalDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
        id: string,
        text: string,
        content: UpdatePureOriginalModel
    }, private renderer: Renderer2) {

        this.getEcuName();
        this.getEcuProducerName();
    }

    editPureOriginalForm = new FormGroup({
        id: new FormControl(""),
        vehicleProducer: new FormControl('', Validators.required),
        vehicleSeries: new FormControl(''),
        vehicleModel: new FormControl(''),
        vehicleModelYear: new FormControl(''),
        vehicleBuild: new FormControl(''),
        winOlsDbName: new FormControl(''),
        vehicleType: new FormControl(''),
        ecuSoftwareVersion: new FormControl(''),
        ecuUse: new FormControl(''),
        ecuBuild: new FormControl('', Validators.required),
        ecuProducer: new FormControl('', Validators.required),
        engineDisplacement: new FormControl(''),
        engineMaxTorque: new FormControl(''),
        engineName: new FormControl(''),
        engineProducer: new FormControl(''),
        engineType: new FormControl('', Validators.required),
        engineTransmission: new FormControl(''),
        outputPs: new FormControl(''),
        outputKw: new FormControl(''),
        ecuSoftwareVersionVersion: new FormControl(''),
        engineEmissionStandard: new FormControl(''),
        resellerProjectDetails: new FormControl(''),
        vehicleVin: new FormControl(''),
        ecuProdNr: new FormControl(''),
        vehicleCharacteristic: new FormControl(''),
        ecuStgNr: new FormControl(''),
        resellerProjectType: new FormControl(''),
        resellerCredits: new FormControl(''),
        projectComment: new FormControl(''),
        versionComment: new FormControl(''),
        clientName: new FormControl(''),
        clientNumber: new FormControl(''),
        clientLicencePlace: new FormControl(''),
        readingHardware: new FormControl(''),
        engineEmission: new FormControl(''),
        creditType: new FormControl(''),
        projectType: new FormControl(''),
        userDef1: new FormControl(''),
        userDef2: new FormControl(''),
        userDef3: new FormControl(''),
        userDef4: new FormControl(''),
        userDef5: new FormControl(''),
        userDef6: new FormControl(''),
        userDef7: new FormControl(''),
        userDef11: new FormControl(''),
        userDef12: new FormControl(''),
        userDef13: new FormControl(''),
        userDef14: new FormControl(''),
        userDef15: new FormControl(''),
        userDef16: new FormControl(''),
        userDef17: new FormControl(''),
        userDef18: new FormControl(''),
        userDef19: new FormControl(''),
        userDef20: new FormControl(''),
    })


    ngOnInit() {

        this.contentToShow = this.data['content'];
        this.editFormValue();
        this.editPureOriginalForm.get('ecuBuild').markAsTouched();
        this.editPureOriginalForm.get('ecuBuild').setErrors({'valid': true});
        this.editPureOriginalForm.get('ecuProducer').markAsTouched();
        this.editPureOriginalForm.get('ecuProducer').setErrors({'valid': true});
        this.editPureOriginalForm.get('ecuBuild').markAsTouched();
        this.editPureOriginalForm.get('engineType').markAsTouched();
        this.editPureOriginalForm.get('engineType').setErrors({'valid': true});
        this.getEcuName();
        this.getEcuProducerName();
    }


    editFormValue() {
        this.prevPureOriginalFormState = this.contentToShow.ecuBuild;
        this.editPureOriginalForm.setValue({
            id: this.data.id,
            ecuBuild: this.contentToShow.ecuBuild,
            winOlsDbName: this.contentToShow.winOlsDbName,
            ecuProducer: this.contentToShow.ecuProducer,
            ecuSoftwareVersion: this.contentToShow.ecuSoftwareVersion,
            ecuUse: this.contentToShow.ecuUse,
            engineDisplacement: this.contentToShow.engineDisplacement,
            engineMaxTorque: this.contentToShow.engineMaxTorque,
            engineName: this.contentToShow.engineName,
            engineProducer: this.contentToShow.engineProducer,
            engineTransmission: this.contentToShow.engineTransmission,
            engineType: this.contentToShow.engineType,
            outputKw: this.contentToShow.outputKw,
            outputPs: this.contentToShow.outputPs,
            vehicleBuild: this.contentToShow.vehicleBuild,
            vehicleModel: this.contentToShow.vehicleModel,
            vehicleModelYear: this.contentToShow.vehicleModelYear,
            vehicleProducer: this.contentToShow.vehicleProducer,
            vehicleSeries: this.contentToShow.vehicleSeries,
            vehicleType: this.contentToShow.vehicleType,
            ecuSoftwareVersionVersion: this.contentToShow.ecuSoftwareVersionVersion,
            engineEmissionStandard: this.contentToShow.engineEmissionStandard,
            resellerProjectDetails: this.contentToShow.resellerProjectDetails,
            vehicleVin: this.contentToShow.vehicleVin,
            ecuProdNr: this.contentToShow.ecuProdNr,
            vehicleCharacteristic: this.contentToShow.vehicleCharacteristic,
            ecuStgNr: this.contentToShow.ecuStgNr,
            resellerProjectType: this.contentToShow.resellerProjectType,
            resellerCredits: this.contentToShow.resellerCredits,
            projectComment: this.contentToShow.projectComment,
            versionComment: this.contentToShow.versionComment,
            clientName: this.contentToShow.clientName,
            clientNumber: this.contentToShow.clientNumber,
            clientLicencePlace: this.contentToShow.clientLicencePlace,
            readingHardware: this.contentToShow.readingHardware,
            engineEmission: this.contentToShow.engineEmission,
            creditType: this.contentToShow.creditType,
            projectType: this.contentToShow.projectType,
            userDef1: this.contentToShow.userDef1,
            userDef2: this.contentToShow.userDef2,
            userDef3: this.contentToShow.userDef3,
            userDef4: this.contentToShow.userDef4,
            userDef5: this.contentToShow.userDef5,
            userDef6: this.contentToShow.userDef6,
            userDef7: this.contentToShow.userDef7,
            userDef11: this.contentToShow.userDef11,
            userDef12: this.contentToShow.userDef12,
            userDef13: this.contentToShow.userDef13,
            userDef14: this.contentToShow.userDef14,
            userDef15: this.contentToShow.userDef15,
            userDef16: this.contentToShow.userDef16,
            userDef17: this.contentToShow.userDef17,
            userDef18: this.contentToShow.userDef18,
            userDef19: this.contentToShow.userDef19,
            userDef20: this.contentToShow.userDef20,
        })
    }

    getEcuName() {
        this.editPureOriginalForm.get('ecuBuild').valueChanges.subscribe(response => {
            this.searchQuery = response
        });
    }

    getEcuProducerName() {
        this.editPureOriginalForm.get('ecuProducer').valueChanges.subscribe(response => {
            this.searchQuery = response
        })
    }

    focusOnDialogElement() {
        const firstDialogElement = this.renderer.selectRootElement('#firstDialogElement');
        if (firstDialogElement) {
            firstDialogElement.focus();
        }
    }

    addNewEcu() {
        this.dialog.open(AddDialogComponent, {
            width: "1000px",
            data: {ecuBuildValue: this.editPureOriginalForm.get('ecuBuild').value}
        }).afterClosed().subscribe((value: {
            closed: boolean,
            ecu: EcuBuildNameModel
        }) => {
            if (value.closed) {
                this.editPureOriginalForm.patchValue({
                    ecuBuild: value.ecu.correctEcuBuildNames
                })
                this.ecuBuild = value.ecu;
                this.focusOnDialogElement();
            }
        });
    }

    focusOnEcuProducerElement() {
        const secondDialogElement = this.renderer.selectRootElement('#secondDialogElement');
        if (secondDialogElement) {
            secondDialogElement.focus();
        }
    }

    addNewEcuProducer() {
        this.dialog.open(AddEcuProducerDialogComponent, {
            width: "800px",
            data: {ecuProducerValue: this.editPureOriginalForm.get('ecuProducer').value}
        }).afterClosed().subscribe((value: {
            closed: boolean,
            ecuProducer: CorrectEcuProducersModel
        }) => {
            if (value.closed) {
                this.editPureOriginalForm.patchValue({
                    ecuProducer: value.ecuProducer.correctEcuProducer,
                })
                this.ecuProducer = value.ecuProducer;
                this.focusOnEcuProducerElement();
            }
        });
    }


    canceledOption() {
        this.dialogRef.close({closed: false});
    }

    confirmedOption() {
        this.onEcuBuildChange();
        const content = this.editPureOriginalForm.value as UpdatePureOriginalModel;
        const id = this.data.id;
        this.dialogRef.close({
            closed: true,
            content: content,
            id: id,
            prevEcuBuildState: this.prevPureOriginalFormState
        });
    }

    onEcuBuildChange() {
        const ecuBuildValue = this.editPureOriginalForm.get('ecuBuild')?.value;
        this.pureOriginalEcuBuildChangeEmitter.emit(ecuBuildValue)
    }

    getCorrectEcuName($event: any) {
        this.editPureOriginalForm.patchValue({
            ecuBuild: $event.valueOf()
        });
    }
}
