import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PureOriginalsResultModel} from "../models/PureOriginalsResult-model";
import {PureOriginalMatchingModel} from "../models/PureOriginalMatching-model";
import {MostRequestedMissingTuning} from "../models/Most-Requested-Tunings";
import {NumberOfAllTunings} from "../models/NumberOfAllTunings";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {Location} from "@angular/common";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {NotificationService} from "./Notification.service";
import {SearchFilterModel} from "../models/SearchFilter-model";

@Injectable({
    providedIn: 'root'
})

export class PureOriginalResultsService {
    private url = `${this.API_URL}`;

    public pureOriginalResult = signal<PureOriginalsResultModel>(new PureOriginalsResultModel());
    public displayedOriginalColumns = signal<(keyof PureOriginalsResultModel)[]>([]);

    public displayedColumns = signal<(keyof MostRequestedMissingTuning)[]>([]);
    public displayedTableHeaders = signal<string[]>([]);
    public allPureOriginalResults = signal<PureOriginalsResultModel[]>([]);
    public pureOriginalMatching = signal<PureOriginalMatchingModel[]>([]);

    public mostRequestedTunings = signal<MostRequestedMissingTuning[]>([]);
    public numberOfMissingTunings = signal<NumberOfAllTunings>(new NumberOfAllTunings(0, 0));
    public type = signal<string>('');
    public tableTitle = signal<string>('');

    public pageNumber = signal<number>(0);
    public pageSize = signal<number>(50);
    public sortAttribute = signal<string>("id");
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: 'id', direction: 'desc'});
    public isLoading = signal<boolean>(false);
    public openAdvanceSettings = signal<boolean>(false);
    public searchString = signal<string>('');
    public selectedFilters = signal<SearchFilterModel[]>([]);


    deleteService = inject(DeleteFileFromDatabaseService);
    notificationService = inject(NotificationService);

    httpClient = inject(HttpClient);
    location = inject(Location);
    dialog = inject(MatDialog);

    constructor(@Inject("API_URL") private API_URL: string) {
        if (localStorage.getItem('sortDirectionValues')) {
            this.sortDirectionValues.set(JSON.parse(localStorage.getItem('sortDirectionValues')));
        }
    }

    openAdvancedSearchSettings() {
        this.openAdvanceSettings.set(!this.openAdvanceSettings());
    }


    getMostRequestedTableHeaders() {
        let objectKeys = Object.keys(new MostRequestedMissingTuning());
        for (let key of objectKeys) {
            this.displayedColumns().push(key as keyof MostRequestedMissingTuning)
        }
        this.displayedTableHeaders.set([...this.displayedColumns()]);
    }

    resetMostRequestedTuningsTable() {
        this.displayedColumns.set([]);
        this.displayedTableHeaders.set([]);
        this.mostRequestedTunings.set([]);
        this.pageSize.set(50);
        this.pageNumber.set(0);
    }

    resetTable() {
        this.pageNumber.set(0);
        this.allPureOriginalResults.set([]);
        this.getPureOriginalResults();
    }

    getTableKeysForPureOriginalResults() {
        let objectKeys = Object.keys(this.pureOriginalResult());
        for (let key of objectKeys) {
            this.displayedOriginalColumns().push(key as keyof PureOriginalsResultModel);
        }
    }

    getPureOriginalResults() {
        this.httpClient.get<{content:PureOriginalsResultModel[]}>(`${this.url}/pureOriginals/pureOriginalResults`, {
            params: this.sortedParams(this.sortDirectionValues())
        }).subscribe(response => {
            this.allPureOriginalResults.update((pureOriginalResults) => [...pureOriginalResults, ...response["content"]]);
        })
    }

    getPureOriginalCompletelyMatchedEcu(ecuBuild: string) {
        this.httpClient.get<PureOriginalMatchingModel[]>(`${this.url}/pureOriginals/pureOriginalMatchingStatsCompletelyTunedWithEcuBuild/${this.pageNumber()}/${this.pageSize()}/${this.sortAttribute()}/${ecuBuild}`).subscribe(response => {
            this.pureOriginalMatching.update((pureOriginalMatching) => [...pureOriginalMatching, ...response]);
        })
    }

    getPureOriginalMatchingStatsByHitRateAndEcuBuild(from: number, to: number, ecuBuild: string) {
        this.httpClient.get<PureOriginalMatchingModel[]>(`${this.url}/pureOriginals/pureOriginalMatchingStatsByEcuBuildAndHitRate/${this.pageNumber()}/${this.pageSize()}/${this.sortAttribute()}/${from}/${to}/${ecuBuild}`).subscribe(response => {
            this.pureOriginalMatching.update((pureOriginalMatching) => [...pureOriginalMatching, ...response]);
        })
    }

    sortedParams(sortedParams: SortDirectionValuesModel) {
        return new HttpParams()
            .set('pageNumber', this.pageNumber())
            .set('pageSize', this.pageSize())
            .set('sortAttribute', sortedParams.active)
            .set('sortDirection', sortedParams.direction);
    }

    getMostRequestedPartialTunings() {
        this.isLoading.set(true);
        this.tableTitle.set("Most Requested Partial Tuning Table");
        this.httpClient.get<{
            content: MostRequestedMissingTuning[]
        }>(`${this.url}/pureOriginals/listAllMostRequestedPartiallyTuningsSortedByAttribute`, {
            params: this.sortedParams(this.sortDirectionValues())
        }).subscribe(response => {
            this.mostRequestedTunings.update((mostRequestedTunings) => [...mostRequestedTunings, ...response["content"]]);
            this.isLoading.set(false);
        })
    }

    getMostRequestedMissingTunings() {
        this.isLoading.set(true);
        this.tableTitle.set("Most Requested Missing Tuning Table");
        this.httpClient.get<{
            content: MostRequestedMissingTuning[]
        }>(`${this.url}/pureOriginals/listAllMostRequestedMissingTuningsSortedByAttribute`,
            {
                params: this.sortedParams(this.sortDirectionValues())
            }).subscribe(response => {
            this.mostRequestedTunings.update((mostRequestedTunings) => [...mostRequestedTunings, ...response["content"]]);
            this.isLoading.set(false);
        })
    }

    getMostRequestedTuningsFiltered(filters: SearchFilterModel[]) {
        return this.httpClient.post<MostRequestedMissingTuning[]>(`${this.url}/pureOriginals/listMostRequestedMissingTuningsFiltered`, filters, {
            params: this.sortedParams(this.sortDirectionValues())
        })
    }

    deleteMissingTuningFromTable(id: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteService.deleteFileFromDatabase(id, tableName).subscribe((response) => {
                    console.log(id, tableName)
                    if (response.status === 200) {
                        this.mostRequestedTunings.update((missing) => missing.filter((tuning) => +tuning.id !== id));
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error["message"]}`,);
                });
            }
        })
    }


    getNumberOfPartialTunings() {
        this.httpClient.get<NumberOfAllTunings>(`${this.url}/pureOriginals/numberOfMissingAndPartialTunings`).subscribe(value => {
            this.numberOfMissingTunings.set(value)
        })
    }

    changeSortDirection() {
        if (this.type() === 'partial') {
            this.changeSortDirectionAndAttributePartialTunings(this.sortDirectionValues());
        } else if (this.type() === 'missing') {
            this.changeSortDirectionAndAttributeMissingTunings(this.sortDirectionValues());
        }
    }

    changeSortingAttributes(sortEvent: SortDirectionValuesModel) {
        localStorage.setItem("sortDirectionValues", JSON.stringify(sortEvent));
        this.sortAttribute.set(sortEvent.active);
        this.pageNumber.set(0);
        this.pageSize.set(50);
        return this.sortedParams(sortEvent);
    }

    changeSortDirectionAndAttributePartialTunings(sortEvent: SortDirectionValuesModel) {
        const sortedParams = this.changeSortingAttributes(sortEvent);
        this.httpClient.get<MostRequestedMissingTuning[]>(`${this.url}/pureOriginals/listAllMostRequestedPartiallyTuningsSortedByAttribute`, {params: sortedParams}).subscribe(response => {
            this.mostRequestedTunings.set(response["content"]);
        })
    }

    changeSortDirectionAndAttributeMissingTunings(sortEvent: SortDirectionValuesModel) {
        const sortedParams = this.changeSortingAttributes(sortEvent);
        this.httpClient.get<MostRequestedMissingTuning[]>(`${this.url}/pureOriginals/listAllMostRequestedMissingTuningsSortedByAttribute`, {params: sortedParams}).subscribe(response => {
            this.mostRequestedTunings.set(response["content"]);
        })
    }

    triggerOnScroll() {
        this.increasePageNumber();
        this.getPureOriginalResults();
    }

    onScrollMostRequestedTables() {
        console.log("triggered", this.pageNumber(), this.type());
        this.increasePageNumber();
        if (this.type() === "partial") {
            this.getMostRequestedPartialTunings();
        } else if (this.type() === "missing") {
            this.getMostRequestedMissingTunings();
        }
    }

    increasePageNumber() {
        this.pageNumber.update((pageNum) => pageNum + 1);
    }

    backToMainTable() {
        this.location.back();
    }
}

