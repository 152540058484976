import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
  @HostBinding('class.fileover') fileOver: boolean | undefined;
  @Output() fileDropped:EventEmitter<File> = new EventEmitter()
  @Output() multipleFilesSelected: EventEmitter<boolean> = new EventEmitter();

  constructor() { }


  @HostListener('dragover', ['$event']) onDragOver(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
    console.log('Drag over')
  }

  @HostListener('dragleave', ['$event']) public onDragLeave (event:any) {
    event.preventDefault();
    event.stopPropagation();

    console.log("Drag Leave!!!")
  }


  @HostListener('drop', ['$event']) onDropFile(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;

    if (files.length === 1 ) {
      console.log(`You Dropped ${files.length} files.`)
      this.fileDropped.emit(files[0]);
      this.multipleFilesSelected.emit(false);
    }
    if (files.length > 1 ) {
      this.multipleFilesSelected.emit(true);
    }
  }

}
