import {Component, Input} from '@angular/core';
import {IdentifyStockFileModel} from "../../../models/IdentifyStockFile-model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-input-car-details',
  templateUrl: './user-input-car-details.component.html',
  styleUrls: ['./user-input-car-details.component.css']
})
export class UserInputCarDetailsComponent {

  @Input() stockFileDTO: IdentifyStockFileModel;
  @Input() carImage: string;
  @Input() imageIsLoading: boolean;
  constructor(private router: Router) {}

  openPureOriginalDetails(id: number)  {
    this.router.navigate([`/app/pure-originals/pure-original-details`], {queryParams: {pureOriginalId: id}})

     }

}



