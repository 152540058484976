<div class="main-display">
  <div class="login">
    <form class="form"
          [formGroup]="userSignUpForm">
      <div class="form-title">
        <h2>Sign Up</h2>
        <span>Please enter your details.</span>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="First Name" formControlName="firstName"/>

        <div class="error-message"
             *ngIf="userSignUpForm.get('firstName')?.invalid && (userSignUpForm.get('firstName')?.touched || userSignUpForm.get('firstName')?.dirty)">
          <div *ngIf="userSignUpForm.get('firstName').hasError('required')">
            Required field.
          </div>
        </div>

      </div>
      <div class="form-group">
        <input class="form-control" type="text" placeholder="Last Name" formControlName="lastName"/>
        <div class="error-message"
             *ngIf="userSignUpForm.get('lastName')?.invalid && (userSignUpForm.get('lastName')?.touched || userSignUpForm.get('lastName')?.dirty)">
          <div *ngIf="userSignUpForm.get('lastName').hasError('required')">
            Required field.
          </div>
        </div>


      </div>
      <div class="form-group">
        <input class="form-control" type="email" placeholder="E-mail" formControlName="email" (blur)="onBlurMethod()"/>

        <div class="lds-ellipsis" *ngIf="!userSignUpForm.controls.email.value"><div></div><div></div><div></div><div></div></div>
        <div class="error-message" *ngIf="isEmailInputTouched">
          <div *ngIf="userSignUpForm.get('email').hasError('emailExists')">
            Email is already taken
          </div>
        </div>
        <!--    Email error handling-->
        <div class="error-message"
             *ngIf="userSignUpForm.get('email')?.invalid && (userSignUpForm.get('email')?.touched || userSignUpForm.get('email')?.dirty)">
          <div *ngIf="userSignUpForm.get('email').hasError('email')">
            Please enter valid email address.
          </div>
          <div *ngIf="userSignUpForm.get('email').hasError('required')">
            Required field.
          </div>
        </div>
      </div>


        <div class="form-group">
            <input type="password" class="form-control" placeholder="Enter new password" formControlName="newPassword"/>
            <div class="error-message"
                 *ngIf="userSignUpForm.get('newPassword')?.invalid && (userSignUpForm.get('newPassword')?.touched || userSignUpForm.get('newPassword')?.dirty)">
                <div *ngIf="userSignUpForm.get('newPassword').hasError('required')">
                    Required field.
                </div>
                <div *ngIf="userSignUpForm.get('newPassword').hasError('minLength')">
                    Password must be at least 8 characters long.
                </div>
                <div *ngIf="userSignUpForm.get('newPassword').hasError('pattern')">
                    Password must contain capital letter, symbol and number.
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="Confirm password"
                   formControlName="confirmNewPassword"/>
            <div class="error-message"
                 *ngIf="userSignUpForm.get('confirmNewPassword')?.invalid && (userSignUpForm.get('confirmNewPassword')?.touched || userSignUpForm.get('confirmNewPassword')?.dirty)">
                <div *ngIf="userSignUpForm.get('confirmNewPassword').hasError('required')">
                    Required field.
                </div>
                <div *ngIf="userSignUpForm.get('confirmNewPassword').hasError('matching')">
                    Incorrect password.
                </div>
            </div>
        </div>

      <div class="form-group">
        <input class="form-control" type="text" placeholder="Phone Number" formControlName="phoneNumber"/>
        <div class="error-message"
             *ngIf="userSignUpForm.get('phoneNumber')?.invalid && (userSignUpForm.get('phoneNumber')?.touched || userSignUpForm.get('phoneNumber')?.dirty)">
          <div *ngIf="userSignUpForm.get('phoneNumber').hasError('required')">
            Required field.
          </div>
        </div>


      </div>

      <div
        class="action-buttons">
        <button class="custom-btn" (click)="signUpUser()" [disabled]="userSignUpForm.invalid">Sign Up</button>
        <p (click)="switchMode()" class="switch-button mt-2"><span class="connector">or</span><span class="main-text">Login</span>
        </p>
      </div>
    </form>
  </div>

</div>
