import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AllUsersStatisticsModel} from "../models/AllUsersStatisticsModel";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {PureOriginalUserStatisticsModel} from "../models/PureOriginalUserStatistics-model";
import {PureOriginalDetailedStatisticsResponseModel} from "../models/PureOriginalDetailedStatisticsResponse-model";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {MasterStatsModel} from "../models/MasterStats-model";
import {MasterDetailsStatsModel} from "../models/MasterDetailsStats-model";


@Injectable({
    providedIn: 'root'
})

export class UserStatisticsService {
    private url: string = `${this.API_URL}`;

    isLoading = signal<boolean>(false);
    public userStatistics = signal<AllUsersStatisticsModel[]>([]);
    userStatisticsPureOriginalDetails = signal<PureOriginalUserStatisticsModel[]>([]);
    pureOriginalsTableStatisticDetails = signal<PureOriginalDetailedStatisticsResponseModel[]>([]);
    isDateRangePickerModalOpened = signal<boolean>(false);
    userStatisticsMasterDetails = signal<MasterStatsModel[]>([]);
    masterStatisticsTableDetails = signal<MasterDetailsStatsModel[]>([]);
    searchString = signal<string>("");


    public selectedTimeFrame = signal<string | undefined>('');
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "pureOriginalId", direction: 'desc'});
    userId: number;

    public startDate: string;
    public endDate: string;

    pageNumber = signal<number>(0);
    pageSize = signal<number>(50);


    private datePipe = inject(DatePipe);
    router = inject(Router);


    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }


    resetTableUserDetails() {
        this.userStatisticsPureOriginalDetails.set([]);
        this.pureOriginalsTableStatisticDetails.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.userStatisticsMasterDetails.set([]);
        this.masterStatisticsTableDetails.set([]);
        this.searchString.set("");
    }


    public getUserStatistics() {
        this.isLoading.set(true);
        this.httpClient.get<AllUsersStatisticsModel[]>(`${this.url}/userStatistics/all`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
            }
        }).subscribe(response => {
            this.userStatistics.set(response);
            this.isLoading.set(false)
        })
    }

    getUserStatisticsOnScroll() {
        this.isLoading.set(true);
        this.httpClient.get<AllUsersStatisticsModel[]>(`${this.url}/userStatistics/all`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
            }
        }).subscribe(response => {
            this.userStatistics.update((statistics) => [...statistics, ...response["content"]]);
            this.isLoading.set(false);
        });
    }

    increasePageNumber() {
        this.pageNumber.update((prevNum) => prevNum + 1);
    }

    public getUserStatisticPureOriginalDetails(userId: number) {
        this.isLoading.set(true);
        this.httpClient.get<PureOriginalUserStatisticsModel[]>(`${this.url}/userStatistics/pureOriginals/${userId}`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        }).subscribe(response => {
            this.userStatisticsPureOriginalDetails.set(response["content"]);
            this.isLoading.set(false);
        })
    }

    getUserStatisticPureOriginalDetailsOnScroll(userId: number) {
        this.httpClient.get<PureOriginalUserStatisticsModel[]>(`${this.url}/userStatistics/pureOriginals/${userId}`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            }
        }).subscribe(response => {
            this.userStatisticsPureOriginalDetails.update((details) => [...details, ...response["content"]]);
        })
    }

    getPureOriginalTableStatisticDetails(userId: number, pureOriginalId: string) {
        this.isLoading.set(true);
        this.httpClient.get<PureOriginalDetailedStatisticsResponseModel[]>(`${this.url}/userStatistics/pureOriginal/${userId}/${pureOriginalId}`, {}).subscribe(response => {
            this.pureOriginalsTableStatisticDetails.set(response);
            this.isLoading.set(false);
        })
    }

    searchPureOriginalsByWinOlsReference(userId: number) {
        if (this.searchString().length >= 3) {
            this.isLoading.set(true);
            this.httpClient.get<PureOriginalDetailedStatisticsResponseModel[]>(`${this.url}/userStatistics/pureOriginals/${userId}/searchByWinOls`, {
                params: {
                    winOls: this.searchString(),
                    pageNumber: this.pageNumber(),
                    pageSize: this.pageSize(),
                    startDate: this.startDate,
                    endDate: this.endDate
                }
            }).subscribe(response => {
                this.userStatisticsPureOriginalDetails.set(response["content"]);
                this.isLoading.set(false);
            })
        } else {
            this.getUserStatisticPureOriginalDetails(userId);
        }
    }

    searchPureOriginalsByWinOlsReferenceOnScroll(userId: number) {
        this.httpClient.get<PureOriginalDetailedStatisticsResponseModel[]>(`${this.url}/userStatistics/pureOriginals/${userId}/searchByWinOlsReference`, {
            params: {
                winOlsReference: this.searchString()
            }
        }).subscribe(response => {
            this.userStatisticsPureOriginalDetails.update((details) => [...details, ...response["content"]]);
        })
    }

    public getMasterStatisticsDetails(userId: number) {
        this.isLoading.set(true);
        this.httpClient.get<MasterStatsModel[]>(`${this.url}/userStatistics/masters/${userId}`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        }).subscribe(response => {
            this.userStatisticsMasterDetails.set(response["content"]);
            this.isLoading.set(false);
        })
    }

    getMasterStatisticsDetailsOnScroll(userId: number) {
        this.httpClient.get<MasterStatsModel[]>(`${this.url}/userStatistics/masters/${userId}`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            }
        }).subscribe(response => {
            this.userStatisticsMasterDetails.update((details) => [...details, ...response["content"]]);
        })
    }


    searchMasterStatsByWinOls(userId: number) {
        if (this.searchString().length >= 3) {
            this.isLoading.set(true);
            this.httpClient.get<MasterStatsModel[]>(`${this.url}/userStatistics/masters/${userId}/searchByWinOlsReference`, {
                params: {
                    winOlsReference: this.searchString(),
                    startDate: this.startDate,
                    endDate: this.endDate
                }
            }).subscribe(response => {
                this.userStatisticsMasterDetails.set(response["content"]);
                this.isLoading.set(false);
            })
        } else {
            this.getMasterStatisticsDetails(userId);
        }
    }

    searchMasterStatsByWinOlsOnScroll(userId: number) {
        this.httpClient.get<MasterStatsModel[]>(`${this.url}/userStatistics/masters/${userId}/searchByWinOlsReference`, {
            params: {
                winOlsReference: this.searchString()
            }
        }).subscribe(response => {
            this.userStatisticsMasterDetails.update((details) => [...details, ...response["content"]]);
        })
    }

    onScrollPureOriginalsStats(userId: number) {
        if (this.searchString().length >= 3) {
            this.searchPureOriginalsByWinOlsReferenceOnScroll(userId);
        } else {
            this.getUserStatisticPureOriginalDetailsOnScroll(userId);
        }
    }

    onScrollMasterStats(userId: number) {
        if (this.searchString().length >= 3) {
            this.searchPureOriginalsByWinOlsReference(userId);
        } else {
            this.searchMasterStatsByWinOlsOnScroll(userId);
        }
    }


    getMasterStatisticsDetailsWithMasterId(userId: number, masterId: string) {
        this.isLoading.set(true);
        this.httpClient.get<MasterDetailsStatsModel[]>(`${this.url}/userStatistics/masters/${userId}/${masterId}`, {}).subscribe(response => {
            this.masterStatisticsTableDetails.set(response);
            this.isLoading.set(false);
        })
    }

    closeDateRangePickerModal($event: any) {
        this.isDateRangePickerModalOpened.set($event.valueOf());
    }

    async submitUserFormDate($event: any) {
        const startDateFormValue = $event.valueOf().startDate;
        const endDateFormValue = $event.valueOf().endDate;


        this.startDate = new Date(startDateFormValue).toISOString();
        this.endDate = new Date(endDateFormValue).toISOString();

        localStorage.setItem('startDate', this.startDate);
        localStorage.setItem('endDate', this.endDate);

        this.getUserStatistics();

        const formattedStart = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
        const formattedEnd = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
        this.selectedTimeFrame.set(`${formattedStart} - ${formattedEnd}`);
    }


    openDateRangePickerModal() {
        this.isDateRangePickerModalOpened.set(true);
    }

}
