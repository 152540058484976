<div class="details-wrapper">
    <div class="details-container">

        @if (pureOriginalService.imageIsLoading()) {
            <app-pure-original-info-skeleton></app-pure-original-info-skeleton>
        }
        @if (!pureOriginalService.imageIsLoading()) {
            <form class="vehicle-info-container">
                <div class="restart-button-container">
                    <button class="restart-po-process" role="button"
                            (click)="repeatPureOriginalsProcess(+pureOriginalDetailObject()['id'])">
                        Restart Pure Original Process
                    </button>
                </div>
                @if (pureOriginalDetailObject()) {
                    <ng-container>
                        <div class="title-image-wrapper">
                            <div class="header-info-container">
                                <div class="title">
                     <span class="content-item"><span
                             class="content-item-text">{{ pureOriginalDetailObject()["vehicleProducer"] }}</span></span>
                                    <span class="content-item">
                    <span
                            class="content-item-text">{{ pureOriginalDetailObject()["vehicleSeries"] | shorten: 17 }}</span></span>
                                    <span class="content-item">
                   <span class="content-item-text">{{ pureOriginalDetailObject()["vehicleModelYear"] }}</span></span>
                                </div>
                            </div>
                            <div class="vehicle-info-card">
                                <img [src]="carImageService.image" alt=""
                                     [ngClass]="!carImageService.image ? 'startLoad' : ''">
                            </div>
                        </div>

                        <div class="new-tuning-container">
                            <div class="download-button-container">
                                <button class="add-tuning-button"
                                        (click)="downloadVersionFile(pureOriginalId(), pureOriginalDetailObject().winOlsReference, 'PURE_ORIGINAL_BASE')"
                                        [disabled]="isDownloadInProgress(pureOriginalId())">
                                    <span class="button-text">Download</span>
                                    <i class="bi bi-arrow-down-short"></i>
                                </button>
                            </div>
                            <div class="download-button-container">
                                <div class="add-tuning-container">
                                    <button class="add-tuning-button" (click)="openFileUploadModal()"
                                            [disabled]="buttonIsDisabled()"
                                            [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                        <span class="button-text">Add Tuning</span>
                                        <i class="bi bi-arrow-up-short"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="edit-button-container">
                                <button class="add-tuning-button"
                                        (click)="editPureOriginalItem(pureOriginalDetailObject().id, pureOriginalDetailObject())"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                    <i class="bi bi-pencil"></i>
                                </button>

                            </div>
                            <div>
                                <div class="add-tuning-container">
                                    <button class="add-tuning-button"
                                            (click)="downloadVersionFile(pureOriginalId(), pureOriginalDetailObject().winOlsReference, 'PURE_ORIGINAL_LOSE_MATCHING')"
                                            [disabled]="isDownloadInProgress(pureOriginalId()) || buttonIsDisabled()"
                                            [matTooltip]="buttonIsDisabled() ? 'Please claim  the file' : ''">
                                        <span style="margin-left: 2px; margin-right: 2px" class="button-text">Download all partially matched tunings</span>
                                        <i class="bi bi-arrow-down-short"></i>
                                    </button>
                                </div>
                            </div>

                            <div>
                                <button class="add-tuning-button"
                                        (click)="fileInput.click()"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                    Upload INI
                                </button>
                                <input #fileInput class="file-upload-input"
                                       type="file"
                                       style="display: none;"
                                       [disabled]="buttonIsDisabled()"
                                       (change)="uploadIni($event)"/>

                            </div>

                            <div class="edit-button-container">
                                <button class="add-tuning-button"
                                        (click)="deletePureOriginalItem(+pureOriginalDetailObject().id, 'PURE_ORIGINAL_BASE', true)"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"><i
                                        class="bi bi-trash"></i></button>
                            </div>
                        </div>

                        <form [formGroup]="pureOriginalDetailsFormGroup" (keydown.enter)="submitForm()"
                              (keydown.escape)="cancelForm()">
                            <div class="info-card-details">
                                <div class="info-column">
                          <span class="matching-detail-item"><span
                                  class="matching-item-name">New WinOls Name</span><span
                                  class="matching-item-text">{{ pureOriginalDetailObject()['winOlsDbName'] || '-'| shorten: 30 }}</span></span>
                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">WinOLS</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()['winOlsReference'] || '-' | shorten: 25 }}</span></span>

                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Tunings</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()['numberOfOptions'] || '-' }}</span></span>

                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Client Counter</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()["clientImportCounter"] || '-' }}</span></span>
                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Master Counter</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()["masterUploadCounter"] || '-' }}</span></span>
                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Ecu Version</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()["ecuSoftwareVersion"] || '-' | shorten: 22 }}</span></span>
                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Vehicle Name</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()["engineName"] || '-' }}</span></span>

                                    <span class="matching-detail-item"><span
                                            class="matching-item-name">Data uploaded</span><span
                                            class="matching-item-text">{{ pureOriginalDetailObject()["dateUploaded"] || '-' | shorten: 20 }}</span></span>


                                    <span class="matching-detail-item">
                              <span class="matching-item-name">Ecu Build</span>
                                        @if (!editFlags.ecuBuild) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('ecuBuild', $event)">{{ pureOriginalDetailsFormGroup.get('ecuBuild').value ? pureOriginalDetailObject()["ecuBuild"] : '-' }}
                               </span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="ecuBuild"
                                                   (click)="stopPropagation($event)"/>
                                        }
                              </span>

                                    <span class="matching-detail-item">
                              <span class="matching-item-name">Ecu Producer</span>
                                        @if (!editFlags.ecuProducer) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('ecuProducer', $event)">{{ pureOriginalDetailsFormGroup.get('ecuProducer').value ? pureOriginalDetailObject()["ecuProducer"] : '-' }}
                               </span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="ecuProducer"
                                                   (click)="stopPropagation($event)"/>
                                        }
                              </span>

                                    <span class="matching-detail-item">
                              <span class="matching-item-name">Type</span>
                                        @if (!editFlags.engineType) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('engineType', $event)">{{ pureOriginalDetailsFormGroup.get('engineType').value ? pureOriginalDetailObject().engineType : "-" }}
                               </span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="engineType"
                                                   (click)="stopPropagation($event)"/>
                                        }
                              </span>

                                    <span class="matching-detail-item">
                                  <span class="matching-item-name">Transmission</span>
                                        @if (!editFlags.engineTransmission) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('engineTransmission', $event)">{{ pureOriginalDetailsFormGroup.get("engineTransmission").value ? pureOriginalDetailObject()["engineTransmission"] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="engineTransmission"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                 </span>
                                    <span class="matching-detail-item">
                                <span class="matching-item-name">Engine Producer</span>
                                        @if (!editFlags.engineProducer) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('engineProducer', $event)">{{ pureOriginalDetailsFormGroup.get('engineProducer').value ? pureOriginalDetailObject()['engineProducer'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="engineProducer"
                                                   (click)="stopPropagation($event)"/>
                                        }
                               </span>
                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Ecu Software Version</span>
                                        @if (!editFlags.ecuSoftwareVersionVersion) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('ecuSoftwareVersionVersion', $event)">{{ pureOriginalDetailsFormGroup.get('ecuSoftwareVersionVersion').value ? pureOriginalDetailObject()['ecuSoftwareVersionVersion'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="ecuSoftwareVersionVersion"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>

                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Engine Emission Standard</span>
                                        @if (!editFlags.engineEmissionStandard) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('engineEmissionStandard', $event)">{{ pureOriginalDetailsFormGroup.get('engineEmissionStandard').value ? pureOriginalDetailObject()['engineEmissionStandard'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="engineEmissionStandard"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>

                                    <span class="matching-detail-item">
                                    <span class="matching-item-name">Displacement</span>
                                        @if (!editFlags.engineDisplacement) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('engineDisplacement', $event)">{{ pureOriginalDetailsFormGroup.get('engineDisplacement').value ? pureOriginalDetailObject()["engineDisplacement"] : '-' }}
                                     </span>
                                        } @else {
                                            <input type="text" class="matching-item-text new-text"
                                                   formControlName="engineDisplacement"
                                                   (click)="stopPropagation($event)"/>
                                            @if (isInputChanged()) {
                                                <span *ngIf="pureOriginalDetailsFormGroup.get('engineDisplacement').hasError('pattern')"
                                                      class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                            }
                                        }
                                   </span>
                                </div>
                                <div class="info-column">

                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Vehicle Producer</span>
                                        @if (!editFlags.vehicleProducer) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleProducer', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleProducer').value ? pureOriginalDetailObject()['vehicleProducer'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="vehicleProducer"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>

                                    <span class="matching-detail-item">
                                <span class="matching-item-name">Vehicle Series</span>
                                        @if (!editFlags.vehicleSeries) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleSeries', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleSeries').value ? pureOriginalDetailObject()['vehicleSeries'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="vehicleSeries"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                </span>
                                    <span class="matching-detail-item">
                                 <span class="matching-item-name">Vehicle Build</span>
                                        @if (!editFlags.vehicleBuild) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleBuild', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleBuild').value ? pureOriginalDetailObject()['vehicleBuild'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="vehicleBuild"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                 </span>
                                    <span class="matching-detail-item">
                                  <span class="matching-item-name">Vehicle Model</span>
                                        @if (!editFlags.vehicleModel) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleModel', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleModel').value ? pureOriginalDetailObject()['vehicleModel'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="vehicleModel"
                                                   (click)="stopPropagation($event)"/>
                                        }
                             </span>
                                    <span class="matching-detail-item">
                        <span class="matching-item-name">Vehicle Type</span>
                                        @if (!editFlags.vehicleType) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleType', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleType').value ? pureOriginalDetailObject()['vehicleType'] : '-' }}</span>

                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="vehicleType"
                                                   (click)="stopPropagation($event)"/>
                                        }
                             </span>
                                    <span class="matching-detail-item">
                        <span class="matching-item-name">Vehicle Model Year</span>
                                        @if (!editFlags.vehicleModelYear) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleModelYear', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleModelYear').value ? pureOriginalDetailObject()['vehicleModelYear'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="vehicleModelYear"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                </span>
                                    <span class="matching-detail-item">
                                      <span class="matching-item-name">Vehicle Characteristic</span>
                                        @if (!editFlags.vehicleCharacteristic) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleCharacteristic', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleCharacteristic').value ? pureOriginalDetailObject()['vehicleCharacteristic'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="vehicleCharacteristic"
                                                   (click)="stopPropagation($event)"
                                            />
                                        }
                                 </span>
                                    <span class="matching-detail-item">
                                      <span class="matching-item-name">Vehicle VIn</span>
                                        @if (!editFlags.vehicleVin) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('vehicleVin', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleVin').value ? pureOriginalDetailObject()['vehicleVin'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="vehicleVin"
                                                   (click)="stopPropagation($event)"
                                            />
                                        }
                                 </span>
                                    <span class="matching-detail-item">
                                      <span class="matching-item-name">Ecu Use</span>
                                        @if (!editFlags.ecuUse) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('ecuUse', $event)">{{ pureOriginalDetailsFormGroup.get('ecuUse').value ? pureOriginalDetailObject()['ecuUse'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="ecuUse"
                                                   (click)="stopPropagation($event)"
                                            />
                                        }
                                 </span>
                                    <span class="matching-detail-item">
                                      <span class="matching-item-name">Ecu StgNr</span>
                                        @if (!editFlags.ecuStgNr) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('ecuStgNr', $event)">{{ pureOriginalDetailsFormGroup.get('ecuStgNr').value ? pureOriginalDetailObject()['ecuStgNr'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="ecuStgNr"
                                                   (click)="stopPropagation($event)"
                                            />
                                        }
                                 </span>

                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Project Details</span>
                                        @if (!editFlags.resellerProjectDetails) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('resellerProjectDetails', $event)">{{ pureOriginalDetailsFormGroup.get('resellerProjectDetails').value ? pureOriginalDetailObject()['resellerProjectDetails'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="resellerProjectDetails"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>

                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Project Type</span>
                                        @if (!editFlags.resellerProjectType) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('resellerProjectType', $event)">{{ pureOriginalDetailsFormGroup.get('resellerProjectType').value ? pureOriginalDetailObject()['resellerProjectType'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="resellerProjectType"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>
                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Credits</span>
                                        @if (!editFlags.resellerCredits) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('resellerCredits', $event)">{{ pureOriginalDetailsFormGroup.get('resellerCredits').value ? pureOriginalDetailObject()['resellerCredits'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text"
                                                   formControlName="resellerCredits"
                                                   (click)="stopPropagation($event)"/>
                                        }
                                   </span>
                                    <span class="matching-detail-item">
                                     <span class="matching-item-name">Hp</span>
                                        @if (!editFlags.outputPs) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('outputPs', $event)">{{ pureOriginalDetailsFormGroup.get('outputPs').value ? pureOriginalDetailObject()['outputPs'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="outputPs"
                                                   (click)="stopPropagation($event)"/>
                                            @if (isInputChanged()) {
                                                <span *ngIf="pureOriginalDetailsFormGroup.get('outputPs').hasError('pattern')"
                                                      class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                            }
                                        }
                                   </span>

                                    <span class="matching-detail-item">
                        <span class="matching-item-name">Kw</span>
                                        @if (!editFlags.outputKw) {
                                            <span class="matching-item-text cursor"
                                                  (click)="toggleEdit('outputKw', $event)">{{ pureOriginalDetailsFormGroup.get('outputKw').value ? pureOriginalDetailObject()['outputKw'] : '-' }}</span>
                                        } @else {
                                            <input type="text" class="matching-item-text" formControlName="outputKw"
                                                   (click)="stopPropagation($event)"/>
                                            @if (isInputChanged()) {
                                                <span *ngIf="pureOriginalDetailsFormGroup.get('outputKw').hasError('pattern')"
                                                      class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                            }
                                        }
                                  </span>
                                    <span class="matching-detail-item">
                                       <span class="matching-item-name">Nm</span>
                                        @if (!editFlags.engineMaxTorque) {
                                            <span
                                                    class="matching-item-text cursor"
                                                    (click)="toggleEdit('engineMaxTorque', $event)">{{ pureOriginalDetailsFormGroup.get('engineMaxTorque').value ? pureOriginalDetailObject()['engineMaxTorque'] : '-' }}</span>
                                        } @else {

                                            <input type="text" class="matching-item-text"
                                                   formControlName="engineMaxTorque"
                                                   (click)="stopPropagation($event)"/>
                                            @if (isInputChanged()) {
                                                <span *ngIf="pureOriginalDetailsFormGroup.get('engineMaxTorque').hasError('pattern')"
                                                      class="error-tooltip">Only accepts formats like this -> 100.0 or 100 </span>
                                            }
                                        }
                                 </span>
                                </div>
                            </div>


                            @if (isVisibleButton()) {
                                <div class="save-button">
                                    <button type="button" class="add-tuning-button"
                                            (click)="submitForm()" [disabled]="!pureOriginalDetailsFormGroup.valid">Save
                                    </button>
                                    <button type="button" class="add-tuning-button"
                                            (click)="cancelForm()">Cancel
                                    </button>
                                </div>
                            }
                        </form>
                    </ng-container>
                }
            </form>
        }
    </div>
</div>
