import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-forgot-password-verification',
  templateUrl: './forgot-password-verification.component.html',
  styleUrls: ['./forgot-password-verification.component.css']
})
export class ForgotPasswordVerificationComponent {



  constructor(private router: Router) {
  }


  backToLoginPage () {
    this.router.navigate(["/login"])
  }

}
