import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchBarComponent} from "./search-bar/search-bar.component";
import {ReactiveFormsModule} from "@angular/forms";
import { PureOriginalsFilters } from './pure-originals-filters/pure-originals-filters';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlider, MatSliderThumb} from "@angular/material/slider";

@NgModule({
  declarations: [SearchBarComponent, PureOriginalsFilters],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatSelectModule, MatIconModule, MatButtonModule, MatAutocompleteModule, MatSlider, MatSliderThumb],
  exports: [SearchBarComponent, CommonModule, ReactiveFormsModule, PureOriginalsFilters],

})
export class SharedModule {}
