<div class="car-info-wrapper">
    <div class="image-container">
        <img [src]="carImage" alt="" [ngClass]="imageIsLoading ? 'loading' : ''"/>
    </div>
    <div class="details-wrapper-content">
        <div class="main-details-container">
            <div class="info-car-container">
              <span class="content-item"><span
                 class="content-item-text">{{stockFileDTO.vehicleProducer}}</span></span>
                <span class="content-item">
               <span
                  class="content-item-text">{{stockFileDTO.vehicleSeries}}</span></span>
                <span class="content-item">
                   <span
                  class="content-item-text">{{stockFileDTO.vehicleModelYear}}</span></span>
            </div>
            <div class="view-button-container">
                   <button class="button-view" (click)="openPureOriginalDetails(stockFileDTO.pureOriginalId)"><i class="bi bi-eye"></i>
                     </button>
                <span class="tooltip">View original</span>
                    </div>
        </div>

        <div class="info-card-details">

        <span class="matching-detail-item"><span class="matching-item-name">Vehicle Build</span><span
                class="matching-item-text">{{stockFileDTO.vehicleBuild}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Model Year</span><span
                    class="matching-item-text">{{stockFileDTO.vehicleModelYear}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Ecu Version</span><span
                    class="matching-item-text">{{stockFileDTO.ecuSoftwareVersion}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Ecu</span><span
                    class="matching-item-text ecu">{{stockFileDTO.ecuProducer}}</span><span
                    class="matching-item-text">{{stockFileDTO.ecuBuild}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Ecu Use</span><span
                    class="matching-item-text">{{stockFileDTO.ecuUse}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Engine Name</span><span
                    class="matching-item-text">{{stockFileDTO.engineName}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Transmission</span><span
                    class="matching-item-text">{{stockFileDTO.engineTransmission}}</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Displacement</span><span
                    class="matching-item-text">{{(+stockFileDTO.engineDisplacement).toFixed(1)}}
                L</span></span>
            <span class="matching-detail-item"><span class="matching-item-name">Type</span><span
                    class="matching-item-text">{{stockFileDTO.engineType}}</span></span>

        </div>
        <div class="vehicle-power-container">
          <span class="matching-power-item"><span class="matching-power-name">Hp</span><span
                  class="matching-power-text">{{stockFileDTO.outputPs}}</span></span>
            <span class="matching-power-item"><span class="matching-power-name">Kw</span><span
                    class="matching-power-text">{{stockFileDTO.outputKw}}</span></span>
            <span class="matching-power-item"><span class="matching-power-name">Nm</span><span
                    class="matching-power-text">{{stockFileDTO.engineMaxTorque}}</span></span>
        </div>
    </div>
</div>
