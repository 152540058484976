import {
    Component,
    ElementRef, inject,
    input,
    OnChanges,
    OnInit,
    Renderer2,
    signal,
    SimpleChanges
} from '@angular/core';


@Component({
    selector: 'app-heap-server-memory-component',
    templateUrl: './heap-server-memory-component.component.html',
    styleUrls: ['./heap-server-memory-component.component.css']
})
export class HeapServerMemoryComponentComponent implements OnInit, OnChanges {

    maxHeapMemory = input<number>();
    freeHeapMemory = input<number>();
    progressWidth = signal<number>(0);

    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    ngOnChanges(changes: SimpleChanges) {
        if (changes['maxHeapMemory'] || changes['freeHeapMemory']) {
            const maxHeapMemory = changes['maxHeapMemory'] ? changes['maxHeapMemory'].currentValue : this.maxHeapMemory();
            const freeHeapMemory = changes['freeHeapMemory'] ? changes['freeHeapMemory'].currentValue : this.freeHeapMemory();
            this.extractHeapMemoryValues(maxHeapMemory, freeHeapMemory);
        }
    }

    ngOnInit() {
        this.extractHeapMemoryValues(this.maxHeapMemory(), this.freeHeapMemory());
    }

    extractHeapMemoryValues(maxHeapMemory: number, freeHeapMemory: number) {
        const progressBar = this.el.nativeElement.querySelector('.heap-progress-bar-custom');
        const maxHeapMemoryEl = this.el.nativeElement.querySelector('.maxHeapMemory');
        const usedHeapMemoryEl = this.el.nativeElement.querySelector('.usedHeapMemory');

        const maxHeapMemoryToGB = (maxHeapMemory / 1024).toFixed(2);
        const usedHeapMemoryToGB = ((maxHeapMemory - freeHeapMemory) / 1024).toFixed(2);


        const result = (+usedHeapMemoryToGB / +maxHeapMemoryToGB) * 100;
        this.progressWidth.set(result);


        if (maxHeapMemoryEl != null) {
            this.renderer.setProperty(maxHeapMemoryEl, 'innerHTML', maxHeapMemoryToGB + " " + 'gb');
        }

        if (usedHeapMemoryEl != null) {
            this.renderer.setProperty(usedHeapMemoryEl, 'innerHTML', usedHeapMemoryToGB + " " + 'gb');
        }

        if (this.progressWidth() != null) {
            if (this.progressWidth() < 70 && this.progressWidth() > 0) {
                progressBar.style.setProperty('--pseudoEl', 'lightgreen');
            }
            if (this.progressWidth() >= 70) {
                progressBar.style.setProperty('--pseudoEl', 'orange');
            }
            if (this.progressWidth() >= 90) {
                progressBar.style.setProperty('--pseudoEl', 'red');
            }
        }

    }
}
