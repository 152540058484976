import { Component } from '@angular/core';

@Component({
  selector: 'app-pure-original-matching-details-skeleton',
  templateUrl: './pure-original-matching-details-skeleton.component.html',
  styleUrls: ['./pure-original-matching-details-skeleton.component.css']
})
export class PureOriginalMatchingDetailsSkeletonComponent {

}
