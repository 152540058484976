@if (isOpened) {
    <div class="content-overlay"></div>

    <div class="content">
        <div class="content-title-container">
            <h1 class="content-title">Enable two-factor authentication</h1>
            <button class="content-close-btn" (click)="closeTemplate()"><i class="bi bi-x-lg"></i></button>
        </div>
        <!--        <div class="info-card">-->
        <!--            <i class="bi bi-info-lg"></i>-->
        <!--            <span class="info-card-desc">Two factor authentication is mandatory. You must enable it first before you can access the application.</span>-->
        <!--        </div>-->
        <h1 class="content-title fs-5">
            Scan the QR code
        </h1>
        <span>
        Use an authenticator app like Google Authenticator, Duo Mobile, LastPass Authenticator or similar,
        from your phone to scan the QR code below.
        If you are unable to scan, enter the code shown below the QR code instead.
        </span>
        @if (userService.secretImageUrl()) {
            <div class="qr-container">
                <img [src]="userService.secretImageUrl()"
                     alt=""/>
            </div>
        } @else {
            <app-mfa-template-placeholder></app-mfa-template-placeholder>
        }
        <span>
        Once you have added the application to your app,
        fill in the code generated by the app to complete enabling two-factor authentication.
    </span>
        <form [formGroup]="twoFactorForm" (ngSubmit)="onSubmitForm()">
            <label for="two-factor">Two-factor code</label>
            <input class="form-control"
                   formControlName="twoFactorCode"
                   id="two-factor"
                   type="text"
            >
            @if (errorMessage) {
                <div class="error">
                    Invalid two-factor code.
                </div>
            }

            <span class="description">Enter the code generated by your two-factor authentication app here.</span>
            <button class="custom-btn" type="submit" [disabled]="!twoFactorForm.valid && !errorMessage">Save</button>
        </form>


    </div>


}
