import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IdentifyStockFileModel} from "../models/IdentifyStockFile-model";


@Injectable({
    providedIn: "root"
})

export class FileUploadService {
    private url = `${this.API_URL}`;

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    pureOriginalMasterUpload(pureOriginalBaseId: string, versionName: string, versionComment: string, pureOriginalVersionBinFile: File, impossibleTuning: boolean): Observable<any> {
        const formData = new FormData();
        formData.set('pureOriginalBaseId', pureOriginalBaseId);
        formData.set('versionName', versionName);
        formData.set('versionComment', versionComment);
        formData.set('pureOriginalVersionBinFile', pureOriginalVersionBinFile);
        formData.set('impossibleTuning', impossibleTuning.toString());

        return this.httpClient.post(`${this.url}/pureOriginalMasterUpload`, formData, {
            reportProgress: true,
            observe: "events"
        });
    }

    uploadFileToIdentify(base64: string) {
        return this.httpClient.post<IdentifyStockFileModel>(`${this.url}/identifyStockFile`, {base64: base64}, {
            reportProgress: true,
            observe: "events"
        });
    }

    requestFileTune(blobFileName: string, requestedTunings: string[]) {
        return this.httpClient.post(`${this.url}/requestFileTune`, {
            blobFileName: blobFileName,
            requestedTunings: requestedTunings
        }, {reportProgress: true, observe: "response"});
    }

    requestManualFileTune(blobFileName: string, ecuBuild: any, requestedTunings: string[]) {
        return this.httpClient.post(`${this.url}/requestManualFileTune`, {
            blobFileName: blobFileName,
            ecuBuild: ecuBuild,
            requestedTunings: requestedTunings
        }, {reportProgress: true, observe: "response"});
    }

    uploadPureOriginalFile(winOls: string, bin: File, ini: File): Observable<any> {
        const formData = new FormData();
        formData.set('bin', bin)
        formData.set('ini', ini)

        const winOlsWithoutExtension = winOls.replace(/\.ols$/, '');

        return this.httpClient.post(`${this.url}/pureOriginals/uploadPureOriginalFiles`, formData, {
            reportProgress: true,
            observe: 'response',
            params: {
                winOls: winOlsWithoutExtension,
            },
        });
    }

    uploadMasterIniFile(masterIniFile: File, masterId: number): Observable<any> {
        const formData = new FormData();
        formData.set('masterIniFile', masterIniFile)
        formData.set('masterId', masterId.toString())
        return this.httpClient.post(`${this.url}/master/masterIniUpload`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    uploadPureOriginalIniFile(pureOriginalIniFile: File, pureOriginalId: number): Observable<any> {
        const formData = new FormData();
        formData.set('pureOriginalIniFile', pureOriginalIniFile)
        formData.set('pureOriginalId', pureOriginalId.toString())
        return  this.httpClient.post(`${this.url}/pureOriginals/pureOriginalIniUpload`, formData,{
            reportProgress: true,
            observe:'events'
        })
    }
}
