import {Component, OnInit, inject, signal, Inject} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalResultsService} from "../../../service/PureOriginalResults.service";
import {PureOriginalService} from "../../../service/pure-original.service";
import {SearchFilterModel} from "../../../models/SearchFilter-model";
import {LocalStorageService} from "../../../service/LocalStorageService";
import {AuthService} from "../../../service/auth.service";

@Component({
    selector: 'app-requested-tunings-tables',
    templateUrl: './requested-tunings-tables.html',
    styleUrls: ['./requested-tunings-tables.css']
})
export class RequestedTuningsTables implements OnInit {


    private disabledVersionsCheckBox = signal<string[]>([]);
    public filterCount = signal<number>(0);
    router = inject(Router);
    route = inject(ActivatedRoute);

    pureOriginalResultsService = inject(PureOriginalResultsService);
    pureOriginalsService = inject(PureOriginalService);
    localStorageService = inject(LocalStorageService);
    public authService = inject(AuthService);

    constructor(@Inject('LOCAL_MISSING_KEY') private missingFilter: string, @Inject('LOCAL_PARTIAL_KEY') private partialFilter: string) {

    }

    ngOnInit() {
        this.resetTableValue();
        this.getMostRequestedTableHeaders()
        this.getTableTypeFromRoute();

    }

    get filterKey(): string {
        return this.pureOriginalResultsService.type() === 'missing'
            ? this.missingFilter
            : this.partialFilter;
    }

    getTableTypeFromRoute() {
        this.route.queryParams.subscribe(params => {
            this.pureOriginalResultsService.type.set(params['type']);
            console.log(this.pureOriginalResultsService.type());

            // Retrieve filters using the dynamic key
            const savedFilters = this.localStorageService.getFilters(this.filterKey);

            if (this.pureOriginalResultsService.type() === 'missing') {
                if (this.isSuperAdmin()) {
                    this.pureOriginalResultsService.displayedTableHeaders.set([
                        ...this.pureOriginalResultsService.displayedColumns(),
                        'actions'
                    ]);
                }

                if (savedFilters && savedFilters.length > 0) {
                    this.pureOriginalResultsService.selectedFilters.set(savedFilters);
                    this.getFilteredTunings(savedFilters);
                    this.updateFilterCount(savedFilters.length);
                } else {
                    this.getAllMostRequestedMissingTunings();
                }
            } else if (this.pureOriginalResultsService.type() === 'partial') {
                if (savedFilters && savedFilters.length > 0) {
                    this.pureOriginalResultsService.selectedFilters.set(savedFilters);
                    this.getFilteredTunings(savedFilters);
                    this.updateFilterCount(savedFilters.length);
                } else {
                    this.getAllMostRequestedPartialTunings();
                }
            } else {
                // Fallback: treat unknown types as partial
                if (savedFilters && savedFilters.length > 0) {
                    this.pureOriginalResultsService.selectedFilters.set(savedFilters);
                    this.getFilteredTunings(savedFilters);
                    this.updateFilterCount(savedFilters.length);
                } else {
                    this.getAllMostRequestedPartialTunings();
                }
            }
        });
    }

    onScroll() {
        this.pureOriginalResultsService.onScrollMostRequestedTables();
    }

    getMostRequestedTableHeaders() {
        this.pureOriginalResultsService.getMostRequestedTableHeaders();
    }

    resetTableValue() {
        this.pureOriginalResultsService.resetMostRequestedTuningsTable();
    }

    getAllMostRequestedPartialTunings() {
        console.log("triggered partial");
        this.pureOriginalResultsService.getMostRequestedPartialTunings();
    }


    getAllMostRequestedMissingTunings() {
        console.log("triggered missing")
        this.pureOriginalResultsService.getMostRequestedMissingTunings();
    }

    openPureOriginalDetails(id: number, versionName: string) {
        this.router.navigate(['app/pure-originals/pure-original-details'], {
            queryParams: {
                pureOriginalId: id,
                versionName: versionName
            }
        })
    }

    backToMainTable() {
        this.pureOriginalsService.triggerBackFunction();
    }

    changeSortDirection($event: any) {
        this.pureOriginalResultsService.sortDirectionValues.set($event.valueOf());
        this.pureOriginalResultsService.changeSortDirection();
    }

    deleteMissingTuning(id: number, tableName: string) {
        this.pureOriginalResultsService.deleteMissingTuningFromTable(id, tableName)
    }

    changeMissingTuning(event: any, id: string, versionName: string) {
        this.disabledVersionsCheckBox().push(versionName);
        console.log(versionName, 'version name');
        let state = event.target.checked;

        this.pureOriginalsService.changeImpossibleTunings(id, state).subscribe(response => {
            console.log(response);
            this.pureOriginalsService.pureOriginalMatchingDetails.set(response);

            if (!state) {
                this.pureOriginalResultsService.mostRequestedTunings.update((tunings) =>
                    tunings.filter(tuning => tuning.id !== id)
                );
                console.log(state)
            }
        });
    }

    openAdvancedSearchSettings() {
        this.pureOriginalResultsService.openAdvancedSearchSettings();
    }


    getFilteredTunings(filters: SearchFilterModel[]): void {
        this.pureOriginalResultsService.isLoading.set(true);
        if (this.pureOriginalResultsService.type() === 'missing') {
            this.pureOriginalResultsService.getMostRequestedTuningsFiltered(filters).subscribe(response => {
                console.log(response, 'filtered response');
                const tunings = response['content'] || [];
                this.pureOriginalResultsService.mostRequestedTunings.set(tunings);
                this.pureOriginalResultsService.isLoading.set(false);
            });
        } else {
            this.pureOriginalResultsService.getMostRequestedPartialTuningFiltered(filters).subscribe(response => {
                console.log(response, 'filtered response');
                const tunings = response['content'] || [];
                this.pureOriginalResultsService.mostRequestedTunings.set(tunings);
                this.pureOriginalResultsService.isLoading.set(false);
            });
        }

    }

    selectFilters($event: any) {
        this.pureOriginalResultsService.selectedFilters.set($event.valueOf());
        this.pureOriginalResultsService.mostRequestedTunings.set([]);
        this.getFilteredTunings(this.pureOriginalResultsService.selectedFilters());
    }

    onAdvancedSettingClosed($event: boolean) {
        this.pureOriginalResultsService.openAdvanceSettings.set($event.valueOf())

    }

    updateFilterCount(count: number) {
        this.filterCount.set(count);
    }

    isTableEmpty() {
        return this.pureOriginalResultsService.mostRequestedTunings().length === 0;
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }
}
