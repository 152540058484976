import {Component, OnInit} from '@angular/core';
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
  selector: 'app-all-masters-database',
  templateUrl: './all-masters-database.component.html',
  styleUrls: ['./all-masters-database.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class AllMastersDatabaseComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
