import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener, Input,
    input,
    OnChanges,
    Output,
    signal,
    SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnChanges {
    @Output() file = new EventEmitter<{ fileToUpload: File }>();
    @Output() closeDisplay = new EventEmitter<string>();
    customBackground= input<string>();
    inputText = input<string>();
    progressWidth = input<number>();
    @Input() error: string;
    resetSignal = input<boolean>();
    public multipleFileSelected = signal<boolean>(false);
    public selectedFile = signal<File | null>(null);

    @ViewChild('fileInput', {static: false})
    myFileInput: ElementRef;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["resetSignal"] && !changes["resetSignal"].firstChange ) {
            if (changes["resetSignal"].currentValue === true ) {
                this.resetComponent();
            }
        }
    }

    resetComponent(): void {
        this.selectedFile.set(null);
        this.multipleFileSelected.set(false);
        this.myFileInput.nativeElement.value = '';
    }

    uploadDroppedFile($event: any) {
        this.selectedFile.set($event.valueOf());
        this.uploadFile(this.selectedFile());
    }

    refactoredFileName(file: File): string {
        const fileName = file.name;
        if (fileName.length > 21) {
            return fileName.substring(0, 16) + '...' + fileName.substring(fileName.length - 3);
        }
        return fileName;
    }

    uploadFileOnClick(event: any) {
        this.error = "";
        this.selectedFile.set(event.target.files[0]);
        this.uploadFile(event.target.files[0]);
    }

    uploadFile(selectedFile: File) {
        this.file.emit({fileToUpload: selectedFile});
        this.myFileInput.nativeElement.value = '';
    }

    @HostListener('window:resize', ['$event'])
    isMobile(): boolean {
        return window.innerWidth < 768
    }

    onMultipleFilesSelected($event: boolean) {
        this.multipleFileSelected.set($event.valueOf());
    }



}
