import {Component, EventEmitter, inject, input, Output} from '@angular/core';
import {FaultyEcuBuildsService} from "../../../service/FaultyEcuBuilds.service";
import {PureOriginalService} from "../../../service/pure-original.service";

@Component({
    selector: 'app-faulty-ecu-builds-table',
    templateUrl: './faulty-ecu-builds-table.component.html',
    styleUrl: './faulty-ecu-builds-table.component.css'
})
export class FaultyEcuBuildsTableComponent {

    faultyEcuBuildsService = inject(FaultyEcuBuildsService);
    pureOriginalService = inject(PureOriginalService);
    @Output() faultyEcuBuild = new EventEmitter<string>();
    faultyEcuBuildList = input.required<string[]>();
    selectedEcu: string | null = null;

    constructor() {
        this.selectedEcu = null;
    }

    getFaultyPureOriginalsByFaultyEcuBuild(ecuBuild: string) {
        this.selectedEcu = ecuBuild;
        this.faultyEcuBuild.emit(ecuBuild);
    }

}
