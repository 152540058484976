import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {


  public isClicked: boolean = false;


  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  userLoginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(`^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+{}\\":;'?/<>,.]).{8,}$`)])
  })

  loginUser() {
    this.isClicked = true;

    this.authService.login(this.userLoginForm.value.email, this.userLoginForm.value.password);

    setTimeout(() => {
      this.isClicked = false;
    }, 2500)
  }

  forgotPasswordHandler () {
    this.router.navigate(["/forgot-password"]);
  }


  switchMode() {
    this.router.navigate(['/signup']);
  }
}
