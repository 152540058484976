import {Injectable} from "@angular/core";
import {catchError, map, Observable} from "rxjs";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {LocalAuthService} from "./localAuth.service";

@Injectable({
  providedIn: "root"
})
export class EmailValidatorService {

  constructor(private localAuthService: LocalAuthService) {
  }
  checkIfEmailIsUsed (email: string): Observable<boolean> {
    return this.localAuthService.checkUserEmail(email).pipe(
        map(response => {
          if (response.status === 200) {
            console.log(response["message"]);
            return response["message"];
          }
        }),
        catchError(error => {
          if (error) {
            console.log(error.error["message"]);
            return error.error["message"];
          }
        })
    );
  }


  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfEmailIsUsed(control.value).pipe(
        map(res => {
          // if res is true, username exists, return true
          return res ? { emailExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

}
