import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TodoListModel} from "../models/todoList-model";

@Injectable({
    providedIn: "root"
})
export class TodoListService {

    private url = `${this.API_URL}`;

    public todos = signal<TodoListModel[]>([]);
    public isOpened = signal<boolean>(false);
    public isEditing = signal<boolean>(false);
    public todoUnderEdit = signal<TodoListModel | undefined>(null);

    private httpClient = inject(HttpClient);

    constructor(@Inject('API_URL') private API_URL: string) {
    }

    loadListOfTodos() {
        this.httpClient.get<TodoListModel[]>(`${this.url}/toDo`).subscribe(value => {
            this.todos.update((todos) => [...todos, ...value]);
        })
    }

    refreshTodoList() {
        this.todos.set([]);
        this.loadListOfTodos();
    }

    createNewTodo(title: string, content: string, date: Date) {
        this.httpClient.put<TodoListModel>(`${this.url}/toDo`, {
            title: title,
            content: content,
            date: date
        }).subscribe(value => {
            this.todos.update((todos) => [value, ...todos]);
        });
    }


    deleteTodo(id: number) {
        this.httpClient.delete(`${this.url}/toDo/${id}`).subscribe(() => {
            this.todos.update((todos) => todos.filter((item) => item.id !== id))
        })
    }

    showTodoForm () {
        this.isOpened.set(!this.isOpened());
    }
    updateTodo(todo: TodoListModel) {
        this.httpClient.post<TodoListModel>(`${this.url}/toDo/${todo.id}`, {
            title: todo.title,
            content: todo.content,
            date: todo.date,
            validation: todo.validation
        }).subscribe(value => {
            this.todos.update((todos) => todos.map(currentTodo => (currentTodo.id === todo.id) ? value : currentTodo))
        })
    }

    checkTodoValidation($event: any, todo: TodoListModel) {
        this.todos().findIndex(selectedTodo => {
            if (selectedTodo === todo) {
                todo.validation = $event.valueOf().target.checked;
                this.updateTodo(todo);
            }
        })
    }

    close() {
        this.isEditing.set(false);
        this.isOpened.set(false)
    }

}