<div class="selected-options-container">
    @if (stockFileDTO().identified) {
        <div class="car-image-container">

            <app-user-input-car-details [carImage]="carImageService.image" [imageIsLoading]="imageIsLoading()"
                                        [stockFileDTO]="stockFileDTO()"></app-user-input-car-details>

        </div>
    }

    @else {
        <div>
            <div class="custom-file-title">
                <span>File is not in our database.</span>
                <span class="red">Please specify ecu build for manual tune.</span>
            </div>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Ecu Build</mat-label>
                    <input type="text" matInput [formControl]="ecuBuildNameControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.correctEcuBuildNames}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="ecuBuildNameControl.hasError('required') && ecuBuildNameControl.touched">Invalid ECU Name
                    </mat-error>
                </mat-form-field>
            </form>
        </div>
    }


    <app-user-input-selection [stockFileDTO]="stockFileDTO()" [ecuBuildNameControl]="ecuBuildNameControl"
                              [selectedOptions]="tuningService.selectedOptions" (activeOption)="setOptionToActive($event)"
                              (stockFileBlobName)="requestFileTune($event)"></app-user-input-selection>

</div>
