<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>

    </div>
    @defer (when !resellerService.isLoading()) {
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="onScroll()">
            <table mat-table [dataSource]="resellerService.allResellerRecords()"
                   multiTemplateDataRows matSort matSortDisableClear [matSortDirection]="resellerService.sortDirectionValues().direction" [matSortActive]="resellerService.sortDirectionValues().active"
                   (matSortChange)="changeResellersTableSortDirection($event)">

                @for (column of resellerService.displayedResellerKeys(); track $index) {
                    <ng-container [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | shorten: 25}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element[column] | shorten: 1000 }}
                        </td>
                    </ng-container>
                }

                <tr class="header" mat-header-row *matHeaderRowDef="resellerService.displayedResellerKeys()"></tr>
                <tr mat-row *matRowDef="let element; columns: resellerService.displayedResellerKeys()"></tr>
            </table>
        </div>
    } @placeholder (minimum 150ms) {
        <div class="skeleton-table">
            <app-skeleton-table></app-skeleton-table>
        </div>
    }


</section>



