export class MasterResultsModel {
  public zipid: string;
  public zipname: string;
  public messageoriginal:string;
  public dateuploaded: string;
  public originaluploaded : string;
  public originalid: string;
  public tuningsuploaded : string;
  public dtcuploaded: string;
  public note: string;
  public originalname: string;
  public inifilename: string;
  public errormessage: string;
  public tableerror: string;
  public dtctablename: string;
  public size: string;
  public dtccanbeloaded: string;
  public dtcmessage: string;
  public error: string;
  public message: string;
  public tuningmessage: string;
  public succeeded: string;
  public errors: string;
  public namestuningsuploaded: string;
  public tuningsuploadedtodatabaseoriginal: string;


  constructor() {
    this.zipid = "zipid";
    this.zipname = "zipname";
    this.messageoriginal = "messageoriginal";
    this.dateuploaded = "dateuploaded";
    this.originaluploaded = "originaluploaded";
    this.originalid = "originalid";
    this.tuningsuploaded = "tuningsuploaded";
    this.dtcuploaded = "dtcuploaded";
    this.note = "note";
    this.originalname = "originalname";
    this.inifilename = "inifilename";
    this.errormessage = "errormessage";
    this.tableerror = "tableerror";
    this.dtctablename = "dtctablename";
    this.size = "size";
    this.dtccanbeloaded = "dtccanbeloaded";
    this.dtcmessage = "dtcmessage";
    this.error = "error";
    this.message = "message";
    this.tuningmessage = "tuningmessage";
    this.succeeded = "succeeded";
    this.errors = "errors";
    this.namestuningsuploaded = "namestuningsuploaded";
    this.tuningsuploadedtodatabaseoriginal = "tuningsuploadedtodatabaseoriginal";
  }
}
