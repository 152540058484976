import {Component, Inject} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {UserService} from "../../service/user.service";
import {NotificationService} from "../../service/Notification.service";

@Component({
  selector: 'app-mfa-confirmation-dialog',
  standalone: true,
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle
    ],
  templateUrl: './mfa-confirmation-dialog.component.html',
  styleUrl: './mfa-confirmation-dialog.component.css'
})
export class MFAConfirmationDialogComponent {
    constructor(public dialogRef: MatDialogRef<MFAConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private notificationService: NotificationService) {
    }

    confirmedOption () {
        this.dialogRef.close(true);
        this.userService.mfaUpdate(!this.userService.loggedUser().mfaEnabled);
    }

    canceledOption () {
        this.dialogRef.close(false);
        this.notificationService.showWarningMessage("Please enable MFA.", "MFA disabled.", {positionClass: 'toast-top-right', timeOut: 3000});
    }
}
