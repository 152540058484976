import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from "../environments/environment";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header-component/header.component';
import {SidebarComponent} from './sidebar-component/sidebar.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {ActionButtonsComponent} from './header-component/action-buttons/action-buttons.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {SharedModule} from "./shared/shared.module";
import {ShortenPipe} from "./helpers/title.pipe";
import {ToastComponent} from './toast/toast.component';
import {LayoutComponent} from './layout/layout.component';
import {AllMastersDatabaseComponent} from "./all-masters-database/all-masters-database.component";
import {MasterRecordsComponent} from "./all-masters-database/master-records/master-records.component";
import {PureOriginalsComponent} from "./pure-originals/pure-originals.component";
import {PureOriginalsTableComponent} from "./pure-originals/pure-originals-table/pure-originals-table.component";
import {PureOriginalDetailsComponent} from "./pure-originals/pure-original-details/pure-original-details.component";
import {FileServiceTableComponent} from "./file-service-table/file-service-table.component";
import {FormsModule} from "@angular/forms";
import {ResellerRecordsComponent} from "./reseller-records/reseller-records.component";
import {NgbModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {MasterResultsComponent} from "./master-results/master-results.component";
import {UploadFileComponent} from "./uploading-actions/upload-file/upload-file.component";
import {LoginPageComponent} from "./login-page/login-page.component";
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginFormComponent} from './login-page/login-form/login-form.component';
import {DragAndDropDirective} from './directives/drag-and-drop.directive';
import {UserInputsComponent} from './uploading-actions/user-inputs/user-inputs.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StatisticCardsComponent} from "./dashboard/statistic-cards/statistic-cards.component";
import {TodolistComponent} from './dashboard/todolist/todolist.component';
import {UploadingActionsComponent} from './uploading-actions/uploading-actions.component';
import {SystemInfoChartComponent} from './statistics/system-info-chart/system-info-chart.component';
import {
  CpuDetailsComponentComponent
} from './statistics/system-info-chart/cpu-details-component/cpu-details-component.component';
import {
  DiskSpaceComponentComponent
} from './statistics/system-info-chart/disk-space-component/disk-space-component.component';
import {
  HeapServerMemoryComponentComponent
} from './statistics/system-info-chart/heap-server-memory-component/heap-server-memory-component.component';
import {SignupFormComponent} from './register-user/signup-form/signup-form.component';
import {AuthInterceptor} from "./authentication/AuthInterceptor";
import {PureOriginalsResultsComponent} from './pure-originals-results/pure-originals-results.component';
import {
  RequestedTunings
} from './requested-tunings-wrapper/requested-tunings/requested-tunings';
import {
  PureOriginalResultsTableComponent
} from './pure-originals-results/pure-original-results-table/pure-original-results-table.component';
import {
  RequestedTuningsTables
} from './requested-tunings-wrapper/requested-tunings/requested-tunings-tables/requested-tunings-tables';
import {
  PureOriginalEcuDetailsComponent
} from './requested-tunings-wrapper/requested-tunings/requested-tunings-tables/pure-original-ecu-details/pure-original-ecu-details.component';
import {ToastrModule} from 'ngx-toastr';
import {RegisterUserComponent} from './register-user/register-user.component';
import {RegistrationTemplateComponent} from './register-user/registration-template/registration-template.component';
import {ActivationTemplateComponent} from "./register-user/activation-template/activation-template.component";
import {UserSettingsPageComponent} from "./user-settings-page/user-settings-page.component";
import {DatePipe, NgOptimizedImage} from "@angular/common";
import {
  PureOriginalsInfoComponent
} from './pure-originals/pure-original-details/pure-originals-info/pure-originals-info.component';
import {
  PureOriginalMatchingDetailsComponent
} from './pure-originals/pure-original-details/pure-original-matching-details/pure-original-matching-details.component';
import {AdminPanelComponent} from './admin-panel/admin-panel.component';
import {AllUsersTableComponent} from './admin-panel/all-users-table/all-users-table.component';
import {SkeletonTableComponent} from './skeleton-table/skeleton-table.component';
import {FileUploadModalComponent} from './pure-originals/pure-original-details/file-upload-modal/file-upload-modal.component';
import {
  PureOriginalInfoSkeletonComponent
} from "./pure-originals/pure-original-details/pure-original-info-skeleton/pure-original-info-skeleton.component";
import {
  PureOriginalMatchingDetailsSkeletonComponent
} from './pure-originals/pure-original-details/pure-original-matching-details-skeleton/pure-original-matching-details-skeleton.component';
import {GlobalSearchComponent} from './header-component/global-search/global-search.component';
import {WeatherAndTimeComponent} from './header-component/weather-and-time/weather-and-time.component';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSortModule} from "@angular/material/sort";
import {DeleteDialogComponent} from './helpers/delete-dialog/delete-dialog.component';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {TuningNamesTableComponent} from './tuning-names-wrapper/tuning-names-table/tuning-names-table.component';
import {EcuBuildNamesTableComponent} from './ecu-names-wrapper/ecu-build-names-table/ecu-build-names-table.component';
import {FaultyMastersComponent} from './ecu-names-wrapper/faulty-masters/faulty-masters.component';
import {EcuNamesWrapperComponent} from './ecu-names-wrapper/ecu-names-wrapper.component';
import {
  MasterRecordsTableComponent
} from './all-masters-database/master-records/master-records-table/master-records-table.component';
import {EditMasterDialogComponent} from './helpers/edit-master-dialog/edit-master-dialog.component';
import {MasterDetailsComponent} from './master-details-wrapper/master-details/master-details.component';
import {
  UserInputCarDetailsComponent
} from './uploading-actions/user-inputs/user-input-car-details/user-input-car-details.component';
import {
  UserInputSelectionComponent
} from './uploading-actions/user-inputs/user-input-selection/user-input-selection.component';
import {MasterDetailsWrapperComponent} from './master-details-wrapper/master-details-wrapper.component';
import {
  MasterVersionDetailsComponent
} from './master-details-wrapper/master-version-details/master-version-details.component';
import {TuningNamesWrapperComponent} from './tuning-names-wrapper/tuning-names-wrapper.component';
import {AddDialogComponent} from './helpers/add-dialog/add-dialog.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {RequestedTuningsWrapperComponent} from './requested-tunings-wrapper/requested-tunings-wrapper.component';
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { RestartDialogComponent } from './helpers/restart-dialog/restart-dialog.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { NumberOfProcessedFilesComponent } from './header-component/number-of-processed-files/number-of-processed-files.component';
import { NumberOfFilesInQueueComponent } from './header-component/number-of-files-in-queue/number-of-files-in-queue.component';
import { ServerStatsComponent } from './header-component/server-stats/server-stats.component';
import { CpuUsageComponent } from './header-component/server-stats/cpu-usage/cpu-usage.component';
import { UserModalMobileComponent } from './header-component/user-modal-mobile/user-modal-mobile.component';
import { ServerStatsPlaceholderComponent } from './header-component/server-stats/server-stats-placeholder/server-stats-placeholder.component';
import { ServerStatsModalComponent } from './header-component/server-stats-modal/server-stats-modal.component';
import { SidebarServerStatsComponent } from './sidebar-component/sidebar-server-stats/sidebar-server-stats.component';
import { UserModalComponentComponent } from './header-component/user-modal-component/user-modal-component.component';
import { ClaimFileComponent } from './pure-originals/pure-original-details/claim-file/claim-file.component';
import { ClaimedFilesCardComponent } from './dashboard/claimed-files-card/claimed-files-card.component';
import { PureOriginalTableWrapperComponent } from './pure-originals/pure-original-table-wrapper/pure-original-table-wrapper.component';
import { ThemePickerComponent } from './sidebar-component/theme-picker/theme-picker.component';
import { SidebarListComponent } from './sidebar-component/sidebar-list/sidebar-list.component';
import { DataPickerModalComponent } from './dashboard/statistic-cards/data-picker-modal/data-picker-modal.component';
import { DataRangePickerModalComponent } from './dashboard/statistic-cards/data-range-picker-modal/data-range-picker-modal.component';
import { EmptyTableComponent } from './empty-table/empty-table.component';
import { PureOriginalsMatchingDetailsTableComponent } from './pure-originals-matching-details-table/pure-originals-matching-details-table.component';
import { SelectPartnerComponent } from './sidebar-component/select-partner/select-partner.component';
import { EditPureOriginalDialogComponent } from './pure-originals/edit-pure-original-dialog/edit-pure-original-dialog.component';
import { PureOriginalsUploadComponent } from './pure-originals/pure-originals-upload/pure-originals-upload.component';
import { ForgotPasswordComponent } from './login-page/forgot-password/forgot-password.component';
import { EmailConfirmationFormComponent } from './login-page/forgot-password/email-confirmation-form/email-confirmation-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './reset-password/reset-password-form/reset-password-form.component';
import { ForgotPasswordVerificationComponent } from './login-page/forgot-password/forgot-password-verification/forgot-password-verification.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {CorrectEcuProducersComponent} from "./correct-ecu-producers/correct-ecu-producers.component";
import {WinOlsTransactionComponent} from "./win-ols-transaction/win-ols-transaction.component";
import {
    WinOlsTransactionTableComponent
} from "./win-ols-transaction/win-ols-transaction-table/win-ols-transaction-table.component";
import {
    TwoFactorLoginTemplateComponent
} from "./login-page/two-factor-login-template/two-factor-login-template.component";
import {
    TwoFactorActivateTemplateComponent
} from "./user-settings-page/two-factor-activate-template/two-factor-activate-template.component";
import {
    MfaTemplatePlaceholderComponent
} from "./user-settings-page/mfa-template-placeholder/mfa-template-placeholder.component";
import {FileServiceFiltersComponent} from "./shared/file-service-filters/file-service-filters.component";
import {MasterFiltersComponent} from "./shared/master-filters/master-filters.component";
import {WinOlsCardsComponent} from "./dashboard/win-ols-cards/win-ols-cards.component";
import {AllUsersStatisticsComponent} from "./statistics/user-statistics-wrapper/all-users-statistics/all-users-statistics.component";
import {
    UsersStatisticsDetailsComponent
} from "./statistics/user-statistics-wrapper/users-statistics-details/users-statistics-details.component";
import {
    MostRequestedTuningFiltersComponent
} from "./shared/most-requested-tuning-filters/most-requested-tuning-filters.component";
import {FaultyPureOriginalsComponent} from "./ecu-names-wrapper/faulty-pure-originals/faulty-pure-originals.component";
import {SearchPureOriginalsComponent} from "./pure-originals/search-pure-originals/search-pure-originals.component";
import {UploadFileDialogComponent} from "./helpers/upload-file-dialog/upload-file-dialog.component";
import {
    ResellerTuningCombinationsComponent
} from "./reseller-tuning-combinations/reseller-tuning-combinations.component";



@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        StatisticsComponent,
        ActionButtonsComponent,
        ShortenPipe,
        LoginPageComponent,
        UploadFileComponent,
        ToastComponent,
        LayoutComponent,
        FileServiceTableComponent,
        AllMastersDatabaseComponent,
        MasterRecordsComponent,
        PureOriginalsComponent,
        PureOriginalsTableComponent,
        PureOriginalDetailsComponent,
        ResellerRecordsComponent,
        MasterResultsComponent,
        DashboardComponent,
        LoginFormComponent,
        DragAndDropDirective,
        UserInputsComponent,
        StatisticCardsComponent,
        TodolistComponent,
        UploadingActionsComponent,
        SystemInfoChartComponent,
        CpuDetailsComponentComponent,
        DiskSpaceComponentComponent,
        HeapServerMemoryComponentComponent,
        SignupFormComponent,
        PureOriginalsResultsComponent,
        RequestedTunings,
        PureOriginalResultsTableComponent,
        RequestedTuningsTables,
        PureOriginalEcuDetailsComponent,
        RegisterUserComponent,
        RegistrationTemplateComponent,
        ActivationTemplateComponent,
        UserSettingsPageComponent,
        PureOriginalsInfoComponent,
        PureOriginalMatchingDetailsComponent,
        AdminPanelComponent,
        AllUsersTableComponent,
        SkeletonTableComponent,
        FileUploadModalComponent,
        PureOriginalInfoSkeletonComponent,
        PureOriginalMatchingDetailsSkeletonComponent,
        GlobalSearchComponent,
        WeatherAndTimeComponent,
        TuningNamesTableComponent,
        EcuBuildNamesTableComponent,
        FaultyMastersComponent,
        EcuNamesWrapperComponent,
        MasterRecordsTableComponent,
        EditMasterDialogComponent,
        DeleteDialogComponent,
        TuningNamesWrapperComponent,
        AddDialogComponent,
        MasterDetailsComponent,
        UserInputCarDetailsComponent,
        UserInputSelectionComponent,
        MasterDetailsWrapperComponent,
        MasterVersionDetailsComponent,
        RequestedTuningsWrapperComponent,
        RestartDialogComponent,
        NumberOfProcessedFilesComponent,
        NumberOfFilesInQueueComponent,
        ServerStatsComponent,
        CpuUsageComponent,
        UserModalMobileComponent,
        ServerStatsPlaceholderComponent,
        ServerStatsModalComponent,
        SidebarServerStatsComponent,
        ClaimFileComponent,
        ClaimedFilesCardComponent,
        PureOriginalTableWrapperComponent,
        UserModalComponentComponent,
        ThemePickerComponent,
        SidebarListComponent,
        DataPickerModalComponent,
        DataRangePickerModalComponent,
        EmptyTableComponent,
        PureOriginalsMatchingDetailsTableComponent,
        SelectPartnerComponent,
        EditPureOriginalDialogComponent,
        PureOriginalsUploadComponent,
        ForgotPasswordComponent,
        EmailConfirmationFormComponent,
        ResetPasswordComponent,
        ResetPasswordFormComponent,
        ForgotPasswordVerificationComponent,
        CorrectEcuProducersComponent,
        WinOlsTransactionComponent,
        WinOlsTransactionTableComponent,
        TwoFactorLoginTemplateComponent,
        TwoFactorActivateTemplateComponent,
        MfaTemplatePlaceholderComponent,
        AllUsersStatisticsComponent,
        UsersStatisticsDetailsComponent,
        FaultyPureOriginalsComponent,
        SearchPureOriginalsComponent,
        UploadFileDialogComponent,
        ResellerTuningCombinationsComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        InfiniteScrollModule,
        HttpClientModule,
        NgxDatatableModule,
        NgbTooltipModule,
        FormsModule,
        NgbModule,
        SharedModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgbModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            closeButton: true,
            positionClass: 'toast-bottom-left',
            enableHtml: true
        }),
        NgOptimizedImage,
        MatDialogModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatTooltipModule,
        FileServiceFiltersComponent,
        MasterFiltersComponent,
        WinOlsCardsComponent,
        MostRequestedTuningFiltersComponent,


    ],
    providers: [
        {provide: 'PRODUCTION', useValue: environment.production},
        {provide: 'API_URL', useValue: environment.url},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: 'LOCAL_STORAGE_KEY', useValue: 'pureOriginalsFilter'},
        {provide: 'LOCAL_FILE_KEY', useValue: 'fileServiceFilter'},
        {provide: 'LOCAL_MASTER_KEY', useValue: 'masterFilter'},
        {provide: 'LOCAL_MISSING_KEY', useValue: 'missingFilter'},
        DatePipe
    ],
    exports: [
        SkeletonTableComponent,
        DataRangePickerModalComponent,
        PureOriginalsTableComponent,
        DragAndDropDirective
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
