<section class="main">
    <div class="header-wrapper">
        <div class="table-title">
            <div class="head-button">
                <button class="r-btn" (click)="backToMainTable()"><i class="icon bi bi-arrow-left"></i></button>
                <span class="head-text"></span>
            </div>
            <div class="title-text">
                {{ pureOriginalResultsService.tableTitle() }}
            </div>
        </div>
        @if (pureOriginalResultsService.type() === 'missing') {
            <div class="filter-wrapper">
                <div class="filtering-buttons">
                    <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i
                            class="bi bi-funnel"></i>
                        @if (filterCount() > 0) {
                            <p class="count-filter">{{ filterCount() }}</p>
                        }</button>
                </div>
            </div>
        }

        @if (pureOriginalResultsService.openAdvanceSettings()) {
            <div *ngIf="pureOriginalResultsService.type() === 'missing'">
                <app-most-requested-tuning-filters
                        [displayedColumns]="pureOriginalResultsService.displayedColumns()"
                        (mostRequestedFilterClosed)="onAdvancedSettingClosed($event)"
                        (selectedFiltersChange)="selectFilters($event)"
                        (filterCount)="updateFilterCount($event)"
                ></app-most-requested-tuning-filters>

            </div>

        }

    </div>
    @defer (when !pureOriginalResultsService.isLoading()) {
        @if (!isTableEmpty()) {
            <div class="table-container" infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="onScroll()">
                <table mat-table [dataSource]="pureOriginalResultsService.mostRequestedTunings()"
                       matSort
                       matSortDisableClear
                       [matSortActive]="pureOriginalResultsService.sortDirectionValues().active"
                       [matSortDirection]="pureOriginalResultsService.sortDirectionValues().direction"
                       (matSortChange)="changeSortDirection($event)"
                       [ngClass]="pureOriginalResultsService.displayedTableHeaders().includes('actions') ? 'sticky-header': ''">

                    @for (column of pureOriginalResultsService.displayedColumns(); track $index) {
                        <ng-container [matColumnDef]="column">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ column }}</th>
                            <td mat-cell *matCellDef="let element">{{ element[column] | shorten: 25 }}</td>

                        </ng-container>
                    }

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element" class="actions-details"
                            (click)="$event.stopPropagation()">

                            <div class="checkbox-wrapper-18">
                                <div class="round">
                                    <input type="checkbox" id="checkbox-18"
                                           [checked]="element.enabled"
                                           (change)="changeMissingTuning($event, element.id, element.tuningName)"/>
                                    <label for="checkbox-18"
                                           [matTooltip]="'Change tuning to be impossible'"></label>
                                </div>
                            </div>

                            <button class="table-buttons"
                                    (click)="deleteMissingTuning(element.id, 'PURE_ORIGINAL_MISSING_VERSIONS')"><i
                                    class="bi bi-trash"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="pureOriginalResultsService.displayedTableHeaders()"></tr>
                    <tr mat-row *matRowDef="let element; columns: pureOriginalResultsService.displayedTableHeaders()"
                        (click)="openPureOriginalDetails(+element.pureOriginalBaseId, element.tuningName)"></tr>
                </table>
            </div>
        } @else {
            <div class="empty-table-wrapper">
                <div class="empty-table">NO RESULTS</div>
            </div>
        }
    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

</section>
