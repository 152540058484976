import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MasterHistoryModel} from "../../../models/MasterHistory-model";

@Component({
  selector: 'app-master-history-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './master-history-dialog.component.html',
  styleUrl: './master-history-dialog.component.css'
})
export class MasterHistoryDialogComponent {
  constructor(public dialogRef: MatDialogRef<MasterHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    id: string,
    text: string,
    content: MasterHistoryModel[]
  }) {}

}
