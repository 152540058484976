<div class="main">
    <div class="main-container-wrapper">
        @defer (when !faultyEcuBuildsService.isLoading()) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="faultyEcuBuildsService.onScroll()">
                <table>
                    <tr class="header">
                        <th>ID</th>
                        <th>Faulty Ecu Builds</th>
                    </tr>

                    @for (element of faultyEcuBuildsService.faultyEcuBuilds(); track $index) {
                        <tr (click)="getFaultyPureOriginalsByFaultyEcuBuild(element)" [ngClass]="selectedEcu === element ? 'active' : ''">

                            <td>{{ $index + 1 }}</td>
                            <td>
                                {{ element }}
                            </td>

                        </tr>
                    } @empty {
                        <ng-container>
                            <tr>
                                <td>
                                    -
                                </td>
                                <td style="min-width: 100%">
                                    No Results
                                </td>
                            </tr>
                        </ng-container>
                    }

                </table>

            </div>
        } @placeholder (minimum 150ms) {
            <div class="skeleton-wrapper">
                <app-skeleton-table></app-skeleton-table>
            </div>
        }
    </div>
</div>

