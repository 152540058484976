<div class="details-wrapper">
    @if (allMastersService.masterDetailsIsLoading()) {
        <ng-container>
            <app-pure-original-info-skeleton></app-pure-original-info-skeleton>
        </ng-container>
    } @else {
        <div class="vehicle-info-container">
            <div class="main-title-img">
                <div class="title">
                    <span class="content-item">
                        <span class="content-item-text">
                            {{ allMastersService!.masterItem()["vehicleProducer"] }}
                        </span>
                    </span>
                </div>

                <div class="vehicle-info-card">
                    <img [src]="imageService.image" alt="" [ngClass]="!imageService.image ? 'startLoad' : ''">
                </div>

                <div class="main-details-container">
                    <span class="content-item">
                        <span class="content-item-text-details">
                            {{ allMastersService.masterItem()["vehicleSeries"] | shorten: 17 }}
                        </span>
                    </span>
                    <span class="content-item">
                        <span class="content-item-text-details">
                            {{ allMastersService.masterItem()["vehicleModelYear"] }}
                        </span>
                    </span>
                </div>
            </div>

        </div>

        <div class="new-tuning-container">
            <button class="add-tuning-button"
                    (click)="downloadMasterItem(allMastersService.masterItem().id, allMastersService.masterItem().winOLSReference, 'MASTER')"
                    [disabled]="isDownloadInProgress(+allMastersService.masterItem().id)">
                <span class="button-text">Download</span>
                <i class="bi bi-arrow-down-short"></i>
            </button>
            <button class="add-tuning-button"
                    (click)="deleteMasterItem(allMastersService.masterItem().id, 'MASTER')">
                <span class="button-text">Delete Master</span>
                <i class="bi bi-x"></i>
            </button>
            @if (isSuperAdmin()) {
                <div>
                    <div class="add-tuning-container">
                        <button class="add-tuning-button"
                                #historyTooltip="matTooltip"
                                [matTooltip]="tooltipMessage()"
                                matTooltipPosition="above"
                                (click)="openHistoryModal(allMastersService.masterItem().id)"
                        >
                            <span style="margin-left: 2px; margin-right: 2px" class="button-text">History</span>
                            <i class="bi bi-clock-history"></i>
                        </button>
                    </div>
                </div>
            }
            <button class="add-tuning-button"
                    (click)="editMasterItem(allMastersService.masterItem(), allMastersService.masterItem().id)">
                <i class="bi bi-pencil"></i>
            </button>
        </div>


        <form [formGroup]="masterDetailsFormGroup" (keydown.enter)="submitForm()"
              (keydown.escape)="cancelForm()">
            <div class="column-wrapper">
                <div class="first-column">
                    <div class="info-card-details">
                        <div class="info-column">
                            <span class="matching-detail-item">
                             <span class="matching-item-name">WinOls Reference</span>
                                <span class="matching-item-text">
                                {{ allMastersService.masterItem()["winOLSReference"] || '-' }}
                                </span>
                            </span>

                            <span class="matching-detail-item">
                                <span class="matching-item-name">Ecu Producer</span>
                                @if (!editFlags["ecuProducer"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuProducer', $event)">
                                {{ masterDetailsFormGroup.get('ecuProducer').value ? masterDetailObject()["ecuProducer"] : '-' }}
                                </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuProducer"
                                           (click)="stopPropagation($event)"/>
                                }
                            </span>

                            <span class="matching-detail-item">
                                 <span class="matching-item-name">Ecu Build</span>
                                @if (!editFlags["ecuBuild"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuBuild', $event)">
                            {{ masterDetailsFormGroup.get('ecuBuild').value ? masterDetailObject()["ecuBuild"] : '-' }}
                                </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuBuild"
                                           (click)="stopPropagation($event)"/>
                                }
                             </span>

                            <span class="matching-detail-item">
                            <span class="matching-item-name">Ecu Software Version</span>
                                @if (!editFlags["ecuSoftwareVersion"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuSoftwareVersion', $event)">
                            {{ masterDetailsFormGroup.get('ecuSoftwareVersion').value ? masterDetailObject()["ecuSoftwareVersion"] : '-' }}
                             </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuSoftwareVersion"
                                           (click)="stopPropagation($event)"/>
                                }
                            </span>

                            <span class="matching-detail-item">
                                  <span class="matching-item-name">Ecu Software Version Version</span>
                                @if (!editFlags["ecuSoftwareVersionVersion"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuSoftwareVersionVersion', $event)">
                            {{ masterDetailsFormGroup.get('ecuSoftwareVersionVersion').value ? masterDetailObject()["ecuSoftwareVersionVersion"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuSoftwareVersionVersion"
                                           (click)="stopPropagation($event)"/>
                                }
                                   </span>

                            <span class="matching-detail-item">
                                <span class="matching-item-name">Ecu Prod Nr</span>
                                @if (!editFlags["ecuProdNr"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuProdNr', $event)">
                            {{ masterDetailsFormGroup.get('ecuProdNr').value ? masterDetailObject()["ecuProdNr"] : '-' }}
                                </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuProdNr"
                                           (click)="stopPropagation($event)"/>
                                }
                             </span>


                        </div>

                        <div class="info-column">


                            <span class="matching-detail-item">
                    <span class="matching-item-name">Reading Hardware</span>
                                @if (!editFlags["readingHardware"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('readingHardware', $event)">
                            {{ masterDetailsFormGroup.get('readingHardware').value ? masterDetailObject()["readingHardware"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="readingHardware"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>


                            <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Producer</span>
                                @if (!editFlags["vehicleProducer"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('vehicleProducer', $event)">
                            {{ masterDetailsFormGroup.get('vehicleProducer').value ? masterDetailObject()["vehicleProducer"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="vehicleProducer"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>

                            <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Series</span>
                                @if (!editFlags["vehicleSeries"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('vehicleSeries', $event)">
                            {{ masterDetailsFormGroup.get('vehicleSeries').value ? masterDetailObject()["vehicleSeries"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="vehicleSeries"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>

                            <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Model</span>
                                @if (!editFlags["vehicleModel"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('vehicleModel', $event)">
                            {{ masterDetailsFormGroup.get('vehicleModel').value ? masterDetailObject()["vehicleModel"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="vehicleModel"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>


                            <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Name</span>
                                @if (!editFlags["engineName"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('vehicleName', $event)">
                            {{ masterDetailsFormGroup.get('engineName').value ? masterDetailObject()["engineName"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuStgNr"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>

                            <span class="matching-detail-item">
                    <span class="matching-item-name">Ecu Stg Nr</span>
                                @if (!editFlags["ecuStgNr"]) {
                                    <span class="matching-item-text cursor"
                                          (click)="toggleEdit('ecuStgNr', $event)">
                            {{ masterDetailsFormGroup.get('ecuStgNr').value ? masterDetailObject()["ecuStgNr"] : '-' }}
                        </span>
                                } @else {
                                    <input type="text" class="matching-item-text"
                                           formControlName="ecuStgNr"
                                           (click)="stopPropagation($event)"/>
                                }
                </span>
                        </div>
                    </div>
                </div>

            </div>


            <div *ngIf="showMore" class="info-card-details">
                <div class="info-column">
                <span class="matching-detail-item">
                         <span class="matching-item-name">Type</span>
                    @if (!editFlags["engineType"]) {
                        <span class="matching-item-text cursor"
                              (click)="toggleEdit('engineType', $event)">
                            {{ masterDetailsFormGroup.get('engineType').value ? masterDetailObject()["engineType"] : '-' }}
                        </span>
                    } @else {
                        <input type="text" class="matching-item-text"
                               formControlName="engineType"
                               (click)="stopPropagation($event)"/>
                    }
                     </span>


                    <span class="matching-detail-item">
                                <span class="matching-item-name">Date Uploaded</span>
                                <span class="matching-item-text">
                             {{ allMastersService.masterItem()["dateUploaded"] || '-' }}
                             </span>
                            </span>



                    <span class="matching-detail-item">
                    <span class="matching-item-name">Date Exported</span>
                    <span class="matching-item-text">
                        {{ allMastersService.masterItem()[""] || '-' }}
                    </span>
                </span>
                    <span class="matching-detail-item">
                    <span class="matching-item-name">Date Updated</span>
                    <span class="matching-item-text">
                        {{ allMastersService.masterItem()[""] || '-' }}
                    </span>
                </span>
                    <span class="matching-detail-item">
                    <span class="matching-item-name">Transmission</span>
                        @if (!editFlags["engineTransmission"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('engineTransmission', $event)">
                            {{ masterDetailsFormGroup.get('engineTransmission').value ? masterDetailObject()["engineTransmission"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="engineTransmission"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Engine Producer</span>
                        @if (!editFlags["engineProducer"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('engineProducer', $event)">
                            {{ masterDetailsFormGroup.get('engineProducer').value ? masterDetailObject()["engineProducer"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="engineProducer"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">Engine Emission Standard</span>
                        @if (!editFlags["engineEmissionStandard"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('engineEmissionStandard', $event)">
                            {{ masterDetailsFormGroup.get('engineEmissionStandard').value ? masterDetailObject()["engineEmissionStandard"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="engineEmissionStandard"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Engine Displacement</span>
                        @if (!editFlags["engineDisplacement"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('engineDisplacement', $event)">
                            {{ masterDetailsFormGroup.get('engineDisplacement').value ? masterDetailObject()["engineDisplacement"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="engineDisplacement"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Build</span>
                        @if (!editFlags["vehicleBuild"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('vehicleBuild', $event)">
                            {{ masterDetailsFormGroup.get('vehicleBuild').value ? masterDetailObject()["vehicleBuild"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="vehicleBuild"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Model Year</span>
                        @if (!editFlags["vehicleModelYear"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('vehicleModelYear', $event)">
                            {{ masterDetailsFormGroup.get('vehicleModelYear').value ? masterDetailObject()["vehicleModelYear"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="vehicleModelYear"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 1</span>
                        @if (!editFlags["userDef1"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef1', $event)">
                            {{ masterDetailsFormGroup.get('userDef1').value ? masterDetailObject()["userDef1"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef1"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 2</span>
                        @if (!editFlags["userDef2"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef2', $event)">
                            {{ masterDetailsFormGroup.get('userDef2').value ? masterDetailObject()["userDef2"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef2"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 3</span>
                        @if (!editFlags["userDef3"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef3', $event)">
                            {{ masterDetailsFormGroup.get('userDef3').value ? masterDetailObject()["userDef3"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef3"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 4</span>
                        @if (!editFlags["userDef4"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef4', $event)">
                            {{ masterDetailsFormGroup.get('userDef4').value ? masterDetailObject()["userDef4"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef4"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 5</span>
                        @if (!editFlags["userDef5"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef5', $event)">
                            {{ masterDetailsFormGroup.get('userDef5').value ? masterDetailObject()["userDef5"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef5"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 6</span>
                        @if (!editFlags["userDef6"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef6', $event)">
                            {{ masterDetailsFormGroup.get('userDef6').value ? masterDetailObject()["userDef6"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef6"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 7</span>
                        @if (!editFlags["userDef7"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef7', $event)">
                            {{ masterDetailsFormGroup.get('userDef7').value ? masterDetailObject()["userDef7"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef7"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 11</span>
                        @if (!editFlags["userDef11"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef11', $event)">
                            {{ masterDetailsFormGroup.get('userDef11').value ? masterDetailObject()["userDef11"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef11"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 12</span>
                        @if (!editFlags["userDef12"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef12', $event)">
                            {{ masterDetailsFormGroup.get('userDef12').value ? masterDetailObject()["userDef12"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef12"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                </div>
                <div class="info-column">

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Ecu Use</span>
                        @if (!editFlags["ecuUse"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('ecuUse', $event)">
                            {{ masterDetailsFormGroup.get('ecuUse').value ? masterDetailObject()["ecuUse"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="ecuUse"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                                <span class="matching-item-name">Vehicle Vin</span>
                                <span class="matching-item-text">
                             {{ allMastersService.masterItem()["vin"] || '-' }}
                             </span>
                    </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Reseller Project Details</span>
                        @if (!editFlags["resellerProjectDetails"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('resellerProjectDetails', $event)">
                            {{ masterDetailsFormGroup.get('resellerProjectDetails').value ? masterDetailObject()["resellerProjectDetails"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="resellerProjectDetails"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Reseller Project Type</span>
                        @if (!editFlags["resellerProjectType"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('resellerProjectType', $event)">
                            {{ masterDetailsFormGroup.get('resellerProjectType').value ? masterDetailObject()["resellerProjectType"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="resellerProjectType"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>
                    <span class="matching-detail-item">
                    <span class="matching-item-name">Reseller Credits</span>
                        @if (!editFlags["resellerCredits"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('resellerCredits', $event)">
                            {{ masterDetailsFormGroup.get('resellerCredits').value ? masterDetailObject()["resellerCredits"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="resellerCredits"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">Hp</span>
                        @if (!editFlags["outputPs"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('outputPs', $event)">
                            {{ masterDetailsFormGroup.get('outputPs').value ? masterDetailObject()["outputPs"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="outputPs"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Kw</span>
                        @if (!editFlags["outputKw"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('outputKw', $event)">
                            {{ masterDetailsFormGroup.get('outputKw').value ? masterDetailObject()["outputKw"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="outputKw"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">Nm</span>
                        @if (!editFlags["engineMaxTorque"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('engineMaxTorque', $event)">
                            {{ masterDetailsFormGroup.get('engineMaxTorque').value ? masterDetailObject()["engineMaxTorque"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="engineMaxTorque"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Type</span>
                        @if (!editFlags["vehicleType"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('vehicleType', $event)">
                            {{ masterDetailsFormGroup.get('vehicleType').value ? masterDetailObject()["vehicleType"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="vehicleType"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Vehicle Characteristic </span>
                        @if (!editFlags["vehicleCharacteristic"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('vehicleCharacteristic', $event)">
                            {{ masterDetailsFormGroup.get('vehicleCharacteristic').value ? masterDetailObject()["vehicleCharacteristic"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="vehicleCharacteristic"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">Credit type</span>
                        @if (!editFlags["vehicleCharacteristic"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('creditType', $event)">
                            {{ masterDetailsFormGroup.get('creditType').value ? masterDetailObject()["creditType"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="creditType"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>




                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 13</span>
                        @if (!editFlags["userDef13"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef13', $event)">
                            {{ masterDetailsFormGroup.get('userDef13').value ? masterDetailObject()["userDef13"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef13"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 14</span>
                        @if (!editFlags["userDef14"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef14', $event)">
                            {{ masterDetailsFormGroup.get('userDef14').value ? masterDetailObject()["userDef14"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef14"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 15</span>
                        @if (!editFlags["userDef15"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef15', $event)">
                            {{ masterDetailsFormGroup.get('userDef15').value ? masterDetailObject()["userDef15"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef15"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 16</span>
                        @if (!editFlags["userDef16"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef16', $event)">
                            {{ masterDetailsFormGroup.get('userDef16').value ? masterDetailObject()["userDef16"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef16"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 17</span>
                        @if (!editFlags["userDef17"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef17', $event)">
                            {{ masterDetailsFormGroup.get('userDef17').value ? masterDetailObject()["userDef17"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef17"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 18</span>
                        @if (!editFlags["userDef18"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef18', $event)">
                            {{ masterDetailsFormGroup.get('userDef18').value ? masterDetailObject()["userDef18"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef18"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 17</span>
                        @if (!editFlags["userDef19"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef19', $event)">
                            {{ masterDetailsFormGroup.get('userDef19').value ? masterDetailObject()["userDef19"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef19"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>


                    <span class="matching-detail-item">
                    <span class="matching-item-name">User Def 20</span>
                        @if (!editFlags["userDef20"]) {
                            <span class="matching-item-text cursor"
                                  (click)="toggleEdit('userDef20', $event)">
                            {{ masterDetailsFormGroup.get('userDef20').value ? masterDetailObject()["userDef20"] : '-' }}
                        </span>
                        } @else {
                            <input type="text" class="matching-item-text"
                                   formControlName="userDef20"
                                   (click)="stopPropagation($event)"/>
                        }
                      </span>

                </div>


            </div>
            @if (isVisibleButton()) {
                <div class="save-button">
                    <button type="button" class="add-tuning-button"
                            (click)="submitForm()" [disabled]="!masterDetailsFormGroup.valid">Save
                    </button>
                    <button type="button" class="add-tuning-button"
                            (click)="cancelForm()">Cancel
                    </button>
                </div>

            }

        </form>

        <div class="button-wrapper">
            <button type="button" class="add-tuning-button" (click)="toggleShowMore()">
                {{ showMore ? 'See less details...' : 'See more details... ' }}
            </button>
        </div>


        <div class="file-upload-container">
            <app-upload-file (file)="uploadIniFile($event)" [error]="allMastersService.myInputs.get('ini').message"
                             [inputText]="allMastersService.inputText" customBackground="var(--primary-nav-bg)"
                             [progressWidth]="allMastersService.progressWidth()"
                             [resetSignal]="allMastersService.resetSignal()"></app-upload-file>
        </div>

    }
</div>
