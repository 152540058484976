<div>

    <div class="title" mat-dialog-title>
        <h2>Add new Ecu</h2>
    </div>
    <div class="underline"></div>


    <mat-dialog-content [formGroup]="addNewEcuForm" (keydown.enter)="saveNewRow()">
        <mat-form-field class="input-with-button">
            <mat-label>Correct Ecu Build Names</mat-label>
            <input matInput type="text" formControlName="correctEcuBuildNames"/>
            <mat-error *ngIf="addNewEcuForm.controls['correctEcuBuildNames'].hasError('required')">
                Please enter a correct Ecu Name.
            </mat-error>
            <mat-error *ngIf="addNewEcuForm?.controls['correctEcuBuildNames']?.hasError('ecuBuildExists')">
                EcuBuild already exists in Database.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="input-with-button">
            <mat-label>Connected Ecu Build Names</mat-label>
            <input matInput type="text" formControlName="connectedEcuBuildNames"/>
        </mat-form-field>
        <mat-form-field class="input-with-button">
            <mat-label>Accepted Ecu Build Names</mat-label>
            <input matInput type="text" formControlName="acceptedEcuBuildNames"/>
            <mat-error *ngIf="addNewEcuForm.controls['acceptedEcuBuildNames'].hasError('required')">
                Please enter a accepted Ecu Name.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="input-with-button">
            <mat-label>Correct Ecu Producer</mat-label>
            <input matInput type="text" formControlName="correctEcuProducer"/>
            <mat-error *ngIf="addNewEcuForm.controls['correctEcuProducer'].hasError('required')">
                Please enter a Correct Ecu Producer.
            </mat-error>
        </mat-form-field>
        <div mat-dialog-actions class="button-actions">
            <button mat-raised-button class="dialog-save-btn"
                    (click)="saveNewRow()">Save
            </button>
            <button mat-raised-button class="dialog-btn" (click)="canceledOption()">Close</button>
        </div>
    </mat-dialog-content>

</div>
