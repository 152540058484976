import {Component, EventEmitter, inject, Inject, Input, OnInit, Output} from '@angular/core';
import {KeyValuePipe, NgForOf} from "@angular/common";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedModule} from "../shared.module";
import {SearchFilterModel} from "../../models/SearchFilter-model";
import {FileService} from "../../service/FileService.service";
import {LocalStorageService} from "../../service/LocalStorageService";

@Component({
    selector: 'app-file-service-filters',
    standalone: true,
    imports: [
        KeyValuePipe,
        MatFormField,
        MatInput,
        MatOption,
        MatSelect,
        NgForOf,
        ReactiveFormsModule,
        SharedModule
    ],
    templateUrl: './file-service-filters.component.html',
    styleUrl: './file-service-filters.component.css'
})


export class FileServiceFiltersComponent implements OnInit {

    public selectedOptions: SearchFilterModel[] = [];
    public groupKey: string = '';

    @Input() displayedColumns: undefined | string[];
    @Output() fileServiceFilterClosed = new EventEmitter<boolean>;
    @Output() fileServiceSelectedFilters = new EventEmitter<SearchFilterModel[]>;
    @Output() selectedFiltersChange = new EventEmitter<SearchFilterModel[]>();
    @Output() filterCount = new EventEmitter<number>();

    private fileService = inject(FileService);
    private localStorageService = inject(LocalStorageService)


    searchFilterForm = new FormGroup({})


    constructor(@Inject('LOCAL_FILE_KEY') private fileServiceFilter: string) {
    }

    addFormControl() {
        const control = new FormGroup({
            'option': new FormControl(this.displayedColumns![0]),
            'value': new FormControl('', Validators.required)
        })
        this.searchFilterForm.addControl(`control-${Object.keys(this.searchFilterForm.controls).length + 1}`, control);
    }


    ngOnInit(): void {
        const savedFilters = this.localStorageService.getFilters(this.fileServiceFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.searchFilterForm = this.localStorageService.createFormGroupFromFilters(savedFilters, this.displayedColumns || []);
            this.fileServiceSelectedFilters.emit(savedFilters);
            this.filterCount.emit(savedFilters.length);
        }
    }


    clearFilters() {
        this.localStorageService.clearFilters(this.fileServiceFilter);

        const controlKeys = Object.keys(this.searchFilterForm.controls);
        controlKeys.forEach(key => {
            this.searchFilterForm.removeControl(key);
        });
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
        this.fileService.getAllFileServiceRecords()
    }


    closeModal() {
        this.fileServiceFilterClosed.emit(false);
    }

    onSubmit() {
        for (const group in this.searchFilterForm.controls) {
            const value = this.searchFilterForm.get(group)?.value.value as string;
            const trimmedValue = value.trim();
            this.selectedOptions.push({
                option: this.searchFilterForm.get(group)?.value.option,
                value: trimmedValue
            });
        }
        this.localStorageService.saveFilters(this.fileServiceFilter, this.selectedOptions);
        this.fileServiceSelectedFilters.emit(this.selectedOptions)
        this.fileServiceFilterClosed.emit(false);
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length)
    }


    deleteSearchBar(key: string) {
        this.searchFilterForm.removeControl(key);
        console.log(this.searchFilterForm.value)
    }

    hasFormControls(): boolean {
        return Object.keys(this.searchFilterForm.controls).length > 0;
    }
}



