<div class="tuning-details-wrapper">


    @defer (when !versionService.isLoading()) {
        <div class="tuning-details-container">
            @if (versionService.allVersions().length > 0) {
                <h2>Completed</h2>
            }
            @for (item of versionService.sortListByTuningNames(); track $index) {
                <div class="tuning-details"
                     [ngClass]="versionService.versionName() == item.versionName ? 'highlight-card' : ''">
                    <div class="main-content-wrapper">
                        <div class="top-left-corner" [ngClass]="item ? 'green' : ''"></div>
                        <div class="top-right-corner">
                            <div class="main-content-header">
                                <span class="tuning color">WinOls: {{ item.masterWinOLSReference | shorten: 10 }}
                                    <span class="tooltip-text">{{ item.masterWinOLSReference }}</span></span>
                            </div>
                            <div class="main-content-header">
                                <span class="tuning color">Date: {{ versionService.formatDate(item.dateUploaded) }}</span>
                            </div>
                            <div class="tuning-title-name">
                                <span class="tuning-title">{{ item.versionName | shorten: 15 }}</span>
                                <form (click)="onEditVersionComment($index)" style="width: 100%"
                                      [formGroup]="versionService.versionCommentForm">
                                    @if ($index !== versionService.editedItemIndex() && item.versionComment) {
                                        <div class="tuning-title-comment color"
                                             matTooltip="Add version comment"
                                             [matTooltipDisabled]="versionService.isUser()"
                                             matTooltipPosition="below"
                                             [ngClass]="versionService.isUser() ? 'disabled' : ''">{{ item.versionComment | shorten: 25 }}
                                        </div>
                                    }
                                    <div class="version-comment-edit-container">
                                        @if ($index === versionService.editedItemIndex()) {
                                            <input class="versionCommentInput"
                                                   type="text"
                                                   formControlName="versionComment"
                                                   (keyup.enter)="saveVersionComment(+item.id)"
                                                   [value]="item.versionComment ? item.versionComment : ''"
                                            />
                                        }
                                        @if ($index === versionService.editedItemIndex() && !versionService.isLoading()) {
                                            <div class="version-comment-buttons">
                                                <button (click)="saveVersionComment(+item.id)"
                                                        class="versionCommentButton"><i class="bi bi-check"></i>
                                                </button>
                                                <button (click)="closeEditMode()"
                                                        class="versionCommentButton close"><i class="bi bi-x"></i>
                                                </button>
                                            </div>
                                        }

                                    </div>
                                    @if (versionService.isLoading() && $index === versionService.editedItemIndex()) {
                                        <div class="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    }
                                    @if (!item.versionComment && $index !== versionService.editedItemIndex()) {
                                        <div class="tuning-title-comment color"
                                             matTooltip="Add version comment"
                                             [matTooltipDisabled]="versionService.isUser()"
                                             matTooltipPosition="below"
                                             [ngClass]="versionService.isUser() ? 'disabled' : ''">No Comment
                                        </div>
                                    }

                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="button-container-position">
                        <div class="buttons-complete">
                            <button class="r-btn complete"
                                    (click)="openPureOriginalsByVersionId(+item.id, item.versionName, item.masterWinOLSReference)"
                                    [disabled]="versionService.isUser()">
                                <i
                                        class="bi bi-list-task"></i></button>
                            <button class="r-btn partially-tuned-button"
                                    (click)="openPartiallyTunePureOriginalsByVersionId(+item.id, item.versionName, item.masterWinOLSReference)"
                                    [disabled]="versionService.isUser()">
                                <i
                                        class="bi bi-list-task"></i></button>
                            <button class="r-btn delete"
                                    (click)="deleteTuningRecordFromTable(+item.id,  'VERSION')"
                                    [disabled]="versionService.isUser()"><span
                                    class="btn-text">Delete</span>
                            </button>
                        </div>
                        <div class="download-buttons-container">
                            <button
                                    (click)="downloadMasterVersion(+item.id, item.masterWinOLSReference, item.versionName)"
                                    [disabled]="isDownloadInProgress(+versionService.masterId())"><span> Download <i
                                    class="bi bi-arrow-down-short"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    } @placeholder (minimum 150ms) {
        <app-pure-original-matching-details-skeleton></app-pure-original-matching-details-skeleton>
    }

</div>


