<button (click)="openServerStatsModal()" class="open-modal-button"
        [ngClass]="statisticService.isServerStatsModalOpened() ? 'rotate' : ''">
    <i class="bi bi-chevron-down"></i></button>

@if (statisticService.isServerStatsModalOpened()) {
    <div (click)="closeModal()" class="close-modal"></div>
}
<div class="server-stats-modal" [ngClass]="statisticService.isServerStatsModalOpened() ? 'active' : ''"
     [@fade]="statisticService.isServerStatsModalOpened() ? 'open' : 'void'">

    <div class="processed-files-container">
        <app-number-of-processed-files
                [numberOfProcessedFiles]="pureOriginalService.numberOfProcessedFiles()"></app-number-of-processed-files>
    </div>

    <div class="server-stats-wrapper">
        <app-server-stats [isLoading]="statisticService.isLoading()"
                          [systemInfoData]="statisticService.systemInfoData()"></app-server-stats>
    </div>

</div>
