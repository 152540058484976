<div class="sidebar-container padding-block">
    <div class="nav-list-wrapper">
        <div class="logo-wrapper">
            @if (PRODUCTION === false && !isSmallScreen()) {
                <div class="line">STAGING</div>
            }
            @if (!statisticService.isThemeChanged() && !isSmallScreen() && !statisticService.isOpened()) {
                <a routerLink="/app" class="logo"><img
                        src="assets/logo-white.png"
                        alt=""/></a>
            }
            @if (statisticService.isThemeChanged() && !isSmallScreen() && !statisticService.isOpened()) {
                <a routerLink="/app" class="logo"><img src="assets/logo-black.svg" alt=""/></a>
            }
        </div>

        @if (isSmallScreen()) {
            <div class="user-menu">
                @if (isAdmin()) {
                    <ng-container>
                        @if (statisticService.isOpened()) {
                            <ng-container>
                                <app-sidebar-server-stats
                                        [isSideBarOpened]="statisticService.isOpened()"></app-sidebar-server-stats>
                            </ng-container>
                        }

                        <div class="filesInQueue-container">
                            <app-number-of-files-in-queue></app-number-of-files-in-queue>
                        </div>
                    </ng-container>
                }
            </div>
        }

        <app-select-partner></app-select-partner>

        <app-sidebar-list></app-sidebar-list>

    </div>
    <div class="second-sidebar-container">

        <div class="theme-picker-wrapper">
            <app-theme-picker (changeTheme)="getChangedThemeProperty($event)"></app-theme-picker>
        </div>

        <button class="button" data-toggler="nav-toggler" (click)="shrinkSidebarNavigation()"
                [ngClass]="!statisticService.isOpened() ? '' : 'show-btn'">
            <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                <path stroke="#635FC7" stroke-width="2" fill="white" d="m1 1 4 4 4-4"/>
            </svg>
            <span>Hide Sidebar</span>
        </button>
    </div>
</div>


