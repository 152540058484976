<table mat-table [dataSource]="pureOriginals()"
       multiTemplateDataRows matSort matSortDisableClear [matSortDirection]="sortDirectionValues().direction"
       [matSortActive]="sortDirectionValues().active"
       (matSortChange)="changePureOriginalsTableSortDirection($event)">

    <mat-dialog-content>
        <ng-container *ngFor="let column of displayedOriginalColumns()" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{
                    'blue' : element.ecuUse === 'TCU',
                    'done': element.status === 'DONE',
                    'waiting': element.status === 'WAITING',
                    'in-progress': element.status === 'IN_PROGRESS',
                    'error-text': element.status === 'ERROR'
                }">
                {{ element[column] | shorten: 25 }}
            </td>
        </ng-container>


    </mat-dialog-content>
    <mat-dialog-actions>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                <div [ngStyle]="{'display':'flex', 'justify-content': 'center', 'flex-direction':'row', 'gap': '10px', 'align-items': 'center'}">
                    Actions

                    @if (headerActionButton) {

                        <input
                                type="checkbox"
                                [id]="'select-all-checkbox'"
                                [checked]="isAllSelected()"
                                (change)="toggleSelectAll($event)">
                    }
                </div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">

                <ng-container *ngIf="actionsTemplate; else defaultActions">
                    <ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: element }"></ng-container>
                </ng-container>

                <ng-template #defaultActions>
                    <button class="table-buttons"
                            (click)="deletePureOriginalItem(element, +element.id, 'PURE_ORIGINAL_BASE')">
                        <i class="bi bi-trash"></i>
                    </button>
                    <button class="table-buttons" [disabled]="!element.id"
                            (click)="openPureOriginalDetails(+element.id)">
                        <i class="bi bi-eye"></i>
                    </button>
                    <button *ngIf="authService.isAdmin()" class="table-buttons"
                            matTooltip="Upload Ini file" matTooltipPosition="below"
                            (click)="openPureOriginalUploadModal(element.id)">
                        <i class="bi bi-file-arrow-up"></i>
                    </button>
                </ng-template>
            </td>
        </ng-container>
    </mat-dialog-actions>

    <tr mat-header-row *matHeaderRowDef="displayedTableHeaders()"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedTableHeaders()"></tr>

</table>


@if (isModalOpened()) {
    <app-upload-file-dialog
            (isFileModalClosed)="fileModalClosed()"
            [title]="'Upload Pure Original Ini File'"
    >
        <div class="upload-file-container">
            <app-upload-file (file)="uploadIniFile($event)" [error]="pureOriginalsService.myInputs.get('ini').message"
                             [inputText]="pureOriginalsService.inputText" customBackground="var(--primary-nav-bg)"
                             [progressWidth]="pureOriginalsService.progressWidth()"
                             [resetSignal]="pureOriginalsService.resetSignal()"></app-upload-file>
        </div>
    </app-upload-file-dialog>
}
