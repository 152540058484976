import {Component, ElementRef, inject, OnInit, signal} from '@angular/core';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CommonModule, Location} from "@angular/common";
import {UserStatisticsService} from "../../../../service/User-statistics-service";
import {ActivatedRoute, Router} from "@angular/router";
import {MasterStatsModel} from "../../../../models/MasterStats-model";
import {SharedModule} from "../../../../shared/shared.module";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-master-stats',
  standalone: true,
    imports: [
        InfiniteScrollModule,
        CommonModule,
        SharedModule,
        MatTooltip
    ],
  templateUrl: './master-stats.component.html',
  styleUrl: './master-stats.component.css'
})
export class MasterStatsComponent implements OnInit{

    userId = signal<number>(-1);
    userStatisticsService = inject(UserStatisticsService);
    location = inject(Location);

    router = inject(Router);
    route = inject(ActivatedRoute);
    elRef = inject(ElementRef);

    expandedElement: MasterStatsModel;
    selectedElement: MasterStatsModel;
    expandedDetails: any[] = [];
    tooltipPosition : 'above'| 'below' | 'left' | 'right' = 'below';


    allPureOriginalStatsHeaders = signal<MasterStatsModel>(new MasterStatsModel())
    public displayedOriginalColumns = signal<(keyof MasterStatsModel)[]>([]);
    public displayedTableHeaders = signal<string[]>([]);


    ngOnInit() {
        this.getParamsId();
        this.userStatisticsService.resetTableUserDetails();
        this.getTableHeaderNamesForPureOriginals();
    }

    getTableHeaderNamesForPureOriginals() {
        let objectKeys = Object.keys(this.allPureOriginalStatsHeaders());
        for (let key of objectKeys) {
            this.displayedOriginalColumns().push(key as keyof MasterStatsModel);
        }
        this.displayedTableHeaders.set([...this.displayedOriginalColumns()]);
    }

    getQueryParams() {
        this.route.queryParams.subscribe((queryParam) => {
            const startDate = queryParam["startDate"];
            const endDate = queryParam["endDate"];
            this.userStatisticsService.startDate = startDate;
            this.userStatisticsService.endDate = endDate;
        })
    }

    getParamsId() {
        this.route.parent?.params.subscribe((params) => {  // Access parent route params
            if (params && params["id"] !== undefined) {
                this.userStatisticsService.resetTableUserDetails();
                this.userId.set(Number(params["id"]));
                this.getQueryParams();
                this.userStatisticsService.getMasterStatisticsDetails(this.userId());
            } else {
                console.error("User ID is missing from parent route params!");
            }
        });
    }

    onScroll() {
        this.userStatisticsService.increasePageNumber()
        this.userStatisticsService.onScrollMasterStats(this.userId());
    }

    navigateToMasterId(event: MouseEvent, masterId: number) {
        event.stopPropagation();
        const selection = window.getSelection();

        if (selection && !selection.isCollapsed && selection.toString().trim().length > 0) {
            return;
        }
        this.router.navigate([`/app/master-details/${masterId}`]);
    }

    toggleExpandedRow(element: MasterStatsModel, event: MouseEvent): void {
        event.stopPropagation();
        this.selectedElement = element;
        if (this.expandedElement === element) {
            this.expandedElement = null;
            this.expandedDetails = [];
        } else {
            this.expandedElement = element;
            this.fetchExpandedDetails(element.masterId);
        }
    }

    fetchExpandedDetails(masterId: string): void {
        console.log(masterId, "PURE ORIGINAL ID");
        console.log(this.userId());
        this.userStatisticsService.masterStatisticsTableDetails.set([]);

        this.userStatisticsService.getMasterStatisticsDetailsWithMasterId(this.userId(), masterId);

        console.log(this.userStatisticsService.pureOriginalsTableStatisticDetails())
    }

    openMasterDetailsPageWithMasterId(masterId: number, versionName: string) {
        this.router.navigate([`/app/master-details/${masterId}`], {
            queryParams: {
                masterId,
                versionName
            }
        });
    }


    changeSortDirection(activeSortDirectionValue : string) {
        const tableElement: HTMLElement = this.elRef.nativeElement.querySelector('.table-container');

        this.userStatisticsService.pageNumber.set(0);
        const direction = this.userStatisticsService.sortDirectionValues().direction === 'asc' ? 'desc' : 'asc';
        console.log(direction, "Direction");
        this.userStatisticsService.sortDirectionValues.set({active: activeSortDirectionValue, direction: direction });
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        this.userStatisticsService.getMasterStatisticsDetails(this.userId());
    }

    onSearchInput($event: any) {
        this.userStatisticsService.searchString.set($event.valueOf());
        this.userStatisticsService.searchMasterStatsByWinOls(this.userId())
    }

    backToMainTable() {
        this.location.back()
    }
}
