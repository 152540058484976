import {Component, OnInit} from '@angular/core';
import {PartnersService} from "../../service/Partners.service";
import {CurrentPartner} from "../../service/Partners.service";
import {AuthService} from "../../service/auth.service";


@Component({
  selector: 'app-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.css']
})
export class SelectPartnerComponent implements OnInit {

  public dynoPartner = "Dyno-ChipTuningFiles.com";
  public atmPartner = "Performance-TuningFiles.com";
  public dummyPartnersList: string[];
  public selectedPartner: string;

  constructor(public partnersService: PartnersService, private authService: AuthService) {

    this.dummyPartnersList = [this.dynoPartner, this.atmPartner];
    this.dummyPartnersList = [CurrentPartner.DynoChipTuningFiles, CurrentPartner.ATMChipTunings];
  }

  ngOnInit() {
    this.selectedPartner = this.partnersService.currentPartner;
    this.partnersService.setNewPartner(CurrentPartner.DynoChipTuningFiles);
  }

  setPartner(selectedPartner: CurrentPartner) {
    switch (selectedPartner) {
      case this.dynoPartner: {
        return CurrentPartner.DynoChipTuningFiles;
      }
      case this.atmPartner: {
        return CurrentPartner.ATMChipTunings;
      }
      default: {
        return CurrentPartner.DynoChipTuningFiles;
      }
    }
  }

  changePartner(event: any) {
    let partner = this.setPartner(event.target.value);
    this.partnersService.setNewPartner(partner);
  }
  isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }
}
