import {
    Component,
    EventEmitter,
    inject,
    input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    signal,
    SimpleChanges
} from '@angular/core';
import {PureOriginalService} from "../../../service/pure-original.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-pure-original-matching-details',
    templateUrl: './pure-original-matching-details.component.html',
    styleUrls: ['./pure-original-matching-details.component.css']
})
export class PureOriginalMatchingDetailsComponent implements OnInit, OnChanges, OnDestroy {


    subscription: Subscription;
    pureOriginalId = input<number>();
    pureOriginalMatchingId = input<number>();
    isClaimedFile = input<boolean>();
    isImpossibleTuningUploaded = input<boolean>();
    error: string;

    private disabledVersionsCheckBox = signal<string[]>([]);
    public versionNameParam = signal<string | null>(null);
    @Output() public versionName: EventEmitter<string> = new EventEmitter<string>();
    @Output() public isFileModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    pureOriginalsService = inject(PureOriginalService);
    route = inject(ActivatedRoute);
    router = inject(Router);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["isImpossibleTuningUploaded"] && !changes["isImpossibleTuningUploaded"].firstChange) {
            if (this.isImpossibleTuningUploaded()) {
                this.getPureOriginalDetails();
            }
        }
    }

    ngOnInit() {
        this.pureOriginalsService.clearInterval();
        this.pureOriginalsService.pureOriginalMatchingDetails.set(null);
        this.getPureOriginalDetails();
        this.route.queryParams.subscribe(params => {
            this.versionNameParam.set(params['versionName']);
        });
        this.startIntervalOnEvery5Seconds();
    }

    getPureOriginalDetails() {
       this.subscription = this.pureOriginalsService.getPureOriginalMatchingDetails(this.pureOriginalId());
    }

    isDownloadInProgress(id: number) {
        return this.pureOriginalsService.isDownloadInProgress(id);
    }

    downloadVersionFile(id: number, winOls: string, tableName: string) {
        this.pureOriginalsService.downloadFileFromDatabase(id, winOls, tableName);
    }

    downloadMissingTuning(id: number, versionName: string, tableName: string) {
        this.pureOriginalsService.downloadFileFromDatabase(id, versionName, tableName);
    }

    openFileUploadModal(versionName?: string) {
        this.isFileModalOpened.emit(true);
        if (versionName) {
            this.versionName.emit(versionName);
        }
    }

    checkIfVersionIsInQueue(versionName: string) {
        return this.pureOriginalsService.pureOriginalMatchingInQueue().includes(versionName);
    }

    startIntervalOnEvery5Seconds() {
        this.pureOriginalsService.startIntervalForEvery5Seconds(this.pureOriginalId());
    }

    changeTuningStatus(event: any, id: string, versionName: string) {
        this.disabledVersionsCheckBox().push(versionName);
        let state = event.valueOf().target.checked
        this.pureOriginalsService.changeImpossibleTunings(id, state).subscribe(response => {
            this.pureOriginalsService.pureOriginalMatchingDetails.set(response);
            this.disabledVersionsCheckBox.update((versions) => versions.filter(versionName => versionName != versionName));
        })
    }

    isDisabledCheckbox(versionName: string) {
        if (this.isClaimedFile()) {
            return true;
        }
        return this.disabledVersionsCheckBox().includes(versionName);
    }

    deleteVersionTuning(objectName: string, id: number, tableName: string) {
        this.pureOriginalsService.deleteVersion(objectName, id, tableName);
    }

    masterDetails(masterId: number, versionName: string) {
        this.router.navigate([`/app/master-details/${masterId}`], {
            queryParams: {
                versionName: versionName
            }
        });
    }

    ngOnDestroy () {
        this.subscription.unsubscribe();
        this.pureOriginalsService.clearInterval();
    }
}
