<div class="dropzone-wrapper" [style.background]="customBackground()">
    <div class="dropzone" appDragAndDrop (multipleFilesSelected)="onMultipleFilesSelected($event)"
         (fileDropped)="uploadDroppedFile($event)" [ngStyle]="{'--width' : progressWidth()}">
        <div class="image-wrapper">
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 width="30px" height="30px" viewBox="0 0 42 32" xml:space="preserve">
                 <g>
	         <path fill="#828282" d="M33.958,12.988C33.531,6.376,28.933,0,20.5,0C12.787,0,6.839,5.733,6.524,13.384
		C2.304,14.697,0,19.213,0,22.5C0,27.561,4.206,32,9,32h6.5c0.276,0,0.5-0.224,0.5-0.5S15.776,31,15.5,31H9c-4.262,0-8-3.972-8-8.5
		C1,19.449,3.674,14,9,14h1.5c0.276,0,0.5-0.224,0.5-0.5S10.776,13,10.5,13H9c-0.509,0-0.99,0.057-1.459,0.139
		C7.933,7.149,12.486,1,20.5,1C29.088,1,33,7.739,33,14v1.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V14
		c0-0.003,0-0.006,0-0.009c3.019,0.331,7,3.571,7,8.509c0,3.826-3.691,8.5-8,8.5h-7.5c-3.238,0-4.5-1.262-4.5-4.5V12.783l4.078,4.07
		C25.176,16.951,25.304,17,25.432,17s0.256-0.049,0.354-0.146c0.195-0.195,0.195-0.513,0-0.707l-4.461-4.452
		c-0.594-0.592-1.055-0.592-1.648,0l-4.461,4.452c-0.195,0.194-0.195,0.512,0,0.707s0.512,0.195,0.707,0L20,12.783V26.5
		c0,3.804,1.696,5.5,5.5,5.5H33c4.847,0,9-5.224,9-9.5C42,17.333,37.777,13.292,33.958,12.988z"/>
                </g>
      </svg>
        </div>
        @if (!error) {
            @if (!selectedFile()) {
                <span class="drag-text">{{ inputText() }}</span>
            } @else {
                <span class="drag-text">{{ refactoredFileName(selectedFile()) }}</span>
            }
        } @else {
            <span class="error-message">
                {{ error }}
            </span>
        }
        <button class="f-btn"><input type="file" #fileInput id="dropzone-input"
                                     (change)="uploadFileOnClick($event)"/></button>
    </div>

</div>





