import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StatisticsService} from "../../../service/statistics.service";


@Component({
    selector: 'app-data-range-picker-modal',
    templateUrl: './data-range-picker-modal.component.html',
    styleUrls: ['./data-range-picker-modal.component.css']
})
export class DataRangePickerModalComponent {


    @Input() isDateRangePickerOpened: boolean = false;
    @Output() dataRangePickerEvent = new EventEmitter<boolean>();
    @Output() emitFormValueEvent = new EventEmitter();
    @Input() currentPartner: string;
    @Input() disableFutureDates: boolean = false;

    today: string = new Date().toISOString().slice(0, 16);

    statisticService = inject(StatisticsService)

    constructor() {
    }

    datePickerForm = new FormGroup({
        startDate: new FormControl('', Validators.required),
        endDate: new FormControl('', Validators.required)
    })

    submitFormDate() {
        if (this.datePickerForm.valid) {
            const startDateFormValue = this.datePickerForm.value.startDate;
            const endDateFormValue = this.datePickerForm.value.endDate;
            if (!this.isValidDate(new Date(startDateFormValue)) || !this.isValidDate(new Date(endDateFormValue))) {
                return;
            }
            this.emitFormValue(startDateFormValue, endDateFormValue);
        }

        this.closeDateRangePickerModal()
    }

    isValidDate(date: Date): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    emitFormValue(startDate: string, endDate: string) {
        this.emitFormValueEvent.emit({startDate, endDate})
    }

    closeDateRangePickerModal() {
        this.dataRangePickerEvent.emit(false);
        this.datePickerForm.reset();
    }
}
