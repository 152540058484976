import {inject, Inject, Injectable, signal} from "@angular/core";
import {FileServiceRecordModel} from "../models/FileServiceRecord-model";
import {HttpClient} from "@angular/common/http";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {Subject} from "rxjs";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {NotificationService} from "./Notification.service";

export enum CurrentPartner {
    DynoChipTuningFiles = "Dyno-ChipTuningFiles.com",
    ATMChipTunings = "Performance-TuningFiles.com",
}

@Injectable({
    providedIn: "root"
})
export class PartnersService {

    private url = `${this.API_URL}`;

    private _currentPartner = signal<CurrentPartner>(CurrentPartner.DynoChipTuningFiles);

    private currentPartnerSubject = new Subject<CurrentPartner>();
    currentPartner$ = this.currentPartnerSubject.asObservable();

    public pageNumber = signal<number>(0);
    private pageSize = signal<number>(50);
    public partnerId = signal<number>(1);
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "importid", direction: "desc"});

    notificationService = inject(NotificationService);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {}

    setNewPartner(partner: CurrentPartner) {
        this.currentPartner = partner;
    }

    public get currentPartner(): CurrentPartner {
        return this._currentPartner();
    }

    public set currentPartner(value: CurrentPartner) {
        this._currentPartner.set(value);
        this.currentPartnerSubject.next(value);
    }


    getAllImportsForPartners() {
        return this.httpClient.get<FileServiceRecordModel[]>(`${this.url}/partner/all`, {
            params: {
                partnerId: this.partnerId(),
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        });
    }

    getAllImportsForPartnersFiltered(filterOptions: SearchFilterModel[]) {
        return this.httpClient.post<FileServiceRecordModel[]>(`${this.url}/partner/listAllPartnerImportsFiltered`, filterOptions, {
            params: {
                partnerId: this.partnerId(),
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active
            }
        });
    }

    getPartnerStatisticsBetweenTwoDates(startDate: string, endDate: string, status: string) {
        return this.httpClient.get<FileServiceRecordModel[]>(`${this.url}/partnerStatistics/statisticsRecordsBetweenTwoDatesByStatus/${this.partnerId()}/${this.pageNumber()}/${this.pageSize()}/id`, {
            params: {
                "start date": startDate,
                "end date": endDate,
                "status": status,
            }
        })
    }

    downloadPartnerClientImport (importId: number, fileName: string) {
        return this.httpClient.post<{url: string, message: string}>(`${this.url}/partner/downloadPartnerClientImport`, {}, {observe: "response", params: {
                importId: importId
            }}).subscribe(response => {
            if (response.status === 200) {
                window.location.href = response.body.url;
                this.notificationService.showSuccessMessage(`<div><span>Your file ${fileName} is downloaded</span></div>`, "Success");
            } else {
                this.notificationService.showErrorMessage('Something went wrong with file.', 'Failed');
            }
        })
    }

    loadNewPage() {
        this.pageNumber.update((prevPage) => prevPage + 1);
    }
}
