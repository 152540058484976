<div class="login">
    <form class="form" [formGroup]="forgotPasswordForm">
        <div class="form-title">
            <h2>Forgot Password?</h2>
            <span>Please enter your email.</span>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Email" formControlName="email"/>
        </div>
        <div class="action-buttons">
            <button class="custom-btn" [disabled]="isLoading" (click)="sendForgotPasswordRequest()">
                <ng-container *ngIf="!isLoading; else loadingContent">Send Request</ng-container>
                <ng-template #loadingContent>
                    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                </ng-template>
            </button>
        </div>
    </form>
</div>
