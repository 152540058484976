import {Component, ElementRef, EventEmitter, input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FileUploadService} from "../../../service/fileUpload.service";
import {fadeInAnimation} from "../../../animations/fadeInAnimation";
import {TuningsService} from "../../../service/Tunings.service";
import {HttpEventType} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: 'app-file-upload-modal',
    templateUrl: './file-upload-modal.component.html',
    styleUrls: ['./file-upload-modal.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class FileUploadModalComponent implements OnInit {

    public isFileModalOpened = input<boolean>(false)
    @Output() public isFileModalClosed = new EventEmitter<boolean>;
    @Output() public isImpossibleTuning: EventEmitter<boolean> = new EventEmitter<boolean>;
    @ViewChild('errorDiv') errorDiv!: ElementRef;

    pureOriginalBaseId = input<string>("");
    versionName = input<string>("");
    public isUpdated: boolean = false;
    public progressWidth: number;
    public isLoading: boolean = false;
    public error: string;

    public selectedFile: File | undefined;
    isImpossible: boolean = false;
    @ViewChild('fileUploadInput') fileUploadInput: ElementRef;


    constructor(private fileUploadService: FileUploadService, public tuningService: TuningsService, private toastr: ToastrService) {
    }

    ngOnInit() {
        this.tuningService.getTuningOptions().then();
        if (this.versionName) {
            this.fileUploadForm.patchValue({
                tuningOption: this.versionName()
            });
        }
    }

    fileUploadForm = new FormGroup({
        tuningOption: new FormControl('', Validators.required),
        comment: new FormControl(''),
        pureOriginalVersionBinFile: new FormControl('', Validators.required),
        impossibleTuning: new FormControl(false),
    })

    getFileOnUpload(event: any) {
        this.selectedFile = event.target.files[0];
        if (this.selectedFile) {
            if (this.selectedFile.size < 6 * 1024) {
                this.errorDiv.nativeElement.textContent = "The file is smaller than 6KB. Please select a larger file.";
                this.resetFileInput(this.fileUploadInput);
                this.selectedFile = null;
                return;
            }
            this.fileUploadForm.patchValue({ pureOriginalVersionBinFile: this.selectedFile.name });
            this.errorDiv.nativeElement.textContent ="";
        } else {
            this.toastr.error("Oops! Can't load Bin file. Please try again.");
        }
    }

    onSelected(event: any) {
        this.fileUploadForm.value.tuningOption = event.target.value;
    }

    uploadFileToBlob() {

        if (this.fileUploadForm.valid) {

            console.log(this.pureOriginalBaseId());
            this.fileUploadService.pureOriginalMasterUpload(this.pureOriginalBaseId().toString(), this.fileUploadForm.value.tuningOption, this.fileUploadForm.value.comment, this.selectedFile, this.fileUploadForm.value.impossibleTuning).subscribe(response => {
                this.isLoading = true;
                if (this.fileUploadForm.value.impossibleTuning) {
                    this.isImpossibleTuning.emit(this.fileUploadForm.value.impossibleTuning);
                }
                if (response.type === HttpEventType.UploadProgress) {
                    this.progressWidth = Math.round((response.loaded / response.total) * 100) / 2;
                } else if (response.status === 200) {
                    this.progressWidth = 100;
                    this.isUpdated = true;
                    setTimeout(() => {
                        this.closeModal();
                    }, 1500)
                }
                this.selectedFile = null;
            }, error => {
                {
                    if (error.status === 400) {
                        this.toastr.error('Incorrect version file size')
                        {
                            setTimeout(() => this.closeModal(), 2000);
                        }
                    } else {
                        this.toastr.error('ISorry, something went wrong', 'Sorry, something went wrong')
                        {
                            console.error("Error:", error);
                            setTimeout(() => this.closeModal(), 2000);
                        }
                    }
                }
            });
        }
    }

    isInputValid() {
        if (this.versionName && Array.isArray(this.tuningService.options)) {
            return this.tuningService.options.includes(this.fileUploadForm.value.tuningOption);
        }
        return false;
    }

    resetFileInput(fileInputRef: ElementRef) {
        console.log(fileInputRef.nativeElement.value);
        if (fileInputRef && fileInputRef.nativeElement) {
            fileInputRef.nativeElement.value = null;
            console.log(fileInputRef.nativeElement.value);
        }
    }

    closeModal() {
        this.isLoading = false;
        this.isFileModalClosed.emit(false);
        this.fileUploadForm.reset();
        this.resetFileInput(this.fileUploadInput);
    }


    onChange(event: any) {
        let state = event.valueOf().target.checked
        if (state) {
            this.fileUploadForm.controls.pureOriginalVersionBinFile.clearValidators();
            this.fileUploadForm.controls.pureOriginalVersionBinFile.reset();
        } else {
            this.fileUploadForm.controls.pureOriginalVersionBinFile.setValidators(Validators.required);
            this.fileUploadForm.controls.pureOriginalVersionBinFile.reset();
        }
        this.fileUploadForm.patchValue({
            impossibleTuning: state
        });
    }
}
