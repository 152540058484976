import { Component, inject, OnInit } from '@angular/core';
import { UserStatisticsService } from '../../../service/User-statistics-service';
import { AuthService } from '../../../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-all-users-statistics',
    templateUrl: './all-users-statistics.component.html',
    styleUrls: ['./all-users-statistics.component.css'],
})
export class AllUsersStatisticsComponent implements OnInit {
    userStatisticsService = inject(UserStatisticsService);
    authService = inject(AuthService);
    router = inject(Router);
    route = inject(ActivatedRoute);

    ngOnInit() {
        // 1. Check local storage
        const storedStartDate = localStorage.getItem('startDate');
        const storedEndDate   = localStorage.getItem('endDate');

        if (storedStartDate && storedEndDate) {
            // Use stored values
            this.userStatisticsService.startDate = storedStartDate;
            this.userStatisticsService.endDate = storedEndDate;
        } else {
            // 2. If no dates in local storage, set to default (today logic)
            this.getTodayStatistics();
        }

        this.userStatisticsService.getUserStatistics();
    }

    getTodayStatistics() {
        // End date: now
        this.userStatisticsService.endDate = new Date().toISOString();

        // Start date: beginning of today
        const d = new Date();
        d.setUTCHours(0, 0, 0, 0);
        this.userStatisticsService.startDate = d.toISOString();

        // 3. Store in local storage
        localStorage.setItem('startDate', this.userStatisticsService.startDate);
        localStorage.setItem('endDate', this.userStatisticsService.endDate);
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }

    onScroll() {
        this.userStatisticsService.increasePageNumber();
        this.userStatisticsService.getUserStatisticsOnScroll();
    }

    openPureOriginalDetails(userId: number) {
        this.router.navigate([`../${userId}`, 'pure-original-stats'], {
            relativeTo: this.route,
            queryParams: {
                startDate: this.userStatisticsService.startDate,
                endDate: this.userStatisticsService.endDate,
            },
        });
    }

    openMasterDetails(userId: number) {
        this.router.navigate([`../${userId}`, 'master-stats'], {
            relativeTo: this.route,
            queryParams: {
                startDate: this.userStatisticsService.startDate,
                endDate: this.userStatisticsService.endDate,
            },
        });
    }
}
