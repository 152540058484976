import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserModel} from "../models/User-model";
import {NotificationService} from "./Notification.service";
import {Roles} from "../models/Roles";
import {Router} from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class UserService {

    private url: string = `${this.API_URL}`;
    public allUsers = signal<UserModel[]>([]);
    public input_options = signal<string[]>(['User', 'Admin', 'Moderator']);
    public user_status = signal<string[]>(['Active', 'Disabled']);

    public loggedUser = signal<UserModel>({} as UserModel);
    public userInfo = signal<UserModel>({} as UserModel);
    public isUserSettingsOpened = signal<boolean>(false);
    public secretImageUrl = signal<string | null>(null);

    private notificationService = inject(NotificationService);
    private httpClient = inject(HttpClient);
    private router = inject(Router);

    constructor(@Inject('API_URL') private API_URL: string) {
    }

    public getLoggedUser() {
        return this.httpClient.get<UserModel>(`${this.url}/user/logged`).subscribe(response => this.loggedUser.set(response));
    }

    public activateUser(uuid: string) {
        return this.httpClient.get(`${this.url}/user/activate/${uuid}`, {observe: "response"});
    }

    public getUserById(id: number) {
        this.httpClient.get(`${this.url}/user/${id}`).subscribe(value => {
            this.userInfo.set(value as UserModel);
            this.getSplitNames();
        })
    }

    public getAllUsers(): any {
        this.httpClient.get<UserModel[]>(`${this.url}/user/users`).subscribe(value => this.allUsers.set(value));
    }

    getSplitNames(): string {
        const fullName = this.userInfo()?.firstName + ' ' + this.userInfo()?.lastName;
        const splitNames = fullName.split(' ');
        return splitNames.map(name => name.charAt(0)).join('');
    }

    getInitials() {
        const firstNameInitial = this.loggedUser()?.firstName?.[0] ?? '-';
        const lastNameInitial = this.loggedUser()?.lastName?.[0] ?? '-';
        return firstNameInitial + lastNameInitial;
    }

    public updateUserAuthority(user: UserModel, newUserAuthority: string) {
        let userRole = this.setUserRole(newUserAuthority);
        this.httpClient.post(`${this.url}/user/authorities`, {
            id: user.id,
            authorities: userRole.toString()
        }, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                user.authorities[0].authority = userRole.toString();
                this.notificationService.showSuccessMessage('User successfully updated!', 'User Role is updated.');
            } else {
                this.notificationService.showErrorMessage("Error", "Something went wrong");
            }
        });
    }

    public updateUserStatus(user: UserModel, newUserStatus: string) {
        let enabled = this.setUserStatus(newUserStatus);
        this.httpClient.post(`${this.url}/user/status`, {
            email: user.email,
            enabled: enabled
        }, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                user.enabled = enabled;
                this.notificationService.showSuccessMessage("User successfully updated!", "User status is updated.");
            } else {
                this.notificationService.showErrorMessage("Error", "Something went wrong");
            }
        });
    }


    public editUserByAdmin(id: number, firstName: string, lastName: string, phoneNumber: string) {
        this.httpClient.put(`${this.url}/user/adminUpdate/${id}`, {
            firstName,
            lastName,
            phoneNumber
        }, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                this.updateUsers(id, firstName, lastName, phoneNumber);
                this.notificationService.showSuccessMessage('User is updated', 'User successfully updated!');
                this.router.navigate(['/app/admin-panel']);
            } else {
                this.notificationService.showErrorMessage('Something went wrong', 'Error');
            }
        });
    }

    public editUserInformation(id: number, firstName: string, lastName: string, phoneNumber: string) {
        this.httpClient.put(`${this.url}/user/userUpdate/${id}`, {
            firstName,
            lastName,
            phoneNumber
        }, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                this.loggedUser.update((user) => ({
                    ...user,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber
                }));
                this.router.navigate(['/app']);
                this.notificationService.showSuccessMessage('You updated your profile', 'Successfully updated your profile info!');
            }
        })
    }

    private updateUsers(id: number, firstName: string, lastName: string, phoneNumber: string) {
        let updatedUser: UserModel | undefined;
        this.allUsers.update((users) => users.map((user) => {
                    if (user.id === id) {
                        return updatedUser = {...user, firstName, lastName, phoneNumber};
                    }
                    return user
                }
            )
        );
        return updatedUser
    }

    getEnableNotification(enabled: boolean) {
        this.httpClient.post<boolean>(`${this.url}/user/notification`, {enabled}, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                this.loggedUser().emailNotification = response.body;
                if (this.loggedUser().emailNotification === false) {
                    this.notificationService.showSuccessMessage("Email notification deactivated", "Successfully");
                } else {

                    this.notificationService.showSuccessMessage("Email notification activated", "Successfully");
                }
            } else {
                this.notificationService.showErrorMessage("Something went wrong,", "Error");
            }
        });
    }

    openUserSettings() {
        this.isUserSettingsOpened.set(true);
    }

    getUserStatus(user: UserModel) {
        return user.enabled ? "Active" : "Disabled";
    }

    setUserStatus(status: String) {
        return status === "Active";
    }

    setUserRole(authority: String) {
        switch (authority) {
            case "Admin": {
                return Roles.ROLE_ADMIN;
            }
            case "User": {
                return Roles.ROLE_USER;
            }
            case "Moderator": {
                return Roles.ROLE_MODERATOR
            }
            case "SuperAdmin": {
                return Roles.ROLE_SUPER_ADMIN
            }
            default: {
                return Roles.ROLE_USER;
            }
        }
    }

    getUserRole(user: UserModel) {
        const authorities = user.authorities[0].authority;

        switch (authorities) {
            case 'SUPER_ADMIN': {
                return "SuperAdmin";
            }
            case 'ADMIN': {
                return "Admin";
            }
            case 'USER': {
                return "User";
            }
            case 'MODERATOR': {
                return "Moderator"
            }
            default: {
                return "User";
            }
        }
    }


    mfaUpdate(mfaEnabled: boolean) {
        this.httpClient.post<{ message: string, secretImageUri: string }>(`${this.url}/user/mfaUpdate`, {
            mfaEnabled: mfaEnabled
        }).subscribe((response) => {
            this.secretImageUrl.set(response.secretImageUri);
            if (!mfaEnabled) {
                this.userInfo().mfaEnabled = mfaEnabled;
                const toastObj = this.notificationService.showWarningMessage("Please enable MFA.", "MFA disabled.", {
                    positionClass: 'toast-top-right',
                    timeOut: 3000
                });
                setTimeout(() => {
                    this.notificationService.clear(toastObj.toastId)
                }, 3500);
            }
        })
    }

    verifyMfa(twoFactorCode: string, mfaStatus: boolean) {
        return this.httpClient.post<{
            message: string
        }>(`${this.url}/user/verifyPasscode`, {passcode: twoFactorCode, mfaStatus: mfaStatus}, {observe: "response"})
    }

    disableUserMfa(userId: number, mfaEnabled: boolean) {
        console.log(userId);
        this.httpClient.post(`${this.url}/user/mfaUpdateFromAdmin/${userId}`, {mfaEnabled: mfaEnabled}, {observe: "response"}).subscribe(response => {
            if (response.status === 200) {
                const user = this.allUsers().find(user => user.id === userId);
                user.mfaEnabled = mfaEnabled;
                this.notificationService.showSuccessMessage(`${response.body["message"]}`, `MFA updated for user ${userId}`);
            }
        }, error => {
            this.notificationService.showErrorMessage(`${error.error.message}`, "Error occurred!");
        })
    }

}
