import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

import {of} from "rxjs";

import {EcuProducersService} from "../../service/ecu-producers.service";
import {CorrectEcuProducersModel} from "../../models/EcuBuildName-model";

@Component({
  selector: 'app-add-ecu-producer-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './add-ecu-producer-dialog.component.html',
  styleUrl: './add-ecu-producer-dialog.component.css'
})
export class AddEcuProducerDialogComponent implements OnInit {
  @Output() ecuProducerAdded = new EventEmitter<CorrectEcuProducersModel>();


  addNewEcuProducerForm = new FormGroup(
      {
        id: new FormControl(0),
        correctEcuProducer: new FormControl(null, [Validators.required], [this.ecuProducerValidator.bind(this)]),
        acceptedEcuProducer: new FormControl(null, Validators.required),
      }
  )

  constructor(private ecuProducerService: EcuProducersService , public dialogRef: MatDialogRef<AddEcuProducerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.addNewEcuProducerForm.patchValue({
      correctEcuProducer: this.data.ecuProducer,
    })
  }


  ecuProducerValidator(control: AbstractControl) {
    const validationResponse = this.ecuProducerService.checkIfEcuProducerIsInDatabase(control.value);
    return of(validationResponse);
  }

  canceledOption() {
    this.dialogRef.close({closed: false, ecuProducer: null});
  }

  saveNewRow() {
    const newRow = {
      id: 0,
      correctEcuProducer: this.addNewEcuProducerForm.get('correctEcuProducer')?.value,
      acceptedEcuProducer: this.addNewEcuProducerForm.get('acceptedEcuProducer')?.value,
    }
    this.ecuProducerService.addNewEcuProducerItem(newRow);
    this.canceledOption()
  }

}
