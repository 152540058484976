<div class="master-main">
    <div class="main-wrapper">

        <div class="head-button">
            <button class="r-btn" (click)="backToMainScreen()"><i class="icon bi bi-arrow-left"></i></button>
            <span class="head-text">Master Item</span>
        </div>
        <div class="master-details-wrapper">
            <div class="master-info-details">
                <app-master-details-component></app-master-details-component>
            </div>
            <div class="version-info-details">
                <app-master-version-details></app-master-version-details>
            </div>
        </div>
    </div>
</div>
