import {
    Component,
    EventEmitter,
    Output,
    ViewChild,
    input,
    inject
} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {WinOlsTransactionModel} from "../../models/WinOlsTransaction-model";
import {SortDirectionValuesModel} from "../../models/SortDirectionValues-model";
import {WinOlsTransactionService} from "../../service/winols-transaction.service";
import {Router} from "@angular/router";


@Component({
    selector: 'app-win-ols-transaction-table',
    templateUrl: './win-ols-transaction-table.component.html',
    styleUrl: './win-ols-transaction-table.component.css'
})
export class WinOlsTransactionTableComponent {

    @ViewChild(MatSort) sort: MatSort;

    winOlsTransactions = input<WinOlsTransactionModel[]>([]);
    displayedTableHeaders = input<string[]>();
    displayedColumns = input<(keyof WinOlsTransactionModel)[]>();
    sortDirectionValues = input<SortDirectionValuesModel>();

    @Output() downloadOriginalFile = new EventEmitter();
    @Output() changeSortDirection = new EventEmitter();
    @Output() downloadCompletedFile = new EventEmitter();
    @Output() downloadFileFromTable = new EventEmitter();
    @Output() openSimilarPureOriginalId = new EventEmitter();

    winOlsTransactionService = inject(WinOlsTransactionService);
    router = inject(Router)


    isDownloadInProgress(id: number) {
        return this.winOlsTransactionService.isDownloadInProgress(id);
    }

    downloadFile (trace: string, id: number, route: string) {
        this.downloadFileFromTable.emit({trace, id, route});
    }
    changeMasterTableSortDirection(event: any) {
        this.changeSortDirection.emit(event);
    }
    openPureOriginalMatchingDetails (pureOriginalId: number) {
        this.winOlsTransactionService.openPureOriginalMatchingDetails(pureOriginalId);
    }


    navigateToSimilarPureOriginalId(similarPureOriginalId: string) {
        this.router.navigate(['/app/pure-originals/pure-original-details'], { queryParams: { pureOriginalId: similarPureOriginalId } });
    }

}
