import {Component, inject, OnInit} from '@angular/core';
import {ResellerRecordsService} from "../service/Reseller-records.service";
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
    selector: 'app-reseller-records',
    templateUrl: './reseller-records.component.html',
    styleUrls: ['./reseller-records.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class ResellerRecordsComponent implements OnInit {

    resellerService = inject(ResellerRecordsService);

    ngOnInit() {
        this.refreshTable();
        this.getTableKeysForPureOriginals();
        this.getAllResellerItems();
    }

    refreshTable() {
        this.resellerService.refreshTableOnInit()
    }
    getTableKeysForPureOriginals() {
        this.resellerService.getTableKeysForPureOriginals();
    }

    getAllResellerItems() {
        this.resellerService.getResellerRecords();
    }

    onScroll() {
        this.resellerService.onScroll();
    }

    onRefreshTable() {
        this.resellerService.refreshResellersTable();
    }
    changeResellersTableSortDirection ($event: any) {
        this.resellerService.changeResellersSortDirection($event.valueOf());
    }

}
