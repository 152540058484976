import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";


@Injectable({
    providedIn: "root"
})
export class DownloadFileFromDatabaseService {

    private url = `${this.API_URL}`;

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {

    }

  downloadFileFromDatabase(id: number, tableName: string) {
    return this.httpClient.post<any>(`${this.url}/downloadFileFromDatabase`, {
      id: id,
      tableName: tableName
    }, {reportProgress: true, observe: "response",})
  }

    downloadFileAndDeleteFromDatabase(id: number, tableName: string) {
        return this.httpClient.post<any>(`${this.url}/downloadAndDeleteFileFromDatabase`, {
            id: id,
            tableName: tableName
        }, {reportProgress: true, observe: "response",})
    }
}
