<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>
    </div>
    @defer (when pureOriginalResultService.allPureOriginalResults().length > 0) {
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="onScroll()">

            <table>
                <tr class="header">
                    @for (column of pureOriginalResultService.displayedOriginalColumns(); track $index) {
                        <th>{{ column | shorten: 25 }}</th>
                    }
                </tr>
                @for (element of pureOriginalResultService.allPureOriginalResults(); track $index) {
                    <tr class="body-columns">
                        @for (column of pureOriginalResultService.displayedOriginalColumns(); track $index) {
                            <td>{{ element[column] | shorten: 1000 }}</td>
                        }

                    </tr>
                }
            </table>
        </div>
    } @placeholder (minimum 150ms) {
        <div *ngIf="pureOriginalResultService.allPureOriginalResults().length < 1">
            <app-skeleton-table></app-skeleton-table>
        </div>
    }


</section>

