export class PureOriginalMatchingModel {

  public hitRate: string;
  public vehicleProducer: string;
  public vehicleSeries: string;
  public vehicleModel: string;
  public vehicleModelYear: string
  public pureOriginalMatchingDetailsId: string;
  public versionWinOLSReference: string ;
  public tuningName: string;
  public pureOriginalBaseWinOlsRef: string;
  public ecuBuild: string;
  public vehicleBuild: string;
  public ecuUse: string;
  public ecuProducer: string;
  public engineDisplacement: string;
  public engineMaxTorque: string;
  public engineProducer: string;
  public engineType: string;
  public engineTransmission: string;
  public outputPs: string;
  public outputKw: string;
  public engineName: string;
  public ecuSoftwareVersion: string;
  public dateUploaded: string;
  public versionId: string;
  public pureOriginalBaseId: string;
  public versionComment: string;




  constructor() {
    this.hitRate = "hitRate";
    this.vehicleProducer = "vehicleProducer";
    this.vehicleSeries = "vehicleSeries";
    this.vehicleModel = "vehicleModel";
    this.vehicleModelYear = "vehicleModelYear";
    this.pureOriginalMatchingDetailsId = "pureOriginalMatchingDetailsId";
    this.versionWinOLSReference = "versionWinOLSReference";
    this.pureOriginalBaseWinOlsRef = "pureOriginalBaseWinOlsRef";
    this.ecuBuild = "ecuBuild";
    this.engineName = "engineName";
    this.vehicleBuild = "vehicleBuild";
    this.ecuUse = "ecuUse";
    this.ecuProducer = "ecuProducer";
    this.engineDisplacement = "engineDisplacement";
    this.engineMaxTorque = "engineMaxTorque";
    this.engineProducer = "engineProducer";
    this.engineType = "engineType";
    this.engineTransmission = "engineTransmission";
    this.outputPs = "outputPs";
    this.outputKw = "outputKw";
    this.ecuSoftwareVersion = "ecuSoftwareVersion";
    this.dateUploaded = "dateUploaded";
    this.tuningName = "tuningName";
    this.versionId = "versionId"
    this.pureOriginalBaseId = "pureOriginalBaseId"
    this.versionComment = "versionComment";
  }

  // public getPercentage () {
  //   const hitRateNumber= +this.hitRate * 100;
  //   return hitRateNumber + '%'
  // }

}
