import {Component, ElementRef, inject, OnInit} from '@angular/core';
import {PureOriginalService} from "../../service/pure-original.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalsModel} from "../../models/PureOriginals-model";

@Component({
    selector: 'app-faulty-pure-originals',
    templateUrl: './faulty-pure-originals.component.html',
    styleUrl: './faulty-pure-originals.component.css',

})
export class FaultyPureOriginalsComponent implements OnInit {


    pureOriginalService = inject(PureOriginalService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    elRef = inject(ElementRef);


    ngOnInit(): void {
        this.restartFaultyPureOriginals();
        this.getAllTableHeaders();
        this.getFaultyPureOriginals();
    }

    restartFaultyPureOriginals() {
        this.pureOriginalService.restartFaultyTableValues();
    }

    getAllTableHeaders() {
        this.pureOriginalService.getTableHeaderNamesForPureOriginals();
    }

    getFaultyPureOriginals() {
        this.pureOriginalService.getSortFaultyPureOriginals();
    }

    changeSortDirection($event: any) {
        this.pureOriginalService.changeFaultySortDirection($event.valueOf());
    }


    openPureOriginalDetails($event: any) {
        const id = $event.valueOf();
        return this.router.navigate(['/app/pure-originals/pure-original-details'], {
            queryParams: {pureOriginalId: id}
        });
    }


    editPureOriginalDetails($event: PureOriginalsModel) {
        let element = $event;
        let id =  element.id;
        this.pureOriginalService.updateFaultyPureOriginals(+id,element);
    }

    deleteFaultyPureOriginals($event: any) {
        let id = $event.valueOf().pureOriginalId;
        let tableName = $event.valueOf().tableName;
        this.pureOriginalService.deleteFaultyPureOriginals(id, tableName);

    }
}