import { Component } from '@angular/core';

@Component({
  selector: 'app-tuning-names-wrapper',
  templateUrl: './tuning-names-wrapper.component.html',
  styleUrls: ['./tuning-names-wrapper.component.css']
})
export class TuningNamesWrapperComponent {

}
