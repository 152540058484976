<div *ngIf="isDateRangePickerOpened" class="date-range-module">
    <div class="collapsed-overlay" (click)="closeDateRangePickerModal()"></div>
    <div class="form-control-custom">
        <span class="date-range-picker-title">Select Dates:</span>
        <form [formGroup]="datePickerForm" class="form" (ngSubmit)="submitFormDate()">
            <div class="input-position">
                <input type="datetime-local" placeholder="Start Date" class="form-control" formControlName="startDate"
                       [ngStyle]="{'--filter-number': statisticService.isThemeChanged() ? '0' : '1'}"
                       [attr.max]="disableFutureDates ? today : null"/>
                <input type="datetime-local" placeholder="End Date" class="form-control" formControlName="endDate"
                       [ngStyle]="{'--filter-number': statisticService.isThemeChanged() ? '0' : '1'}"
                       [attr.max]="disableFutureDates ? today : null"
                />
            </div>
            <div class="button-position">
                <button class="button" type="submit" [disabled]="!datePickerForm.valid">Find</button>
            </div>
        </form>
    </div>
</div>
