<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>
        <div class="act-search-bar">
            <app-search-bar (searchValue)="onSearchInput($event)" [value]="'Client Number'"></app-search-bar>
            <div class="filtering-buttons">
                <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i
                        class="bi bi-funnel"></i>
                    @if (filterCount() > 0) {
                        <p class="count-filter">{{ filterCount() }}</p>
                    }</button>
            </div>
        </div>
    </div>

    @if (fileService.openAdvanceSettings()) {
        <div>
            <app-file-service-filters [displayedColumns]="fileService.displayedColumns()"
                                      (fileServiceFilterClosed)="onAdvancedSettingClosed($event)"
                                      (fileServiceSelectedFilters)="selectFilters($event)"
                                      (filterCount)="updateFilterCount($event)"
            ></app-file-service-filters>
        </div>

    }
    @defer (when !fileService.fileServiceTableIsLoading()) {
        @if (!isTableEmpty()) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="onScroll()"
                 (scrolledUp)="onScrolledUp()">

                <table mat-table [dataSource]="fileService.fileServiceRecords()"
                       multiTemplateDataRows matSort matSortDisableClear
                       [matSortDirection]="fileService.sortDirectionValues().direction"
                       [matSortActive]="fileService.sortDirectionValues().active"
                       (matSortChange)="changeFileServiceTableSortDirection($event)">


                    @for (column of fileService.displayedColumns(); track $index) {
                        <ng-container [matColumnDef]="column">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.pureoriginalid ? 'green' : ''">
                                {{ element[column] | shorten: 10000 }}
                            </td>
                        </ng-container>
                    }

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>

                        <td mat-cell *matCellDef="let element" [ngClass]="element.pureoriginalid ? 'green' : 'white'">
                            <button *ngIf="element.pureoriginalid" class="table-buttons car-button"
                                    [disabled]="!element.pureoriginalid"
                                    (click)="openPureOriginalMatchingDetails(+element.pureoriginalid)"><i
                                    class="bi bi-car-front-fill"></i>
                            </button>
                            <button class="table-buttons"
                                    (click)="deleteItemFromTable(+element.importid, 'IMPORTS')"><i
                                    class="bi bi-trash"></i></button>
                            <button class="table-buttons" [disabled]="element.status.includes('Failed')"
                                    (click)="downloadFileFromTable(+element.importid, element.filename)"><i
                                    class="bi bi-file-earmark-arrow-down"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="fileService.displayedTableHeaders()"></tr>
                    <tr mat-row *matRowDef="let element; columns: fileService.displayedTableHeaders()"></tr>
                </table>

            </div>
        } @else {
            <div class="empty-table-wrapper">
                <div class="empty-table">NO RESULTS</div>
            </div>
        }

    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

</section>
