export class VersionRecordsModel {
  public id: string;
  public sha1: string;
  public sha256: string;
  public versionName: string;
  public versionFileBlobZipReference : string;
  public versionComment: string;
  public masterWinOLSReference : string;
  public uploadBy: string;
  public dateUploaded: string;
  public additionalInfo: string;

  constructor() {
    this.id = "id";
    this.versionName = "versionName";
    this.masterWinOLSReference = "masterWinOLSReference";
    this.dateUploaded = "dateUploaded";
    this.uploadBy = "uploadBy";
    this.versionFileBlobZipReference = "versionFileBlobZipReference";
    this.versionComment = "versionComment";
    this.additionalInfo = "additionalInfo";
    this.sha1 = "sha1";
    this.sha256 = "sha256";
  }
}
