import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TuningNameModel} from "../models/Tuning-Name-model";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "./Notification.service";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";


interface CorrectTuningNames {
    correctTuningNames: string[];
}

@Injectable({
        providedIn: "root"
    }
)

export class TuningNamesTablesService {
    private url = `${this.API_URL}`;

    public tuningNames = signal<TuningNameModel[]>([]);
    public tuningIndex = signal<number | null>(null);
    public editInputRow = signal(false);
    public newInputRow = signal<TuningNameModel>({} as TuningNameModel);
    public isLoading = signal<boolean>(false);
    public isAddingNewTuning = signal<boolean>(false);
    public notificationService = inject(NotificationService);
    dialog = inject(MatDialog);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    getTuningNamesTable() {
        this.isLoading.set(true);
        this.httpClient.get<TuningNameModel[]>(`${this.url}/tuningNames`).subscribe(value => {
            this.tuningNames.set(value as TuningNameModel[]);
            this.isLoading.set(false);
        })
    }

    addTuningNames() {
        this.isAddingNewTuning.set(true);
        this.httpClient.put<TuningNameModel>(`${this.url}/tuningNames`, {
                id: this.newInputRow().id,
                correctTuningNames: this.newInputRow().correctTuningNames,
                acceptedTuningNames: this.newInputRow().acceptedTuningNames,
            }, {
                observe: "response"
            }
        ).subscribe(response => {
            if (response.status === 200) {
                this.tuningNames.update((tuningNames) => [...tuningNames, response.body]);
                this.isAddingNewTuning.set(false);
                this.notificationService.showSuccessMessage("Successfully added", 'Successfully added');
            }
            this.resetNewInputRow();
            this.editInputRow.set(false);
        });
    }

    resetNewInputRow() {
        this.newInputRow.set({} as TuningNameModel);
    }

    openNewRow() {
        this.editInputRow.set(!this.editInputRow());
        this.resetNewInputRow();
    }

    updateTuningName(newTuning: TuningNameModel) {
        this.isAddingNewTuning.set(true);
        this.httpClient.post<TuningNameModel>(`${this.url}/tuningNames`, {
            id: newTuning.id,
            correctTuningNames: newTuning.correctTuningNames,
            acceptedTuningNames: newTuning.acceptedTuningNames
        }, {
            observe: "response"
        }).subscribe(response => {
            if (response.status === 200) {
                this.tuningNames.update((tuningNames) => tuningNames.map((prevTuning) => (prevTuning.id === newTuning.id ? response.body : prevTuning)));
                this.isAddingNewTuning.set(false);
                this.notificationService.showSuccessMessage("Successfully update", 'Successfully update');
                this.tuningIndex.set(null);
            }
        })
    }

    deleteTuningNames(tuningName: TuningNameModel) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete Ecu?"
        })
        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteTuningNamesRequest(tuningName.id);
            }
        })
    }

    deleteTuningNamesRequest(id: number) {
        this.httpClient.delete(`${this.url}/tuningNames/${id}`, {observe: "response"}).subscribe(response => {
            if (response.status === 200) {
                this.tuningNames.update((tuningNames) => tuningNames.filter((tuningName) => tuningName.id != id));
                this.notificationService.showSuccessMessage("Successfully deleted", `Successfully deleted tuning ${id}`)
            }
        })
    }

    getListOfCorrectTuningNames() {
        return this.httpClient.get<CorrectTuningNames>(`${this.url}/tuningNames/listCorrectTuningNames`)
    }

}




