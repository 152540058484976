import {Component, ElementRef, inject, OnInit} from '@angular/core';
import {MastersResultsService} from "../service/MastersResults.service";
import {fadeInAnimation} from "../animations/fadeInAnimation";


@Component({
    selector: 'app-master-results',
    templateUrl: './master-results.component.html',
    styleUrls: ['./master-results.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class MasterResultsComponent implements OnInit {
    elRef = inject(ElementRef);

    public masterResultsService = inject(MastersResultsService);


    ngOnInit() {
        this.restartMasterTable();
        this.getMasterResultHeaders();
        this.masterResultsService.getAllMasterResults();
    }

    getMasterResultHeaders() {
        this.masterResultsService.getAllTableHeaderNames();
    }

    openMasterDetailsItem(id: number) {
        this.masterResultsService.openMasterDetailsItem(id)
    }

    refreshTable() {
        this.masterResultsService.onRefreshTable();
    }

    restartMasterTable() {
        this.masterResultsService.restartTableValue();
    }

    deleteMasterRecord(id: number, tableName: string) {
        this.masterResultsService.deleteMasterRecord(id, tableName)
    }

    changeMasterSortDirection($event: any) {
        const tableElement = this.elRef.nativeElement.querySelector(".table-container");
        this.masterResultsService.changeSortDirection($event.valueOf(), tableElement);
    }
}
