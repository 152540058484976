<div class="todo-wrapper">
    <div class="todo-container">
        <div class="title">
            <div class="title-text-wrapper">
                <span class="title-text">Todo List</span>
            </div>
        </div>
        @if (!todoListService.isOpened()) {
            <div class="todo-header">
                <div class="header-title-container">
                    <span class="todo-title">Hi, {{ user.loggedUser()?.firstName }}</span>
                    <span class="todo-subtitle">Welcome to your day planner!</span>
                </div>
                <div class="todo-header-actions">
                    <button class="button" data-button="todo-btn" (click)="showTodoForm()"><i class="bi bi-plus-lg"></i><span>New Todo</span>
                    </button>
                </div>
            </div>
        }

        @if (todoListService.isOpened()) {
            <div class="todo-form">
                <form [formGroup]="todoListForm">
                    <input placeholder="What's your main goal?" type="text" class="form-control"
                           formControlName="title"/>
                    <input placeholder="Describe your goal for today." type="text" class="form-control"
                           formControlName="content"/>
                    <input placeholder="Date" type="date" class="form-control" formControlName="date"/>
                    <div class="button-wrapper">
                        <button class="button" (click)="onSubmit()" [disabled]="!todoListForm.valid">
                            <span>{{ todoListService.isEditing() ? 'Update Item' : 'Add Item' }}</span></button>
                    </div>
                </form>
            </div>
        }

        @if (todoListService.todos().length > 0) {
            <div class="todo-content">
                @for (todo of todoListService.todos(); track $index) {
                    <div class="list-container">
                        <div class="container-header">
                            <span class="list-title"
                                  [ngClass]="!todo.validation ? '' : 'crossed-line'">{{ todo.title! }}</span>
                        </div>
                        <div class="container-content">
                            <div class="content-text">
                                <span class="todo-content-text">{{ todo.content! }}</span>
                                <span class="todo-date">{{ todo.date! }}</span>
                            </div>
                            <div class="content-actions">
                                <div class="todo-button-wrapper"><input type="checkbox"
                                                                        class="todo-input form-check-input"
                                                                        [checked]="todo.validation"
                                                                        (click)="checkTodoValidation($event, todo)"/>
                                </div>
                                <div class="todo-button-wrapper">
                                    <button class="todo-button" (click)="editExistingTodo(todo.id, $index)"
                                            [disabled]="todo.validation! === true"><i class="bi bi-pencil-square"></i>
                                    </button>
                                </div>
                                <div class="todo-button-wrapper">
                                    <button class="todo-button" (click)="deleteExistingTodoItem(todo.id)"
                                            [disabled]="todo.validation! === true"><i class="bi bi-x-lg"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        @if (todoListService.todos().length < 1) {
            <div class="todo-content">
                <div class="title-text-wrapper">
                    <span>Empty List</span>
                </div>
            </div>
        }
    </div>
</div>
