
import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class UploadFileDialogService {
    private url = `${this.API_URL}`;


    private resetSubject = new BehaviorSubject<void>(null);
    reset$ = this.resetSubject.asObservable();


    constructor(@Inject('API_URL') private API_URL: string) {
    }

    triggerReset() {
        this.resetSubject.next();
    }
}