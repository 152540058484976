<div class="main">
    <div class="main-container-wrapper">
        <div class="button-container">
            <button (click)="onAddItems()" class="add-row-btn"><i class="bi bi-plus-lg"></i>Row</button>
        </div>
        @defer (when !tuningNamesService.isLoading()) {
            <div class="table-container">
                <table>
                    <tr class="header">
                        <th>ID</th>
                        <th>Correct Tuning Name</th>
                        <th>Accepted Tuning Names</th>
                        <th>Actions</th>
                    </tr>
                    @if (tuningNamesService.editInputRow()) {
                        <tr>
                            <td>-</td>
                            <td>
                                <mat-form-field class="background-textarea">
              <textarea type="text"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="17"
                        [(ngModel)]="tuningNamesService.newInputRow().correctTuningNames"
                        [ngClass]="'custom-placeholder'" placeholder="Correct Tuning Names"></textarea>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="background-textarea">
            <textarea
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="17"
                    type="text" [(ngModel)]="tuningNamesService.newInputRow().acceptedTuningNames"
                    placeholder="Accepted Tuning Names"></textarea>
                                </mat-form-field>
                            </td>

                            <td>
                                @if (!tuningNamesService.isAddingNewTuning()) {
                                    <button (click)="saveNewRow()" class="row-btn"><i class="bi bi-check-lg"></i>
                                    </button>
                                    <button (click)="deleteRow()" class="row-btn"><i class="bi bi-x-lg"></i></button>

                                } @else {
                                    <div class="spinner-wrapper">
                                        <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                    </div>
                                }
                            </td>
                        </tr>
                    }
                    @for (element of tuningNamesService.tuningNames(); track $index) {
                        <tr>
                            <td>{{ element.id }}</td>
                            <td>{{ element.correctTuningNames }}</td>
                            <td>
                                <ng-container
                                        *ngIf="tuningNamesService.tuningIndex() !== $index; else editAcceptedName">
                            <span class="tag"
                                  *ngFor="let acceptedName of element.acceptedTuningNames.split(',')">{{ acceptedName.trim() }}</span>
                                </ng-container>
                                <ng-template #editAcceptedName>

                                    <mat-form-field class="background-textarea">
                                    <textarea matInput
                                              cdkTextareaAutosize
                                              cdkAutosizeMinRows="1"
                                              cdkAutosizeMaxRows="15" [(ngModel)]="element.acceptedTuningNames"
                                              (keydown.enter)="onSaveItems(element)">
                                     </textarea>

                                    </mat-form-field>
                                </ng-template>
                            </td>
                            <td class="actions">
                                <ng-container *ngIf="tuningNamesService.tuningIndex() !== $index; else editMode">
                                    <button (click)="onDeleteItems(element)" class="table-buttons">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                    <button (click)="onEditItems($index)" class="table-buttons">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </ng-container>
                                <ng-template #editMode>
                                    @if (!tuningNamesService.isAddingNewTuning()) {
                                        <button (click)="onSaveItems(element)" class="table-buttons">
                                            <i class="bi bi-check-circle"></i>
                                        </button>
                                        <button (click)="onExitItems()" class="table-buttons">
                                            <i class="bi bi-x-circle"></i>
                                        </button>
                                    } @else {
                                        <div class="spinner-wrapper">
                                            <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                        </div>
                                    }
                                </ng-template>
                            </td>
                        </tr>
                    }
                </table>

            </div>
        } @placeholder (minimum 150ms) {
            <div>
                <app-skeleton-table></app-skeleton-table>
            </div>
        }
    </div>
</div>





