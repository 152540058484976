import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../service/user.service";

@Component({
  selector: 'app-activation-template',
  templateUrl: './activation-template.component.html',
  styleUrls: ['./activation-template.component.css']
})
export class ActivationTemplateComponent {

  public response: string;
  public responseStatusCode: {};
  public mainText: string;
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
    this.route.queryParams.subscribe(params => {
      this.userService.activateUser(params['uuid']).subscribe(response=> {
        if (response.status == 200) {
          this.responseStatusCode = response.status;
          this.mainText = 'Congratulations, your account is successfully verified.';
          this.response = 'User Successfully verified';
        }
      }, error => {
        if (error.status == 404) {
          this.responseStatusCode = error.status;
          this.mainText = 'Something went wrong seems like UUID code is invalid';
          this.response = 'Invalid UUID code';
        }
        if (error.status == 409) {
          this.responseStatusCode = error.status;
          this.mainText = 'This user is already verified.'
          this.response = 'User account already activated';
        } if (error.status == 500) {
          this.responseStatusCode = error.status;
          this.mainText = 'Internal Server error, contact our support.'
          this.response = 'Internal server error with email sender service'
        }
      })

    })
  }
  login () {
    this.router.navigate(['/login']);
  }
}
