import {Component, inject, OnInit} from '@angular/core';
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";
import {TuningsService} from "../../service/Tunings.service";
import {fadeInAnimation} from "../../animations/fadeInAnimation";

@Component({
    selector: 'app-faulty-masters',
    templateUrl: './faulty-masters.component.html',
    styleUrls: ['./faulty-masters.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class FaultyMastersComponent implements OnInit {

    public masterService = inject(AllMastersDatabaseService);
    public tuningService = inject(TuningsService);

    ngOnInit(): void {
        this.restartFaultyMastersList();
        this.getAllTableHeaderNames();
        this.getAllVersionHeaderNames();
        this.getFaultyMasters();
    }

    getFaultyMasters() {
        this.masterService.getFaultyMastersFilteredByEcuBuildName();
    }

    getAllTableHeaderNames() {
        this.masterService.getAllTableHeaderNames();
    };

    restartFaultyMastersList() {
        this.masterService.restartTableValues()
    }

    getAllVersionHeaderNames() {
        this.masterService.getAllVersionHeaderNames();
    }

    getVersionRecord(id: number) {
        this.tuningService.getVersionRecords(id)
    }

    toggleExpandedRow($event: any) {
        let expandedDetail = $event.valueOf();
        this.getVersionRecord(+expandedDetail.id);
        this.masterService.toggleExpandedMasterRow(expandedDetail);
    }

    deleteFaultyMaster($event: any) {
        let id = $event.valueOf().id;
        this.masterService.deleteFaultyMaster(id);
    }

    downloadFileFromTable($event: any) {
        let id = $event.valueOf().id;
        let winOls = $event.valueOf().winOls;
        let tableName = $event.valueOf().tableName;
        this.masterService.downloadMastersFromTable(id, winOls, tableName)
    }

    editItemDetails($event: any) {
        const winOls = $event.valueOf().winOls;
        let element = $event.valueOf().element;
        let id = $event.id
        this.masterService.updateFaultyMaster(element, winOls, id);
    }

    changeSortDirection($event: any) {
        this.masterService.sortDirectionValues.set($event.valueOf());
        this.masterService.pageNumber.set(0);
        this.masterService.getFaultyMastersFilteredByEcuBuildName();
    }

    deleteTuningRecordFromTable($event: any) {
        let id = $event.valueOf().id;
        let tableName = $event.valueOf().tableName;

        this.tuningService.deleteTuningRecordFromTable(id, tableName);
    }

    downloadTuningRecordFromTable($event: any) {
        let id = $event.valueOf().id;
        let winOls = $event.valueOf().winOls;
        let tableName = $event.valueOf().tableName;

        this.masterService.downloadMastersFromTable(id, winOls, tableName);
    }

}

