import {Injectable} from "@angular/core";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {FormGroup, FormControl, Validators} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() {
    }


    saveFilters(filterKey: string, filters: SearchFilterModel[]) {
        localStorage.setItem(filterKey, JSON.stringify(filters));
    }

    getFilters(filterKey: string): SearchFilterModel[] {
        const savedFilters = localStorage.getItem(filterKey);
        return savedFilters ? JSON.parse(savedFilters) : [];
    }


    clearFilters(filterKey: string) {
        localStorage.removeItem(filterKey);
    }


    createFormGroupFromFilters(savedFilters: SearchFilterModel[], displayedColumns: string[]): FormGroup {
        const formGroup = new FormGroup({});
        savedFilters.forEach((filter, index) => {
            const control = new FormGroup({
                'option': new FormControl(filter.option || displayedColumns[0]),
                'value': new FormControl(filter.value, Validators.required)
            });
            formGroup.addControl(`control-${index + 1}`, control);
        });
        return formGroup;
    }
}
