import {Component, Inject, OnInit, signal} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle,
} from "@angular/material/dialog";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {PureOriginalService} from "../../service/pure-original.service";
import {NotificationService} from "../../service/Notification.service";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {CorrectEcuBuildModel} from "../../uploading-actions/user-inputs/user-inputs.component";
import {map, Observable, startWith} from "rxjs";
import {EcuNamesService} from "../../service/Ecu-names.service";
import {AsyncPipe, CommonModule, NgForOf} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
    selector: 'app-ecu-build-confirmation-dialog',
    standalone: true,
    imports: [
        MatButton,
        ReactiveFormsModule,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatLabel,
        MatInput,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatOption,
        AsyncPipe,
        NgForOf,
        MatProgressSpinner,
        CommonModule
    ],
    templateUrl: './ecu-build-confirmation-dialog.component.html',
    styleUrl: './ecu-build-confirmation-dialog.component.css'
})
export class EcuBuildConfirmationDialogComponent implements OnInit {
    showEcuBuildInput = signal<boolean>(false);
    isLoading = signal<boolean>(false);
    filteredOptions: Observable<CorrectEcuBuildModel[]>;

    public ecuBuildNameControl = new FormControl<string | CorrectEcuBuildModel>('', [Validators.required]);

    constructor(private fb: FormBuilder, private pureOriginalService: PureOriginalService, private notificationService: NotificationService, private ecuNamesService: EcuNamesService, public dialogRef: MatDialogRef<EcuBuildConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
        ecuBuild: string,
        pureOriginalIds: number[]
    }) {


    }

    ngOnInit() {
        this.getCorrectedEcuNameOptions();
    }

    getCorrectedEcuNameOptions() {
        this.ecuNamesService.getCorrectEcuNamesOptions().then(() => {
            this.initializeAutocomplete();
        });
    }

    confirmedOption() {
        this.showEcuBuildInput.set(true);

    }

    canceledOption() {
        this.dialogRef.close(false);
    }

    submitNewEcuBuild() {
        this.isLoading.set(true);
        if (this.ecuBuildNameControl.valid) {
            const newEcuBuild = typeof this.ecuBuildNameControl.value === 'string'
                ? this.ecuBuildNameControl.value
                : this.ecuBuildNameControl.value?.correctEcuBuildNames;

            if (newEcuBuild) {
                this.pureOriginalService.updatePureOriginalsByFaultyEcuBuilds(newEcuBuild, this.data.pureOriginalIds)
                    .subscribe((response) => {
                        if (response.status === 200) {
                            this.notificationService.showSuccessMessage(response.body.message, "Successfully Updated!");
                            this.isLoading.set(false);
                            this.dialogRef.close({success: true, updatedIds: this.data.pureOriginalIds});
                        } else {
                            this.isLoading.set(false);
                            this.notificationService.showErrorMessage(response.body.message, "Failed!");
                        }
                    }, (error) => {
                        this.notificationService.showErrorMessage(`${error.error.message}`, "Error");
                    });
            } else {
                this.notificationService.showErrorMessage("Invalid ECU Build name.", "Validation Error");
            }
        } else {
            this.notificationService.showErrorMessage("Please select a valid ECU Build name.", "Validation Error");
        }
    }


    initializeAutocomplete() {
        this.filteredOptions = this.ecuBuildNameControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                const name = typeof value === 'string' ? value : value?.correctEcuBuildNames;
                return name ? this._filter(name as string) : this.ecuNamesService.correctEcuBuildNames().slice();
            }),
        );
    }

    displayFn(user: CorrectEcuBuildModel): string {
        return user && user.correctEcuBuildNames ? user.correctEcuBuildNames : '';
    }

    private _filter(name: string): CorrectEcuBuildModel[] {
        const filterValue = name;
        return this.ecuNamesService.correctEcuBuildNames().filter(option => option.correctEcuBuildNames.toLowerCase().startsWith(filterValue.toLowerCase()));
    }
}
