import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {LocalAuthResponseModel} from "../models/LocalAuthResponse-model";
import {UserLoginModel} from "../models/UserLogin-model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocalAuthService {

  private url = `${this.API_URL}`;
  private user: UserLoginModel | undefined;

  constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
  }

  loginStart(email: string, password: string): Observable<HttpResponse<any>> {
    return this.httpClient.post<LocalAuthResponseModel>(`${this.url}/authentication/authenticate`, {
      email: email,
      password: password
    }, {
      observe: 'response',
    });
  }

  logout() {
    this.user = null;
  }
  forgotPassword (email: string) {
    return this.httpClient.post(`${this.url}/authentication/forgotPassword`, {}, {params: {email: email}, observe: "response"});
  }
  resetPassword (token:string , newPassword: string, confirmationNewPassword: string) {
    return this.httpClient.post(`${this.url}/authentication/resetPassword`, {newPassword: newPassword, confirmationNewPassword: confirmationNewPassword}, {params: {token: token}, observe: "response"});
  }
  checkUserEmail(email: string) {
    return this.httpClient.post(`${this.url}/authentication/checkUserEmail`, {}, {params: {email: email}, observe: "response"});
  }

  signInWithMfa (email: string, twoFactorCode: string) {
    return this.httpClient.post<{token: string, message: string}>(`${this.url}/authentication/verify-mfa`, {email: email, passcode: twoFactorCode}, {observe: "response"})
  }
}
