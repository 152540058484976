@if (isUserSettingsOpened()) {
    <div class="user-modal">
        <div class="user-modal-overlay" (click)="closeUserSettings()">
        </div>
        <ul class="user-settings-nav">
            <div class="user-modal-header">
                <h2>{{ userService.loggedUser()?.firstName + ' ' + userService.loggedUser()?.lastName }}</h2>
            </div>
            <li (click)="openUserSettingsPage(userService.loggedUser().id)">
                <button><i class="bi bi-gear"></i></button>
                <span>Settings</span>
            </li>
            <li (click)="logout()">
                <button><i class="bi bi-box-arrow-left"></i></button>
                <span>Logout</span>
            </li>
        </ul>
    </div>
}