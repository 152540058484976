export class PureOriginalsResultModel {

  public id: string;
  public winOlsReference: string;
  public pureOriginalUpdated: string;
  public processingDate: string;
  public optionsUpdated: string;
  public fullMessage: string;
  public errorMessage: string;
  public pureOriginalBlobZipName: string;
  public completelyMatchedOptions: string;


  constructor() {
    this.id = "id";
    this.winOlsReference = "winOlsReference";
    this.fullMessage = "fullMessage";
    this.completelyMatchedOptions = "completelyMatchedOptions";
    this.pureOriginalUpdated = "pureOriginalUpdated";
    this.processingDate = "processingDate";
    this.errorMessage = "errorMessage";
    this.optionsUpdated = "optionsUpdated";
    this.pureOriginalBlobZipName = "pureOriginalBlobZipName";

  }
}
