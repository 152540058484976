<div class="number-of-files-wrapper">

    <div class="number-of-files-title">
        <span class="title-content title-position">Files in queue</span>
        <div class="files-info">
            <div class="circle orange"></div>
            <span class="title-content">In Progress</span>
        </div>
        <div class="files-info">
            <div class="circle text-col"></div>
            <span class="title-content title-position-last">In Queue</span>
        </div>
    </div>

    <div class="main-content">
        <div class="num-of-files-container">
            <div class="server-box-container">
                <div class="server-stats-container">
                    <h2 class="server-title">Pure Originals</h2>
                    <h2 class="server-content"><span
                            class="in-progress">{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.pureOriginalsInProgress }}</span>
                    </h2>
                    <h2 class="server-content">
                        <span>{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.pureOriginalsInQueue }}</span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="num-of-files-container">
            <div class="server-box-container">
                <h2 class="server-title">Masters</h2>
                <div class="server-stats-container">
                    <h2 class="server-content"><span
                            class="in-progress">{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.mastersInProgress }}</span>
                    </h2>
                    <h2 class="server-content">
                        <span>{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.mastersInQueue }}</span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="num-of-files-container">
            <div class="server-box-container">
                <h2 class="server-title">Tunings</h2>
                <div class="server-stats-container">
                    <h2 class="server-content"><span
                            class="in-progress">{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.tuningsInProgress }}</span>
                    </h2>
                    <h2 class="server-content">
                        <span>{{ statisticService.isNumberOfFilesInQueueLoading() ? '-' : statisticService.numberOfFiles()?.tuningsInQueue }}</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
