import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";
import {CarImageService} from "../../service/CarImage.service";
import {AllMasterDatabaseModel} from "../../models/AllMasterDatabase-model";


@Component({
    selector: 'app-master-details-component',
    templateUrl: './master-details.component.html',
    styleUrls: ['./master-details.component.css']
})
export class MasterDetailsComponent implements OnInit {

    allMastersService = inject(AllMastersDatabaseService);
    imageService = inject(CarImageService);
    route = inject(ActivatedRoute);

    ngOnInit() {
        this.restartMasterItem();
        this.getIdParams();
        this.getMasterItemDetails();
    }

    restartMasterItem() {
        this.allMastersService.masterItem.set({} as AllMasterDatabaseModel);
    }

    getIdParams() {
        this.route.params.subscribe(value => {
            this.allMastersService.masterId.set(value['id']);
        })
    }

    getMasterItemDetails() {
        this.allMastersService.getMasterById();
    }


    uploadIniFile($event: any) {
        const file = $event.valueOf()["fileToUpload"];
        this.allMastersService.uploadMasterIniFile(file);
    }

    deleteMasterItem(masterItemId: number, tableName: string) {
        this.allMastersService.deleteMasterItem(masterItemId, tableName);
    }

    isDownloadInProgress(id: number) {
        return this.allMastersService.isDownloadInProgress(id);
    }

    downloadMasterItem(id: number, winOls: string, tableName: string) {
        this.allMastersService.downloadMastersFromTable(id, winOls, tableName);
    }

    editMasterItem(masterItem: AllMasterDatabaseModel, id: number) {
        this.allMastersService.updateMasterDetails(masterItem, id);
    }
}

export class CustomMasterUploadFileValidations {
    message: string;
    hasError: boolean;
}
