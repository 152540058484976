import {Component, inject, signal} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {fadeInAnimation} from "../../animations/fadeInAnimation";
import {PureOriginalUserModel} from "../../models/PureOriginalUser-model";
import {PureOriginalService} from "../../service/pure-original.service";



@Component({
    selector: 'app-pure-original-details',
    templateUrl: './pure-original-details.component.html',
    styleUrls: ['./pure-original-details.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class PureOriginalDetailsComponent {

    public pureOriginalId = signal<number>(-1);
    public pureOriginalMatchingId = signal<number>(-1);
    public from = signal<number>(-1);
    public to = signal<number>(-1);
    public pureOriginalUser = signal<PureOriginalUserModel | null>(null);

    public isFileModalOpened = signal<boolean>(false);
    public versionName = signal<string>('');
    public isImpossibleTuningUploaded = signal<boolean>(false);

    public disabled = signal<boolean>(false);


    router = inject(Router);
    route = inject(ActivatedRoute);
    pureOriginalService = inject(PureOriginalService);

    constructor() {
        this.route.queryParams.subscribe(params => {
            this.pureOriginalId.set(params['pureOriginalId'])
            if (params["pureOriginalMatchingId"]) {
                console.log(params["pureOriginalMatchingId"], "MATCHING ID");
                this.pureOriginalMatchingId.set(+params["pureOriginalMatchingId"])
            }
        })
    }


    backToMainTable() {
        this.pureOriginalService.triggerBackFunction();
    }

    openFileModal($event: any) {
        this.isFileModalOpened.set($event.valueOf());
    }

    getVersionName($event: any) {
        this.versionName.set($event.valueOf());
    }

    closeModal($event: any) {
        this.isFileModalOpened.set($event.valueOf());
        this.versionName.set(null);
    }


    getPureOriginalUser($event: any) {
        this.pureOriginalUser.set($event.valueOf());
    }

    getDisabledButton($event: any) {
        this.disabled.set($event.valueOf());
    }

}
