<div class="search-field">
    <div class="title-container">
        <span class="title">Filters</span>
        <button class="plus-btn " (click)="addFormControl()">
            <i class="bi bi-plus-square"></i></button>
    </div>
    <form [formGroup]="searchFilterForm" class="form-group" (keydown.enter)="onSubmit()">
        <ng-container *ngFor="let group of searchFilterForm.controls | keyvalue; let i = index">
            <div [formGroupName]="group.key" class="f-search">

                <mat-form-field>
                    <mat-select formControlName="option" placeholder="Select an option">
                        <mat-option *ngFor="let option of displayedColumns" [value]="option">{{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
<!--                <mat-form-field class="custom-mat-input">-->
<!--                    <input matInput type="text" formControlName="value">-->
<!--                </mat-form-field>-->

                <ng-container *ngIf="searchFilterForm.get(group.key)?.get('option')?.value === 'hitRate'; else defaultInput">
                    <mat-form-field class="custom-mat-input" >
                        <mat-label>From</mat-label>

                        <input matInput type="number" formControlName="hitRateMin" placeholder="From" min="50" max="100">
                    </mat-form-field>
                    <mat-form-field class="custom-mat-input">
                        <mat-label>To</mat-label>

                        <input matInput type="number" formControlName="hitRateMax" placeholder="To" min="50" max="100">
                    </mat-form-field>
                </ng-container>
                <ng-template #defaultInput>
                    <ng-container [ngSwitch]="searchFilterForm.get(group.key)?.get('option')?.value">
                        <ng-container *ngSwitchCase="'tuningName'">
                            <mat-form-field class="custom-mat-input">
                                <mat-select formControlName="value" placeholder="Select Tuning Name">
                                    <mat-option *ngFor="let tuning of tuningNames()" [value]="tuning">
                                        {{ tuning }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <mat-form-field class="custom-mat-input">
                                <input matInput type="text" formControlName="value" placeholder="Value">
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </ng-template>


                <button (click)="deleteSearchBar(group.key)"
                        [disabled]="!group.key" class="delete-btn"><i
                        class="bi bi-x-square"></i></button>
            </div>
        </ng-container>
        <div class="bs-area">
            <div class="buttons-position">
                <button class="custom-btn" (click)="onSubmit()"
                        [disabled]="!hasFormControls() || searchFilterForm.invalid">
                    Search
                </button>
                <button class="close-btn" (click)="clearFilters()">Clear filters</button>
            </div>
            <div class="close-button">
                <button class="close-btn" (click)="closeModal()">Close</button>
            </div>
        </div>
    </form>
</div>

