import {Component} from '@angular/core';

@Component({
    selector: 'app-server-stats-placeholder',
    templateUrl: './server-stats-placeholder.component.html',
    styleUrls: ['./server-stats-placeholder.component.css']
})
export class ServerStatsPlaceholderComponent {

    constructor() {
    }

}
