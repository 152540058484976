import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserRegisterModel} from "../models/userRegister-model";

@Injectable({
  providedIn: "root"
})
export class UserRegisterService {

  private url: string = `${this.API_URL}`;

  constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {

  }

  public registerUser(userRegisterDTO: UserRegisterModel) {
    return this.httpClient.post(`${this.url}/authentication/register`, userRegisterDTO, {observe: "response"});
  }


}
