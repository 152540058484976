<div class="dashboard">
    <div class="act-sidebar-navigation bg-secondary-clr" [ngClass]="statisticService.isOpened() ? 'opened' : ''">
        <app-sidebar-component></app-sidebar-component>
    </div>

    <div class="main-dashboard-container" [ngClass]="statisticService.isOpened() ? 'increase' : ''">

        <div class="main-dashboard-wrapper">
            <div class="header-container">
                <app-header-component></app-header-component>
            </div>

            <div class="main-content-display bg-primary-clr">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<app-two-factor-activate-template [isOpened]="authService.isMFAModalOpened()" (closeModal)="closeMFAModal($event)"></app-two-factor-activate-template>
