import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IdentifyStockFileModel} from "../../../models/IdentifyStockFile-model";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-user-input-selection',
  templateUrl: './user-input-selection.component.html',
  styleUrls: ['./user-input-selection.component.css']
})
export class UserInputSelectionComponent {

  @Input() stockFileDTO: IdentifyStockFileModel;
  @Input() selectedOptions: { key: string, value: boolean, disabled: boolean }[] = [];

  @Output() activeOption = new EventEmitter<{ key: string, value: boolean, disabled: boolean }>();
  @Output() stockFileBlobName = new EventEmitter<string>();

  @Input() ecuBuildNameControl: FormControl;

  constructor() {
  }

  setOptionToActive(option: { key: string, value: boolean, disabled: boolean }) {
    this.activeOption.emit(option);
  }

  checkValidityOfOptions(option: { key: string, value: boolean, disabled: boolean }) {
    if (option.key === 'Stage 1') {
      return this.selectedOptions.some(item => (item.key === 'Stage 2' || item.key === 'Stage 3') && item.value);
    } else if (option.key === 'Stage 2') {
      return this.selectedOptions.some(item => (item.key === 'Stage 1' || item.key === 'Stage 3') && item.value);
    } else if (option.key === 'Stage 3') {
      // Assuming "Stage 3" validity depends on either "Stage 1" or "Stage 2" being selected
      return this.selectedOptions.some(item => (item.key === 'Stage 1' || item.key === 'Stage 2') && item.value);
    }
    return false;
  }

  requestFileTune(stockFileBlobName: string) {
    this.stockFileBlobName.emit(stockFileBlobName);
  }


  anyOptionsSelected() {
    for (let option of this.selectedOptions) {
      if (option.value) {
        return false;
      }
    }
    return true;
  }


}
