import {Inject, Injectable} from "@angular/core";
import {StatisticsModel} from "../models/statistics-model";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: "root"
})
export class PartnerStatisticsService {

  private url = `${this.API_URL}`;
  private partnerId: number = 1;


  constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {}


  getStatisticsByTimeStamp(startDate: string, endDate: string) {
    return this.httpClient.get<StatisticsModel>(`${this.url}/partnerStatistics/statisticsRecordsByTimestamp/${this.partnerId}`, {
      params: {
        'start date': startDate,
        'end date': endDate
      }
    })
  }
}
