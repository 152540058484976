import {Component} from '@angular/core';
import {Location} from "@angular/common";

@Component({
    selector: 'app-master-details-wrapper',
    templateUrl: './master-details-wrapper.component.html',
    styleUrls: ['./master-details-wrapper.component.css']
})
export class MasterDetailsWrapperComponent {

    public ecuBuild: string;
    constructor(private location: Location) {
    }

    backToMainScreen() {
        this.location.back();
    }
}
