import {Component, effect, ElementRef, inject, input, OnChanges, OnInit, signal, SimpleChanges} from '@angular/core';
import {PureOriginalService} from "../../../service/pure-original.service";
import {MatDialog} from "@angular/material/dialog";
import {
    EcuBuildConfirmationDialogComponent
} from "../../../helpers/ecu-build-confirmation-dialog/ecu-build-confirmation-dialog.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-pure-original-faulty-ecu-table',
    templateUrl: './pure-original-faulty-ecu-table.component.html',
    styleUrl: './pure-original-faulty-ecu-table.component.css'
})
export class PureOriginalFaultyEcuTableComponent implements OnInit, OnChanges {

    pureOriginalsService = inject(PureOriginalService);
    faultyEcuBuild = input<string>();
    pureOriginalIds = signal<number[]>([]);
    ecuBuild = signal<string>("");
    router = inject(Router);
    elRef = inject(ElementRef);
    private dialog = inject(MatDialog);


    constructor() {
        effect(() => {
            console.log("NEw EcU BUILD", this.faultyEcuBuild());
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["faultyEcuBuild"].previousValue !== changes["faultyEcuBuild"].currentValue) {
            this.pureOriginalsService.tableIsLoading.set(true);
            const tableElement: HTMLElement = this.elRef.nativeElement.querySelector('.table-container');
            if (tableElement) {
                tableElement.scrollTop = 0;
            }
            this.getFaultyPureOriginalsByFaultyEcuBuild(changes["faultyEcuBuild"].currentValue);
        }
    }


    ngOnInit() {
        this.pureOriginalsService.restartTableValues();
        this.pureOriginalsService.getTableHeaderNamesForPureOriginals();
    }

    getFaultyPureOriginalsByFaultyEcuBuild(ecuBuild: string) {
        this.pureOriginalsService.faultyEcuBuild.set(ecuBuild);
        this.pureOriginalsService.pageNumber.set(0);
        this.pureOriginalsService.getFaultyPureOriginalsByFaultyEcuBuilds();
    }

    viewDetails($event: any, element: any) {
        const isChecked = $event.target.checked;
        this.ecuBuild.set(element.ecuBuild);
        console.log(isChecked);
        if (isChecked) {
            this.pureOriginalIds.update((prevPureOriginalId) => [...prevPureOriginalId, element.id]);
        } else {
            this.pureOriginalIds.update((prevPureOriginalId) =>
                prevPureOriginalId.filter((id) => id !== element.id)
            );
        }

    }

    submitPureOriginalIds() {
        const dialogRef = this.dialog.open(EcuBuildConfirmationDialogComponent, {
            width: "350px",
            disableClose: true,
            data: {ecuBuild: this.ecuBuild(), pureOriginalIds: this.pureOriginalIds()}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result?.success) {
                this.pureOriginalsService.pureOriginals.update((pureOriginals) => pureOriginals.filter(original => !result.updatedIds.includes(original.id)));
                this.pureOriginalIds.set([]);
            }
        });

    }

    openPureOriginalDetails (pureOriginalId: number) {
        return this.router.navigate(['/app/pure-originals/pure-original-details'], {
            queryParams: {pureOriginalId: pureOriginalId},
        })

    }

    isElementSelected(pureOriginalId: number) {
        return this.pureOriginalIds().includes(pureOriginalId)
    }

    getSelectedIdOutputs($event: any) {
        if ($event.valueOf().length > 0) {
            this.pureOriginalIds.set($event.valueOf());
        } else {
            this.pureOriginalIds.set([]);
        }

    }

}
