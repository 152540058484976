<div class="upload-file-wrapper">
    <div class="restart-button-wrapper">
        <h2 class="title-text">Upload Pure Original File</h2>
        <button (click)="refreshUpload()" class="button restart-btn"><i class="bi bi-arrow-clockwise"></i></button>
    </div>
    <div class="first-row">
        <div class="input-container">
            <app-upload-file inputText="Drag & Drop BIN" (file)="uploadBin($event)"
                             [error]="pureOriginalsUploadService.myInputs.get('bin').message" [resetSignal]="pureOriginalsUploadService.resetSignal()" customBackground="var(--primary-clr)"></app-upload-file>
        </div>
        <div class="input-container">
            <app-upload-file inputText="Drag & Drop INI" (file)="uploadIni($event)"
                             [error]="pureOriginalsUploadService.myInputs.get('ini').message" [resetSignal]="pureOriginalsUploadService.resetSignal()" customBackground="var(--primary-clr)"></app-upload-file>
        </div>
    </div>
    <div class="second-row">
        <div class="add-bar-container">
            <input type="text" placeholder="Add WinOls" name="winOlsValue" [formControl]="pureOriginalsUploadService.winOls"
                   (keyup.enter)="uploadAllFiles()"/>
            <div class="error-message-container">
                 <span *ngIf="pureOriginalsUploadService.winOls.hasError('olsFormat')" class="error-upload-message">
               WinOls has to end with '.ols'.
            </span>
                <span *ngIf="pureOriginalsUploadService.myInputs.get('winOls').hasError" class="error-upload-message">
                   {{ pureOriginalsUploadService.myInputs.get('winOls').message }} <a *ngIf="pureOriginalsUploadService.myInputs.get('winOls').pureOriginalId"
                                                           [href]="'/app/pure-originals/pure-original-details?pureOriginalId=' + pureOriginalsUploadService.myInputs.get('winOls').pureOriginalId">View details</a>
            </span>
            </div>
        </div>
        <div class="button-wrapper">
            <div class="button-position">
                <button class="button dropzone" (click)="uploadAllFiles()"
                        [disabled]="pureOriginalsUploadService.hasAnyError() || pureOriginalsUploadService.winOls.invalid || pureOriginalsUploadService.isUploading() || !pureOriginalsUploadService.binFile() || !pureOriginalsUploadService.iniFile()"
                        [ngClass]="pureOriginalsUploadService.hasAnyError() === true ? 'red' : ''"><span class="button-text">Upload</span>
                </button>
            </div>
        </div>
    </div>
</div>

