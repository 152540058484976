import {Component, HostListener, inject, Inject, OnInit} from '@angular/core';
import {StatisticsService} from "../service/statistics.service";
import {AuthService} from "../service/auth.service";
import {Router} from "@angular/router";
import {UserService} from "../service/user.service";

@Component({
    selector: 'app-header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    public statisticService = inject(StatisticsService);
    public authService = inject(AuthService);
    private router = inject(Router);
    public userService = inject(UserService);


    constructor(@Inject('PRODUCTION') public PRODUCTION: boolean) {
    }

    ngOnInit() {
        this.userService.getLoggedUser();
        if (this.authService.isAdmin()) {
            this.userService.getAllUsers();
        }
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isLoggedIn() {
        return this.authService.isLoggedIn();
    }

    @HostListener('window:resize', ['$event'])
    isMobile(): boolean {
        return window.innerWidth < 1116;
    }


    openNavigationMenu() {
        this.statisticService.openNavbarMenu();
    }

    closeNavigationMenu() {
        this.statisticService.isOpened.set(!this.statisticService.isOpened);
    }

    openUserSettings() {
        this.userService.openUserSettings();
    }

    closeUserSettings($event: any) {
        this.userService.isUserSettingsOpened.set($event.valueOf());
    }

    redirectToMainPage() {
        console.log('CLICK')
        this.router.navigate(['/app']);
    }

}



