import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionRecordsModel} from "../models/VersionRecordsModel";
import {TuningNamesTablesService} from "./Tuning-names-tables.service";
import {firstValueFrom} from "rxjs";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {NotificationService} from "./Notification.service";
import {Router} from "@angular/router";
import {PureOriginalService} from "./pure-original.service";
import {AllMastersDatabaseService} from "./all-masters-database.service";
import {DownloadFileFromDatabaseService} from "./DownloadFileFromDatabase.service";
import {AuthService} from "./auth.service";
import {FormControl, FormGroup} from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class TuningsService {

    private url = `${this.API_URL}`;

    public allVersions = signal<VersionRecordsModel[]>([]);
    public isLoading = signal<boolean>(false);
    public options = signal<string[]>([]);
    public selectedOptions: { key: string, value: boolean, disabled: boolean }[] = []
    public masterId = signal<number>(0);
    public tableName = signal<string>('VERSION');
    public editedItemIndex = signal<number | undefined>(-1);
    public addVersionComment = signal<string>('');
    public versionName = signal<string>('');
    public filesToDownload = signal<number[]>([]);

    versionCommentForm = new FormGroup({
        versionComment: new FormControl("")

    })
    private dialog = inject(MatDialog);
    private deleteService = inject(DeleteFileFromDatabaseService);
    private notificationService = inject(NotificationService);
    private pureOriginalsService = inject(PureOriginalService);
    private allMastersService = inject(AllMastersDatabaseService);
    private downloadService = inject(DownloadFileFromDatabaseService);
    private authService = inject(AuthService);
    private router = inject(Router);
    private tuningNamesTablesService = inject(TuningNamesTablesService);
    private httpClient = inject(HttpClient);

    constructor(@Inject('API_URL') private API_URL: string) {
    }

    async getTuningOptions() {
        try {
            const response = await firstValueFrom(this.tuningNamesTablesService.getListOfCorrectTuningNames());
            this.options.set(response.correctTuningNames);
            for (let option of this.options()) {
                this.selectedOptions.push({key: option, value: false, disabled: false});
            }
        } catch (error) {
            console.error('Error fetching tuning options:', error);
            // Handle the error appropriately
        }
    }

    getVersionRecords(masterId: number) {
        this.httpClient.get<VersionRecordsModel[]>(`${this.url}/version/versionByMasterId/${masterId}`).subscribe(response => {
            this.allVersions.set(response as VersionRecordsModel[]);
        });
    }

    sortListByTuningNames() {
        let sortedList: VersionRecordsModel[];
        let tuningNamesOfLoadedTunings: string[];

        sortedList = this.allVersions();

        tuningNamesOfLoadedTunings = sortedList.map(item => item.versionName.toString())
        return this.sortListOfTunings(tuningNamesOfLoadedTunings, sortedList);
    }


    formatDate(dateString: string): string {
        const parts = dateString.split('-');

        const formattedDate = new Date(
            parseInt(parts[2]),
            parseInt(parts[1]) - 1,
            parseInt(parts[0])
        );

        const day = formattedDate.getDate().toString().padStart(2, '0');
        const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = formattedDate.getFullYear();

        return `${day}-${month}-${year}`;
    }

    isDownloadInProgress(id: number) {
        return this.filesToDownload().includes(id);
    }


    getDownloadMasterVersion(id: number, winOls: string, versionName: string) {

        this.filesToDownload.update((prevId) => [...prevId, id]);

        const toastObj = this.notificationService.showInfoMessage(`Preparing your ${winOls} ${versionName}  file...`, 'Preparing..');

        this.downloadService.downloadFileFromDatabase(id, this.tableName()).subscribe(response => {

            const index = this.filesToDownload().indexOf(id);
            this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

            if (response.status === 200) {

                this.notificationService.clear(toastObj.toastId);
                window.location.href = response.body.url;

                this.notificationService.showSuccessMessage(`<div><span>Your file ${winOls}  ${versionName} is downloaded</span></div>`, "Success",
                );

            } else {
                this.notificationService.showErrorMessage('Something went wrong with file.', 'Failed');
            }

            if (response.status === 500) {
                this.notificationService.showErrorMessage("You can't download this file. ", 'Download failed');
            }
        })
    }

    onEditVersionComment(index: number) {
        if (!this.isUser()) {
            this.editedItemIndex.set(index);
            this.addVersionComment.set(this.allVersions()[index].versionComment || '');
            this.versionCommentForm.get('versionComment').setValue(this.addVersionComment());
        }
    }

    saveVersionComment(editedItemId: number) {
        let versionComment = this.versionCommentForm.get("versionComment").value;

        this.isLoading.set(true);
        this.updateVersionComment(editedItemId, versionComment).subscribe(response => {
            this.allVersions.update((allVersions) => allVersions.map((version) => (+version.id === editedItemId) ? response : version));

            this.isLoading.set(false);
            this.editedItemIndex.set(null);
        })
    }

    closeEditMode() {
        setTimeout(() => {
            this.editedItemIndex.set(-1);
        }, 0);
    }


    isUser() {
        return this.authService.isUser();
    }

    getPureOriginalsByVersionId(versionId: number, versionName: string, dcf: string) {
        this.pureOriginalsService.getPureOriginalsByVersionId(versionId).subscribe(value => {
            if (value.length > 0) {
                this.router.navigate(['/app/pure-originals-matching-table'], {
                    queryParams: {
                        versionId: versionId,
                        ecuProducer: this.allMastersService.ecuProducer(),
                        versionName: versionName,
                        dcf: dcf,
                        ecuBuild: this.allMastersService.ecuBuild(),
                        partiallyTuned: false
                    }
                });
            } else {
                this.notificationService.showInfoMessage("This version doesn't have files", "No files in DB");
                return;
            }
        })
    }

    getPartiallyTunePureOriginalsByVersionId(versionId: number, versionName: string, dcf: string) {
        this.pureOriginalsService.getPartiallyTunedPureOriginalsByVersionId(versionId).subscribe(value => {
            if (value.length > 0) {
                this.router.navigate(['/app/pure-originals-matching-table'], {
                    queryParams: {
                        versionId: versionId,
                        ecuProducer: this.allMastersService.ecuProducer(),
                        versionName: versionName,
                        dcf: dcf,
                        ecuBuild: this.allMastersService.ecuBuild(),
                        partiallyTuned: true
                    }
                });
            } else {
                this.notificationService.showInfoMessage("This version doesn't have files", "No files in DB");
                return;
            }
        })
    }

    sortListOfTunings(pureOriginalList: string[], pureOriginalsListSorted: VersionRecordsModel[]) {
        const sortedListOfTunings = ['Stage 1', 'Stage 2', 'Vmax', 'DPF', 'OPF', 'EGR'];

        const newSortedList = sortedListOfTunings.filter((tuning) => {
            return pureOriginalList.includes(tuning);
        });

        const finalSortedList = newSortedList.concat(pureOriginalList.filter((tuning) => !sortedListOfTunings.includes(tuning)));

        return pureOriginalsListSorted.sort((a, b) => finalSortedList.indexOf(a.versionName) - finalSortedList.indexOf(b.versionName));
    }

    updateVersionComment(versionId: number, versionComment: string) {
        return this.httpClient.post<VersionRecordsModel>(`${this.url}/version/updateVersionComment/${versionId}`, {}, {
            params: {
                versionComment: versionComment
            }
        });
    }

    deleteTuningRecordFromTable(id: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteService.deleteFileFromDatabase(id, tableName).subscribe((response) => {
                    if (response.status === 200) {
                        this.allVersions.update((allVersions) => allVersions.filter((version) => +version.id !== id));
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                });
            }

        })
    }

}
