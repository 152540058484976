import {Injectable, signal} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {LocalAuthService} from "./localAuth.service";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {MFAConfirmationDialogComponent} from "../helpers/mfa-confirmation-dialog/mfa-confirmation-dialog.component";


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _accessToken: string | undefined;
    private jwtHelperService: JwtHelperService;
    private _isLoggedIn: boolean;

    public isMFAModalOpened = signal<boolean>(false);

    constructor(private router: Router, private localAuthService: LocalAuthService, private toastrService: ToastrService, private dialog: MatDialog, private route: ActivatedRoute) {
        this.jwtHelperService = new JwtHelperService();
    }

    get accessToken() {
        return localStorage.getItem('_accessToken');
    }

    set accessToken(token) {
        localStorage.setItem('_accessToken', token);
        this._accessToken = token;
    }

    login(email: string, password: string) {
        this.localAuthService.loginStart(email, password).subscribe(response => {
                if (response.status === 200) {
                    this.getTokenFromResponse(response.body.token);
                    this.showMFADialog();
                }
            }, (error: HttpErrorResponse) => {
                this.handleErrorFromResponse(error);
            }
        )
    }

    logout(): boolean {
        this._isLoggedIn = false;
        localStorage.removeItem('_accessToken');
        this.localAuthService.logout();
        this.router.navigate(['/login']);
        return this._isLoggedIn;
    }

    isLoggedIn(): boolean {
        return this.isAdmin() || this.isUser() || this.isModerator() || this.isSuperAdmin();
    }

    isSuperAdmin(): boolean {
        return this.isRole('SUPER_ADMIN');
    }

    isAdmin(): boolean {
        return this.isRole('ADMIN') || this.isSuperAdmin();
    }

    isUser(): boolean {
        return this.isRole('USER');
    }

    isModerator(): boolean {
        return this.isRole('MODERATOR');
    }

    private isRole(targetRole: string): boolean {
        const token = this.decodeToken();
        return (token && token.authorities) ? token.authorities.some((role) => role!.authority === targetRole) : false;
    }

    public isExpired(token: string) {
        return this.jwtHelperService.isTokenExpired(token);
    }

    decodeToken() {
        const token = localStorage.getItem('_accessToken') || '';
        return this.jwtHelperService.decodeToken(token);
    }

    getTokenFromResponse(token: string) {
        this.accessToken = token;
        this.toastrService.success('User is successfully logged in!', 'You are logged in!', {positionClass: 'toast-top-right'});
        this.router.navigate(['/app']);
    }

    showMFADialog() {
        setTimeout(() => {
            const dialogRef = this.dialog.open(MFAConfirmationDialogComponent, {
                width: "350px",
                disableClose: true,
                data: "Do you want to add two-factor authentication?"
            })
            dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.isMFAModalOpened.set(true);
                } else {
                    this.isMFAModalOpened.set(false);
                }
            })
        }, 3000)
    }

    handleErrorFromResponse(error: HttpErrorResponse) {
        if (error.status == 302) {
            console.log(this.route);
            this.router.navigate(["/login/two-factor"], {queryParams: {email: error.error.token}});
        }
        if (error.status == 400) {
            console.log('Error occured', error.status);
            this.toastrService.error('Bad request', 'Error occured!', {positionClass: 'toast-top-right'});
        }
        if (error.status == 401) {
            console.log('Error occured', error.status);
            this.toastrService.error('Try again', 'Incorrect Password', {positionClass: 'toast-top-right'});
        }
        if (error.status == 403) {
            console.log('Error occured', error.status);
            this.toastrService.error('Your IP address is blocked for 2 minutes', 'IP address blocked', {positionClass: 'toast-top-right'});
        }
        if (error.status == 404) {
            console.log('Error occured', error.status);
            this.toastrService.error('User not found', 'No User!', {positionClass: 'toast-top-right'});
        }
        if (error.status == 405) {
            console.log('Error occured', error.status);
            this.toastrService.error('Account is not activated yet!', 'Not Activated!', {positionClass: 'toast-top-right'});
        }
    }


}
