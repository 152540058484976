import {Component, input} from '@angular/core';
import {SystemInfoModel} from "../../models/SystemInfo-model";

@Component({
    selector: 'app-server-stats',
    templateUrl: './server-stats.component.html',
    styleUrls: ['./server-stats.component.css']
})
export class ServerStatsComponent {

   isLoading= input<boolean>();
    systemInfoData= input<SystemInfoModel[]>([]);



}
