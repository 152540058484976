import {
    Component, computed, ElementRef,
    EventEmitter,
    HostListener,
    inject,
    input,
    OnInit,
    Output, signal, ViewChild
} from '@angular/core';
import {PureOriginalService} from "../../../service/pure-original.service";
import {CarImageService} from "../../../service/CarImage.service";
import {PureOriginalsDetailsModel} from "../../../models/PureOriginalsDetails-model";
import {PureOriginalUserModel} from "../../../models/PureOriginalUser-model";
import {UpdatePureOriginalModel} from "../../../models/UpdatePureOriginal-model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../service/Notification.service";
import {AuthService} from "../../../service/auth.service";
import {MatTooltip} from "@angular/material/tooltip";


@Component({
    selector: 'app-pure-originals-info',
    templateUrl: './pure-originals-info.component.html',
    styleUrls: ['./pure-originals-info.component.css']
})
export class PureOriginalsInfoComponent implements OnInit {

    @Output() public isFileModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('historyTooltip') historyTooltip: MatTooltip;

    pureOriginalId = input<number>();
    buttonIsDisabled = input<boolean>();
    public isInputChanged = signal<boolean>(false);
    public isVisibleButton = signal<boolean>(false);
    showMore = false;

    private previousFormValues: any;
    error = input<string>();
    @Output() pureOriginalUser = new EventEmitter<PureOriginalUserModel>
    public pureOriginalService = inject(PureOriginalService);
    public carImageService = inject(CarImageService);
    public elRef = inject(ElementRef);
    public notificationService = inject(NotificationService);
    private authService = inject(AuthService);
    public tooltipMessage = signal<string>("");

    public pureOriginalDetailObject = computed(() => {
        return this.pureOriginalService.pureOriginalDetails();
    })

    pureOriginalDetailsFormGroup = new FormGroup({
        clientName: new FormControl(''),
        clientNumber: new FormControl(''),
        clientLicencePlace: new FormControl(''),
        vehicleProducer: new FormControl(''),
        vehicleSeries: new FormControl(''),
        vehicleModel: new FormControl(''),
        vehicleModelYear: new FormControl(''),
        vehicleBuild: new FormControl(''),
        vehicleCharacteristic: new FormControl(''),
        vehicleVin: new FormControl(''),
        ecuSoftwareVersion: new FormControl(''),
        ecuUse: new FormControl(''),
        ecuBuild: new FormControl(''),
        ecuProducer: new FormControl(''),
        ecuProdNr: new FormControl(''),
        ecuStgNr: new FormControl(''),
        engineDisplacement: new FormControl(0, Validators.pattern(/^\d+(\.\d+)?$/)),
        engineMaxTorque: new FormControl(0, Validators.pattern(/^\d+(\.\d+)?$/)),
        engineName: new FormControl(''),
        engineProducer: new FormControl(''),
        engineType: new FormControl(''),
        engineTransmission: new FormControl(''),
        outputPs: new FormControl(0, Validators.pattern(/^\d+(\.\d+)?$/)),
        outputKw: new FormControl(0, Validators.pattern(/^\d+(\.\d+)?$/)),
        readingHardware: new FormControl(''),
        vehicleType: new FormControl(''),
        ecuSoftwareVersionVersion: new FormControl(''),
        engineEmissionStandard: new FormControl(''),
        engineEmission: new FormControl(''),
        resellerProjectDetails: new FormControl(''),
        resellerProjectType: new FormControl(''),
        resellerCredits: new FormControl(''),
        projectComment: new FormControl(''),
        versionComment: new FormControl(''),
        creditType: new FormControl(''),
        projectType: new FormControl(''),
        userDef1: new FormControl(''),
        userDef2: new FormControl(''),
        userDef3: new FormControl(''),
        userDef4: new FormControl(''),
        userDef5: new FormControl(''),
        userDef6: new FormControl(''),
        userDef7: new FormControl(''),
        userDef11: new FormControl(''),
        userDef12: new FormControl(''),
        userDef13: new FormControl(''),
        userDef14: new FormControl(''),
        userDef15: new FormControl(''),
        userDef16: new FormControl(''),
        userDef17: new FormControl(''),
        userDef18: new FormControl(''),
        userDef19: new FormControl(''),
        userDef20: new FormControl('')
    });


    public editFlags = {
        id: false,
        vehicleProducer: false,
        vehicleSeries: false,
        vehicleModel: false,
        vehicleModelYear: false,
        vehicleBuild: false,
        ecuBuild: false,
        ecuProducer: false,
        ecuUse: false,
        engineDisplacement: false,
        engineMaxTorque: false,
        engineProducer: false,
        engineType: false,
        engineTransmission: false,
        outputPs: false,
        outputKw: false,
        vehicleType: false,
        ecuSoftwareVersion: false,
        ecuSoftwareVersionVersion: false,
        engineEmissionStandard: false,
        resellerProjectDetails: false,
        vehicleVin: false,
        vehicleCharacteristic: false,
        ecuStgNr: false,
        resellerProjectType: false,
        resellerCredits: false,
        readingHardware: false,
        ecuProdNr: false,
        dateUpdated: false,
        dateExported: false,
        dateUploaded: false


    };

    ngOnInit() {
        this.restartPureOriginalInfoDetails();
        this.initializeValueChangeSubscriptions();
        this.getPureOriginalsDetails();
    }

    restartPureOriginalInfoDetails() {
        this.pureOriginalService.pureOriginalDetails.set({} as PureOriginalsDetailsModel);
        this.carImageService.image = null
    }

    toggleShowMore() {
        this.showMore = !this.showMore;
    }

    editFormValue(pureOriginalDetails: any) {
        this.pureOriginalDetailsFormGroup.setValue({
            clientName: pureOriginalDetails.clientName || '',
            clientNumber: pureOriginalDetails.clientNumber || '',
            clientLicencePlace: pureOriginalDetails.clientLicencePlace || '',
            vehicleProducer: pureOriginalDetails.vehicleProducer || '',
            vehicleSeries: pureOriginalDetails.vehicleSeries || '',
            vehicleModel: pureOriginalDetails.vehicleModel || '',
            vehicleModelYear: pureOriginalDetails.vehicleModelYear || '',
            vehicleBuild: pureOriginalDetails.vehicleBuild || '',
            vehicleCharacteristic: pureOriginalDetails.vehicleCharacteristic || '',
            vehicleVin: pureOriginalDetails.vehicleVin || '',
            ecuSoftwareVersion: pureOriginalDetails.ecuSoftwareVersion || '',
            ecuUse: pureOriginalDetails.ecuUse || '',
            ecuBuild: pureOriginalDetails.ecuBuild || '',
            ecuProducer: pureOriginalDetails.ecuProducer || '',
            ecuProdNr: pureOriginalDetails.ecuProdNr || '',
            ecuStgNr: pureOriginalDetails.ecuStgNr || '',
            engineDisplacement: pureOriginalDetails.engineDisplacement || 0,
            engineMaxTorque: pureOriginalDetails.engineMaxTorque || 0,
            engineName: pureOriginalDetails.engineName || '',
            engineProducer: pureOriginalDetails.engineProducer || '',
            engineType: pureOriginalDetails.engineType || '',
            engineTransmission: pureOriginalDetails.engineTransmission || '',
            outputPs: pureOriginalDetails.outputPs || 0,
            outputKw: pureOriginalDetails.outputKw || 0,
            readingHardware: pureOriginalDetails.readingHardware || '',
            vehicleType: pureOriginalDetails.vehicleType || '',
            ecuSoftwareVersionVersion: pureOriginalDetails.ecuSoftwareVersionVersion || '',
            engineEmissionStandard: pureOriginalDetails.engineEmissionStandard || '',
            engineEmission: pureOriginalDetails.engineEmission || '',
            resellerProjectDetails: pureOriginalDetails.resellerProjectDetails || '',
            resellerProjectType: pureOriginalDetails.resellerProjectType || '',
            resellerCredits: pureOriginalDetails.resellerCredits || '',
            projectComment: pureOriginalDetails.projectComment || '',
            versionComment: pureOriginalDetails.versionComment || '',
            creditType: pureOriginalDetails.creditType || '',
            projectType: pureOriginalDetails.projectType || '',
            userDef1: pureOriginalDetails.userDef1 || '',
            userDef2: pureOriginalDetails.userDef2 || '',
            userDef3: pureOriginalDetails.userDef3 || '',
            userDef4: pureOriginalDetails.userDef4 || '',
            userDef5: pureOriginalDetails.userDef5 || '',
            userDef6: pureOriginalDetails.userDef6 || '',
            userDef7: pureOriginalDetails.userDef7 || '',
            userDef11: pureOriginalDetails.userDef11 || '',
            userDef12: pureOriginalDetails.userDef12 || '',
            userDef13: pureOriginalDetails.userDef13 || '',
            userDef14: pureOriginalDetails.userDef14 || '',
            userDef15: pureOriginalDetails.userDef15 || '',
            userDef16: pureOriginalDetails.userDef16 || '',
            userDef17: pureOriginalDetails.userDef17 || '',
            userDef18: pureOriginalDetails.userDef18 || '',
            userDef19: pureOriginalDetails.userDef19 || '',
            userDef20: pureOriginalDetails.userDef20 || ''
        });
        this.previousFormValues = this.pureOriginalDetailsFormGroup.value;
    }


    initializeValueChangeSubscriptions() {
        Object.keys(this.pureOriginalDetailsFormGroup.controls).forEach(key => {
            const control = this.pureOriginalDetailsFormGroup.get(key);
            control.valueChanges.subscribe(() => {
                this.isInputChanged.set(true);
            });
        });
    }

    getPureOriginalsDetails() {
        this.pureOriginalService.getPureOriginalDetails(this.pureOriginalId()).then((response) => {
            this.editFormValue(response as PureOriginalsDetailsModel);
            if (response.pureOriginalUserRecords !== null) {
                this.pureOriginalUser.emit(response.pureOriginalUserRecords)
            }
        }, error => {
            if (error.status === 404) {
                this.pureOriginalService.errorMessage.set('File not found!');
            }
        })
    }

    openFileUploadModal() {
        this.isFileModalOpened.emit(true);
    }

    downloadVersionFile(id: number, winOls: string, tableName: string) {
        this.pureOriginalService.downloadFileFromDatabase(id, winOls, tableName)
    }

    isDownloadInProgress(id: number) {
        return this.pureOriginalService.isDownloadInProgress(id);
    }

    deletePureOriginalItem(id: number, tableName: string, pureOriginalDetailsOpened: boolean) {
        this.pureOriginalService.deletePureOriginalItem(id, tableName, pureOriginalDetailsOpened);
    }

    repeatPureOriginalsProcess(id: number) {
        this.pureOriginalService.repeatPureOriginalsProcess(id);
    }

    async editPureOriginalItem(id: string, editItem: PureOriginalsDetailsModel) {
        const response = await this.pureOriginalService.updatePureOriginalDetails(id, editItem);
        this.editFormValue(response);
    }


    async submitForm() {
        if (this.pureOriginalDetailsFormGroup.valid) {
            const formValues = this.pureOriginalDetailsFormGroup.value as UpdatePureOriginalModel;
            const id = this.pureOriginalDetailObject().id;
            try {
                const response = await this.pureOriginalService.editPureOriginalDetailsOnClick(+id, formValues);
                this.editFormValue(response as PureOriginalsDetailsModel);
            } catch (error) {
                this.cancelForm();
            }
            this.closeAllEdits();
        } else {
            return;
        }
    }

    toggleEdit(field: string | null, $event: any) {
        $event.stopPropagation();
        console.log(this.buttonIsDisabled());
        if (this.buttonIsDisabled()) {
            return;
        }
        if (field === null) {
            this.closeAllEdits();
        } else {
            this.isVisibleButton.set(true);
            this.editFlags[field] = !this.editFlags[field];

            if (this.editFlags[field]) {
                const updatedValue = this.pureOriginalDetailObject()?.[field];
                if (updatedValue !== undefined) {
                    this.pureOriginalDetailsFormGroup.get(field)?.setValue(updatedValue);
                }
            }
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    closeAllEdits() {
        this.isVisibleButton.set(false);
        Object.keys(this.editFlags).forEach(key => {
            this.editFlags[key] = false;
        });
    }

    cancelForm() {
        this.pureOriginalDetailsFormGroup.reset(this.previousFormValues);
        this.closeAllEdits()
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
        const element = this.elRef.nativeElement.querySelector('.matching-item-text');
        if (element && !element.contains(event.target as Node)) {
            this.closeAllEdits();
        }
    }

    uploadIni(event: any) {
        const fileInput = event.target as HTMLInputElement;
        let file = fileInput.files?.[0];

        if (!file) return;

        this.pureOriginalService.uploadIniFile(file, this.pureOriginalId())
            .then(response => {
                this.editFormValue(response);
                fileInput.value = '';
            })
            .catch(error => {
                console.error('Error uploading INI file:', error);
                fileInput.value = '';
            });

        file = null;
    }

    openHistoryModal(pureOriginalId: number) {
        this.pureOriginalService.getPureOriginalHistory(pureOriginalId).subscribe((response) => {
            if (response.status === 200) {
                if (response.body.length > 0) {
                    this.pureOriginalService.showPureOriginalHistory(pureOriginalId, response.body);
                } else {
                    this.tooltipMessage.set("No history available for this PureOriginal");

                    setTimeout(() => {
                        this.historyTooltip.show();
                    }, 0);
                }

            }
        });
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }
}

export class CustomPureOriginalsUploadFileValidations {
    message: string;
    hasError: boolean;
}


