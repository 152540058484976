<div class="number-of-processed-files-container">

    <div class="number-of-processed-files">
        <div class="server-stats-container">
            <h2 class="server-content">Stock Files processed in last 24h:
                @if (numberOfProcessedFiles() !== undefined && numberOfProcessedFiles() > 0) {
                    <span [@fadeInOut]>{{ numberOfProcessedFiles() }}</span>
                }
                @if (numberOfProcessedFiles() === undefined || numberOfProcessedFiles() <= 0) {
                    <span>-</span>
                }
            </h2>

        </div>
    </div>
</div>
