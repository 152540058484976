import { Component } from '@angular/core';

@Component({
  selector: 'app-requested-tunings-wrapper',
  templateUrl: './requested-tunings-wrapper.component.html',
  styleUrls: ['./requested-tunings-wrapper.component.css']
})
export class RequestedTuningsWrapperComponent {

}
