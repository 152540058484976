<table mat-table [dataSource]="allMasters()" *ngIf="allMasters().length > 0"
       multiTemplateDataRows matSort matSortDisableClear [matSortDirection]="sortDirectionValues().direction"
       [matSortActive]="sortDirectionValues().active"
       (matSortChange)="changeMasterTableSortDirection($event)">

    <mat-dialog-content>
        <ng-container *ngFor="let column of displayedColumns()" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">
                {{ element[column] | shorten: 25 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">
                <button (click)="toggleExpandedRowInTable(element)" class="arrow-button">
                    <i class="bi bi-chevron-up" *ngIf="expandedMaster === element"></i>
                    <i class="bi bi-chevron-down" *ngIf="expandedMaster !== element"></i>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedMaster">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns().length"
                [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">
                <table class="version-table">
                    <tr class="header">
                        <th *ngFor="let item of displayedVersionColumns()">{{ item }}</th>
                        <th class="actions">Actions</th>
                    </tr>
                    <tr *ngFor="let version of allVersions()">
                        <td *ngFor="let item of displayedVersionColumns()">
                            {{ version[item] }}
                        </td>
                        <td class="actions">
                            <button class="table-buttons"
                                    (click)="deleteTuningRecordFromTable(version, +version.id, 'VERSION')"><i
                                    class="bi bi-trash"></i></button>
                            <button class="table-buttons"
                                    (click)="downloadTuningRecordFromTable(+version.id, 'VERSION', version.masterWinOLSReference, version.versionName)"
                                    [disabled]="!version.id"><i
                                    class="bi bi-file-earmark-arrow-down"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </td>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element" [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">

                <button class="table-buttons" (click)="openMasterDetailsItem(+element.id)"><i
                        class="bi bi-car-front-fill"></i></button>
                <button class="table-buttons" (click)="deleteOriginalItems(+element.id, 'MASTER')"><i
                        class="bi bi-trash"></i></button>
                <button class="table-buttons"
                        (click)="downloadFileFromTable(+element.id, 'MASTER', element.winOLSReference)"
                        [disabled]="isDownloadInProgress(+element.id)"><i
                        class="bi bi-file-earmark-arrow-down"></i>
                </button>

                <button class="table-buttons" [disabled]="!element.id"
                        (click)="editItemInTable(element, element.winOLSReference, element.id)"><i
                        class="bi bi-pencil"></i>
                </button>

                <button *ngIf="authService.isAdmin()"
                        class="table-buttons"
                        matTooltip="Upload Ini file"
                        matTooltipPosition="below"
                        (click)="openMasterUploadModal(element.id)"><i class="bi bi-file-arrow-up"></i>
                </button>


            </td>
        </ng-container>
    </mat-dialog-actions>

    <tr mat-header-row *matHeaderRowDef="displayedTableHeaders()"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedTableHeaders()"></tr>
    <tr mat-row *matRowDef="let element; columns:['expandedMaster']"
        [class.collapsed-detail]="element != expandedMaster()"></tr>

</table>


@if (isModalOpened()) {
    <app-upload-file-dialog
            (isFileModalClosed)="fileModalClosed()"
            [title]="'Upload Master Ini File'"
    >
        <div class="upload-file-container">
            <app-upload-file (file)="uploadIniFile($event)" [error]="allMastersService.myInputs.get('ini').message"
                             [inputText]="allMastersService.inputText" customBackground="var(--primary-nav-bg)"
                             [progressWidth]="allMastersService.progressWidth()"
                             [resetSignal]="allMastersService.resetSignal()"></app-upload-file>
        </div>
    </app-upload-file-dialog>
}