import {
    Component,
    EventEmitter,
    inject, Input,
    input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {PureOriginalService} from "../../../service/pure-original.service";
import {PureOriginalUserModel} from "../../../models/PureOriginalUser-model";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-claim-file',
    templateUrl: './claim-file.component.html',
    styleUrls: ['./claim-file.component.css']
})
export class ClaimFileComponent implements OnInit, OnChanges {
    @Input() pureOriginalUser: PureOriginalUserModel;
    pureOriginalId = input.required<number>();
    @Output() checkIfButtonIsDisabled = new EventEmitter<boolean>();

    pureOriginalService = inject(PureOriginalService);
    userService = inject(UserService);

    ngOnInit() {
        this.userService.getLoggedUser();
        this.ifUserExists();
        this.checkIfButtonIsDisabled.emit(!this.ifUserExists());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (this.pureOriginalUser) {
                this.isClaimed();
                this.checkIfButtonIsDisabled.emit(!this.isClaimed());
            }
        }
    }

    ifUserExists() {
        return !!this.pureOriginalUser?.userId;
    }


    isClaimed() {
        return this.pureOriginalUser?.userId === this.userService?.loggedUser().id;
    }

    claimFile() {
        if (!this.isClaimed()) {
            this.pureOriginalService.claimPureOriginalFile(this.pureOriginalId()).subscribe(response => {
                this.pureOriginalUser = response.pureOriginalUserRecords;
                this.checkIfButtonIsDisabled.emit(!this.isClaimed());
            })
        } else {
            this.pureOriginalService.unclaimPureOriginalFile(this.pureOriginalId()).subscribe(response => {
                this.pureOriginalUser = response.pureOriginalUserRecords;
                this.checkIfButtonIsDisabled.emit(!this.isClaimed());
            })
        }

    }

}
