import {Component, ElementRef, inject, OnInit, signal} from '@angular/core';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CommonModule, Location} from "@angular/common";
import {UserStatisticsService} from "../../../../service/User-statistics-service";
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalUserStatisticsModel} from "../../../../models/PureOriginalUserStatistics-model";
import {SharedModule} from "../../../../shared/shared.module";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
    selector: 'app-pure-originals-stats',
    standalone: true,
    imports: [
        InfiniteScrollModule,
        CommonModule,
        SharedModule,
        MatTooltip
    ],
    templateUrl: './pure-originals-stats.component.html',
    styleUrl: './pure-originals-stats.component.css'
})
export class PureOriginalsStatsComponent implements OnInit {

    userId = signal<number>(-1);
    userStatisticsService = inject(UserStatisticsService);
    location = inject(Location);

    router = inject(Router);
    route = inject(ActivatedRoute);
    elRef = inject(ElementRef);

    expandedElement: PureOriginalUserStatisticsModel;
    selectedElement: PureOriginalUserStatisticsModel;
    expandedDetails: any[] = [];
    tooltipPosition : 'above'| 'below' | 'left' | 'right' = 'below';


    allPureOriginalStatsHeaders = signal<PureOriginalUserStatisticsModel>(new PureOriginalUserStatisticsModel())
    public displayedOriginalColumns = signal<(keyof PureOriginalUserStatisticsModel)[]>([]);
    public displayedTableHeaders = signal<string[]>([]);

    ngOnInit() {
        this.getParamsId();
        this.userStatisticsService.resetTableUserDetails();
        this.getTableHeaderNamesForPureOriginals();
    }

    getTableHeaderNamesForPureOriginals() {
        let objectKeys = Object.keys(this.allPureOriginalStatsHeaders())
            .filter(key => key !== 'isDeleted');
        for (let key of objectKeys) {
            this.displayedOriginalColumns().push(key as keyof PureOriginalUserStatisticsModel);
        }
        this.displayedTableHeaders.set([...this.displayedOriginalColumns()]);
    }


    getQueryParams() {
        this.route.queryParams.subscribe((queryParam) => {
            const startDate = queryParam["startDate"];
            const endDate = queryParam["endDate"];
            this.userStatisticsService.startDate = startDate;
            this.userStatisticsService.endDate = endDate;
        })
    }

    getParamsId() {
        this.route.parent?.params.subscribe((params) => {  // Access parent route params
            if (params && params["id"] !== undefined) {
                this.userStatisticsService.resetTableUserDetails();
                this.userId.set(Number(params["id"]));
                this.getQueryParams();
                this.userStatisticsService.getUserStatisticPureOriginalDetails(this.userId());
            } else {
                console.error("User ID is missing from parent route params!");
            }
        });
    }

    onScroll() {
        this.userStatisticsService.increasePageNumber()
        this.userStatisticsService.onScrollPureOriginalsStats(this.userId());

    }


    navigateToPureOriginalId(event: MouseEvent, id: number) {
        event.stopPropagation();
        const selection = window.getSelection();

        if (selection && !selection.isCollapsed && selection.toString().trim().length > 0) {
            return;
        }
        this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id}});
    }

    toggleExpandedRow(element: PureOriginalUserStatisticsModel, event: MouseEvent): void {
        event.stopPropagation();
        this.selectedElement = element;
        if (this.expandedElement === element) {
            this.expandedElement = null;
            this.expandedDetails = [];
        } else {
            this.expandedElement = element;
            this.fetchExpandedDetails(element.pureOriginalId);
        }
    }

    fetchExpandedDetails(pureOriginalId: string): void {
        console.log(pureOriginalId, "PURE ORIGINAL ID");
        console.log(this.userId());

        this.userStatisticsService.getPureOriginalTableStatisticDetails(this.userId(), pureOriginalId);

        console.log(this.userStatisticsService.pureOriginalsTableStatisticDetails())
    }

    openPureOriginalPageWithPureOriginalMatchingId(pureOriginalId: number, pureOriginalMatchingId: number) {
        this.router.navigate(['/app/pure-originals/pure-original-details'], {
            queryParams: {
                pureOriginalId,
                pureOriginalMatchingId
            }
        });
    }


    changeSortDirection(activeSortDirectionValue: string) {
        const tableElement: HTMLElement = this.elRef.nativeElement.querySelector('.table-container');
        this.userStatisticsService.pageNumber.set(0);
        const direction = this.userStatisticsService.sortDirectionValues().direction === 'asc' ? 'desc' : 'asc';
        console.log(direction, "Direction");
        this.userStatisticsService.sortDirectionValues.set({active: activeSortDirectionValue, direction: direction});
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        this.userStatisticsService.getUserStatisticPureOriginalDetails(this.userId());
    }

    onSearchInput($event: any) {
        this.userStatisticsService.searchString.set($event.valueOf());
        this.userStatisticsService.searchPureOriginalsByWinOlsReference(this.userId())
    }

    backToMainTable() {
        this.location.back()
    }

    protected readonly top = top;
}
