<div class="skeleton-wrapper">
    <div class="skeleton-restart-button-container">
        <div class="skeleton-restart-button-details"></div>
    </div>
  <div class="skeleton-container-img">
    <div class="skeleton-container-img-details"></div>
  </div>

  <div class="skeleton-container-buttons">
    <div class="skeleton-buttons skeleton"></div>
  </div>

  <div class="skeleton-container-info">
    <div class="skeleton-info"></div>
  </div>
</div>


