import {Component, EventEmitter, inject, Inject, Input, OnInit, Output} from '@angular/core';
import {SearchFilterModel} from "../../models/SearchFilter-model";
import {KeyValuePipe, NgForOf} from "@angular/common";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LocalStorageService} from "../../service/LocalStorageService";
import {PureOriginalResultsService} from "../../service/PureOriginalResults.service";

@Component({
    selector: 'app-most-requested-tuning-filters',
    standalone: true,
    imports: [
        KeyValuePipe,
        MatFormField,
        MatInput,
        MatOption,
        MatSelect,
        NgForOf,
        ReactiveFormsModule
    ],
    templateUrl: './most-requested-tuning-filters.component.html',
    styleUrl: './most-requested-tuning-filters.component.css'
})
export class MostRequestedTuningFiltersComponent implements OnInit {

    public selectedOptions: SearchFilterModel[] = [];

    @Input() displayedColumns: undefined | string[];
    @Output() mostRequestedFilterClosed = new EventEmitter<boolean>;
    @Output() selectedFiltersChange = new EventEmitter<SearchFilterModel[]>();
    @Output() filterCount = new EventEmitter<number>();

    pureOriginalResultsService = inject(PureOriginalResultsService);
    private localStorageService = inject(LocalStorageService)


    searchFilterForm = new FormGroup({})

    constructor(@Inject('LOCAL_MISSING_KEY') private missingFilter: string) {

    }


    ngOnInit(): void {
        this.searchFilterForm = new FormGroup({}); // Initialize the form group
        const savedFilters = this.localStorageService.getFilters(this.missingFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.searchFilterForm = this.localStorageService.createFormGroupFromFilters(savedFilters, this.displayedColumns || []);
            this.selectedFiltersChange.emit(savedFilters);
            this.filterCount.emit(savedFilters.length);
        }
    }


    addFormControl() {
        const control = new FormGroup({
            'option': new FormControl(this.displayedColumns![0]),
            'value': new FormControl('', Validators.required)
        })
        this.searchFilterForm.addControl(`control-${Object.keys(this.searchFilterForm.controls).length + 1}`, control);

    }


    onSubmit() {

        for (const group in this.searchFilterForm.controls) {
            const value = this.searchFilterForm.get(group)?.value.value as string;
            const trimmedValue = value.trim();
            this.selectedOptions.push({
                option: this.searchFilterForm.get(group)?.value.option,
                value: trimmedValue
            });
        }
        console.log('Selected Filters:', this.selectedOptions);
        this.localStorageService.saveFilters(this.missingFilter, this.selectedOptions);
        this.selectedFiltersChange.emit(this.selectedOptions);
        this.mostRequestedFilterClosed.emit(false);
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
    }

    deleteSearchBar(key: string) {
        this.searchFilterForm.removeControl(key);
        this.updateFilterCount();

    }
    updateFilterCount() {
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
    }

    clearFilters() {
        this.localStorageService.clearFilters(this.missingFilter);

        const controlKeys = Object.keys(this.searchFilterForm.controls);
        controlKeys.forEach(key => {
            this.searchFilterForm.removeControl(key);
        });
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
        this.pureOriginalResultsService.getMostRequestedMissingTunings();

    }
    closeModal() {
        this.mostRequestedFilterClosed.emit(false);
    }

    hasFormControls(): boolean {
        return Object.keys(this.searchFilterForm.controls).length > 0;
    }
}
