<div class="main">
    <div class="main-container-wrapper">
        <div class="button-container">
            <button (click)="toggleInputRow()" class="add-row-btn"><i class="bi bi-plus-lg"></i>Row</button>
        </div>
        @defer (when !ecuNamesService.ecuNamesIsLoading()) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="ecuNamesService.onScrollEcu()">
                <table>
                    <tr class="header">
                        <th>ID</th>
                        <th><input type="text"
                                   class="header-input"
                                   [value]="ecuNamesService.searchQuery()"
                                   (input)="searchForCorrectEcuNames($event)"
                                   placeholder="Search Correct Ecu Names"/>
                        </th>
                        <th><input type="text"
                                   class="header-input"
                                   (input)="searchForConnectedEcuNames($event)"
                                   placeholder="Search Connected Ecu Names"/>
                        </th>
                        <th><input type="text"
                                   class="header-input"
                                   (input)="searchForAcceptedEcuNames($event)"
                                   placeholder="Search Accepted Ecu Names"/>
                        </th>
                        <th>Correct Ecu Producer</th>
                        <th>Actions</th>
                    </tr>

                    <tr *ngIf="ecuNamesService.showInputRow()">
                        <td>-</td>
                        <td>
                            <mat-form-field class="background-input">
                                <mat-label>Correct Ecu Name</mat-label>
                                <input type="text" matInput
                                       [(ngModel)]="ecuNamesService.newRow().correctEcuBuildNames"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="background-input">
                                <mat-label>Connected Ecu Name</mat-label>
                                <input type="text" matInput
                                       [(ngModel)]="ecuNamesService.newRow().connectedEcuBuildNames"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="background-input">
                                <mat-label>Accepted Ecu Name</mat-label>
                                <textarea type="text" matInput
                                          [(ngModel)]="ecuNamesService.newRow().acceptedEcuBuildNames"
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="1"
                                          cdkAutosizeMaxRows="17"
                                ></textarea>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <mat-label>Select producer</mat-label>
                                <mat-select [(value)]="ecuNamesService.newRow().correctEcuProducer"
                                            [(ngModel)]="ecuNamesService.newRow().correctEcuProducer">
                                    <mat-option
                                            *ngFor="let correctEcuProducer of ecuProducersService.correctEcuProducersList()"
                                            [value]="correctEcuProducer">
                                        {{ correctEcuProducer }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            @if (!ecuNamesService.isWaitingResponse()) {
                                <button (click)="addNewRow()" class="row-btn"><i class="bi bi-check-lg"></i></button>
                                <button (click)="deleteRow()" class="row-btn"><i class="bi bi-x-lg"></i></button>
                            } @else {
                                <div class="spinner-wrapper">
                                    <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                </div>
                            }

                        </td>
                    </tr>

                    @for (element of ecuNamesService.ecuNamesFiltered(); track $index) {
                        <tr class="body-columns">

                            <td>{{ element.id }}</td>
                            <td>
                                <ng-container
                                        *ngIf="ecuNamesService.ecuToEditIndex() !== $index; else editCorrectNames">
                            <span class="tag" *ngFor="let correctName of element.correctEcuBuildNames.split(',')"
                                  (dblclick)="editEcu($index)"
                                  (click)="getCorrectEcuBuildName($event)">{{ correctName.trim() }}</span>
                                </ng-container>
                                <ng-template #editCorrectNames>
                                    <mat-form-field class="background-input">
                                        <input matInput type="text" [(ngModel)]="element.correctEcuBuildNames"
                                               (keyup.enter)="saveEditedItem(element, element.id)"/>
                                    </mat-form-field>
                                </ng-template>
                            </td>
                            <td>
                                <ng-container
                                        *ngIf="ecuNamesService.ecuToEditIndex() !== $index; else editConnectedNames">
                                    <ng-container *ngIf="element.connectedEcuBuildNames !== null ; else nullEcuValues">
                     <span class="tag" (dblclick)="editEcu($index)"
                           *ngFor="let connectedName of element.connectedEcuBuildNames.split(',')">{{ connectedName.trim() }}</span>
                                    </ng-container>

                                    <ng-template #nullEcuValues>
                                        <span class="tag" (dblclick)="editEcu($index)">-</span>
                                    </ng-template>

                                </ng-container>
                                <ng-template #editConnectedNames>
                                    <mat-form-field class="background-input">
                                        <input matInput type="text" [(ngModel)]="element.connectedEcuBuildNames"
                                               (keyup.enter)="saveEditedItem(element, element.id)"/>
                                    </mat-form-field>
                                </ng-template>
                            </td>

                            <td>
                                <ng-container
                                        *ngIf="ecuNamesService.ecuToEditIndex() !== $index; else editAcceptedNames">
                        <span class="tag" (dblclick)="editEcu($index)"
                              *ngFor="let acceptedName of element.acceptedEcuBuildNames.split(',')">{{ acceptedName.trim() }}</span>
                                </ng-container>
                                <ng-template #editAcceptedNames>
                                    <mat-form-field class="background-input">
                                <textarea matInput cdkTextareaAutosize
                                          cdkAutosizeMinRows="1"
                                          cdkAutosizeMaxRows="17"
                                          [(ngModel)]="element.acceptedEcuBuildNames"
                                          (keyup.enter)="saveEditedItem(element, element.id)"
                                ></textarea>
                                    </mat-form-field>
                                </ng-template>
                            </td>
                            <td class="option-row">
                                <ng-container
                                        *ngIf="ecuNamesService.ecuToEditIndex() !== $index; else editCorrectProducerInput">
                            <span class="tag" (dblclick)="editEcu($index)">
                                {{ element.correctEcuProducer ? element.correctEcuProducer : "-" }}
                            </span>
                                </ng-container>
                                <ng-template #editCorrectProducerInput>
                                    <mat-form-field>
                                        <mat-label>Select producer</mat-label>
                                        <mat-select [(value)]="element.correctEcuProducer"
                                                    (keyup.enter)="saveEditedItem(element,element.id)">
                                            <mat-option
                                                    *ngFor="let correctEcuProducer of ecuProducersService.correctEcuProducersList()"
                                                    [value]="correctEcuProducer">
                                                {{ correctEcuProducer }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-template>

                            </td>
                            <td class="actions">
                                <ng-container *ngIf="ecuNamesService.ecuToEditIndex() !== $index; else editModeButtons">
                                    <button class="table-buttons" (click)="deleteItemFromDatabase(element)"><i
                                            class="bi bi-trash"></i></button>
                                    <button class="table-buttons" [disabled]="!element.id" (click)="editEcu($index)"><i
                                            class="bi bi-pencil"></i>
                                    </button>
                                </ng-container>
                                <ng-template #editModeButtons>
                                    @if (!ecuNamesService.isWaitingResponse()) {
                                        <button class="table-buttons" (click)="saveEditedItem(element,element.id)"><i
                                                class="bi bi-check-lg"></i></button>
                                        <button class="table-buttons" (click)="ecuNamesService.closeEditedItem()"><i
                                                class="bi bi-x-lg"></i>
                                        </button>
                                    } @else {
                                        <div class="spinner-wrapper">
                                            <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                        </div>
                                    }
                                </ng-template>
                            </td>
                        </tr>
                    } @empty {
                        <ng-container>
                            <tr>
                                <td>
                                    -
                                </td>
                                <td style="min-width: 100%">
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    }

                </table>

            </div>
        } @placeholder (minimum 150ms) {
            <div class="skeleton-wrapper">
                <app-skeleton-table></app-skeleton-table>
            </div>
        }
    </div>
</div>

