<div class="profile-photo" (click)="openUserProfileSettings()">
  <div class="user-name-picture">
    <p>{{userService.getInitials()}}</p>
  </div>
</div>
<div class="user-info" [ngClass]="isUserSettingsOpened ? 'active' : ''">
  <div class="user-modal-overlay" (click)="closeUserSettings()">
  </div>
  <ul role="list" class="user-settings-nav">
    <li (click)="openUserSettingsPage()"><a><i class="bi bi-gear"></i></a></li>
    <li (click)="logout()"><a><i class="bi bi-box-arrow-left"></i></a></li>
  </ul>
</div>
