<div class="statistic-card-wrapper">
  <div class="search-statistics-container">
    <h2>Statistic Cards</h2>

    <div class="date-picker-container">
      <app-data-picker-modal (emitStatisticsValue)="statisticService.getStatisticsByDatePicker($event)"
                             (emitWinOlsValues)="winOlsTransaction.getWinOlsByDatePicker($event)"
                             [selectedTimeFrame]="statisticService.selectedTimeFrame()" [currentPartner]="partnersService.currentPartner" ></app-data-picker-modal>


      <button class="date-select-btn" (click)="statisticService.openDateRangePickerModal()"><i class="bi bi-calendar-week"></i></button>
      <app-data-range-picker-modal [isDateRangePickerOpened]="statisticService.isDateRangePickerOpened()"
                                   (dataRangePickerEvent)="statisticService.closeDateRangePickerModal($event)"
                                   (emitFormValueEvent)="statisticService.submitFormDate($event)"
                                   [currentPartner]="partnersService.currentPartner"></app-data-range-picker-modal>
    </div>
  </div>
  <div class="even-columns">

    <div class="statistic-card" data-total="true" (click)="statisticService.openFileServiceByStatus('')">
      <div class="cf-container">
        <div class="text-container">
          <span class="s-num">{{statisticService.total()}}</span>
          <span class="title">Total Number of Files</span>
        </div>
        <div class="icon-container">
          <div class="icon-button">
            <i class="bi bi-app-indicator"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="statistic-card" (click)="statisticService.openFileServiceByStatus('Successful')">
      <div class="cf-container">
        <div class="text-container">
          <span class="s-num">{{statisticService.successful()}}</span>
          <span class="title">Successfully Tuned Files</span>
        </div>
        <div class="icon-container">
          <div class="icon-button">
            <i class="bi bi-check2-square"></i>
          </div>
        </div>
      </div>
      <div class="cs-container">
        <ngb-progressbar [animated]="true" [max]="statisticService.total()!" [type]="'success'"
                         [value]="statisticService.successful()!"></ngb-progressbar>
      </div>
    </div>

    <div class="statistic-card" (click)="statisticService.openFileServiceByStatus('Partially tuned')">
      <div class="content">
        <div class="cf-container">
          <div class="text-container">
            <span class="s-num">{{statisticService.partlyTuned()}}</span>
            <span class="title">Partially Tuned Files</span>
          </div>
          <div class="icon-container">
            <div class="icon-button">
              <i class="bi bi-body-text"></i>
            </div>
          </div>
        </div>
        <div class="cs-container">
          <ngb-progressbar [animated]="true" [max]="statisticService.total()!" [type]="'warning'"
                           [value]="statisticService.partlyTuned()!"></ngb-progressbar>
        </div>
      </div>
    </div>

    <div class="statistic-card" (click)="statisticService.openFileServiceByStatus('Failed')">
      <div class="cf-container">
        <div class="text-container">
          <span class="s-num">{{statisticService.failed()}}</span>
          <span class="title">Failed Files</span>
        </div>
        <div class="icon-container">
          <div class="icon-button">
            <i class="bi bi-x-square"></i>
          </div>
        </div>
      </div>
      <div class="cs-container">
        <ngb-progressbar [animated]="true" [max]="statisticService.total()!" [type]="'danger'"
                         [value]="statisticService.failed()!"></ngb-progressbar>
      </div>
    </div>


    <div class="statistic-container-mobile">
      <div class="content">
        <div class="cf-container-mobile">
          <div class="first-row">
            <div class="content-mobile" (click)="statisticService.openFileServiceByStatus('')">
              <div class="icon-button">
                <i class="bi bi-app-indicator"></i>
              </div>
              <div class="t-text">{{statisticService.total()}}</div>
            </div>
            <div class="content-mobile" (click)="statisticService.openFileServiceByStatus('Successful')">
              <div class="icon-button" [ngClass]="statisticService.successful() ? 'successful' : ''">
                <i class="bi bi-check2-square"></i>
              </div>
              <div class="t-text">{{statisticService.successful()}}</div>
            </div>
          </div>
          <div class="second-row">
            <div class="content-mobile" (click)="statisticService.openFileServiceByStatus('Partially tuned')">
              <div class="icon-button" [ngClass]="statisticService.partlyTuned() ? 'partially-tuned' : ''">
                <i class="bi bi-body-text"></i>
              </div>
              <div class="t-text">{{statisticService.partlyTuned()}}</div>
            </div>
            <div class="content-mobile" (click)="statisticService.openFileServiceByStatus('Failed')">
              <div class="icon-button" [ngClass]="statisticService.failed() ? 'failed' : ''">
                <i class="bi bi-x-square"></i>
              </div>
              <div class="t-text">{{statisticService.failed()}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


