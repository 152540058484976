import {Component, EventEmitter, Inject, OnInit, Output, Renderer2} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UpdateMasterModel} from "../../models/UpdateMaster-model";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";
import {Observable} from "rxjs";
import {AddDialogComponent} from "../add-dialog/add-dialog.component";
import {CorrectEcuProducersModel, EcuBuildNameModel} from "../../models/EcuBuildName-model";
import {Clipboard} from '@angular/cdk/clipboard'
import {AddEcuProducerDialogComponent} from "../add-ecu-producer-dialog/add-ecu-producer-dialog.component";


@Component({
    selector: 'app-edit-dialog',
    templateUrl: './edit-master-dialog.component.html',
    styleUrls: ['./edit-master-dialog.component.css']
})
export class EditMasterDialogComponent implements OnInit {


    @Output() masterEcuBuildChangeEmitter = new EventEmitter<string>();
    public contentToShow: UpdateMasterModel;
    public ecuBuild: EcuBuildNameModel;
    public ecuProducer: CorrectEcuProducersModel;

    public searchQuery: string = '';
    public prevEcuBuildState: string;

    constructor(private clipboard: Clipboard, private dialog: MatDialog, public dialogRef: MatDialogRef<EditMasterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
        id: number,
        text: string,
        content: UpdateMasterModel
    }, private allMasterService: AllMastersDatabaseService, private renderer: Renderer2) {
        this.getEcuName();
        this.getEcuProducer();

    }

    updateMasterItemForm = new FormGroup({
        // id: new FormControl(0, Validators.required),
        ecuUse: new FormControl(''),
        ecuProducer: new FormControl('', Validators.required),
        ecuBuild: new FormControl('', [Validators.required], [this.ecuBuildValidator.bind(this)]),
        ecuProdNr: new FormControl(''),
        ecuSoftwareVersion: new FormControl(''),
        ecuSoftwareVersionVersion: new FormControl(''),
        engineName: new FormControl(''),
        engineDisplacement: new FormControl(''),
        ecuStgNr: new FormControl(''),
        ecuProcessor: new FormControl(''),
        engineType: new FormControl('', Validators.required),
        engineMaxTorque: new FormControl(''),
        outputPs: new FormControl(''),
        outputKw: new FormControl(''),
        engineTransmission: new FormControl(''),
        additionalInfo: new FormControl(''),
        vehicleModel: new FormControl(''),
        vehicleModelYear: new FormControl(''),
        vehicleProducer: new FormControl(''),
        vehicleSeries: new FormControl(''),
        uploadedBy: new FormControl(''),
        clientName: new FormControl(''),
        clientNumber: new FormControl(''),
        clientLicencePlace: new FormControl(''),
        vehicleType: new FormControl(''),
        vin: new FormControl(''),
        vehicleBuild: new FormControl(''),
        vehicleCharacteristic: new FormControl(''),
        engineProducer: new FormControl(''),
        engineEmission: new FormControl(''),
        engineGear: new FormControl(''),
        projectCreatedBy: new FormControl(''),
        projectChangedBy: new FormControl(''),
        readingHardware: new FormControl(''),
        resellerProjectDetails: new FormControl(''),
        engineEmissionStandard: new FormControl(''),
        resellerProjectType: new FormControl(''),
        projectComment: new FormControl(''),
        versionComment: new FormControl(''),
        softwareNumber: new FormControl(''),
        projectType: new FormControl(''),
        userDef1: new FormControl(''),
        userDef2: new FormControl(''),
        userDef3: new FormControl(''),
        userDef4: new FormControl(''),
        userDef5: new FormControl(''),
        userDef6: new FormControl(''),
        userDef7: new FormControl(''),
        userDef11: new FormControl(''),
        userDef12: new FormControl(''),
        userDef13: new FormControl(''),
        userDef14: new FormControl(''),
        userDef15: new FormControl(''),
        userDef16: new FormControl(''),
        userDef17: new FormControl(''),
        userDef18: new FormControl(''),
        userDef19: new FormControl(''),
        userDef20: new FormControl(''),
    })

    ngOnInit() {
        this.contentToShow = this.data['content'];
        this.editFormValue();
        this.updateMasterItemForm.get('ecuBuild').markAsTouched();
        this.updateMasterItemForm.get('ecuBuild').setErrors({'valid': true});
        this.updateMasterItemForm.get('ecuProducer').markAsTouched();
        this.updateMasterItemForm.get('ecuBuild').markAsTouched();
        this.updateMasterItemForm.get('engineType').markAsTouched();
        this.updateMasterItemForm.get('engineType').setErrors({'valid': true});
    }


    getWinOlsToClipboard() {
        this.clipboard.copy(this.data['text']);
    }

    getEcuName() {
        this.updateMasterItemForm.get('ecuBuild').valueChanges.subscribe(response => {
            this.searchQuery = response
        });
    }

    getEcuProducer() {
        this.updateMasterItemForm.get('ecuProducer').valueChanges.subscribe(response => {
            this.searchQuery = response
        });
    }

    editFormValue() {
        this.prevEcuBuildState = this.contentToShow.ecuBuild;
        this.updateMasterItemForm.setValue({
            // id: this.contentToShow.id,
            ecuUse: this.contentToShow.ecuUse,
            ecuProducer: this.contentToShow.ecuProducer,
            ecuBuild: this.contentToShow.ecuBuild,
            ecuProdNr: this.contentToShow.ecuProdNr,
            ecuSoftwareVersion: this.contentToShow.ecuSoftwareVersion,
            ecuSoftwareVersionVersion: this.contentToShow.ecuSoftwareVersionVersion,
            engineName: this.contentToShow.engineName,
            engineDisplacement: this.contentToShow.engineDisplacement,
            ecuStgNr: this.contentToShow.ecuStgNr,
            ecuProcessor: this.contentToShow.ecuProcessor,
            engineType: this.contentToShow.engineType,
            engineMaxTorque: this.contentToShow.engineMaxTorque,
            outputPs: this.contentToShow.outputPs,
            outputKw: this.contentToShow.outputKw,
            engineTransmission: this.contentToShow.engineTransmission,
            additionalInfo: this.contentToShow.additionalInfo,
            vehicleModel: this.contentToShow.vehicleModel,
            vehicleModelYear: this.contentToShow.vehicleModelYear,
            vehicleProducer: this.contentToShow.vehicleProducer,
            vehicleSeries: this.contentToShow.vehicleSeries,
            uploadedBy: this.contentToShow.uploadedBy,
            clientName: this.contentToShow.clientName,
            clientNumber: this.contentToShow.clientNumber,
            clientLicencePlace: this.contentToShow.clientLicencePlace,
            vehicleType: this.contentToShow.vehicleType,
            vin: this.contentToShow.vin,
            vehicleBuild: this.contentToShow.vehicleBuild,
            vehicleCharacteristic: this.contentToShow.vehicleCharacteristic,
            engineProducer: this.contentToShow.engineProducer,
            engineEmission: this.contentToShow.engineEmission,
            engineGear: this.contentToShow.engineGear,
            projectCreatedBy: this.contentToShow.projectCreatedBy,
            projectChangedBy: this.contentToShow.projectChangedBy,
            readingHardware: this.contentToShow.readingHardware,
            resellerProjectDetails: this.contentToShow.resellerProjectDetails,
            engineEmissionStandard: this.contentToShow.engineEmissionStandard,
            resellerProjectType: this.contentToShow.resellerProjectType,
            projectComment: this.contentToShow.projectComment,
            versionComment: this.contentToShow.versionComment,
            softwareNumber: this.contentToShow.softwareNumber,
            projectType: this.contentToShow.projectType,
            userDef1: this.contentToShow.userDef1,
            userDef2: this.contentToShow.userDef2,
            userDef3: this.contentToShow.userDef3,
            userDef4: this.contentToShow.userDef4,
            userDef5: this.contentToShow.userDef5,
            userDef6: this.contentToShow.userDef6,
            userDef7: this.contentToShow.userDef7,
            userDef11: this.contentToShow.userDef11,
            userDef12: this.contentToShow.userDef12,
            userDef13: this.contentToShow.userDef13,
            userDef14: this.contentToShow.userDef14,
            userDef15: this.contentToShow.userDef15,
            userDef16: this.contentToShow.userDef16,
            userDef17: this.contentToShow.userDef17,
            userDef18: this.contentToShow.userDef18,
            userDef19: this.contentToShow.userDef19,
            userDef20: this.contentToShow.userDef20,
        })
    }

    ecuBuildValidator(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.allMasterService.checkIfEcuIsValid(control.value);
    }

    onEcuBuildChange() {
        const ecuBuildValue = this.updateMasterItemForm.get('ecuBuild')?.value;
        this.masterEcuBuildChangeEmitter.emit(ecuBuildValue);
    }

    confirmedOption() {
        this.onEcuBuildChange();
        this.dialogRef.close({
            closed: true,
            content: this.updateMasterItemForm.value,
            prevEcuBuildState: this.prevEcuBuildState
        });
    }

    canceledOption() {
        this.dialogRef.close({closed: false});
    }

    focusOnDialogElement() {
        const firstDialogElement = this.renderer.selectRootElement('#firstDialogElement');
        if (firstDialogElement) {
            firstDialogElement.focus();
        }
    }

    focusOnEcuProducerElement() {
        const firstDialogElement = this.renderer.selectRootElement('# secondDialogElement');
        if (firstDialogElement) {
            firstDialogElement.focus();
        }
    }

    addNewEcu() {
        this.dialog.open(AddDialogComponent, {
            width: "1000px",
            data: {ecuBuildValue: this.updateMasterItemForm.get('ecuBuild').value}
        }).afterClosed().subscribe((value: {
            closed: boolean,
            ecu: EcuBuildNameModel
        }) => {
            if (value.closed) {
                this.updateMasterItemForm.patchValue({
                    ecuBuild: value.ecu.correctEcuBuildNames
                })
                this.ecuBuild = value.ecu;
                this.focusOnDialogElement();
            }
        });
    }

    getCorrectEcuName($event: any) {
        this.updateMasterItemForm.patchValue({
            ecuBuild: $event.valueOf()
        });
    }

    addNewEcuProducer() {
        this.dialog.open(AddEcuProducerDialogComponent, {
            width: "800px",
            data: {ecuProducerValue: this.updateMasterItemForm.get('ecuProducer').value}
        }).afterClosed().subscribe((value: {
            closed: boolean,
            ecuProducer: CorrectEcuProducersModel
        }) => {
            if (value.closed) {
                this.updateMasterItemForm.patchValue({
                    ecuProducer: value.ecuProducer.acceptedEcuProducer
                })
                this.ecuProducer = value.ecuProducer;
                this.focusOnEcuProducerElement()
            }
        });
    }
}
