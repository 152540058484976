import {Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {UserService} from "../../service/user.service";
import {NotificationService} from "../../service/Notification.service";

@Component({
    selector: 'app-two-factor-activate-template',
    templateUrl: './two-factor-activate-template.component.html',
    styleUrl: './two-factor-activate-template.component.css'
})
export class TwoFactorActivateTemplateComponent implements OnChanges {

    userService = inject(UserService);
    notificationService = inject(NotificationService);

    @Input() isOpened: boolean;
    @Output() closeModal = new EventEmitter<boolean>();

    // twoFactorCode!: string;

    errorMessage: null | string = null;

    twoFactorForm = new FormGroup({
        twoFactorCode: new FormControl("", [Validators.required, Validators.minLength(6)])
    })


    @ViewChild(NgForm)
    formDir!: NgForm;

    constructor() {
        this.twoFactorForm.get('twoFactorCode')?.valueChanges.subscribe(value => {
            console.log('Two-factor code changed:', value);
            this.errorMessage = "";
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["isOpened"].currentValue !== changes["isOpened"].previousValue) {
            this.isOpened = changes['isOpened'].currentValue;
        }
    }

    onSubmitForm() {
        if (this.twoFactorForm.invalid) return;

        this.userService.verifyMfa(this.twoFactorForm.value.twoFactorCode.trim(), true).subscribe((response) => {
            if (response.status === 200) {
                this.userService.userInfo().mfaEnabled = true;
                this.notificationService.showSuccessMessage(response.body.message, "Successfully Activated MFA")
                this.twoFactorForm.reset();
                this.closeModal.emit(false);
            }
        }, (error => {
            this.errorMessage = error.error.message;
            this.notificationService.showErrorMessage(error.error.message, "Error occurred");
        }));

    }

    closeTemplate() {
        // $event.stopPropagation();
        this.closeModal.emit(false);
        this.userService.verifyMfa("", false).subscribe(() => {
        }, error => {
            this.notificationService.showErrorMessage(error.error.message, "Error occurred")
        });
        this.userService.secretImageUrl.set(null)
    }
}


