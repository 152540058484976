import {Component, ElementRef, inject, Inject, OnInit, signal} from '@angular/core';
import {SearchFilterModel} from '../../models/SearchFilter-model';
import {AllMastersDatabaseService} from '../../service/all-masters-database.service';
import {TuningsService} from '../../service/Tunings.service';
import {LocalStorageService} from '../../service/LocalStorageService';

@Component({
    selector: 'app-master-records',
    templateUrl: './master-records.component.html',
    styleUrls: ['./master-records.component.css']
})
export class MasterRecordsComponent implements OnInit {

    public filterCount = signal<number>(0);

    allMasterService = inject(AllMastersDatabaseService);
    tuningService = inject(TuningsService);
    elRef = inject(ElementRef);
    localStorageService = inject(LocalStorageService);

    constructor(@Inject('LOCAL_MASTER_KEY') private masterFilter: string) {
    }

    ngOnInit() {
        this.resetTableValue();
        this.getAllTableHeaderNames();
        this.getAllVersionHeaderNames();

        const savedFilters = this.localStorageService.getFilters(this.masterFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.allMasterService.selectedFilters.set(savedFilters);
            this.getFilteredRecords(savedFilters);
            this.updateFilterCount(savedFilters.length)
        } else {
            this.getAllMasterRecords();
        }
    }

    getAllMasterRecords() {
        this.allMasterService.getAllMasterRecords();
    }

    getAllTableHeaderNames() {
        this.allMasterService.getAllTableHeaderNames();
    }

    getAllVersionHeaderNames() {
        this.allMasterService.getAllVersionHeaderNames();
    }

    resetTableValue() {
        this.allMasterService.restartTableValues();
    }

    getFilteredRecords(filters: SearchFilterModel[]): void {
        this.allMasterService.isLoading.set(true);
        this.allMasterService.getAllMastersAdvancedFiltered(filters).subscribe(response => {
            this.allMasterService.allMasters.set(response['content']);
            this.allMasterService.isLoading.set(false);
        });
    }

    getVersionRecord(id: number) {
        this.tuningService.getVersionRecords(id);
    }

    toggleExpandedRow($event: any) {
        let expandedDetail = $event.valueOf();
        this.getVersionRecord(+expandedDetail.id);
        this.allMasterService.toggleExpandedMasterRow(expandedDetail);
    }

    openAdvancedSearchSettings() {
        this.allMasterService.openAdvanceSettings.set(!this.allMasterService.openAdvanceSettings());
    }

    selectFilters($event: any) {
        this.allMasterService.selectedFilters.set($event.valueOf());
        this.allMasterService.allMasters.set([]);
        this.getFilteredRecords(this.allMasterService.selectedFilters());
    }

    onAdvancedSettingClosed($event: boolean) {
        this.allMasterService.openAdvanceSettings.set($event.valueOf());
    }

    deleteOriginalItems($event: any) {
        let id = $event.valueOf().id;
        let tableName = $event.valueOf().tableName;
        this.allMasterService.deleteMasterFromDatabase(id, tableName);
    }

    downloadFileFromTable($event: any) {
        let id = $event.valueOf().id;
        let winOls = $event.valueOf().winOls;
        let tableName = $event.valueOf().tableName;

        this.allMasterService.downloadMastersFromTable(id, winOls, tableName);
    }

    onRefreshTable() {
        this.allMasterService.pageNumber.set(0);
        this.allMasterService.searchString.set('');

        const savedFiltersTable = this.localStorageService.getFilters(this.masterFilter);
        if (savedFiltersTable && savedFiltersTable.length > 0) {
            this.allMasterService.selectedFilters.set(savedFiltersTable);
            this.getFilteredRecords(savedFiltersTable);
        } else {
            this.allMasterService.allMasters.set([]);
            this.getAllMasterRecords();
        }
    }

    onSearchInput($event: string) {
        this.allMasterService.searchString.set($event.toLowerCase());
        if (this.allMasterService.searchString().length >= 3) {
            this.allMasterService.pageNumber.set(0);
            this.allMasterService.pageSize.set(50);
            this.allMasterService.getAllMastersByWinOls(this.allMasterService.searchString());
        }
        if (this.allMasterService.searchString().length === 0) {
            this.onRefreshTable();
        }
    }

    deleteTuningRecordFromTable($event: any) {
        let id = $event.valueOf().id;
        let tableName = $event.valueOf().tableName;

        this.tuningService.deleteTuningRecordFromTable(id, tableName);
    }

    downloadTuningRecordFromTable($event: any) {
        let id = $event.valueOf().id;
        let winOls = $event.valueOf().winOls;
        let tableName = $event.valueOf().tableName;

        this.allMasterService.downloadMastersFromTable(id, winOls, tableName);
    }

    editItemDetails($event: any) {
        let element = $event.valueOf().element;
        let winOls = $event.valueOf().winOls;
        let id = $event.valueOf().id;

        this.allMasterService.updateMasterElement(element, winOls, id);
    }

    changeSortDirection($event: any) {
        const tableElement: HTMLElement = this.elRef.nativeElement.querySelector('.table-container');
        this.allMasterService.pageNumber.set(0);
        this.allMasterService.sortDirectionValues.set($event.valueOf());
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        this.allMasterService.getAllMasterRecords();
    }

    isTableEmpty() {
        return this.allMasterService.allMasters().length === 0;
    }

    updateFilterCount(count: number) {
        this.filterCount.set(count);
    }
}
