import {PureOriginalFilterHeadersModel} from "../../models/PureOriginalFilterHeaders-model";

export const pureOriginalFilterFields: (keyof PureOriginalFilterHeadersModel)[] = [
    'dateUploaded',
    'winOlsReference',
    'winOlsDbName',
    'sha1',
    'sha256',
    'md5',
    'vehicleProducer',
    'vehicleSeries',
    'vehicleModel',
    'vehicleModelYear',
    'vehicleBuild',
    'vehicleCharacteristic',
    'ecuSoftwareVersion',
    'ecuUse',
    'ecuBuild',
    'ecuProducer',
    'ecuProdNr',
    'ecuStgNr',
    'engineDisplacement',
    'engineProducer',
    'engineType',
    'outputPs',
    'outputKw',
    'dateExported',
    'readingHardware',
    'dateUpdated',
    'tuningName',
    'hitRate',
];
