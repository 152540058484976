<div class="main">
    <div class="user-settings-wrapper">
        <div class="user-settings-container">
            <h2 class="main-title">My Profile</h2>
            <div class="user-info">
                <div class="img-container">
                    <div class="user-name-picture"><p>{{ userService.getSplitNames() }}</p></div>
                </div>
                <div class="user-name-container">
                    <h2 class="title">{{ userService.userInfo()?.firstName + ' ' + userService.userInfo()?.lastName }}</h2>
                    @if (userService.userInfo().authorities) {
                        <h2 class="subtitle">{{ userService.userInfo().authorities[0].authority }}</h2>
                    } @else {
                        <h2 class="subtitle">-</h2>
                    }
                </div>
            </div>
            <div class="personal-information">
                <div class="title-container">
                    <h2 class="custom-title">Personal Information</h2>
                    <button class="edit-btn" (click)="editUserPersonalInfo(userService.userInfo())"><i
                            class="bi bi-pencil"></i></button>
                </div>
                <div class="personal-info-content">

                    <div class="content">
                        <p class="address-title">First Name</p>
                        <h2 class="title">{{ userService.userInfo()?.firstName }}</h2>
                    </div>

                    <div class="content">
                        <p class="address-title">Last Name</p>
                        <h2 class="title">{{ userService.userInfo()?.lastName }}</h2>
                    </div>

                    <div class="content">
                        <p class="address-title">Email Address</p>
                        <h2 class="title">{{ userService.userInfo()?.email }}</h2>
                    </div>

                    <div class="content">
                        <p class="address-title">Phone</p>
                        <h2 class="title">{{ userService.userInfo()?.phoneNumber }}</h2>
                    </div>

                    <div class="content">
                        <p class="address-title">Role</p>
                        @if (userService.userInfo().authorities) {
                            <h2 class="title">{{ userService.userInfo().authorities[0].authority }}</h2>
                        } @else {
                            <h2 class="title">-</h2>
                        }

                    </div>
                    <div class="content">
                        <p class="address-title">Registration Date</p>
                        <h2 class="title">{{ userService.userInfo()?.registrationDate }}</h2>
                    </div>
                </div>
                <div class="actions-wrapper">
                    <div class="enable-button-wrapper"
                         *ngIf="authService.isAdmin() && isCurrentUser(userService.userInfo()?.id)">
                        <p class="address-title">Email notification</p>
                        <label class="switch" for="enableNotifications">
                            <input type="checkbox" id="enableNotifications" (click)="toggleNotifications()"
                                   [checked]="isNotificationEnabled()"
                                   [ngClass]="isNotificationEnabled() ? 'checked' : ''"/>
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <div class="enable-button-wrapper"
                         *ngIf="isCurrentUser(userService.userInfo()?.id)">
                        <p class="address-title">Enable MFA</p>
                        <label class="switch" for="enableMfa">
                            <input type="checkbox" id="enableMfa" (click)="toggleMfa()"
                                   [ngClass]="isMfaEnabled() ? 'checked' : ''"/>
                            <div class="slider round"></div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="user-form" *ngIf="isEditing" id="scroll">
                <div class="user-form-wrapper">
                    <form [formGroup]="userForm">
                        <div class="form-group">
                            <div class="input-wrapper">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" placeholder="First Name" formControlName="firstName"/>
                            </div>

                            <div class="input-wrapper">
                                <label for="lastName">Last Name</label>
                                <input type="text" placeholder="Last Name" id="lastName" formControlName="lastName"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-wrapper">
                                <label for="phoneNum">Phone Number</label>
                                <input type="text" placeholder="Phone Number" id="phoneNum"
                                       formControlName="phoneNumber"/>
                            </div>
                        </div>
                    </form>
                    <div class="button-wrapper">
                        <button class="button" (click)="onSubmitForm(+userService.userInfo()?.id)">Submit</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <app-two-factor-activate-template [isOpened]="isMfaModalOpened()"
                                      (closeModal)="closeMFAModal($event)"></app-two-factor-activate-template>
</div>




