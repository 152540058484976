import {Component} from '@angular/core';

@Component({
    selector: 'app-users-statistics-details',
    templateUrl: './users-statistics-details.component.html',
    styleUrl: './users-statistics-details.component.css'
})


export class UsersStatisticsDetailsComponent {}
