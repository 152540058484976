<div class="details-wrapper" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
    <div class="details-container">
        @if (pureOriginalService.imageIsLoading()) {
            <app-pure-original-info-skeleton></app-pure-original-info-skeleton>
        }
        @if (!pureOriginalService.imageIsLoading()) {
            <form class="vehicle-info-container">
                <div class="restart-button-container">
                    <button class="restart-po-process" role="button"
                            (click)="repeatPureOriginalsProcess(+pureOriginalDetailObject()['id'])">
                        Restart Pure Original Process
                    </button>
                </div>
                @if (pureOriginalDetailObject()) {
                    <ng-container>
                        <div class="title-image-wrapper">
                            <div class="header-info-container">
                                <div class="title">
                                    <span class="content-item"><span
                                            class="content-item-text">{{ pureOriginalDetailObject()["vehicleProducer"] }}</span>
                                    </span>
                                    <span class="content-item">
                                        <span class="content-item-text">{{ pureOriginalDetailObject()["vehicleSeries"] | shorten: 17 }}</span>
                                    </span>
                                    <span class="content-item">
                                        <span class="content-item-text">{{ pureOriginalDetailObject()["vehicleModelYear"] }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="vehicle-info-card">
                                <img [src]="carImageService.image" alt=""
                                     [ngClass]="!carImageService.image ? 'startLoad' : ''">
                            </div>
                        </div>

                        <div class="new-tuning-container">
                            <div class="download-button-container">
                                <button class="add-tuning-button"
                                        (click)="downloadVersionFile(pureOriginalId(), pureOriginalDetailObject().winOlsReference, 'PURE_ORIGINAL_BASE')"
                                        [disabled]="isDownloadInProgress(pureOriginalId())">
                                    <span class="button-text">Download</span>
                                    <i class="bi bi-arrow-down-short"></i>
                                </button>
                            </div>
                            <div class="download-button-container">
                                <div class="add-tuning-container">
                                    <button class="add-tuning-button" (click)="openFileUploadModal()"
                                            [disabled]="buttonIsDisabled()"
                                            [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                        <span class="button-text">Add Tuning</span>
                                        <i class="bi bi-arrow-up-short"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="edit-button-container">
                                <button class="add-tuning-button"
                                        (click)="editPureOriginalItem(pureOriginalDetailObject().id, pureOriginalDetailObject())"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                    <i class="bi bi-pencil"></i>
                                </button>

                            </div>
                            <div>
                                <div class="add-tuning-container">
                                    <button class="add-tuning-button"
                                            (click)="downloadVersionFile(pureOriginalId(), pureOriginalDetailObject().winOlsReference, 'PURE_ORIGINAL_LOSE_MATCHING')"
                                            [disabled]="isDownloadInProgress(pureOriginalId()) || buttonIsDisabled()"
                                            [matTooltip]="buttonIsDisabled() ? 'Please claim  the file' : ''">
                                        <span style="margin-left: 2px; margin-right: 2px" class="button-text">Download all partially matched tunings</span>
                                        <i class="bi bi-arrow-down-short"></i>
                                    </button>
                                </div>
                            </div>

                            <div>
                                <button class="add-tuning-button"
                                        (click)="fileInput.click()"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''">
                                    Upload INI
                                </button>
                                <input #fileInput class="file-upload-input"
                                       type="file"
                                       style="display: none;"
                                       [disabled]="buttonIsDisabled()"
                                       (change)="uploadIni($event)"/>

                            </div>

                            <div class="edit-button-container">
                                <button class="add-tuning-button"
                                        (click)="deletePureOriginalItem(+pureOriginalDetailObject().id, 'PURE_ORIGINAL_BASE', true)"
                                        [disabled]="buttonIsDisabled()"
                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"><i
                                        class="bi bi-trash"></i></button>
                            </div>
                        </div>

                        <form [formGroup]="pureOriginalDetailsFormGroup" (keydown.enter)="submitForm()"
                              (keydown.escape)="cancelForm()">
                            <div class="column-wrapper">
                                <div class="info-card-details">
                                    <div class="info-column">
                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">New WinOls Name</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()['winOlsDbName'] || ''| shorten: 30 }}</span></span>
                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">Ecu Producer</span>
                                            @if (!editFlags.ecuProducer) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuProducer', $event)">{{ pureOriginalDetailsFormGroup.get('ecuProducer').value ? pureOriginalDetailObject()["ecuProducer"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuProducer"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                       </span>
                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">Ecu Build</span>
                                            @if (!editFlags.ecuBuild) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuBuild', $event)">{{ pureOriginalDetailsFormGroup.get('ecuBuild').value ? pureOriginalDetailObject()["ecuBuild"] : '' }}
                                           </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuBuild"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                     </span>
                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Ecu Software Version</span>
                                            @if (!editFlags.ecuSoftwareVersion) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuSoftwareVersion', $event)">{{ pureOriginalDetailsFormGroup.get('ecuSoftwareVersion').value ? pureOriginalDetailObject()['ecuSoftwareVersion'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuSoftwareVersion"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Ecu Software Version Version</span>
                                            @if (!editFlags.ecuSoftwareVersionVersion) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuSoftwareVersionVersion', $event)">{{ pureOriginalDetailsFormGroup.get('ecuSoftwareVersionVersion').value ? pureOriginalDetailObject()['ecuSoftwareVersionVersion'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuSoftwareVersionVersion"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>


                                        <span class="matching-detail-item">
                                             <span class="matching-item-name">Ecu Prod Nr</span>
                                            @if (!editFlags.ecuProdNr) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuProdNr', $event)">{{ pureOriginalDetailsFormGroup.get('ecuProdNr').value ? pureOriginalDetailObject()['ecuProdNr'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuProdNr"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                        </span>

                                    </div>
                                    <div class="info-column">
                                        <span class="matching-detail-item">
                                      <span class="matching-item-name">Reading Hardware</span>
                                            @if (!editFlags.readingHardware) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('readingHardware', $event)">{{ pureOriginalDetailsFormGroup.get('readingHardware').value ? pureOriginalDetailObject()['readingHardware'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="readingHardware"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                       </span>

                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Vehicle Producer</span>
                                            @if (!editFlags.vehicleProducer) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleProducer', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleProducer').value ? pureOriginalDetailObject()['vehicleProducer'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleProducer"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>

                                        <span class="matching-detail-item">
                                  <span class="matching-item-name">Vehicle Series</span>
                                            @if (!editFlags.vehicleSeries) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleSeries', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleSeries').value ? pureOriginalDetailObject()['vehicleSeries'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleSeries"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>

                                        <span class="matching-detail-item">
                                  <span class="matching-item-name">Vehicle Model</span>
                                            @if (!editFlags.vehicleModel) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleModel', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleModel').value ? pureOriginalDetailObject()['vehicleModel'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleModel"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                    </span>
                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Engine Name</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["engineName"] || '-' }}</span></span>

                                        <span class="matching-detail-item">
                                      <span class="matching-item-name">Ecu StgNr</span>
                                            @if (!editFlags.ecuStgNr) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuStgNr', $event)">{{ pureOriginalDetailsFormGroup.get('ecuStgNr').value ? pureOriginalDetailObject()['ecuStgNr'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuStgNr"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                      </span>

                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showMore">
                                <div class="info-card-details">
                                    <div class="info-column">

                                             <span class="matching-detail-item"><span
                                                     class="matching-item-name">WinOLS</span><span
                                                     class="matching-item-text">{{ pureOriginalDetailObject()['winOlsReference'] || '' | shorten: 25 }}</span></span>
                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Ecu Version</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["ecuSoftwareVersion"] || '' | shorten: 22 }}</span></span>

                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Tunings</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()['numberOfOptions'] || '' }}</span></span>

                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Client Counter</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["clientImportCounter"] || '' }}</span></span>
                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Master Counter</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["masterUploadCounter"] || '' }}</span></span>

                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Date Exported</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()['dateExported'] || ''| shorten: 30 }}</span></span>

                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Date Uploaded</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["dateUploaded"] || '' | shorten: 20 }}</span></span>
                                        <span class="matching-detail-item"><span
                                                class="matching-item-name">Date Updated</span><span
                                                class="matching-item-text">{{ pureOriginalDetailObject()["dateUpdated"] || '' | shorten: 20 }}</span></span>
                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">Type</span>
                                            @if (!editFlags.engineType) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('engineType', $event)">{{ pureOriginalDetailsFormGroup.get('engineType').value ? pureOriginalDetailObject().engineType : "" }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="engineType"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                        </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">Transmission</span>
                                            @if (!editFlags.engineTransmission) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('engineTransmission', $event)">{{ pureOriginalDetailsFormGroup.get("engineTransmission").value ? pureOriginalDetailObject()["engineTransmission"] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="engineTransmission"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                        </span>
                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">Engine Producer</span>
                                            @if (!editFlags.engineProducer) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('engineProducer', $event)">{{ pureOriginalDetailsFormGroup.get('engineProducer').value ? pureOriginalDetailObject()['engineProducer'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="engineProducer"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                        </span>

                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Engine Emission Standard</span>
                                            @if (!editFlags.engineEmissionStandard) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('engineEmissionStandard', $event)">{{ pureOriginalDetailsFormGroup.get('engineEmissionStandard').value ? pureOriginalDetailObject()['engineEmissionStandard'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="engineEmissionStandard"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                        </span>

                                        <span class="matching-detail-item">
                                            <span class="matching-item-name">Displacement</span>
                                            @if (!editFlags.engineDisplacement) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('engineDisplacement', $event)">{{ pureOriginalDetailsFormGroup.get('engineDisplacement').value ? pureOriginalDetailObject()["engineDisplacement"] : '' }}
                                             </span>
                                            } @else {
                                                <input type="text" class="matching-item-text new-text"
                                                       formControlName="engineDisplacement"
                                                       (click)="stopPropagation($event)"/>
                                                @if (isInputChanged()) {
                                                    <span *ngIf="pureOriginalDetailsFormGroup.get('engineDisplacement').hasError('pattern')"
                                                          class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                                }
                                            }
                                            </span>


                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 1</span>
                                            @if (!editFlags["userDef1"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef1', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef1').value ? pureOriginalDetailObject()["userDef1"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef1"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>


                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 2</span>
                                            @if (!editFlags["userDef2"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef2', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef2').value ? pureOriginalDetailObject()["userDef2"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef2"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 3</span>
                                            @if (!editFlags["userDef3"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef3', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef3').value ? pureOriginalDetailObject()["userDef3"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef3"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 4</span>
                                            @if (!editFlags["userDef4"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef4', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef4').value ? pureOriginalDetailObject()["userDef4"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef4"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>


                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 5</span>
                                            @if (!editFlags["userDef5"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef5', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef5').value ? pureOriginalDetailObject()["userDef5"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef5"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 6</span>
                                            @if (!editFlags["userDef6"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef6', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef6').value ? pureOriginalDetailObject()["userDef6"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef6"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 7</span>
                                            @if (!editFlags["userDef7"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef7', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef7').value ? pureOriginalDetailObject()["userDef7"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef7"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>

                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 11</span>
                                            @if (!editFlags["userDef11"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef11', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef11').value ? pureOriginalDetailObject()["userDef11"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef11"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>


                                        <span class="matching-detail-item">
                                        <span class="matching-item-name">User Def 12</span>
                                            @if (!editFlags["userDef12"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef12', $event)">
                                                {{ pureOriginalDetailsFormGroup.get('userDef12').value ? pureOriginalDetailObject()["userDef12"] : '' }}
                                            </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef12"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                          </span>


                                    </div>

                                    <div class="info-column">
                                        <span class="matching-detail-item">
                                             <span class="matching-item-name">Vehicle Build</span>
                                            @if (!editFlags.vehicleBuild) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleBuild', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleBuild').value ? pureOriginalDetailObject()['vehicleBuild'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleBuild"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                         </span>
                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Vehicle Type</span>
                                            @if (!editFlags.vehicleType) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleType', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleType').value ? pureOriginalDetailObject()['vehicleType'] : '' }}</span>

                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleType"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                     </span>
                                        <span class="matching-detail-item">
                                     <span class="matching-item-name">Vehicle Model Year</span>
                                            @if (!editFlags.vehicleModelYear) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleModelYear', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleModelYear').value ? pureOriginalDetailObject()['vehicleModelYear'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleModelYear"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>
                                        <span class="matching-detail-item">
                                      <span class="matching-item-name">Vehicle Characteristic</span>
                                            @if (!editFlags.vehicleCharacteristic) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleCharacteristic', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleCharacteristic').value ? pureOriginalDetailObject()['vehicleCharacteristic'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleCharacteristic"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                        </span>
                                        <span class="matching-detail-item">
                                      <span class="matching-item-name">Vehicle VIn</span>
                                            @if (!editFlags.vehicleVin) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('vehicleVin', $event)">{{ pureOriginalDetailsFormGroup.get('vehicleVin').value ? pureOriginalDetailObject()['vehicleVin'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="vehicleVin"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                       </span>
                                        <span class="matching-detail-item">
                                      <span class="matching-item-name">Ecu Use</span>
                                            @if (!editFlags.ecuUse) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('ecuUse', $event)">{{ pureOriginalDetailsFormGroup.get('ecuUse').value ? pureOriginalDetailObject()['ecuUse'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="ecuUse"
                                                       (click)="stopPropagation($event)"
                                                />
                                            }
                                         </span>

                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Project Details</span>
                                            @if (!editFlags.resellerProjectDetails) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('resellerProjectDetails', $event)">{{ pureOriginalDetailsFormGroup.get('resellerProjectDetails').value ? pureOriginalDetailObject()['resellerProjectDetails'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="resellerProjectDetails"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>

                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Project Type</span>
                                            @if (!editFlags.resellerProjectType) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('resellerProjectType', $event)">{{ pureOriginalDetailsFormGroup.get('resellerProjectType').value ? pureOriginalDetailObject()['resellerProjectType'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="resellerProjectType"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>
                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Reseller Credits</span>
                                            @if (!editFlags.resellerCredits) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('resellerCredits', $event)">{{ pureOriginalDetailsFormGroup.get('resellerCredits').value ? pureOriginalDetailObject()['resellerCredits'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="resellerCredits"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                   </span>
                                        <span class="matching-detail-item">
                                     <span class="matching-item-name">Hp</span>
                                            @if (!editFlags.outputPs) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('outputPs', $event)">{{ pureOriginalDetailsFormGroup.get('outputPs').value ? pureOriginalDetailObject()['outputPs'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="outputPs"

                                                       (click)="stopPropagation($event)"/>
                                                @if (isInputChanged()) {
                                                    <span *ngIf="pureOriginalDetailsFormGroup.get('outputPs').hasError('pattern')"
                                                          class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                                }
                                            }
                                   </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">Kw</span>
                                            @if (!editFlags.outputKw) {
                                                <span class="matching-item-text cursor"
                                                      [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                      (click)="toggleEdit('outputKw', $event)">{{ pureOriginalDetailsFormGroup.get('outputKw').value ? pureOriginalDetailObject()['outputKw'] : '' }}</span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="outputKw"
                                                       (click)="stopPropagation($event)"/>
                                                @if (isInputChanged()) {
                                                    <span *ngIf="pureOriginalDetailsFormGroup.get('outputKw').hasError('pattern')"
                                                          class="error-tooltip">Only accepts formats like this -> 100.0 or 100</span>
                                                }
                                            }
                                  </span>
                                        <span class="matching-detail-item">
                                       <span class="matching-item-name">Nm</span>
                                            @if (!editFlags.engineMaxTorque) {
                                                <span
                                                        class="matching-item-text cursor"
                                                        [matTooltip]="buttonIsDisabled() ? 'Please claim the file' : ''"
                                                        (click)="toggleEdit('engineMaxTorque', $event)">{{ pureOriginalDetailsFormGroup.get('engineMaxTorque').value ? pureOriginalDetailObject()['engineMaxTorque'] : '' }}</span>
                                            } @else {

                                                <input type="text" class="matching-item-text"
                                                       formControlName="engineMaxTorque"
                                                       (click)="stopPropagation($event)"/>
                                                @if (isInputChanged()) {
                                                    <span *ngIf="pureOriginalDetailsFormGroup.get('engineMaxTorque').hasError('pattern')"
                                                          class="error-tooltip">Only accepts formats like this -> 100.0 or 100 </span>
                                                }
                                            }
                                 </span>


                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">Credit Type</span>
                                            @if (!editFlags["creditType"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('creditType', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('creditType').value ? pureOriginalDetailObject()["creditType"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="creditType"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 13</span>
                                            @if (!editFlags["userDef13"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef13', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef13').value ? pureOriginalDetailObject()["userDef13"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef13"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 14</span>
                                            @if (!editFlags["userDef14"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef14', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef14').value ? pureOriginalDetailObject()["userDef14"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef14"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 15</span>
                                            @if (!editFlags["userDef15"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef15', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef15').value ? pureOriginalDetailObject()["userDef15"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef15"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 16</span>
                                            @if (!editFlags["userDef16"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef16', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef16').value ? pureOriginalDetailObject()["userDef16"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef16"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>

                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 17</span>
                                            @if (!editFlags["userDef17"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef17', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef17').value ? pureOriginalDetailObject()["userDef17"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef17"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>


                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 18</span>
                                            @if (!editFlags["userDef18"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef18', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef18').value ? pureOriginalDetailObject()["userDef18"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef18"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>


                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 17</span>
                                            @if (!editFlags["userDef19"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef19', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef19').value ? pureOriginalDetailObject()["userDef19"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef19"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>


                                        <span class="matching-detail-item">
                                    <span class="matching-item-name">User Def 20</span>
                                            @if (!editFlags["userDef20"]) {
                                                <span class="matching-item-text cursor"
                                                      (click)="toggleEdit('userDef20', $event)">
                                            {{ pureOriginalDetailsFormGroup.get('userDef20').value ? pureOriginalDetailObject()["userDef20"] : '' }}
                                        </span>
                                            } @else {
                                                <input type="text" class="matching-item-text"
                                                       formControlName="userDef20"
                                                       (click)="stopPropagation($event)"/>
                                            }
                                      </span>
                                    </div>
                                </div>
                            </div>

                            @if (isVisibleButton()) {
                                <div class="save-button">
                                    <button type="button" class="add-tuning-button"
                                            (click)="submitForm()" [disabled]="!pureOriginalDetailsFormGroup.valid">Save
                                    </button>
                                    <button type="button" class="add-tuning-button"
                                            (click)="cancelForm()">Cancel
                                    </button>
                                </div>
                            }

                            <button type="button" class="add-tuning-button details-btn" (click)="toggleShowMore()">
                                {{ showMore ? 'See less details...' : 'See more details... ' }}
                            </button>
                        </form>
                    </ng-container>
                }
            </form>
        }
    </div>
</div>
