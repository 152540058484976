import {
    Component,
    EventEmitter,
    Output,
    ViewChild,
    input,
    inject, signal, OnInit
} from '@angular/core';
import {AllMasterDatabaseModel} from "../../../models/AllMasterDatabase-model";
import {VersionRecordsModel} from "../../../models/VersionRecordsModel";
import {UpdateMasterModel} from "../../../models/UpdateMaster-model";
import {Router} from "@angular/router";
import {MatSort} from "@angular/material/sort";
import {SortDirectionValuesModel} from "../../../models/SortDirectionValues-model";
import {AllMastersDatabaseService} from "../../../service/all-masters-database.service";
import {AuthService} from "../../../service/auth.service";
import {UploadFileDialogService} from "../../../service/uploadFileDialog.service";

@Component({
    selector: 'app-master-records-table',
    templateUrl: './master-records-table.component.html',
    styleUrls: ['./master-records-table.component.css']
})
export class MasterRecordsTableComponent implements OnInit {

    // INPUTS
    @ViewChild(MatSort) sort: MatSort;

    allMasters = input<AllMasterDatabaseModel[]>([]);
    displayedTableHeaders = input<string[]>();
    displayedColumns = input<(keyof AllMasterDatabaseModel)[]>();
    expandedMaster = input<AllMasterDatabaseModel | null>();
    displayedVersionColumns = input<(keyof VersionRecordsModel)[]>();
    allVersions = input<VersionRecordsModel[]>([])
    sortDirectionValues = input<SortDirectionValuesModel>();


    // OUTPUTS
    @Output() editItemDetails = new EventEmitter();
    @Output() deleteOriginalItem = new EventEmitter();
    @Output() downloadOriginalFile = new EventEmitter();
    @Output() deleteTuningRecord = new EventEmitter();
    @Output() toggleExpandedRow = new EventEmitter<AllMasterDatabaseModel>();
    @Output() uploadMasterIniFile = new EventEmitter();
    @Output() downloadTuningRecord = new EventEmitter();
    @Output() changeSortDirection = new EventEmitter();

    allMastersService = inject(AllMastersDatabaseService);
    authService = inject(AuthService);
    uploadFileService = inject(UploadFileDialogService);

    isModalOpened = signal<boolean>(false);

    private router = inject(Router);


    ngOnInit() {
        this.uploadFileService.reset$.subscribe(() => {
            this.resetMasterIniFile();
        });

    }

    resetMasterIniFile() {
        this.allMastersService.refreshIniFile();

    }

    deleteOriginalItems(id: number, tableName: string) {
        this.deleteOriginalItem.emit({id, tableName});
    }

    downloadFileFromTable(id: number, tableName: string, winOls: string) {
        this.downloadOriginalFile.emit({id, tableName, winOls})
    }

    openMasterUploadModal(masterId: number) {
        this.allMastersService.masterId.set(masterId);
        this.isModalOpened.set(true);
    }

    uploadIniFile($event: any) {
        const file = $event.valueOf()["fileToUpload"];
        this.allMastersService.uploadMasterIniFile(file);
    }


    isDownloadInProgress(id: number) {
        return this.allMastersService.isDownloadInProgress(id);
    }

    toggleExpandedRowInTable(element: AllMasterDatabaseModel) {
        this.toggleExpandedRow.emit(element)
    }

    deleteTuningRecordFromTable(version: any, id: number, tableName: string) {
        this.deleteTuningRecord.emit({version, id, tableName})
    }

    downloadTuningRecordFromTable(id: number, tableName: string, winOls: string, versionName: string) {
        this.downloadTuningRecord.emit({id, tableName, winOls, versionName});
    }

    editItemInTable(element: UpdateMasterModel, winOls: string, id: number) {
        this.editItemDetails.emit({element: element, winOls: winOls, id});
    }

    openMasterDetailsItem(id: number) {
        this.router.navigate([`/app/master-details/${id}`])
    }

    changeMasterTableSortDirection(event: any) {
        this.changeSortDirection.emit(event);
    }

    fileModalClosed() {
        this.isModalOpened.set(false);
    }
}
