<div class="card-wrapper">
  <div class="card-container">
    <div class="statistic-card" (click)="openListOfClaimedFiles()">
      <div class="cf-container">
        <div class="text-container">
          <span class="s-num">{{pureOriginalService.numberOfClaimedFiles()}}</span>
          <span class="title">Claimed Files</span>
        </div>
        <div class="icon-container">
          <div class="icon-button">
            <i class="bi bi-folder"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




