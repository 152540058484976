import {Component, input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-number-of-processed-files',
    templateUrl: './number-of-processed-files.component.html',
    styleUrls: ['./number-of-processed-files.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({opacity: 0}), animate('0.5s', style({opacity: 1}))]),
            transition(':leave', [style({opacity: 1}), animate('0.5s', style({opacity: 0}))]),
        ]),
    ],
})
export class NumberOfProcessedFilesComponent implements OnInit {


    public numberOfProcessedFiles = input<number>();

    ngOnInit() {
    }
}
