import {Component, ElementRef, Inject, inject, OnInit, signal, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalService} from "../../service/pure-original.service";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {SearchFilterModel} from "../../models/SearchFilter-model";
import {LocalStorageService} from "../../service/LocalStorageService";
import {PureOriginalsModel} from "../../models/PureOriginals-model";
import {pureOriginalFilterFields} from "../../shared/constants/pure-original-filter-fields";

export enum PureOriginalStatusEnum {
    DONE = "DONE",
    WAITING = "WAITING",
    IN_PROGRESS = "IN_PROGRESS",
    ERROR = "ERROR"
}


@Component({
    selector: 'app-pure-original-table-content',
    templateUrl: './pure-original-table-wrapper.component.html',
    styleUrls: ['./pure-original-table-wrapper.component.css']
})
export class PureOriginalTableWrapperComponent implements OnInit {
    public filterCount = signal<number>(0);


    pureOriginalColumns: string[] = pureOriginalFilterFields;


    pureOriginalsService = inject(PureOriginalService);
    localStorageService = inject(LocalStorageService);
    selectedStatus: PureOriginalStatusEnum | null = null;
    router = inject(Router);
    route = inject(ActivatedRoute);
    elRef = inject(ElementRef);
    @ViewChild(InfiniteScrollDirective) infinitiveScrollDirective: {
        disposeScroller: { unsubscribe: () => void; };
        setup: () => void;
    };

    public openAdvanceSettings = signal<boolean>(false);

    constructor(@Inject('LOCAL_STORAGE_KEY') private pureOriginalsFilter: string) {
        this.restartTableValue();
        this.route.queryParams.subscribe((params) => {
            this.pureOriginalsService.myClaimedFiles.set(params["myClaimedFiles"]);
            if (params['pureOriginalId']) {
                console.log(params['pureOriginalId']);
                const selectedFilter: SearchFilterModel[] = [{
                    option: "id",
                    value: params['pureOriginalId'] as string,
                    hitRateMin: 0,
                    hitRateMax: 0
                }];

                this.pureOriginalsService.selectedFilters.set(selectedFilter as SearchFilterModel[]);
                console.log(this.pureOriginalsService.selectedFilters());
            }
        })
    }

    ngOnInit() {
        console.log(this.pureOriginalsService.columnsToFilter)
        this.getTableKeysForPureOriginals();
        const savedFilters = this.localStorageService.getFilters(this.pureOriginalsFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.pureOriginalsService.selectedFilters.set(savedFilters);
            this.getFilteredRecords(savedFilters)
            this.updateFilterCount(savedFilters.length);
        } else {
            if (!this.pureOriginalsService.myClaimedFiles()) {
                this.getAllPureOriginalRecords();
            } else {
                this.getMyClaimFiles();
            }
        }

    }


    getFilteredRecords(filters: SearchFilterModel[]) {
        this.pureOriginalsService.tableIsLoading.set(true);
        this.pureOriginalsService.getAdvancedPureOriginals(filters).subscribe(response => {
            this.pureOriginalsService.tableIsLoading.set(false);
            this.pureOriginalsService.pureOriginals.set(response['content']);
        });
    }

    getAllPureOriginalRecords() {
        this.pureOriginalsService.getPureOriginals();
    }

    getTableKeysForPureOriginals() {
        this.pureOriginalsService.getTableHeaderNamesForPureOriginals()
    }


    onAdvancedSettingClosed($event: boolean) {
        this.openAdvanceSettings.set($event.valueOf());
    }

    selectFilters($event: any) {
        this.pureOriginalsService.selectedFilters.set($event.valueOf());
        this.onRefreshPureOriginalsTable();
    }

    filterPureOriginalsByStatus(status: PureOriginalStatusEnum) {
        this.pureOriginalsService.pageNumber.set(0);
        const tableElement = this.elRef.nativeElement.querySelector(".table-container");
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        if (this.selectedStatus === status) {
            this.selectedStatus = null;
            this.pureOriginalsService.pureOriginalStatus.set(null);
            this.getAllPureOriginalRecords();
        } else {
            this.selectedStatus = status;
            this.pureOriginalsService.pureOriginalStatus.set(status);
            this.pureOriginalsService.filterPureOriginalsByStatus(status);
        }


    }

    openAdvancedSearchSettings() {
        this.openAdvanceSettings.set(!this.openAdvanceSettings());
    }

    async onScroll() {
        this.pureOriginalsService.triggerOnScroll()
    }

    openPureOriginalDetails($event: any) {
        const id = $event.valueOf();
        return this.router.navigate(['pure-original-details'], {
            queryParams: {pureOriginalId: id},
            relativeTo: this.route
        })
    }

    deletePureOriginalItem($event: any) {
        let id = $event.valueOf().pureOriginalId;
        let tableName = $event.valueOf().tableName;
        this.pureOriginalsService.deletePureOriginalItem(id, tableName);
    }

    onRefreshPureOriginalsTable() {
        this.pureOriginalsService.onRefreshPureOriginalsTable();
    }

    onSearchInput($event: string) {
        this.pureOriginalsService.searchString.set($event.valueOf().toLowerCase().trim());
        this.pureOriginalsService.onSearchInput();
    }

    getMyClaimFiles() {
        this.pureOriginalsService.getMyClaimFiles()
    }

    showMyClaimedFiles() {
        this.pureOriginalsService.showMyClaimedFiles();
    }

    restartTableValue() {
        this.pureOriginalsService.restartTableValues();
    }

    editPureOriginalDetails($event: PureOriginalsModel) {
        let element = $event;
        let id = element.id;
        this.pureOriginalsService.editPureOriginalDetails(+id, element);
    }

    onScrolledUp() {
        this.infinitiveScrollDirective.disposeScroller.unsubscribe();
        this.infinitiveScrollDirective.setup();
    }

    changeSortDirection($event: any) {
        const tableElement = this.elRef.nativeElement.querySelector(".table-container");
        this.pureOriginalsService.changeSortDirection($event.valueOf(), tableElement);
    }

    updateFilterCount(count: number) {
        this.filterCount.set(count);
    }

    protected readonly PureOriginalStatusEnum = PureOriginalStatusEnum;
}
