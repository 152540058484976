import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-user-statistics-wrapper',
  standalone: true,
    imports: [
        RouterOutlet
    ],
  templateUrl: './user-statistics-wrapper.component.html',
  styleUrl: './user-statistics-wrapper.component.css'
})
export class UserStatisticsWrapperComponent {

}
