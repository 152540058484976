<section class="main">
    <div class="table-title">
        <div class="head-buttons">
            <div class="head-buttons-container">
                <button class="r-btn" (click)="onRefreshPureOriginalsTable()"><i class="icon bi bi-arrow-clockwise"></i>
                </button>
                <button class="change-content-btn"
                        (click)="showMyClaimedFiles()">{{ !pureOriginalsService.myClaimedFiles() ? 'My Files' : 'All Originals' }}
                </button>
            </div>
            <div class="info-container">

                <button class="info-item" [ngClass]="{'active': selectedStatus === PureOriginalStatusEnum.DONE}"
                        (click)="filterPureOriginalsByStatus(PureOriginalStatusEnum.DONE)">
                    <div class="info-circle done"></div>
                    <p class="info-text">PureOriginal file successfully processed</p>
                </button>

                <button class="info-item" [ngClass]="{'active': selectedStatus === PureOriginalStatusEnum.IN_PROGRESS}"
                        (click)="filterPureOriginalsByStatus(PureOriginalStatusEnum.IN_PROGRESS)">
                    <div class="info-circle in-progress"></div>
                    <p class="info-text">PureOriginal file in Progress</p>
                </button>


                <button class="info-item" [ngClass]="{'active': selectedStatus === PureOriginalStatusEnum.WAITING}"
                        (click)="filterPureOriginalsByStatus(PureOriginalStatusEnum.WAITING)">
                    <div class="info-circle waiting"></div>
                    <p class="info-text">PureOriginal file in Queue</p>
                </button>

                <button class="info-item" [ngClass]="{'active': selectedStatus === PureOriginalStatusEnum.ERROR}"
                        (click)="filterPureOriginalsByStatus(PureOriginalStatusEnum.ERROR)">
                    <div class="info-circle error-bg"></div>
                    <p class="info-text">Error processing the PureOriginal file</p>
                </button>

            </div>
        </div>
        <div class="act-search-bar">
            <app-search-bar [value]="'WinOls'" (searchValue)="onSearchInput($event)"></app-search-bar>
            <div class="filtering-buttons">
                <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i
                        class="bi bi-funnel"></i>
                    @if (filterCount() > 0) {
                        <p class="count-filter">{{ filterCount() }}</p>
                    }
                </button>
            </div>
        </div>
    </div>

    @if (openAdvanceSettings()) {

        <app-pure-originals-filters [displayedColumns]="pureOriginalColumns"
                                    (pureOriginalsFilterClosed)="onAdvancedSettingClosed($event)"
                                    (selectedFilters)="selectFilters($event)"
                                    (filterCount)="updateFilterCount($event)"
        ></app-pure-originals-filters>

    }

    @defer (when !pureOriginalsService.tableIsLoading()) {
        @if (pureOriginalsService.pureOriginals().length > 0) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="onScroll()"
                 (scrolledUp)="onScrolledUp()">
                <app-pure-originals-table [pureOriginals]="pureOriginalsService.pureOriginals()"
                                          [sortDirectionValues]="pureOriginalsService.sortDirectionValues()"
                                          [displayedTableHeaders]="pureOriginalsService.displayedTableHeaders()"
                                          [displayedOriginalColumns]="pureOriginalsService.displayedOriginalColumns()"
                                          (deletePureOriginalItemOutput)="deletePureOriginalItem($event)"
                                          (changeSortDirection)="changeSortDirection($event)"
                                          (openPureOriginalDetailsOutput)="openPureOriginalDetails($event)"
                                          (editPureOriginalDetails)="editPureOriginalDetails($event)">

                </app-pure-originals-table>
            </div>
        } @else {
            <div>
                <app-empty-table></app-empty-table>
            </div>
        }


    } @placeholder (minimum 150ms) {
        <div *ngIf="pureOriginalsService.tableIsLoading()">
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

</section>
