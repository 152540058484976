export class MostRequestedMissingTuning {
    public id: string;
    public tuningName: string;
    public vehicleProducer: string;
    public ecuBuild: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public pureOriginalBaseId: string;
    public hitRate: string;
    public enabled: string;
    public timesRequested: string;


    constructor() {
        this.id = 'id';
        this.tuningName = 'tuningName';
        this.vehicleProducer = 'vehicleProducer';
        this.ecuBuild = 'ecuBuild';
        this.vehicleSeries = 'vehicleSeries';
        this.vehicleModel = 'vehicleModel';
        this.pureOriginalBaseId = 'pureOriginalBaseId';
        this.hitRate = 'hitRate';
        this.timesRequested = 'timesRequested';
        this.enabled = 'enabled';

    }
}
