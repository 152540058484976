<nav class="primary-navigation">
    <ul role="list" class="sidebar-list">

        <li routerLink="/app" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
            <a class="navigation-link">
                <i class="bi bi-house"></i>
                <span data-list="true">Home</span></a>
        </li>

        <li routerLink="/app/pure-originals" routerLinkActive="active" (click)="closeNavMenuAfterClickOnLink()"
            *ngIf="isModerator() && isLoggedIn()">
            <a class="navigation-link"><i class="bi bi-shield"></i>
                <span data-list="true">Pure Originals</span></a>
        </li>

        <li *ngIf="isAdmin() && isLoggedIn()" routerLink="/app/admin-panel" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
            <a class="navigation-link">
                <i class="bi bi-person-lines-fill"></i>
                <span data-list="true">Admin Panel</span></a>
        </li>


        <ng-container *ngIf="isAdmin() && isLoggedIn()">

        <span class="nav-section-title" (click)="openNestedListForActions()">OPTIONS <button data-list="navButton"
                                                                                             [ngClass]="isActionListOpened ? 'rotated': ''"><svg
                width="10" height="7"
                xmlns="http://www.w3.org/2000/svg"><path
                stroke="#635FC7" stroke-width="2" fill="none" d="M9 6 5 2 1 6"></path></svg></button></span>
            <ul role="list" class="sidebar-list" [ngClass]="isActionListOpened ? 'navShowed' : ''">
                <!--            <li class="download-button" routerLinkActive="active">-->
                <!--              <a class="navigation-link">-->
                <!--                <i class="bi bi-file-arrow-down"></i>-->
                <!--                <span data-list="true">Download Files</span></a>-->
                <!--            </li>-->
                <!--            <li routerLink="/app/upload-files" routerLinkActive="active"-->
                <!--                [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">-->
                <!--              <a class="navigation-link">-->
                <!--                <i class="bi bi-file-arrow-up"></i><span data-list="true">Apply Tuning</span>-->
                <!--              </a>-->
                <!--            </li>-->
                <!--            <li routerLink="/" routerLinkActive="active"-->
                <!--                [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">-->
                <!--              <a class="navigation-link">-->
                <!--                <i class="bi bi-file-arrow-up"></i><span data-list="true">Master Upload</span>-->
                <!--              </a>-->
                <!--            </li>-->
                <div>
                    <li (click)="openNestedListForEcuNames()">
                        <a class="navigation-link">
                            <i class="bi bi-cpu"></i><span data-list="true">ECU<button
                                data-list="navButton"
                                [ngClass]="isEcuNamesOpened ? 'rotated' : ''"><svg
                                width="10" height="7"
                                xmlns="http://www.w3.org/2000/svg"><path
                                stroke="#635FC7" stroke-width="2" fill="none"
                                d="M9 6 5 2 1 6"></path></svg></button></span>
                        </a>
                    </li>

                    <ul role="list" class="ecu-list" [ngClass]="isEcuNamesListOpened ? 'navShowed' : ''">

                        <li routerLink="/app/ecu-names/ecu-build-names" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                            <a>
                                <span data-list="true"> - ECU Builds</span></a>
                        </li>

                        <li routerLink="/app/ecu-names/correct-ecu-producers" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                            <a class="navigation-link">
                                <span data-list="true">- Ecu Producers</span>
                            </a>
                        </li>
                        <li routerLink="/app/ecu-names/faulty-pure-originals" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                            <a>
                                <span data-list="true"> - Faulty Pure Originals</span></a>
                        </li>

                        <li routerLink="/app/ecu-names/faulty-masters" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                            <a>
                                <span data-list="true"> - Faulty Masters</span></a>
                        </li>
                    </ul>
                </div>


                <li routerLink="/app/tuning-wrapper-names/tuning-names" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-unlock"></i><span data-list="true">Tuning Names</span>
                    </a>
                </li>

                <li routerLink="/app/tuning-wrapper-names/reseller-tuning-combinations" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-unlock"></i><span data-list="true">Reseller combinations</span>
                    </a>
                </li>
                <li routerLink="/app/custom-functions" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-sliders2"></i><span data-list="true">Custom Functions</span>
                    </a>
                </li>
            </ul>

            <span class="nav-section-title" (click)="openNestedListForTables()">TABLES <button data-list="navButton"
                                                                                               [ngClass]="isTableListOpened ? 'rotated' : ''"><svg
                    width="10" height="7"
                    xmlns="http://www.w3.org/2000/svg"><path
                    stroke="#635FC7" stroke-width="2" fill="none" d="M9 6 5 2 1 6"></path></svg></button></span>
            <ul role="list" class="sidebar-list" [ngClass]="isTableListOpened ? 'navShowed' : ''">
                <li routerLink="/app/file-service" routerLinkActive="active" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-files"></i>
                        <span data-list="true">File Service</span></a>
                </li>
                <li routerLink="/app/masters-results" routerLinkActive="active"
                    (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-file-earmark-binary"></i>
                        <span data-list="true">Masters Results</span></a>
                </li>
                <li routerLink="/app/pure-originals" routerLinkActive="active" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link"><i class="bi bi-shield"></i>
                        <span data-list="true">Pure Originals</span></a>
                </li>
                <li routerLink="/app/pure-originals-results" routerLinkActive="active"
                    (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link"><i class="bi bi-clipboard2-pulse"></i>
                        <span data-list="true">Pure Originals Results</span></a>
                </li>
                <li routerLink="/app/master-database" routerLinkActive="active"
                    (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-database"></i>
                        <span data-list="true">Master Database</span></a>
                </li>

                <li routerLink="/app/reseller-results" routerLinkActive="active"
                    (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-file-arrow-up"></i>
                        <span data-list="true">Reseller Results</span>
                    </a>
                </li>
                <li routerLink="/app/winOls-transactions" routerLinkActive="active"
                    (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link"><i class="bi bi-arrow-left-right"></i>
                        <span data-list="true">Win Ols Transaction</span></a>
                </li>
            </ul>

            <span class="nav-section-title" (click)="openNestedListForAnalytics()">ANALYTICS <button
                    data-list="navButton"
                    [ngClass]="isAnalyticsListOpened ? 'rotated' : ''"><svg
                    width="10" height="7"
                    xmlns="http://www.w3.org/2000/svg"><path
                    stroke="#635FC7" stroke-width="2" fill="none" d="M9 6 5 2 1 6"></path></svg></button></span>
            <ul role="list" class="sidebar-list" [ngClass]="isAnalyticsListOpened ? 'navShowed' : ''">

                <li routerLink="/app/statistics" routerLinkActive="active" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-file-bar-graph"></i>
                        <span data-list="true">Statistics</span></a>
                </li>
                <li routerLink="/app/user-statistics" routerLinkActive="active" (click)="closeNavMenuAfterClickOnLink()">
                    <a class="navigation-link">
                        <i class="bi bi-file-bar-graph-fill"></i>
                        <span data-list="true">User Statistics</span></a>
                </li>
            </ul>

        </ng-container>
    </ul>

</nav>
