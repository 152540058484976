import {Component, inject, OnInit} from '@angular/core';
import {PureOriginalService} from "../../service/pure-original.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-claimed-files-card',
    templateUrl: './claimed-files-card.component.html',
    styleUrls: ['./claimed-files-card.component.css']
})
export class ClaimedFilesCardComponent implements OnInit {

    pureOriginalService = inject(PureOriginalService);
    router = inject(Router);

    ngOnInit() {
        this.restartTableValues()
        this.getMyClaimedFiles();
    }

    restartTableValues() {
        this.pureOriginalService.restartTableValues();
    }

    getMyClaimedFiles() {
        this.pureOriginalService.getMyClaimFiles();
    }

    openListOfClaimedFiles() {
        if (this.pureOriginalService.numberOfClaimedFiles() > 0) {
            this.router.navigate(['/app/pure-originals'], {queryParams: {myClaimedFiles: true}});
        } else {
            return;
        }

    }
}
