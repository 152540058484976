import {Component, HostListener, inject, input, OnDestroy, OnInit} from '@angular/core';
import {PureOriginalService} from "../../service/pure-original.service";
import {StatisticsService} from "../../service/statistics.service";

@Component({
    selector: 'app-sidebar-server-stats',
    templateUrl: './sidebar-server-stats.component.html',
    styleUrls: ['./sidebar-server-stats.component.css']
})
export class SidebarServerStatsComponent implements OnInit, OnDestroy {


    private intervalId: any;

    public pureOriginalService = inject(PureOriginalService);
    public statisticService = inject(StatisticsService);
    public isSideBarOpened = input<boolean>();


    ngOnInit() {
        this.statisticService.getSystemInfos();
        this.pureOriginalService.getNumberOfProcessedFilesInLast24Hours();
        this.triggerIntervalEvery10Seconds();
    }


    triggerIntervalEvery10Seconds() {
        this.intervalId = setInterval(() => {
            if (this.isMobile() && this.isSideBarOpened()) {
                this.pureOriginalService.getNumberOfProcessedFilesInLast24Hours();
                this.statisticService.getSystemInfos();
            }
        }, 10000)

    }

    @HostListener('window:resize', ['$event'])
    isMobile(): boolean {
        return window.innerWidth < 1115
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);
    }

}
