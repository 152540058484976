import {inject, Inject, Injectable, signal} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {CorrectEcuProducersModel} from "../models/EcuBuildName-model";
import {NotificationService} from "./Notification.service";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {of} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class EcuProducersService {

    private url = `${this.API_URL}`;

    isLoading = signal<boolean>(false);
    showNewInputRow = signal<boolean>(false);
    public newEcuProducerRow = signal<CorrectEcuProducersModel>({} as CorrectEcuProducersModel)
    public ecuProducerToEdit = signal<number | null>(null)
    correctEcuProducers = signal<CorrectEcuProducersModel[]>([]);
    public ecuProducer = signal<CorrectEcuProducersModel[]>([]);
    isWaitingForResponse = signal<boolean>(false);
    ecuProducerIndex = signal<number>(-1);
    correctEcuProducersList = signal<string[]>([]);
    public newRow = signal<CorrectEcuProducersModel>({} as CorrectEcuProducersModel);


    notificationService = inject(NotificationService);
    dialog = inject(MatDialog);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    resetTableValue() {
        this.correctEcuProducers.set([]);
    }

    getAllCorrectEcuProducers() {
        this.isLoading.set(true);
        this.httpClient.get<CorrectEcuProducersModel[]>(`${this.url}/ecuProducers`).subscribe((response) => {
                let producersList: string[] = [];
                for (let producer of response) {
                    if (producer.correctEcuProducer) {
                        producersList.push(producer.correctEcuProducer);
                    }
                    if (producer.acceptedEcuProducer) {
                        producersList.push(producer.acceptedEcuProducer);
                    }
                }
                producersList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
                this.correctEcuProducersList.set(producersList);
                this.correctEcuProducers.set(response);
                this.isLoading.set(false);
            },
        );
    }

    deleteItemFromDatabase(id: number) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete Ecu?"
        })
        dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.httpClient.delete(`${this.url}/ecuProducers/${id}`, {
                        params: {ecuProducerId: id},
                        observe: "response"
                    }).subscribe((response) => {
                        if (response.status === 200) {
                            this.correctEcuProducers.update((producers) => producers.filter(producer => producer.id !== id));
                            this.notificationService.showSuccessMessage('Successfully deleted', `Ecu Producer ${id} deleted.`);
                        } else {
                            this.notificationService.showErrorMessage('Something went wrong', 'Error',);
                        }

                    })
                }
            }
        )
    }

    toggleEditView(index: number) {
        this.ecuProducerIndex.set(index);
    }


    editEcuProducer(id: number, ecuProducer: CorrectEcuProducersModel) {
        this.isWaitingForResponse.set(true);

        const requestBody = {
            newEcuProducerName: ecuProducer.correctEcuProducer,
            acceptedEcuProducerName: ecuProducer.acceptedEcuProducer
        };

        this.httpClient.put<CorrectEcuProducersModel>(`${this.url}/ecuProducers/${id}`, requestBody, {observe: "response"})
            .subscribe((response) => {
                if (response.status === 200 && response.body) {
                    this.correctEcuProducers.update((producers) =>
                        producers.map((producer) =>
                            producer.id === id ? (response.body as CorrectEcuProducersModel) : producer
                        )
                    );
                    this.notificationService.showSuccessMessage("Ecu Producer successfully updated", "Ecu Producer updated");
                }
                this.isWaitingForResponse.set(false);
                this.closeEditedItem();
            }, (error: HttpErrorResponse) => {
                this.isWaitingForResponse.set(false);
                if (error.status === 404) {
                    this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
                }
                if (error.error?.errors) {
                    const errorMessage = error.error?.errors;
                    for (const [key, value] of Object.entries(errorMessage)) {
                        this.notificationService.showErrorMessage(value as string, key + ['Error occurred']);
                    }
                }
            });
    }

    checkIfEcuProducerIsInDatabase(ecuProducer: string) {
        const ecuProducerExists = this.ecuProducer().some(value => value.correctEcuProducer == ecuProducer);
        return ecuProducerExists ? of({ecuProducerExists: true}) : of(null);
    }

    addNewEcuProducerItem(newEcuProducerRow: CorrectEcuProducersModel) {
        this.isWaitingForResponse.set(true);
        this.httpClient.post<CorrectEcuProducersModel>(`${this.url}/ecuProducers`, {
            id: newEcuProducerRow.id,
            correctEcuProducer: newEcuProducerRow.correctEcuProducer,
            acceptedEcuProducer: newEcuProducerRow.correctEcuProducer
        }, {observe: "response"}).subscribe(response => {
            if (response.status === 201) {
                this.ecuProducer.update((ecuProducer) => [...ecuProducer, response.body]);
                this.isWaitingForResponse.set(false);
                this.notificationService.showSuccessMessage('Successfully added.', `Successfully added new ecu producer.`)
            }
            this.showNewInputRow.set(false);
            this.resetNewEcuProducesValue();
        }, (error: HttpErrorResponse) => {
            this.isWaitingForResponse.set(false);
            if (error.status === 403) {
                this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
            }
            if (error.error?.errors) {
                const errorMessage = error.error?.errors;
                for (const [key, value] of Object.entries(errorMessage)) {
                    this.notificationService.showErrorMessage(value as string, key['Error occurred']);
                }
            }
        })
    }

    resetNewEcuProducesValue() {
        this.newEcuProducerRow.set({} as CorrectEcuProducersModel);
    }

    toggleNewInputRow() {
        this.showNewInputRow.set(true);
    }

    resetNewRowValue() {
        this.newRow.set({} as CorrectEcuProducersModel);
    }


    saveNewRow(newEcuProducer: CorrectEcuProducersModel) {
        this.isWaitingForResponse.set(true);
        this.httpClient.post<CorrectEcuProducersModel>(`${this.url}/ecuProducers`, newEcuProducer, {observe: "response"}).subscribe((response) => {
            if (response.status === 201) {
                this.correctEcuProducers.update((producers) => [response.body, ...producers]);
                this.isWaitingForResponse.set(false)
                this.notificationService.showSuccessMessage("Ecu Producer successfully added", "Successfully added");
            }
            this.showNewInputRow.set(false);
            this.resetNewRowValue();

        }, (error: HttpErrorResponse) => {
            this.isWaitingForResponse.set(false);
            if (error.status === 400) {
                this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
            }
            if (error.error?.errors) {
                const errorMessage = error.error?.errors;
                for (const [key, value] of Object.entries(errorMessage)) {
                    this.notificationService.showErrorMessage(value as string, key ['Error occurred'])
                }
            }
        })
    }


    closeRow() {
        this.showNewInputRow.set(false);
    }

    closeEditedItem() {
        this.ecuProducerIndex.set(-1);
    }
}
