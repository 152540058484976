import {Component, inject, OnInit, signal} from '@angular/core';
import {EcuProducersService} from "../service/ecu-producers.service";
import {FormControl, Validators} from "@angular/forms";
import {CorrectEcuProducersModel} from "../models/EcuBuildName-model";

@Component({
    selector: 'app-correct-ecu-producers',
    templateUrl: './correct-ecu-producers.component.html',
    styleUrl: './correct-ecu-producers.component.css'
})
export class CorrectEcuProducersComponent implements OnInit {

    ecuProducersService = inject(EcuProducersService);
    correctEcuProducer = new FormControl("", Validators.required);
    acceptedEcuProducer = new FormControl("", Validators.required)
    editingText = signal<string>('');
    oldProducerValue = signal<string>("");
    newProducerValue = signal<string>("")

    ngOnInit() {
        this.resetTableValue();
        this.getCorrectEcuProducers();
    }

    resetTableValue() {
        this.ecuProducersService.resetTableValue();
    }

    getCorrectEcuProducers() {
        this.ecuProducersService.getAllCorrectEcuProducers();
    }

    toggleInputRow(): void {
        this.correctEcuProducer.reset('');
        this.acceptedEcuProducer.reset('')
        this.ecuProducersService.toggleNewInputRow();
    }

    deleteItemFromDatabase(id: number) {
        this.ecuProducersService.deleteItemFromDatabase(id);
    }

    toggleEditView(index: number, ecuProducer: CorrectEcuProducersModel) {
        this.ecuProducersService.toggleEditView(index);
        this.correctEcuProducer.setValue(ecuProducer.correctEcuProducer || '');
        this.acceptedEcuProducer.setValue(ecuProducer.acceptedEcuProducer || '');

    }

    saveEditedItem(event: Event, oldProducerValue: string) {
        const target = event.target as HTMLInputElement;
        this.newProducerValue.set(target.value);
        this.editingText.set(target.value);
        this.oldProducerValue.set(oldProducerValue);
    }

    editEcuProducer(id: number, element: CorrectEcuProducersModel) {
        const updatedProducer: CorrectEcuProducersModel = {
            id: element.id,
            correctEcuProducer: this.correctEcuProducer.value,
            acceptedEcuProducer: this.acceptedEcuProducer.value
        };

        this.ecuProducersService.editEcuProducer(id, updatedProducer);
    }


    saveNewRow() {
        const ecuProducer = {
            id: 0,
            correctEcuProducer: this.correctEcuProducer.value,
            acceptedEcuProducer: this.acceptedEcuProducer.value
        }
        this.ecuProducersService.saveNewRow(ecuProducer);
    }

}
