<div class="available-options-container">
  <span class="title-list">Available Tunings</span>
  <div class="selected-options-wrapper">
    <div *ngFor="let option of selectedOptions; let i = index" class="selected-options">
      <span class="option-text">{{option.key}}</span>
      <label class="switch" [for]="option.key">
        <input type="checkbox" (click)="setOptionToActive(option)" [value]="option.key" [checked]="option.value"
               [id]="option.key" [disabled]="checkValidityOfOptions(option)"/>
        <div class="slider round"></div>
      </label>
    </div>
  </div>
  <div class="action-button" *ngIf="ecuBuildNameControl">
    <button (click)="requestFileTune(stockFileDTO.stockFileBlobName)" [disabled]="ecuBuildNameControl.hasError('required') || anyOptionsSelected()"
            class="button">
      Request Tune
    </button>
  </div>

  <div class="action-button" *ngIf="!ecuBuildNameControl">
    <button (click)="requestFileTune(stockFileDTO.stockFileBlobName)" [disabled]="anyOptionsSelected()"
            class="button">
      Request Tune
    </button>
  </div>

</div>
