import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-data-picker-modal',
  templateUrl: './data-picker-modal.component.html',
  styleUrls: ['./data-picker-modal.component.css']
})
export class DataPickerModalComponent implements OnInit, OnChanges {

  public isDatePickerModalOpened: boolean = false;
  @Output() emitStatisticsValue = new EventEmitter();
  @Output() emitWinOlsValues = new EventEmitter();

  private startDate: Date;
  private endDate: Date;
  @Input() selectedTimeFrame: string | undefined;
  @Input() currentPartner: string;

  constructor() {}

  ngOnInit() {
    this.endDate = new Date();
    if (this.currentPartner) {
      this.getTodayStatistics();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["currentPartner"] && !changes["currentPartner"].firstChange) {
      this.getTodayStatistics();
    }
  }

  getTodayStatistics() {
    let d = new Date();
    this.selectedTimeFrame = "Today";
    d.setUTCHours(0, 0, 0, 0);
    this.startDate = d;
    this.emitStatistics(this.startDate, this.endDate, this.currentPartner);
    this.emitWinOlsStatistics(this.startDate, this.endDate);

  }

  getLastDayStatistics() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.selectedTimeFrame = "Last Day";
    this.emitStatistics(this.startDate, this.endDate, this.currentPartner);
    this.emitWinOlsStatistics(this.startDate, this.endDate);
  }


  getLastTwoDaysStatistics() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 2));
    this.selectedTimeFrame = "Last 48H";
    this.emitStatistics(this.startDate, this.endDate, this.currentPartner);
    this.emitWinOlsStatistics(this.startDate, this.endDate);
  }

  getLastWeekStatistics() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.selectedTimeFrame = "Last Week";
    this.emitStatistics(this.startDate, this.endDate, this.currentPartner);
    this.emitWinOlsStatistics(this.startDate, this.endDate);
  }

  getLastMonthStatistics() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    this.selectedTimeFrame = "Last 30 days";
    this.emitStatistics(this.startDate, this.endDate, this.currentPartner);
    this.emitWinOlsStatistics(this.startDate, this.endDate);
  }

  emitStatistics(startDate: Date, endDate: Date, currentPartner: string) {
    this.emitStatisticsValue.emit({startDate, endDate, currentPartner});
    this.closeDropDownMenu()
  }
  emitWinOlsStatistics(startDate: Date, endDate: Date) {
    this.emitWinOlsValues.emit({startDate, endDate})

  }

  openDatePickerModal() {
    this.isDatePickerModalOpened = !this.isDatePickerModalOpened
  }

  closeDropDownMenu() {
    this.isDatePickerModalOpened = false;
  }

}
