<div class="server-stats-sidebar">
    <div class="processed-files-container">
        <app-number-of-processed-files
                [numberOfProcessedFiles]="pureOriginalService.numberOfProcessedFiles()"></app-number-of-processed-files>
    </div>

    <div class="server-stats-wrapper">
        <app-server-stats [systemInfoData]="statisticService.systemInfoData()"
                          [isLoading]="statisticService.isLoading()"></app-server-stats>
    </div>
</div>
