import {inject, Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";



@Injectable({
    providedIn: "root"
})

export class NotificationService {

    private toastrService = inject(ToastrService);
    private position = {positionClass: 'toast-bottom-center'};


    showSuccessMessage(message: string, title: string, position?: any) {
        this.toastrService.success(message, title, position ? position : { ...this.position, timeOut: 60000 });
    }

    showErrorMessage (message: string, title: string) {
        this.toastrService.error(message, title, this.position);
    }
    showInfoMessage(message: string, title: string) {
        return this.toastrService.info(message, title, { positionClass: 'toast-bottom-center', timeOut: 60000 });
    }
    showWarningMessage (message: string, title: string, position?: any) {
        return this.toastrService.warning(message, title, position);
    }
    clear (toastId: number) {
        this.toastrService.remove(toastId)
    }

}
