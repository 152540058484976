import {Component, inject, OnInit} from '@angular/core';
import {TuningsService} from "../../service/Tunings.service";
import {ActivatedRoute} from "@angular/router";


@Component({
    selector: 'app-master-version-details',
    templateUrl: './master-version-details.component.html',
    styleUrls: ['./master-version-details.component.css']
})
export class MasterVersionDetailsComponent implements OnInit {

    public versionService = inject(TuningsService);
    route = inject(ActivatedRoute);


    ngOnInit() {
        this.getIdFromParams()
        this.getVersionNameFromParams();
        this.versionService.getVersionRecords(this.versionService.masterId());
    }

    getIdFromParams() {
        this.route.params.subscribe(params => {
            this.versionService.masterId.set(params['id']);

        })
    }

    getVersionNameFromParams() {
        this.route.queryParams.subscribe(query => {
            this.versionService.versionName.set(query['versionName']);
        })
    }


    deleteTuningRecordFromTable(versionId: number, tableName: string) {
        this.versionService.deleteTuningRecordFromTable(versionId, tableName)
    }

    openPureOriginalsByVersionId(versionId: number, versionName: string, dcf: string) {
        this.versionService.getPureOriginalsByVersionId(versionId, versionName, dcf);
    }

    openPartiallyTunePureOriginalsByVersionId(versionId: number, versionName: string, dcf: string) {
        this.versionService.getPartiallyTunePureOriginalsByVersionId(versionId, versionName, dcf);
    }

    isDownloadInProgress(id: number) {
        return this.versionService.isDownloadInProgress(id);
    }

    downloadMasterVersion(id: number, winOls: string, versionName: string) {
        this.versionService.getDownloadMasterVersion(id, winOls, versionName);
    }

    onEditVersionComment(index: number) {
        this.versionService.onEditVersionComment(index)
    }

    saveVersionComment(editedItemId: number) {
        this.versionService.saveVersionComment(editedItemId);
    }

    closeEditMode() {
        this.versionService.closeEditMode();
    }
}





