import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';


@Component({
  selector: 'app-disk-space-component',
  templateUrl: './disk-space-component.component.html',
  styleUrls: ['./disk-space-component.component.css']
})
export class DiskSpaceComponentComponent implements OnInit{

  @Input() totalDiskSpace: number;
  @Input() freeDiskSpace: number;
  public progressWidth: number = 0;

  public color:string = '';
  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngOnInit() {
    this.extractDiskSpaceValues(this.totalDiskSpace, this.freeDiskSpace);
  }



  extractDiskSpaceValues(totalDiskSpace: number, freeDiskSpace: number) {
    const progressBar = this.el.nativeElement.querySelector('.progress-bar-custom');
    const totalDiskSpaceEl = this.el.nativeElement.querySelector('.totalDiskSpace');
    const usedDiskSpaceEl = this.el.nativeElement.querySelector('.usedDiskSpace');

    const totalDiskSpaceToGB = (totalDiskSpace / 1024).toFixed(2);
    const usedDiskSpaceToGB = ((totalDiskSpace - freeDiskSpace) / 1024).toFixed(2);


    this.progressWidth = (+usedDiskSpaceToGB / +totalDiskSpaceToGB) * 100;


    if (totalDiskSpaceEl != null) {
      this.renderer.setProperty(totalDiskSpaceEl, 'innerHTML', totalDiskSpaceToGB + 'gb');
    }

    if (usedDiskSpaceEl != null) {
      this.renderer.setProperty(usedDiskSpaceEl, 'innerHTML', usedDiskSpaceToGB  + 'gb');
    }

    if (this.progressWidth != null) {
      if (this.progressWidth < 70 && this.progressWidth > 0) {
        progressBar.style.setProperty('--pseudoEl', 'lightgreen');
      }
      if(this.progressWidth >= 70) {
        progressBar.style.setProperty('--pseudoEl', 'orange');
      }
      if (this.progressWidth >= 90) {
        progressBar.style.setProperty('--pseudoEl', 'red');
      }
    }

  }




}
