<table mat-table [dataSource]="winOlsTransactions()"
       multiTemplateDataRows matSort matSortDisableClear [matSortDirection]="sortDirectionValues().direction"
       [matSortActive]="sortDirectionValues().active"
       (matSortChange)="changeMasterTableSortDirection($event)">

    <mat-dialog-content>
        <ng-container *ngFor="let column of displayedColumns()" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{
                'green': element.pureOriginalId,
                'orange': !element.pureOriginalId && element.clientFile
            }">
                <ng-container *ngIf="column === 'similarPureOriginalId'; else defaultCell">
                    <a class="similar" (click)="navigateToSimilarPureOriginalId(element.similarPureOriginalId)">
                        {{ element.similarPureOriginalId }}
                    </a>
                </ng-container>
                <ng-template #defaultCell>
                    {{ element[column] | shorten: 150 }}
                </ng-template>
            </td>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element" class="actions" [ngClass]="{
                'green': element.pureOriginalId,
                 'orange': !element.pureOriginalId && element.clientFile}">
                <button *ngIf="element.pureOriginalId" class="table-buttons car-button"
                        [disabled]="!element.pureOriginalId"
                        (click)="openPureOriginalMatchingDetails(+element.pureOriginalId)"><i
                        class="bi bi-car-front-fill"></i>
                </button>
                <button class="table-buttons" matTooltip="Modifed"
                        (click)="downloadFile(element.trace, +element.id, 'downloadCompletedFile')"
                        [disabled]="element.status !== 'COMPLETED' || isDownloadInProgress(+element.id)"><i
                        class="bi bi-file-earmark-binary"></i></button>
                <button class="table-buttons" matTooltip="Original"
                        (click)="downloadFile(element.trace, +element.id, 'downloadOriginalFile')"
                        [disabled]="isDownloadInProgress(+element.id) || !element.pureOriginalId && !element.clientFile"><i
                        class="bi bi-file-earmark"></i>
                </button>

            </td>
        </ng-container>
    </mat-dialog-actions>

    <tr mat-header-row *matHeaderRowDef="displayedTableHeaders()"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedTableHeaders()"></tr>

</table>
