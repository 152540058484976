import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
    public search: string = '';

    @Input() public newInputValue?: string;
    @Output() public searchValue = new EventEmitter<string>;
    @Input() public value: string;
    public placeholder: string

    constructor() {
    }

    ngOnInit() {
        if (this.value) {
            this.placeholder = `Search for ${this.value}`
        }
    }

    onSubmit() {
        this.searchValue.emit(this.search);
    }

    handleInputChange($event: any) {
        this.search = $event.target.value;
        this.searchValue.emit(this.search);
    }
}
