import {Component, HostListener, inject} from '@angular/core';
import {StatisticsService} from "../../service/statistics.service";
import {PartnersService} from "../../service/Partners.service";
import {WinOlsTransactionService} from "../../service/winols-transaction.service";


@Component({
    selector: 'app-statistic-cards',
    templateUrl: './statistic-cards.component.html',
    styleUrls: ['./statistic-cards.component.css']
})
export class StatisticCardsComponent  {

    public statisticService = inject(StatisticsService);
    public partnersService = inject(PartnersService);
    public winOlsTransaction = inject(WinOlsTransactionService)


    @HostListener('window:resize', ['$event'])
    isMobile(): boolean {
        return window.innerWidth < 768
    }
}
