<div class="details-wrapper">
    @if (allMastersService.masterDetailsIsLoading()) {
        <ng-container>
            <app-pure-original-info-skeleton></app-pure-original-info-skeleton>
        </ng-container>
    } @else {
        <div class="vehicle-info-container">
            <div class="main-title-img">
                <div class="title">
               <span class="content-item"><span
                       class="content-item-text">{{ allMastersService!.masterItem()["vehicleProducer"] }}</span></span>
                </div>
                <div class="vehicle-info-card">
                    <img [src]="imageService.image" alt="" [ngClass]="!imageService.image ? 'startLoad' : ''">
                </div>
                <div class="main-details-container">
                <span class="content-item">
                <span
                        class="content-item-text-details">{{ allMastersService.masterItem()["vehicleSeries"] | shorten: 17 }}</span></span>
                    <span class="content-item">
          <span class="content-item-text-details">{{ allMastersService.masterItem()["vehicleModelYear"] }}</span></span>
                </div>
            </div>
            <div class="new-tuning-container">
                <button class="tuning-button"
                        (click)="downloadMasterItem(allMastersService.masterItem().id, allMastersService.masterItem().winOLSReference, 'MASTER')"
                        [disabled]="isDownloadInProgress(+allMastersService.masterItem().id)">
                    <span class="button-text">Download</span>
                    <i class="bi bi-arrow-down-short"></i>
                </button>
                <button class="tuning-button"
                        (click)="deleteMasterItem(allMastersService.masterItem().id, 'MASTER')">
                    <span class="button-text">Delete Master</span>
                    <i class="bi bi-x"></i>
                </button>
                <button class="tuning-button" (click)="editMasterItem(allMastersService.masterItem(), allMastersService.masterItem().id)">
                    <i class="bi bi-pencil"></i>
                </button>
            </div>

            <div class="vehicle-power-container">
          <span class="matching-power-item"><span class="matching-power-name">Hp</span><span
                  class="matching-power-text">{{ allMastersService.masterItem()["outputPs"] }}</span></span>
                <span class="matching-power-item"><span class="matching-power-name">Kw</span><span
                        class="matching-power-text">{{ allMastersService.masterItem()["outputKw"] }}</span></span>
                <span class="matching-power-item"><span class="matching-power-name">Nm</span><span
                        class="matching-power-text">{{ allMastersService.masterItem()["engineMaxTorque"] | shorten: 25 }}</span></span>
            </div>
            <div class="info-card-details">
         <span class="matching-detail-item"><span class="matching-item-name">ID</span><span
                 class="matching-item-text">{{ allMastersService.masterItem()['id'] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">WinOLS</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["winOLSReference"] | shorten: 30 }}</span></span>

                <span class="matching-detail-item"><span class="matching-item-name">Versions</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()['numberOfVersions'] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Date Uploaded</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["dateUploaded"] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Ecu</span>
          <span class="matching-item-text ecu-detail"><span
                  class="matching-detail-item ecu">{{ allMastersService.masterItem()["ecuProducer"] || '-' }}</span>{{ allMastersService.masterItem()["ecuBuild"] }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Ecu Version</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["ecuSoftwareVersion"] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Engine Name</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["engineName"] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Transmission</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["engineTransmission"] || '-' }}</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Displacement</span><span
                        class="matching-item-text">{{ (+allMastersService.masterItem()["engineDisplacement"]).toFixed(1) || '-' }}
                    L</span></span>
                <span class="matching-detail-item"><span class="matching-item-name">Type</span><span
                        class="matching-item-text">{{ allMastersService.masterItem()["engineType"] || '-' }}</span></span>
            </div>

            <div class="file-upload-container">
                <app-upload-file (file)="uploadIniFile($event)" [error]="allMastersService.myInputs.get('ini').message"
                                 [inputText]="allMastersService.inputText" customBackground="var(--primary-nav-bg)"
                                 [progressWidth]="allMastersService.progressWidth()"
                                 [resetSignal]="allMastersService.resetSignal()"></app-upload-file>
            </div>
        </div>
    }


</div>
