import {Component, inject, OnInit} from '@angular/core';
import {UserStatisticsService} from "../../../service/User-statistics-service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {PureOriginalService} from "../../../service/pure-original.service";

@Component({
    selector: 'app-users-statistics-details',
    templateUrl: './users-statistics-details.component.html',
    styleUrl: './users-statistics-details.component.css'
})


export class UsersStatisticsDetailsComponent implements OnInit {

    userStatisticsService = inject(UserStatisticsService);
    pureOriginalService = inject(PureOriginalService);
    location= inject(Location);

    router = inject(Router);
    route = inject(ActivatedRoute);

    ngOnInit() {
        this.getParamsId();
        this.userStatisticsService.resetTableUserDetails();
        this.userStatisticsService.getAllTableHeaderUserDetails();
        this.pureOriginalService.errorMessage.set('');

    }

    getQueryParams () {
        this.route.queryParams.subscribe((queryParam )=>{
            const startDate = queryParam["startDate"];
            const endDate = queryParam["endDate"];
            this.userStatisticsService.startDate = startDate;
            this.userStatisticsService.endDate = endDate;
        })
    }
    getParamsId () {
        this.route.params.subscribe((params) => {
            const userId = +params["id"];
            this.getQueryParams();
            this.userStatisticsService.getUserStatisticsDetails(userId);
        })
    }

    onScroll() {
        this.userStatisticsService.increasePageNumber();
        this.route.params.subscribe((params) => {
            const userId = +params["id"];
        this.userStatisticsService.getUserStatisticsDetailsOnScroll(userId);
        })
    }

    navigateToPureOriginalId(id: number) {
        this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id}});
    }

    navigateToMatchingId(id: number) {
        this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id}});
    }

    navigateToMasterId(id: number) {
        this.router.navigate([`/app/master-details/${id}`])

    }

    backToMainTable() {
        this.location.back()
    }
}
