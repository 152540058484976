import { Component } from '@angular/core';

@Component({
  selector: 'app-weather-and-time',
  templateUrl: './weather-and-time.component.html',
  styleUrls: ['./weather-and-time.component.css']
})
export class WeatherAndTimeComponent {
  public time = new Date();
  private intervalId;
  private minute = 1000 * 60;

  ngOnInit () {
    this.intervalId = setInterval( () => {
      this.time = new Date();
    }, this.minute)
  }
}
