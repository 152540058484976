import {Component} from '@angular/core';
import {UploadingSteps} from "../dashboard/uploading-steps";
import {HttpEventType} from "@angular/common/http";
import {FileUploadService} from "../service/fileUpload.service";
import {IdentifyStockFileModel} from "../models/IdentifyStockFile-model";
import {TuningsService} from "../service/Tunings.service";

@Component({
  selector: 'app-uploading-actions',
  templateUrl: './uploading-actions.component.html',
  styleUrls: ['./uploading-actions.component.css'],
})
export class UploadingActionsComponent {

  private fileToApply: string;
  public progressWidth: number;
  public currentStep = UploadingSteps.UPLOAD_FILE;
  public error: string | null = null;
  public isNotClicked: boolean = false;

  public stockFileDTO: IdentifyStockFileModel;

  constructor(private fileUploadService: FileUploadService, private tuningService: TuningsService) {
  }


  convertFileToBase64 ($event: any) {
    const reader = new FileReader();
    let selectedFile = $event.valueOf()["fileToUpload"];
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      selectedFile = reader.result as string;
      this.onUploadFile(selectedFile);
    }
  }

  onUploadFile(file: string) {
    this.restartPrevFileState();

    this.fileToApply = file;

    let base64 = this.fileToApply.split(',')[1];

    this.fileUploadService.uploadFileToIdentify(base64).subscribe((response) => {

      if (response.type === HttpEventType.UploadProgress) {
        this.progressWidth = Math.round(response.loaded / response.total * 100);
      } else if (response.type === HttpEventType.Response) {
        if (response.status === 200) {
          this.stockFileDTO = response.body;
          this.currentStep = UploadingSteps.STOCK_FILE_OPTIONS;
        }
      }
    }, (error => {
      if (error.status == 404) {
        this.error = "Sorry, file not found in our database";
      }
    }))


  }

  restartPrevFileState() {
    this.tuningService.selectedOptions = [];
    this.fileToApply = '';
    this.error = '';
    this.progressWidth = 0;
  }

  restartFileUploadAfterRequestIsSent($event: any) {
    if ($event.valueOf() === true) {
      this.restartFileUploadModal()
    }
  }

  restartFileUploadModal() {
    this.currentStep = this.UploadingSteps.UPLOAD_FILE;
    this.fileToApply = '';
    this.progressWidth = 0;
    this.error = null;
    this.isNotClicked = false;
    this.tuningService.options.set([]);
    this.tuningService.selectedOptions = [];
  }


  protected readonly UploadingSteps = UploadingSteps;
}
