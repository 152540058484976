<div class="system-info-item">
    <div class="outer">
      <div class="inner">
        <div class="text-wrapper">
          <div class="percentage"></div><div class="coreNumber"></div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg"  width="150px" height="150px" class="circle-progress-bar">
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stop-color="#e91e63"/>
            <stop offset="100%" stop-color="#673ab7"/>
          </linearGradient>
        </defs>
        <circle cx="75" cy="75" r="68" stroke-linecap="round"
                [ngStyle]="{'stroke-dasharray': strokeDashArray, 'stroke-dashoffset' : strokeDashOffset}"/>
      </svg>
    </div>
</div>
<div class="chart-name">
  <span>CPU USAGE & CORES</span>
</div>



