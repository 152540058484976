
<button class="button date-picker-button" (click)="openDatePickerModal()">{{selectedTimeFrame}}</button>
<div class="dropdown-date-picker" *ngIf="isDatePickerModalOpened">
  <div class="collapsed-overlay" (click)="closeDropDownMenu()"></div>
  <ul>
    <li (click)="getTodayStatistics()">
      Today
    </li>
    <li (click)="getLastDayStatistics()">
      Last Day
    </li>
    <li (click)="getLastTwoDaysStatistics()">
      Last 48 hours
    </li>
    <li (click)="getLastWeekStatistics()">
      Last week
    </li>
    <li (click)="getLastMonthStatistics()">
      Last 30 Days
    </li>
  </ul>
</div>
