<div class="main">
    <div class="bg">
        <div class="verification-wrapper">
            <div class="verification-container">
                <div class="logo">
                    <img src="../../../../assets/logo-black.svg" alt="lg">
                </div>
                <div class="verification-content">
                    <h1 class="verification-title">
                        Hi,
                    </h1>
                    <h1 class="middle-text">Please check your email for password reset instructions.</h1>
                </div>
                <div class="team-text">
                    <h2 class="verification-sub-title">Your Team</h2>
                    <h2 class="verification-sub-title">The DynoChipTuning</h2>
                </div>
                <div class="button-container">
                    <button (click)="backToLoginPage()">Back to login?</button>
                </div>
            </div>
        </div>
    </div>
</div>
