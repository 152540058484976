import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {StatisticsService} from "../../service/statistics.service";

@Component({
    selector: 'app-theme-picker',
    templateUrl: './theme-picker.component.html',
    styleUrls: ['./theme-picker.component.css']
})
export class ThemePickerComponent implements OnInit {

    public isThemeChanged: boolean = false;

    @Output() changeTheme = new EventEmitter<boolean>();
    public colorThemes: any;
    public darkThemePlaceHolder = 'dark';
    public lightThemePlaceHolder = 'light';

    constructor(private statisticService: StatisticsService, private el: ElementRef) {
    }

    ngOnInit() {
        this.colorThemes = this.el.nativeElement.querySelectorAll('[name="theme"]');
        if (!localStorage.getItem('data-theme')) {
            localStorage.setItem('data-theme', 'dark');
        }
        this.retrieveTheme();
        this.statisticService.isThemeChanged.set(this.isThemeChanged);
    }

    storeThemeToLocalStorage(dataTheme: string, theme: string) {
        localStorage.setItem(dataTheme, theme);
    }

    changeThemeOption() {
        this.isThemeChanged = !this.isThemeChanged;
        this.statisticService.isThemeChanged.set(this.isThemeChanged);
        this.colorThemes.forEach(themeChanged => {
            if (themeChanged.checked) {
                this.storeThemeToLocalStorage("data-theme", 'light');
            }
            if (!themeChanged.checked) {
                this.storeThemeToLocalStorage('data-theme', 'dark');
            }
        })
        this.changeTheme.emit(this.isThemeChanged);
    }

    retrieveTheme(): any {
        const activeTheme = localStorage.getItem("data-theme");
        if (activeTheme === this.darkThemePlaceHolder) {
            this.isThemeChanged = false;
            this.changeTheme.emit(this.isThemeChanged);
        }
        if (activeTheme === this.lightThemePlaceHolder) {
            this.isThemeChanged = true;
            this.changeTheme.emit(this.isThemeChanged);
        }

    }

}
