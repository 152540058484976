<div class="win-ols-card-wrapper">
    <div class="search-statistics-container">
        <h2> WinOls Transactions</h2>
    </div>

    <div class="win-ols-even-columns">
        <div class="statistic-card" data-total="true" (click)="viewDetails('TOTAL')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.total()}}</span>
                    <span class="title">Total number of files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-app-indicator"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="statistic-card" data-total="preCheck" (click)="viewDetails('PRE_CHECK')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.preCheck() }}</span>
                    <span class="title">Precheck files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-square"></i>
                    </div>
                </div>
            </div>
            <div class="cs-container">
                <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'warning'"
                                 [value]="winOlsTransactionService.preCheck()!"></ngb-progressbar>
            </div>
        </div>

        <div class="statistic-card" (click)="viewDetails('REQUESTED')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.requested() }}</span>
                    <span class="title">Requested files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-app-indicator"></i>
                    </div>
                </div>
            </div>
            <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'requested'"
                             [value]="winOlsTransactionService.requested()!"></ngb-progressbar>
        </div>

        <div class="statistic-card" (click)="viewDetails('SOLUTION_OFFERED')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.solutionOffered() }}</span>
                    <span class="title">Solution Offered files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-app-indicator"></i>
                    </div>
                </div>
            </div>
            <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'info'"
                             [value]="winOlsTransactionService.solutionOffered()!"></ngb-progressbar>
        </div>

        <div class="statistic-card" (click)="viewDetails('NO_AVAILABLE_OPTIONS')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.noAvailableOptions() }}</span>
                    <span class="title">No Available Options</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-x-lg"></i>
                    </div>
                </div>
            </div>
            <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'secondary'"
                             [value]="winOlsTransactionService.noAvailableOptions()!"></ngb-progressbar>
        </div>

        <div class="statistic-card" (click)="viewDetails('COMPLETED')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.completed() }}</span>
                    <span class="title">Completed files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-check2-square"></i>
                    </div>
                </div>
            </div>
            <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'success'"
                             [value]="winOlsTransactionService.completed()!"></ngb-progressbar>
        </div>

        <div class="statistic-card" (click)="viewDetails('ERROR')">
            <div class="cf-container">
                <div class="text-container">
                    <span class="s-num">{{ winOlsTransactionService.error() }}</span>
                    <span class="title">Error files</span>
                </div>
                <div class="icon-container">
                    <div class="icon-button">
                        <i class="bi bi-x-square"></i>
                    </div>
                </div>
            </div>
            <ngb-progressbar [animated]="true" [max]="winOlsTransactionService.total()!" [type]="'danger'"
                             [value]="winOlsTransactionService.error()!"></ngb-progressbar>
        </div>
    </div>
</div>