<div class="background-overlay" (click)="closeModal()"></div>
<div class="upload-file-modal">
    <div class="title-modal">
        <h2 class="title-text">Upload New File</h2>
        <button class="close-btn" (click)="closeModal()"><i class="bi bi-x-lg"></i></button>
    </div>
    <form [formGroup]="fileUploadForm" class="form-position">
        <mat-form-field *ngIf="versionName()">
            <mat-label>Selected Tuning:</mat-label>
            <input matInput type="text" class="versioNameInput" [value]="versionName()" formControlName="tuningOption"/>
            <mat-error
                    *ngIf="fileUploadForm.get('tuningOption').hasError('required') && fileUploadForm.get('tuningOption')?.touched">
                Required Field
            </mat-error>
            <mat-error *ngIf="!isInputValid() && fileUploadForm.get('tuningOption').touched">
                Incorrect Version Name!
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="!versionName()">
            <mat-select id="tuning" formControlName="tuningOption" (change)="onSelected($event)"
                        placeholder="Select an option">
                <mat-option *ngFor="let option of tuningService.options()" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="fileUploadForm.get('tuningOption').hasError('required')">
                Tuning name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Comment</mat-label>
            <textarea matInput type="text" id="desc" formControlName="comment"></textarea>
        </mat-form-field>
        <div class="form-group-file">
            <label for="pureOriginalVersionBinFile">Add file</label>
            <div class="file-container">
                <button class="file-upload-btn">
                    <input type="file" id="pureOriginalVersionBinFile"
                           class="file-input-property" (change)="getFileOnUpload($event)" #fileUploadInput/>
                    <span class="file-text" *ngIf="!fileUploadForm.value.pureOriginalVersionBinFile || !selectedFile">Click to add new file</span>
                    <span class="file-text"
                          *ngIf="fileUploadForm.value.pureOriginalVersionBinFile">{{ selectedFile?.name }}</span>
                </button>
            </div>
            <div #errorDiv class="error-file-message"></div>
            <mat-error
                    *ngIf="fileUploadForm.get('pureOriginalVersionBinFile').hasError('required') && fileUploadForm.get('pureOriginalVersionBinFile')?.touched">
                Required Field
            </mat-error>
        </div>
    </form>
    <div class="checkbox-wrapper-modal">
        <div class="checkbox-container">
            <label>
                <input type="checkbox" (change)="onChange($event)" [(ngModel)]="isImpossible"/>
                <span class="checkbox"></span>
            </label>
        </div>
        <div class="checkbox-text-container">
            <p class="checkbox-text">Mark file as impossible</p>
        </div>
    </div>
    <div class="form-actions">
        <button class="button" (click)="uploadFileToBlob()"
                [disabled]="fileUploadForm.invalid || isLoading || !selectedFile"
                [ngClass]="isUpdated ? 'updated': ''" [ngStyle]="{'--width' : progressWidth }">
      <span>
        <ng-container *ngIf="!isLoading; else loadingTemplate">
          {{ isImpossible ? 'Add new  Impossible tuning' : 'Upload' }}
        </ng-container>
        <ng-template #loadingTemplate>
          <span *ngIf="progressWidth === 50">{{ progressWidth }}%</span>
         <span
                 *ngIf="progressWidth !== 50 && progressWidth < 100">{{ progressWidth != undefined ? progressWidth + "%" : "0%" }}</span>
          <span *ngIf="progressWidth === 100">{{ isImpossible ? 'New Impossible tuning added' : 'Uploaded' }}</span>
          </ng-template></span>
        </button>
    </div>
</div>
