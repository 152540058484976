@if (isSuperAdmin()) {
    <section class="main">
        <div class="header-wrapper">
            <div class="title-wrapper">
                <h2 class="main-title">User statistics</h2>
                <div>
                    <div class="date-picker-container">
                        <button class="date-select-btn" (click)="userStatisticsService.openDateRangePickerModal()"><i
                                class="bi bi-calendar-week"></i></button>
                        <div class="open-modal">
                            <app-data-range-picker-modal
                                    [isDateRangePickerOpened]="userStatisticsService.isDateRangePickerModalOpened()"
                                    (dataRangePickerEvent)="userStatisticsService.closeDateRangePickerModal($event)"
                                    (emitFormValueEvent)="userStatisticsService.submitUserFormDate($event)"
                            ></app-data-range-picker-modal>
                        </div>
                    </div>
                </div>
            </div>

            @if (userStatisticsService.userStatistics().length > 0) {
                <div class="table-container"
                     infinite-scroll
                     [scrollWindow]="false"
                     [infiniteScrollDistance]="2"
                     (scroll)="onScroll()">
                    <table>
                        <tr class="header">
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Pure Original Versions Uploaded</th>
                            <th>Pure Originals Processed</th>
                            <th>Masters Processed</th>
                        </tr>
                        @for (element of this.userStatisticsService.userStatistics(); track $index) {
                            <tr class="body-columns"
                                    (click)="(element.tuningCount > 0 || element.pureOriginalCount > 0 || element.masterCount > 0) ? navigateToUserDetailsPage(element.userId) : null"
                                    [ngClass]="{'disabled-row': element.tuningCount === 0 && element.pureOriginalCount === 0 && element.masterCount === 0}">
                                <td>{{ element.firstName }}</td>
                                <td>{{ element.lastName }}</td>
                                <td>{{ element.tuningCount }}</td>
                                <td>{{ element.pureOriginalCount }}</td>
                                <td>{{ element.masterCount }}</td>
                            </tr>
                        }
                    </table>
                </div>
            }
        </div>
    </section>
}




