<section class="main">
    <h2 class="main-title">Admin Panel</h2>
    <div class="table-container">
        <table>
            <tr class="header">
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Registration Date</th>
                <th>Enabled</th>
                <th>Authority</th>
                <th>Actions</th>

            </tr>
            @for (element of userService.allUsers(); track $index) {
                <tr class="body-columns">

                    <td>{{ element.firstName }}</td>
                    <td>{{ element.lastName }}</td>
                    <td>{{ element.email }}</td>
                    <td>{{ element.phoneNumber }}</td>
                    <td>{{ element.registrationDate }}</td>
                    <td>
                        <select name="state" class="custom-select" (change)="changeUserStatus(element, $event)"
                                [disabled]="isCurrentUser(element.id) || !isSuperAdmin()">
                            @for (option of userService.user_status(); track $index) {
                                <option [defaultSelected]="option === userService.getUserStatus(element)"
                                        value="{{option}}">
                                    {{ option }}
                                </option>
                            }
                        </select>
                    </td>
                    <td>
                        <select name="state" class="custom-select" (change)="changeUserRole(element, $event)"
                                [disabled]="isCurrentUser(element.id) || !isSuperAdmin()">
                            @for (option of userService.input_options(); track $index) {
                                <option [defaultSelected]="option === userService.getUserRole(element)"
                                        value="{{option}}">
                                    {{ option }}
                                </option>
                            }
                        </select>
                    </td>
                    <td>
                        <button class="action-btn" (click)="editUserDetails(element.id)"><i class="bi bi-pencil"></i>
                        </button>
                        <button class="mfa-enabled-btn" (click)="disableUserMfa(element.id, false)"
                                [disabled]="!element.mfaEnabled || element.id === userService.loggedUser().id">Clear MFA
                        </button>
                    </td>
                </tr>
            }
        </table>
    </div>
</section>
