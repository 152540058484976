import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FileServiceRecordModel} from "../models/FileServiceRecord-model";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {CurrentPartner, PartnersService} from "./Partners.service";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {NotificationService} from "./Notification.service";

@Injectable({
    providedIn: 'root'
})

export class FileService {
    private url = `${this.API_URL}`;
    public fileServiceTableIsLoading = signal<boolean>(false);


    public fileRecord = signal<FileServiceRecordModel>(new FileServiceRecordModel());
    public displayedColumns = signal<(keyof FileServiceRecordModel)[]>([]);
    public displayedTableHeaders = signal<string[]>([]);
    public fileServiceRecords = signal<FileServiceRecordModel[]>([]);

    public pageNumber = signal<number>(0);
    private pageSize = signal<number>(50);
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "importid", direction: "desc"});

    public searchString = signal<string>('');
    public selectedFilters = signal<SearchFilterModel[]>([]);
    public status = signal<string | undefined>(undefined);
    public startDate = signal<string | undefined>(undefined);
    public endDate = signal<string | undefined>(undefined);

    public openAdvanceSettings = signal<boolean>(false);

    partnersService = inject(PartnersService);
    deleteService = inject(DeleteFileFromDatabaseService);
    notificationService = inject(NotificationService);
    httpClient = inject(HttpClient);
    dialog = inject(MatDialog);

    constructor(@Inject('API_URL') private API_URL: string, @Inject('LOCAL_FILE_KEY') public fileServiceFilter: string) {
    }

    openAdvancedSearchSettings() {
        this.openAdvanceSettings.set(!this.openAdvanceSettings());
    }

    closeAdvancedSettingsModal(event: boolean) {
        this.openAdvanceSettings.set(event);
    }

    restartTableValue() {
        this.displayedColumns.set([]);
        this.displayedTableHeaders.set([])
        this.pageNumber.set(0);
        this.partnersService.pageNumber.set(0);
        this.fileServiceRecords.set([]);
        this.searchString.set('');
    }

    getTableHeaderNames() {
        let objectKeys = Object.keys(this.fileRecord());
        for (let key of objectKeys) {
            this.displayedColumns().push(key as keyof FileServiceRecordModel)
        }
        this.displayedTableHeaders.set([...this.displayedColumns(), 'actions']);
    }

    triggerLoadFileRecordsRequest() {
        return this.httpClient.get<FileServiceRecordModel[]>(`${this.url}/imports/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        })
    }

    loadFileRecords() {
        this.fileServiceTableIsLoading.set(true);
        this.triggerLoadFileRecordsRequest().subscribe(response => {
            this.fileServiceRecords.set(response["content"]);
            this.fileServiceTableIsLoading.set(false);
        });
    }

    triggerFileRecordsByClientNumberRequest(clientNumber: string) {
        return this.httpClient.get<FileServiceRecordModel[]>(`${this.url}/imports/allByClientNumber`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                clientNumberFilter: clientNumber
            }
        })
    }

    loadFileRecordsByClientNumber(clientNumber: string) {
        this.fileServiceTableIsLoading.set(true);
        this.triggerFileRecordsByClientNumberRequest(clientNumber).subscribe(response => {
            this.fileServiceRecords.set([...response["content"]]);
            this.fileServiceTableIsLoading.set(false);
        });
    }


    triggerListImportsFilteredRequest(filterOptions: SearchFilterModel[]) {
        return this.httpClient.post<FileServiceRecordModel[]>(`${this.url}/imports/listAllImportsFiltered`, filterOptions, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
            },
        })
    }

    listImportsFiltered(filterOptions: SearchFilterModel[]) {
        this.fileServiceTableIsLoading.set(true);
        this.triggerListImportsFilteredRequest(filterOptions).subscribe(response => {
            this.fileServiceRecords.set(response);
            this.fileServiceTableIsLoading.set(false);
        });
    }

    getAllFileServiceRecords() {
        if (this.startDate() && this.endDate()) {
            this.getStatisticsRecordsBetweenTwoDates(this.startDate(), this.endDate(), this.status());
        } else {
            this.loadFileRecords();
        }
    }

    getAllImportsForPartners() {
        if (this.startDate() && this.endDate()) {
            this.fileServiceTableIsLoading.set(true);
            this.partnersService.getPartnerStatisticsBetweenTwoDates(this.startDate(), this.endDate(), this.status()).subscribe((response) => {
                this.fileServiceRecords.set([...response]);
                this.fileServiceTableIsLoading.set(false);
            });
        } else {
            this.getPartnerImportsList();

        }
    }

    getPartnerImportsList() {
        this.fileServiceTableIsLoading.set(true);
        this.partnersService.getAllImportsForPartners().subscribe((response) => {
            this.fileServiceRecords.set(response["content"])
            this.fileServiceTableIsLoading.set(false);
        });
    }

    getStatisticsRecordsBetweenTwoDatesRequest(startDate: string, endDate: string, status: string) {
        return this.httpClient.get<FileServiceRecordModel[]>(`${this.url}/statistics/statisticsRecordsBetweenTwoDatesByStatus/${this.pageNumber()}/${this.pageSize()}/${this.sortDirectionValues().active}`, {
            params: {
                "start date": startDate,
                "end date": endDate,
                "status": status,
            }
        })
    };

    getStatisticsRecordsBetweenTwoDates(startDate: string, endDate: string, status: string) {
        this.fileServiceTableIsLoading.set(true);
        this.getStatisticsRecordsBetweenTwoDatesRequest(startDate, endDate, status).subscribe(response => {
            this.fileServiceRecords.set([...response]);
            this.fileServiceTableIsLoading.set(false);
        });
    }

    deleteItemFromTable(id: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteService.deleteFileFromDatabase(id, tableName).subscribe((response) => {
                    if (response.status === 200) {
                        this.fileServiceRecords.update((fileRecords) => fileRecords.filter((file) => +file.importid !== id));
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error["message"]}`,);
                });
            }
        })
    }

    onScroll() {
        switch (this.partnersService.currentPartner) {
            case CurrentPartner.DynoChipTuningFiles : {
                if (this.searchString().length === 0 && this.selectedFilters().length == 0) {
                    if (this.startDate() && this.endDate()) {
                        this.increasePageNumber();
                        this.getStatisticsRecordsBetweenTwoDatesRequest(this.startDate(), this.endDate(), this.status()).subscribe((response) =>
                            this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response]));
                    } else {
                        this.increasePageNumber();
                        this.triggerLoadFileRecordsRequest().subscribe(response => {
                            this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response["content"]]);
                        });
                    }
                }
                if (this.searchString().length >= 3 && this.selectedFilters().length === 0) {
                    this.increasePageNumber();
                    this.triggerFileRecordsByClientNumberRequest(this.searchString().trim()).subscribe(response => {
                        this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response["content"]]);
                    });
                }
                if (this.selectedFilters().length > 0) {
                    this.increasePageNumber();
                    this.triggerListImportsFilteredRequest(this.selectedFilters()).subscribe(response => {
                        this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response]);
                    });
                }
                break;

            }

            case CurrentPartner.ATMChipTunings: {
                if (!this.searchString() && this.selectedFilters().length == 0) {
                    if (this.startDate() && this.endDate()) {
                        this.partnersService.loadNewPage();
                        this.partnersService.getPartnerStatisticsBetweenTwoDates(this.startDate(), this.endDate(), this.status()).subscribe(response => {
                            this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response])
                        });
                    } else {
                        this.partnersService.loadNewPage();
                        this.partnersService.getAllImportsForPartners().subscribe(value => {
                            this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...value["content"]]);
                        });
                    }
                }
                if (this.selectedFilters().length > 0) {
                    this.partnersService.loadNewPage();
                    this.partnersService.getAllImportsForPartnersFiltered(this.selectedFilters()).subscribe(response => {
                        this.fileServiceRecords.update((fileRecords) => [...fileRecords, ...response]);
                    });
                }
                break
            }

        }


    }

    changeSelectFilters(selectedFilters: SearchFilterModel[]) {
        this.selectedFilters.set(selectedFilters);
        this.fileServiceRecords.set([]);
        switch (this.partnersService.currentPartner) {

            case CurrentPartner.DynoChipTuningFiles : {

                this.listImportsFiltered(this.selectedFilters());
                break;
            }

            case CurrentPartner.ATMChipTunings: {

                this.partnersService.getAllImportsForPartnersFiltered(this.selectedFilters()).subscribe(response => {
                    this.fileServiceRecords.set(response);
                })
                break

            }
        }
    }

    onSearchInput(searchString: string) {
        this.searchString.set(searchString);
        if (this.searchString().length >= 3) {
            this.restartFileServiceRecords();
            this.loadFileRecordsByClientNumber(this.searchString().trim());
        } else {
            this.loadFileRecords();
        }
    }

    downloadFileFromTable(importId: number, fileName: string) {
        const toastObj = this.notificationService.showInfoMessage(`Preparing your ${fileName} file...`, 'Preparing..');

        switch (this.partnersService.currentPartner) {
            case CurrentPartner.DynoChipTuningFiles: {
                this.notificationService.clear(toastObj.toastId);
                this.downloadClientImport(importId, fileName);
                break;
            }
            case CurrentPartner.ATMChipTunings: {
                this.partnersService.downloadPartnerClientImport(importId, fileName);
                this.notificationService.clear(toastObj.toastId);
            }
        }
    }

    downloadClientImport(importId: number, fileName: string) {
        return this.httpClient.post<{ url: string, message: string }>(`${this.url}/imports/downloadClientImport`, {}, {
            observe: "response", params: {
                importId: importId
            }
        }).subscribe(response => {
            if (response.status === 200) {
                window.location.href = response.body.url;
                this.notificationService.showSuccessMessage(`<div><span>Your file ${fileName} is downloaded</span></div>`, "Success");
            } else {
                this.notificationService.showErrorMessage('Something went wrong with file.', 'Failed');
            }
        })
    }

    restartFileServiceRecords() {
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.partnersService.pageNumber.set(0);
        this.fileServiceRecords.set([]);
    }

    onRefreshTable() {
        this.searchString.set('');
        this.fileServiceTableIsLoading.set(true);
        const savedFiltersTable = localStorage.getItem(this.fileServiceFilter);
        console.log(savedFiltersTable, 'set filters')

        switch (this.partnersService.currentPartner) {
            case CurrentPartner.DynoChipTuningFiles: {
                if (savedFiltersTable) {
                    console.log(savedFiltersTable, 'file service filters')
                    const filtersFromLocalStorage = JSON.parse(savedFiltersTable) as SearchFilterModel[];
                    console.log(filtersFromLocalStorage, 'filters from local storage')
                    this.restartFileServiceRecords();

                    this.triggerListImportsFilteredRequest(filtersFromLocalStorage).subscribe(response => {
                        this.fileServiceRecords.set([...response]);
                        this.fileServiceTableIsLoading.set(false);
                    });
                } else {
                    this.restartFileServiceRecords();
                    this.getAllFileServiceRecords();
                }
                break;
            }
            case CurrentPartner.ATMChipTunings : {
                if (savedFiltersTable) {
                    const filtersFromLocalStorage = JSON.parse(savedFiltersTable) as SearchFilterModel[];
                    this.restartFileServiceRecords();
                    this.partnersService.getAllImportsForPartnersFiltered(filtersFromLocalStorage).subscribe(response => {
                        this.fileServiceRecords.set([...response]);
                        this.fileServiceTableIsLoading.set(false);
                    })
                } else {
                    this.restartFileServiceRecords();
                    this.getAllImportsForPartners();
                }
            }
        }

    }

    changeFileServiceTableSortDirection(sortEvent: SortDirectionValuesModel, tableElement: HTMLElement) {
        if (tableElement) {
            tableElement.scrollTop = 0;
        }

        switch (this.partnersService.currentPartner) {
            case CurrentPartner.DynoChipTuningFiles: {
                this.sortDirectionValues.set(sortEvent);
                this.pageNumber.set(0);
                this.loadFileRecords();
                break;
            }
            case CurrentPartner.ATMChipTunings: {
                this.partnersService.sortDirectionValues.set(sortEvent);
                this.partnersService.pageNumber.set(0);
                this.getPartnerImportsList();
                break;
            }
        }

    }

    isTableEmpty() {
        return this.fileServiceRecords().length === 0;
    }

    increasePageNumber() {
        this.pageNumber.update((pageNum) => pageNum + 1);
    }
}
