import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-restart-dialog',
  templateUrl: './restart-dialog.component.html',
  styleUrls: ['./restart-dialog.component.css']
})
export class RestartDialogComponent {


  constructor(public dialogRef: MatDialogRef<RestartDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  confirmedOption() {
    this.dialogRef.close(true);
  }

  canceledOption() {
    this.dialogRef.close(false);
  }
}
