import {Component, inject, OnInit,} from '@angular/core';
import {TuningNamesTablesService} from "../../service/Tuning-names-tables.service";
import {TuningNameModel} from "../../models/Tuning-Name-model";
import {fadeInAnimation} from "../../animations/fadeInAnimation";



@Component({
    selector: 'app-tuning-names-table',
    templateUrl: './tuning-names-table.component.html',
    styleUrls: ['./tuning-names-table.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class TuningNamesTableComponent implements OnInit {

    public tuningNamesService = inject(TuningNamesTablesService);


    ngOnInit() {
        this.getTuningNames();
    }

    getTuningNames() {
        this.tuningNamesService.getTuningNamesTable()
    }

    onAddItems() {
        this.tuningNamesService.openNewRow();
    }

    saveNewRow() {
        this.tuningNamesService.addTuningNames();
    }

    deleteRow() {
        this.tuningNamesService.editInputRow.set(false);
    }

    onEditItems(index: number) {
        this.tuningNamesService.tuningIndex.set(index);
    }

    onSaveItems(element: TuningNameModel) {
        this.tuningNamesService.updateTuningName(element)
    }

    onDeleteItems(element: TuningNameModel) {
       this.tuningNamesService.deleteTuningNames(element)
    }

    onExitItems() {
        this.tuningNamesService.tuningIndex.set(null);
    }

}
