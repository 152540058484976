<section class="main">
    <div class="head-button">
        <button class="r-btn" (click)="backToMainTable()"><i class="icon bi bi-arrow-left"></i></button>
        <span class="head-text">Back</span>
    </div>
    <div class="table-wrapper-header">
        <h2 class="main-title">User Statistics details</h2>
        <div>
            <app-search-bar [value]="'WinOls'" (searchValue)="onSearchInput($event)"></app-search-bar>
        </div>
    </div>
    <div class="table-container"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         (scrolled)="onScroll()">
        <table>
            <tr class="header">
                <td class="empty-td"></td>
                @for (header of displayedTableHeaders(); track $index) {
                    <th (click)="changeSortDirection(header)">
                        {{ header }}
                        <span class="sort-icon">
                            @if (userStatisticsService.sortDirectionValues().direction === "asc") {
                                <i class="bi bi-arrow-up"></i>
                            } @else {
                                <i class="bi bi-arrow-down"></i>
                            }
                        </span>
                    </th>
                }
            </tr>
            @for (element of userStatisticsService.userStatisticsMasterDetails(); track $index) {
                <ng-container class="row-group">
                    <tr class="body-columns"
                        [class.disabled-row]="element.isDeleted">
                        <td>
                            <button (click)="toggleExpandedRow(element, $event)" class="arrow-button">
                                <i class="bi bi-chevron-up" *ngIf="expandedElement === element"></i>
                                <i class="bi bi-chevron-down" *ngIf="expandedElement !== element"></i></button>
                        </td>
                        @for (header of displayedTableHeaders(); track $index) {
                            <td (click)="!element.isDeleted && header === 'masterId' && navigateToMasterId($event, +element.masterId)"
                                [ngClass]="header === 'masterId' ? 'masterId' : ''"
                                [matTooltip]="element.isDeleted ? 'This master has been deleted': ''"
                                [matTooltipPosition]="tooltipPosition"
                            >
                                {{ element[header] }}
                            </td>
                        }
                    </tr>
                    @if (expandedElement === element) {
                        <tr>
                            <td [attr.colspan]="8">
                                <div class="expanded-content">
                                    <table class="sub-table">
                                        <tr>
                                            <th>Status</th>
                                            <th>Work Time</th>
                                            <th>Version Name</th>
                                            <th>Version Comment</th>
                                            <th>Win Ols Reference</th>
                                            <th>Version Id</th>

                                        </tr>
                                        @for (detail of userStatisticsService.masterStatisticsTableDetails(); track $index) {
                                            <tr class="expanded-content-row"
                                                [ngClass]="element.isDeleted ? 'disabled-row' : '' "
                                                (click)="!element.isDeleted && openMasterDetailsPageWithMasterId(+element.masterId, detail.versionName)">
                                                <td>{{ detail.status }}</td>
                                                <td>{{ detail.workTime }}</td>
                                                <td>{{ detail.versionName }}</td>
                                                <td>{{ detail.versionComment }}</td>
                                                <td>{{ detail.winOlsReference }}</td>
                                                <td>{{ detail.versionId }}</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </td>
                        </tr>
                    }
                </ng-container>
            }


        </table>
    </div>
</section>


