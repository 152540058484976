import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalService} from "../service/pure-original.service";
import {PureOriginalsMatchingDetailsModel} from "../models/PureOriginalsMatchingDetails-model";
import {PureOriginalMatchingModel} from "../models/PureOriginalMatching-model";
import {DownloadFileFromDatabaseService} from "../service/DownloadFileFromDatabase.service";
import {ToastrService} from "ngx-toastr";
import {Location} from "@angular/common";

@Component({
  selector: 'app-pure-originals-matching-details-table',
  templateUrl: './pure-originals-matching-details-table.component.html',
  styleUrls: ['./pure-originals-matching-details-table.component.css']
})
export class PureOriginalsMatchingDetailsTableComponent implements OnInit{


  public pureOriginalMatchingDetails = new PureOriginalMatchingModel();
  public displayedColumns: (keyof PureOriginalMatchingModel)[] = [];
  public pureOriginalsMatchingDetails: PureOriginalsMatchingDetailsModel[] = [];
  public versionId: number;
  public versionName: string;
  public dcf: string;
  private partiallyTuned: string;
  public ecuBuild: string;
  private tableName: string = "PURE_ORIGINAL_MATCHING";
  public filesToDownload: number[] = [];
  public ecuProducer: string;

  constructor(private route: ActivatedRoute, private router: Router, private pureOriginalsService: PureOriginalService, private downloadService: DownloadFileFromDatabaseService, private toastr: ToastrService, private location: Location) {
    this.route.queryParams.subscribe((params) => {
      this.partiallyTuned = params['partiallyTuned'];
      this.versionId = params['versionId'];
      this.dcf = params['dcf'];
      this.versionName = params['versionName'];
      this.ecuBuild = params['ecuBuild'];
      this.ecuProducer = params['ecuProducer'];
    })
  }

  ngOnInit() {
    this.pureOriginalsMatchingDetails = [];
    this.getTableHeaderNames();
    const isPartiallyTuned = this.partiallyTuned === 'true';

    if (!isPartiallyTuned) {
      this.getPureOriginalsByVersionId(this.versionId);
    } else {
      this.getPureOriginalPartiallyTunedByVersionId(this.versionId);
    }

  }


  getTableHeaderNames() {
    let objectKeys = Object.keys(this.pureOriginalMatchingDetails);
    for (let key of objectKeys) {
      this.displayedColumns.push(key as keyof PureOriginalMatchingModel)
    }
  };
  getPureOriginalPartiallyTunedByVersionId (versionId: number) {
    this.pureOriginalsService.getPartiallyTunedPureOriginalsByVersionId(versionId).subscribe(response => {
      this.pureOriginalsMatchingDetails = [...this.pureOriginalsMatchingDetails, ...response];
    })
  }
  getPureOriginalsByVersionId (versionId: number) {
    this.pureOriginalsService.getPureOriginalsByVersionId(versionId).subscribe(response => {
      this.pureOriginalsMatchingDetails = [...this.pureOriginalsMatchingDetails, ...response];
    })
  }
  onRefreshTable () {
    this.pureOriginalsMatchingDetails = [];
    this.pureOriginalsService.pageNumber.set(0);
    this.getPureOriginalsByVersionId(this.versionId);
  }
  onScroll() {
    // this.getPureOriginalsByVersionId(this.versionId);
  }

  downloadVersionFromPureOriginals (id: number, winOls: string) {


      this.filesToDownload.push(id);

      const toastObj = this.toastr.info(`Preparing your ${winOls} file...`, 'Preparing..', {
        disableTimeOut: true, progressBar: true, progressAnimation: 'increasing'
      });

      this.downloadService.downloadFileFromDatabase(id, this.tableName).subscribe(response => {

        const index = this.filesToDownload.indexOf(id);
        this.filesToDownload.splice(index);

        if (response.status === 200) {

          this.toastr.clear(toastObj.toastId);
          window.location.href = response.body.url;

          this.toastr.success(`<div><span>Your file ${winOls} is downloaded</span></div>`, "Success", {
            timeOut: 5000,
            closeButton: false,
          });
        }

      }, error => {
        this.toastr.clear(toastObj.toastId);
        this.toastr.error(`${error.error['message']}`, 'Failed', {
          disableTimeOut: false
        });
      })

  }
  openPureOriginalDetails (id: number, versionName: string) {
    this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id, versionName: versionName}});
  }

    backToMainTable() {
      this.location.back();
    }
}
