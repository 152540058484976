import {Component, computed, ElementRef, HostListener, inject, OnInit, signal, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";
import {CarImageService} from "../../service/CarImage.service";
import {AllMasterDatabaseModel} from "../../models/AllMasterDatabase-model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UpdateMasterModel} from "../../models/UpdateMaster-model";
import {AuthService} from "../../service/auth.service";
import {MatTooltip} from "@angular/material/tooltip";


@Component({
    selector: 'app-master-details-component',
    templateUrl: './master-details.component.html',
    styleUrls: ['./master-details.component.css']
})
export class MasterDetailsComponent implements OnInit {

    public tooltipMessage = signal<string>("")
    @ViewChild('historyTooltip') historyTooltip: MatTooltip;

    allMastersService = inject(AllMastersDatabaseService);
    imageService = inject(CarImageService);
    route = inject(ActivatedRoute);
    private previousFormValues: any;
    public isVisibleButton = signal<boolean>(false);
    public showMore: boolean = false;
    private authService = inject(AuthService);
    public elRef = inject(ElementRef);


    masterDetailsFormGroup = new FormGroup({
        winOLSReference: new FormControl(''),
        uploadedBy: new FormControl(''),
        clientName: new FormControl(''),
        clientNumber: new FormControl(''),
        clientLicencePlace: new FormControl(''),
        vehicleType: new FormControl(''),
        vehicleProducer: new FormControl(''),
        vehicleSeries: new FormControl(''),
        vehicleModel: new FormControl(''),
        vehicleModelYear: new FormControl(''),
        vehicleBuild: new FormControl(''),
        vehicleCharacteristic: new FormControl(''),
        vin: new FormControl(''),
        ecuUse: new FormControl(''),
        ecuProducer: new FormControl(''),
        ecuBuild: new FormControl(''),
        ecuProdNr: new FormControl(''),
        ecuSoftwareVersion: new FormControl(''),
        ecuSoftwareVersionVersion: new FormControl(''),
        ecuStgNr: new FormControl(''),
        ecuProcessor: new FormControl(''),
        engineName: new FormControl(''),
        engineDisplacement: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
        engineMaxTorque: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
        engineEmission: new FormControl(''),
        engineEmissionStandard: new FormControl(''),
        engineProducer: new FormControl(''),
        engineType: new FormControl(''),
        engineGear: new FormControl(''),
        engineTransmission: new FormControl(''),
        outputPs: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
        outputKw: new FormControl('', Validators.pattern(/^\d+(\.\d+)?$/)),
        readingHardware: new FormControl(''),
        resellerProjectDetails: new FormControl(''),
        resellerProjectType: new FormControl(''),
        resellerCredits: new FormControl(''),
        additionalInfo: new FormControl(''),
        projectCreatedBy: new FormControl(''),
        projectChangedBy: new FormControl(''),
        projectType: new FormControl(''),
        projectComment: new FormControl(''),
        versionComment: new FormControl(''),
        softwareNumber: new FormControl(''),
        creditType: new FormControl(''),
        userDef1: new FormControl(''),
        userDef2: new FormControl(''),
        userDef3: new FormControl(''),
        userDef4: new FormControl(''),
        userDef5: new FormControl(''),
        userDef6: new FormControl(''),
        userDef7: new FormControl(''),
        userDef11: new FormControl(''),
        userDef12: new FormControl(''),
        userDef13: new FormControl(''),
        userDef14: new FormControl(''),
        userDef15: new FormControl(''),
        userDef16: new FormControl(''),
        userDef17: new FormControl(''),
        userDef18: new FormControl(''),
        userDef19: new FormControl(''),
        userDef20: new FormControl(''),
        dateUploaded: new FormControl(''),
    });

    public editFlags = Object.keys(this.masterDetailsFormGroup.controls).reduce((flags, key) => {
        flags[key] = false;
        return flags;
    }, {} as { [key: string]: boolean });

    ngOnInit() {
        this.restartMasterItem();
        this.getIdParams();
        this.getMasterItemDetails();
    }

    public masterDetailObject = computed(() => {
        return this.allMastersService.masterItem();
    })


    editFormValue(masterDetails: AllMasterDatabaseModel) {
        this.masterDetailsFormGroup.setValue({
            winOLSReference: masterDetails.winOLSReference,
            uploadedBy: masterDetails.uploadedBy,
            clientName: masterDetails.clientName,
            clientNumber: masterDetails.clientNumber,
            clientLicencePlace: masterDetails.clientLicencePlace,
            vehicleType: masterDetails.vehicleType,
            vehicleProducer: masterDetails.vehicleProducer,
            vehicleSeries: masterDetails.vehicleSeries,
            vehicleModel: masterDetails.vehicleModel,
            vehicleModelYear: masterDetails.vehicleModelYear,
            vehicleBuild: masterDetails.vehicleBuild,
            vehicleCharacteristic: masterDetails.vehicleCharacteristic,
            vin: masterDetails.vin,
            ecuUse: masterDetails.ecuUse,
            ecuProducer: masterDetails.ecuProducer,
            ecuBuild: masterDetails.ecuBuild,
            ecuProdNr: masterDetails.ecuProdNr,
            ecuSoftwareVersion: masterDetails.ecuSoftwareVersion,
            ecuSoftwareVersionVersion: masterDetails.ecuSoftwareVersionVersion,
            ecuStgNr: masterDetails.ecuStgNr,
            ecuProcessor: masterDetails.ecuProcessor,
            engineName: masterDetails.engineName,
            engineDisplacement: masterDetails.engineDisplacement,
            engineMaxTorque: masterDetails.engineMaxTorque,
            engineEmission: masterDetails.engineEmission,
            engineEmissionStandard: masterDetails.engineEmissionStandard,
            engineProducer: masterDetails.engineProducer,
            engineType: masterDetails.engineType,
            engineGear: masterDetails.engineGear,
            engineTransmission: masterDetails.engineTransmission,
            outputPs: masterDetails.outputPs,
            outputKw: masterDetails.outputKw,
            readingHardware: masterDetails.readingHardware,
            resellerProjectDetails: masterDetails.resellerProjectDetails,
            resellerProjectType: masterDetails.resellerProjectType,
            resellerCredits: masterDetails.resellerCredits,
            additionalInfo: masterDetails.additionalInfo,
            projectCreatedBy: masterDetails.projectCreatedBy,
            projectChangedBy: masterDetails.projectChangedBy,
            projectType: masterDetails.projectType,
            projectComment: masterDetails.projectComment,
            versionComment: masterDetails.versionComment,
            softwareNumber: masterDetails.softwareNumber,
            creditType: masterDetails.creditType,
            userDef1: masterDetails.userDef1,
            userDef2: masterDetails.userDef2,
            userDef3: masterDetails.userDef3,
            userDef4: masterDetails.userDef4,
            userDef5: masterDetails.userDef5,
            userDef6: masterDetails.userDef6,
            userDef7: masterDetails.userDef7,
            userDef11: masterDetails.userDef11,
            userDef12: masterDetails.userDef12,
            userDef13: masterDetails.userDef13,
            userDef14: masterDetails.userDef14,
            userDef15: masterDetails.userDef15,
            userDef16: masterDetails.userDef16,
            userDef17: masterDetails.userDef17,
            userDef18: masterDetails.userDef18,
            userDef19: masterDetails.userDef19,
            userDef20: masterDetails.userDef20,
            dateUploaded: masterDetails.dateUploaded,
        });
        this.previousFormValues = this.masterDetailsFormGroup.value;
    }

    restartMasterItem() {
        this.allMastersService.masterItem.set({} as AllMasterDatabaseModel);
    }

    getIdParams() {
        this.route.params.subscribe(value => {
            this.allMastersService.masterId.set(value['id']);
        })
    }

    getMasterItemDetails() {
        this.allMastersService.getMasterById().subscribe((value) => {
            this.editFormValue(value);
        });
    }

    uploadIniFile($event: any) {
        const file = $event.valueOf()["fileToUpload"];
        this.allMastersService.uploadMasterIniFile(file);
    }

    deleteMasterItem(masterItemId: number, tableName: string) {
        this.allMastersService.deleteMasterItem(masterItemId, tableName);
    }

    isDownloadInProgress(id: number) {
        return this.allMastersService.isDownloadInProgress(id);
    }

    downloadMasterItem(id: number, winOls: string, tableName: string) {
        this.allMastersService.downloadMastersFromTable(id, winOls, tableName);
    }

    editMasterItem(masterItem: AllMasterDatabaseModel, id: number) {
        this.allMastersService.updateMasterDetails(masterItem, id);
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }


    toggleEdit(field: string | null, $event: any) {
        $event.stopPropagation();
        if (field === null) {
            this.closeAllEdits();
        } else {
            this.isVisibleButton.set(true);
            this.editFlags[field] = !this.editFlags[field];

            if (this.editFlags[field]) {
                const updatedValue = this.masterDetailObject()?.[field];
                if (updatedValue !== undefined) {
                    this.masterDetailsFormGroup.get(field)?.setValue(updatedValue);
                }
            }
        }
    }

    closeAllEdits() {
        this.isVisibleButton.set(false);
        Object.keys(this.editFlags).forEach(key => {
            this.editFlags[key] = false;
        });
    }

    toggleShowMore() {
        this.showMore = !this.showMore;
    }


    submitForm() {
        if (this.masterDetailsFormGroup.valid) {
            const formValues = this.masterDetailsFormGroup.value as UpdateMasterModel;
            const id = this.masterDetailObject().id;
            try {
                this.allMastersService.triggerMasterUpdateRequest(+id, formValues).subscribe((response) => {
                    this.allMastersService.masterItem.set(response.body);
                    this.editFormValue(response.body as AllMasterDatabaseModel)
                })

            } catch (error) {
                this.cancelForm();
            }
            this.closeAllEdits();
        } else {
            return;
        }
    }

    cancelForm() {
        this.masterDetailsFormGroup.reset(this.previousFormValues);
        this.closeAllEdits()
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
        const element = this.elRef.nativeElement.querySelector('.matching-item-text');
        if (element && !element.contains(event.target as Node)) {
            this.closeAllEdits();
        }
    }

    isSuperAdmin () {
        return this.authService.isSuperAdmin();
    }

    openHistoryModal (masterId: number) {


        this.allMastersService.getMasterStatisticsHistory(masterId).subscribe((response) => {

            if (response.status === 200) {
                if (response.body.length > 0) {
                    this.allMastersService.showMasterStatisticsHistory(masterId, response.body);
                } else {
                    this.tooltipMessage.set("No history available for this PureOriginal");

                    setTimeout(() => {
                        this.historyTooltip.show();
                    }, 0);
                }

            }
        });

    }


}

export class CustomMasterUploadFileValidations {
    message: string;
    hasError: boolean;
}
