<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()">
                <i class="icon bi bi-arrow-clockwise"></i>
            </button>
        </div>
    </div>

    <ng-container *ngIf="pureOriginalResultService.allPureOriginalResults().length > 0; else loading">
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="onScroll()">

            <!-- Angular Material table -->
            <table mat-table [dataSource]="pureOriginalResultService.allPureOriginalResults()"
                   matSort matSortDisableClear
                   [matSortActive]="pureOriginalResultService.sortDirectionValues().active"
                   [matSortDirection]="pureOriginalResultService.sortDirectionValues().direction"
                   (matSortChange)="changePureOriginalResultsTableSortDirection($event)">

                <ng-container *ngFor="let column of pureOriginalResultService.displayedOriginalColumns()" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
                    <td mat-cell *matCellDef="let element" [ngClass]="element.ecuUse === 'TCU' ? 'blue' : ''">
                        {{ element[column] | shorten: 1000 }}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="pureOriginalResultService.displayedTableHeaders()"></tr>
                <tr mat-row *matRowDef="let element; columns: pureOriginalResultService.displayedTableHeaders()"></tr>
            </table>
        </div>
    </ng-container>

    <ng-template #loading>
        <div *ngIf="pureOriginalResultService.allPureOriginalResults().length < 1">
            <app-skeleton-table></app-skeleton-table>
        </div>
    </ng-template>
</section>
