<div class="statistic-wrapper">
  <div class="main-display">
    <div class="main-content">
<!--      <div class="main-title">-->
<!--        <h3>Statistics dashboard</h3>-->
<!--      </div>-->
      <div class="main-title second-title">
        <h3>System Infos</h3>
      </div>
      <div class="system-info-container">
        <app-system-info-chart></app-system-info-chart>
      </div>
    </div>
  </div>
</div>
