import { Component } from '@angular/core';
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
  selector: 'app-pure-originals-results',
  templateUrl: './pure-originals-results.component.html',
  styleUrls: ['./pure-originals-results.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class PureOriginalsResultsComponent {

}
