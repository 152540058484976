import {Component, inject, OnInit} from '@angular/core';
import {UserModel} from "../../models/User-model";
import {UserService} from "../../service/user.service";
import {Router} from "@angular/router";
import {AuthService} from "../../service/auth.service";

@Component({
    selector: 'app-all-users-table',
    templateUrl: './all-users-table.component.html',
    styleUrls: ['./all-users-table.component.css']
})
export class AllUsersTableComponent implements OnInit {

    public userService = inject(UserService);
    public authService = inject(AuthService);
    private router = inject(Router);


    ngOnInit() {
        this.userService.getAllUsers();
    }

    changeUserStatus(user: UserModel, event: Event) {
        const target = event.target as HTMLInputElement;
        this.userService.updateUserStatus(user, target.value);
    }

    changeUserRole(user: UserModel, event: Event) {
        const target = event.target as HTMLInputElement;
        this.userService.updateUserAuthority(user, target.value)    ;
    }

    editUserDetails(userId: number) {
        this.router.navigate(['/app/user-settings'], {queryParams: {id: userId}});
    }

    isCurrentUser(elementId: number): boolean {
        return this.userService.loggedUser().id === elementId;
    }
    disableUserMfa(userId: number, mfaEnabled: boolean) {
        console.log(userId);
        this.userService.disableUserMfa(userId, mfaEnabled);
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }
}
