import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PureOriginalMatchingModel} from "../../../../models/PureOriginalMatching-model";
import {DownloadFileFromDatabaseService} from "../../../../service/DownloadFileFromDatabase.service";
import {ToastrService} from "ngx-toastr";
import {PureOriginalResultsService} from "../../../../service/PureOriginalResults.service";

@Component({
  selector: 'app-pure-original-ecu-details',
  templateUrl: './pure-original-ecu-details.component.html',
  styleUrls: ['./pure-original-ecu-details.component.css']
})
export class PureOriginalEcuDetailsComponent implements OnInit {


  public allPureOriginalMatching: PureOriginalMatchingModel = new PureOriginalMatchingModel();
  public displayedOriginalColumns: (keyof PureOriginalMatchingModel)[] = [];

  public from: number;
  public to: number;
  public ecuBuild: string;
  public isLoading: boolean = false;
  private tableName: string = 'pure original matching';
  public filesToDownload: number[] = [];

  constructor(public pureOriginalResultsService: PureOriginalResultsService, private route: ActivatedRoute, private router: Router, private downloadService: DownloadFileFromDatabaseService, private toastr: ToastrService) {}

  ngOnInit() {


    this.isLoading = true;
    this.route.queryParams.subscribe(value => {
      this.from = value['from'];
      this.to = value['to'];
      this.ecuBuild = value['ecuBuild'];
      this.getPureOriginalMatchingData()
    })
    this.getTableKeysForPureOriginalsResults();
  }

  // ngOnDestroy() {
  //   this.store.dispatch(new PureOriginalMatchingActions.RefreshPureOriginalMatchingContent())
  // }

  getPureOriginalMatchingData() {
    if (this.from == 1 && this.to == 1) {
      this.pureOriginalResultsService.getPureOriginalCompletelyMatchedEcu(this.ecuBuild);
    }
    if (this.from < 1 && this.to <= 1) {
      this.pureOriginalResultsService.getPureOriginalMatchingStatsByHitRateAndEcuBuild(this.from, this.to, this.ecuBuild);
    }
    this.isLoading = false;
  }

  goBackOneStep() {
    this.router.navigate(['../'], {relativeTo: this.route, queryParams: {from: this.from, to: this.to}});
  }

  goBackTwoSteps() {
    this.router.navigate(['../../../../'], {relativeTo: this.route});
  }

  getTableKeysForPureOriginalsResults() {
    let objectKeys = Object.keys(this.allPureOriginalMatching)
    for (let key of objectKeys) {
      this.displayedOriginalColumns.push(key as keyof PureOriginalMatchingModel);
    }
  }

  onScroll() {
    this.pureOriginalResultsService.increasePageNumber();
    this.getPureOriginalMatchingData();
  }

  isDownloadInProgress(id) {
    return this.filesToDownload.includes(id);
  }

  prepareFileToDownload(id: number, winOls: string) {
    this.filesToDownload.push(id);

    const toastObj = this.toastr.info(`Preparing your ${winOls} file...`, 'Preparing..', {
      disableTimeOut: true, progressBar: true, progressAnimation: 'increasing'
    });

    this.downloadService.downloadFileFromDatabase(id, this.tableName).subscribe(async response => {

      const index = this.filesToDownload.indexOf(id);
      this.filesToDownload.splice(index);

      if (response.status === 200) {

        this.toastr.clear(toastObj.toastId);

        this.toastr.success(`<div><span>Your file ${winOls} is ready to download</span><hr/><a href=${response.body.url} download">Download File</a></div>`, "Success", {
          disableTimeOut: true,
          enableHtml: true,
        });


      } else {
        this.toastr.error('Something went wrong with file.', 'Failed');
      }


      if (response.status === 500) {
        this.toastr.error("You can't download this file. ", 'Download failed');
      }

    })


  }

  openPureOriginalDetails(pureOriginalBaseId: number) {
    this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: pureOriginalBaseId}});
  }

}
