<div class="content">
    <form (ngSubmit)="onSubmitForm()" #form="ngForm">
        <label for="two-factor">Two-factor code</label>
        <input class="form-control"
               [(ngModel)]="twoFactorCode"
               required
               minlength="5"
               name="two-factor-code"
               id="two-factor"
               type="text"
        >
        <span class="description">Enter the code generated by your two-factor authentication app here.</span>
        <button class="custom-btn" type="submit" [disabled]="!twoFactorCode">Confirm</button>
    </form>
</div>
