import {Component, inject, OnInit} from '@angular/core';
import {WinOlsTransactionService} from "../service/winols-transaction.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: 'app-win-ols-transaction',
    templateUrl: './win-ols-transaction.component.html',
    styleUrl: './win-ols-transaction.component.css'
})
export class WinOlsTransactionComponent implements OnInit {

    winOlsTransactionService = inject(WinOlsTransactionService);
    route = inject(ActivatedRoute);
    router = inject(Router)

    ngOnInit() {
        this.resetTableHeaders();
        this.getAllTableHeaders();
        this.route.queryParams.subscribe(value => {
            this.winOlsTransactionService.startDate = new Date(value['startDate'])
            this.winOlsTransactionService.endDate = new Date(value['endDate'])
            this.winOlsTransactionService.status = value['status'];
            if (this.winOlsTransactionService.startDate && this.winOlsTransactionService.endDate && this.winOlsTransactionService.status) {
                this.winOlsTransactionService.getWinOlsDataRangeByStatus(this.winOlsTransactionService.startDate.toISOString(), this.winOlsTransactionService.endDate.toISOString(), this.winOlsTransactionService.status);
            } else {
                this.getAllWinOlsTransactions()
            }

        })
    }

    getAllTableHeaders() {
        this.winOlsTransactionService.getAllTableHeaderNames();
    }

    getAllWinOlsTransactions() {
        this.winOlsTransactionService.getAllWinOlsTransactions();
    }

    onRefreshTable() {
        const queryParams: Params = {};
        this.router.navigate([],
            {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: "",
            })
        this.winOlsTransactionService.onRefreshTable()
    }

    isTableEmpty() {
        return this.winOlsTransactionService.winOlsTransactions().length === 0;
    }

    resetTableHeaders() {
        this.winOlsTransactionService.resetTableHeaders()
    }

    downloadFileFromTable($event: any) {
        const trace = $event.valueOf()["trace"];
        const id = $event.valueOf()["id"];
        const route = $event.valueOf()["route"];

        this.winOlsTransactionService.downloadFileFromTable(trace, id, route);
    }

    changeSortDirection($event: any) {
        const sortDirection = $event.valueOf();
        this.winOlsTransactionService.changeSortDirection(sortDirection);
    }
}
