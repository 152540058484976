import {ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {UserService} from "../service/user.service";
import {ActivatedRoute} from "@angular/router";
import {UserModel} from "../models/User-model";
import {FormControl, FormGroup} from "@angular/forms";
import {fadeInAnimation} from "../animations/fadeInAnimation";
import {AuthService} from "../service/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {
    MfaDisableConfirmationDialogComponent
} from "../helpers/mfa-disable-confirmation-dialog/mfa-disable-confirmation-dialog.component";

@Component({
    selector: 'app-user-settings-page',
    templateUrl: './user-settings-page.component.html',
    styleUrls: ['./user-settings-page.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class UserSettingsPageComponent implements OnInit {
    public authority: string;
    public isEditing: boolean = false;
    dialog = inject(MatDialog);

    public isMfaModalOpened = signal<boolean>(false);

    userForm = new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        email: new FormControl(''),
        phoneNumber: new FormControl(''),
    })

    public userService = inject(UserService);
    private route = inject(ActivatedRoute);
    public authService = inject(AuthService);
    private cdr = inject(ChangeDetectorRef);

    ngOnInit() {
        if (this.authService.isAdmin()) {
            this.route.queryParams.subscribe(params => {
                this.getUserInfo(params['id']);
            })
        } else {
            this.userService.userInfo.set(this.userService.loggedUser());
        }
    }


    getUserInfo(userId: number) {
        this.userService.getUserById(userId);
    }

    editUserPersonalInfo(user: UserModel) {
        this.isEditing = !this.isEditing;
        this.userForm.patchValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
        })
        setTimeout(() => {
            this.scroll();
        }, 100);
    }

    private scroll() {
        let el = document.getElementById("scroll");
        el!.scrollIntoView({behavior: "smooth"});
    }

    onSubmitForm(id: number) {
        if (this.userService.loggedUser().id == id) {
            this.userService.editUserInformation(id, this.userForm.value.firstName, this.userForm.value.lastName, this.userForm.value.phoneNumber);
        } else {
            this.userService.editUserByAdmin(id, this.userForm.value.firstName, this.userForm.value.lastName, this.userForm.value.phoneNumber);
            this.cdr.markForCheck();

        }
    }

    toggleNotifications(): void {
        this.userService.getEnableNotification(!this.userService.loggedUser().emailNotification);
    }

    toggleMfa() {

        if (!this.userService.userInfo().mfaEnabled) {
            this.isMfaModalOpened.set(true)
            this.userService.mfaUpdate(!this.userService.userInfo().mfaEnabled);
        }



        if (this.userService.userInfo().mfaEnabled) {
            const dialogRef = this.dialog.open(MfaDisableConfirmationDialogComponent, {
                width: "350px",
                data: "Are you sure you want to disable authentication?"
            })
            dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.userService.mfaUpdate(false);
                }
            })
        }

    }

    closeMFAModal($event: any) {
        this.isMfaModalOpened.set($event.valueOf());
    }

    isMfaEnabled() {
        return this.userService.userInfo().mfaEnabled;
    }

    isCurrentUser(userId: number) {
        return this.userService.loggedUser().id === userId;
    }

    public isNotificationEnabled(): boolean {
        if (this.userService.loggedUser()) {
            return this.userService.loggedUser().emailNotification;
        }
        return false;
    }

}





