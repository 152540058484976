import {Component, inject, OnInit} from '@angular/core';
import {TodoListModel} from "../../models/todoList-model";
import {TodoListService} from "../../service/TodoList.service";
import {UserService} from "../../service/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-todolist',
    templateUrl: './todolist.component.html',
    styleUrls: ['./todolist.component.css']
})
export class TodolistComponent implements OnInit {

    todoListForm = new FormGroup({
        "id": new FormControl(0, Validators.required),
        "title": new FormControl('', Validators.required),
        "content": new FormControl('', Validators.required),
        "date": new FormControl(new Date(), Validators.required),
        "validation": new FormControl(false)
    })
    public todoListService = inject(TodoListService);
    public user = inject(UserService);

    ngOnInit() {
        this.getTodosList();
    }

    getTodosList() {
        this.todoListService.refreshTodoList();
    }

    showTodoForm() {
        this.todoListService.showTodoForm();
    }

    createNewTodo() {
        this.todoListService.createNewTodo(this.todoListForm.value.title, this.todoListForm.value.content, this.todoListForm.value.date);
    }

    deleteExistingTodoItem(todoId: number) {
        console.log(todoId);
        this.todoListService.deleteTodo(todoId);
    }

    editExistingTodo(todoId: number, index: number) {

        this.todoListService.isOpened.set(true);
        this.todoListService.isEditing.set(true);
        this.todoListService.todoUnderEdit.set(this.todoListService.todos()[index]);
        this.todoListForm.setValue({
            id: todoId,
            title: this.todoListService.todoUnderEdit().title,
            content: this.todoListService.todoUnderEdit().content,
            date: this.todoListService.todoUnderEdit().date,
            validation: this.todoListService.todoUnderEdit().validation
        });
    }


    checkTodoValidation($event: any, todo: TodoListModel) {
        this.todoListService.checkTodoValidation($event, todo);
    }

    close() {
        this.todoListForm.reset();
        this.todoListService.close();
    }

    onSubmit() {
        if (this.todoListService.isEditing()) {
            this.todoListService.updateTodo(this.todoListForm.value as TodoListModel);
            console.log(this.todoListForm.value);
        }
        if (!this.todoListService.isEditing()) {
            this.createNewTodo();
        }
        this.close();

    }

}

