import {Component, inject, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PureOriginalService} from "../../service/pure-original.service";
import {StatisticsService} from "../../service/statistics.service";

@Component({
    selector: 'app-server-stats-modal',
    templateUrl: './server-stats-modal.component.html',
    styleUrls: ['./server-stats-modal.component.css'],
    animations: [
        trigger('fade', [
            state('void', style({opacity: 0})),
            state('open', style({opacity: 1})),
            transition('void => open', animate('300ms ease-in')),
            transition('open => void', animate('300ms ease-out')),
        ]),
    ],
})

export class ServerStatsModalComponent implements OnInit {

    private intervalId: any;

    public pureOriginalService = inject(PureOriginalService);
    public statisticService = inject(StatisticsService);


    ngOnInit() {
    }

    getNumberOfProcessedFiles() {
        this.pureOriginalService.getNumberOfProcessedFilesInLast24Hours();
    }

    getSystemInfos() {
        this.statisticService.getSystemInfos();
    }

    triggerIntervalEvery10Seconds() {
        this.intervalId = setInterval(() => {
            if (this.statisticService.isServerStatsModalOpened()) {
                this.pureOriginalService.getNumberOfProcessedFilesInLast24Hours();
                this.statisticService.getSystemInfos();
            }
        }, 10000)
    }

    openServerStatsModal() {
        this.statisticService.isServerStatsModalOpened.set(true);
        if (this.statisticService.isServerStatsModalOpened()) {
            this.getNumberOfProcessedFiles()
            this.getSystemInfos()
            this.triggerIntervalEvery10Seconds();
        }
    }

    closeModal() {
        this.statisticService.isServerStatsModalOpened.set(false);
        clearInterval(this.intervalId);

    }

}
