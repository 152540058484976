import {Component} from '@angular/core';
import {UserService} from "../../service/user.service";
import {Router} from "@angular/router";
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-user-modal-mobile',
  templateUrl: './user-modal-mobile.component.html',
  styleUrls: ['./user-modal-mobile.component.css']
})
export class UserModalMobileComponent {

  public isUserSettingsOpened: boolean = false;

  constructor(public userService: UserService, private router: Router, private authService: AuthService) {
  }

  openUserProfileSettings() {
    this.isUserSettingsOpened = !this.isUserSettingsOpened
  }

  closeUserSettings() {
    this.isUserSettingsOpened = false;
  }

  openUserSettingsPage() {
    this.router.navigate(['/app/user-settings'], {queryParams: {id: this.userService.loggedUser().id}});
    this.closeNavBar();
  }

  closeNavBar() {
    this.isUserSettingsOpened = false;
  }

  logout() {
    this.authService.logout();
  }
}
