import {Component} from '@angular/core';

@Component({
  selector: 'app-ecu-names-wrapper',
  templateUrl: './ecu-names-wrapper.component.html',
  styleUrls: ['./ecu-names-wrapper.component.css']
})
export class EcuNamesWrapperComponent {

}
