import {Component, OnInit} from '@angular/core';
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class StatisticsComponent implements OnInit {
  constructor() {
  }
  ngOnInit() {
  }

}
