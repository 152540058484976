import {inject, Injectable, signal} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from "@angular/forms";
import {
    CustomPureOriginalUploadValidations
} from "../pure-originals/pure-originals-upload/pure-originals-upload.component";
import {FileUploadService} from "./fileUpload.service";
import {NotificationService} from "./Notification.service";

@Injectable({
    providedIn: 'root'
})
export class PureOriginalsUploadService {

    public binFile = signal<File | null>(null);
    public iniFile = signal<File | null>(null);
    public hasAnyError = signal<boolean>(false);
    public isUploading = signal<boolean>(false);
    public resetSignal = signal<boolean>(false);

    winOls = new FormControl('', [Validators.required, this.olsValidator]);

    myInputs = new Map<string, CustomPureOriginalUploadValidations>([
        ["ini", {
            message: '',
            hasError: false,
            pureOriginalId: null
        }],
        ["bin", {
            message: '',
            hasError: false,
            pureOriginalId: null,

        }],
        ["winOls", {
            message: '',
            hasError: false,
            pureOriginalId: null
        }]
    ]);

    olsValidator(control: FormControl): ValidationErrors | null {
        const value = control.value;
        if (value && !value.endsWith('.ols')) {
            return {'olsFormat': true};
        }

        return null;
    }

    fileUploadService = inject(FileUploadService)
    notificationService = inject(NotificationService);

    checkForChanges() {
        this.hasAnyError.set(false);
        this.winOls.valueChanges.subscribe((value) => {
            function otherFormsHasNoErrors(myInputs: Map<string, CustomPureOriginalUploadValidations>) {
                return !myInputs.get('ini').hasError || !myInputs.get('bin').hasError;
            }

            if (value.length >= 1 && otherFormsHasNoErrors(this.myInputs)) {
                this.hasAnyError.set(false);
                this.myInputs.set('winOls', {
                    message: '',
                    hasError: false,
                    pureOriginalId: null
                });
            }
        });
    }

    refreshUpload() {
        this.winOls.setValue('');
        this.hasAnyError.set(false);
        this.iniFile.set(null);
        this.binFile.set(null);
        this.resetSignal.set(true);
        this.myInputs = new Map<string, any>([
            ["ini", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }],
            ["bin", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }],
            ["winOls", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }]
        ]);
    }

    uploadBin(file: File) {
        if (file) {
            this.hasAnyError.set(false);
            this.myInputs.set('bin', {
                message: '',
                hasError: false,
                pureOriginalId: null
            })
            this.binFile.set(file);
        }
    }

    uploadIni(file: File) {
        if (file) {
            this.hasAnyError.set(false);
            this.myInputs.set('ini', {
                message: '',
                hasError: false,
                pureOriginalId: null
            })
            this.iniFile.set(file);
        }
    }

    uploadAllFiles() {
        this.isUploading.set(true);
        this.fileUploadService.uploadPureOriginalFile(this.winOls.value, this.binFile(), this.iniFile())
            .subscribe(
                (response) => {
                    this.isUploading.set(false);
                    if (response.status === 200) {
                        this.notificationService.showSuccessMessage(`<div><span>Your file is uploaded</span></div>`, "Success");
                    }
                    this.refreshUpload();
                },
                (error) => {
                    this.hasAnyError.set(true);
                    this.isUploading.set(false);
                    if (error.status === 409) {

                        const message = error.error["message"];
                        const pureOriginalBaseId = error.error["existingId"];
                        // this.errorMessages(message, pureOriginalBaseId);
                        let updatedMessage = message
                            .toLowerCase()
                            .replace(/_/g, ' ')
                            .replace(/^./, char => char.toUpperCase());

                        if (message.includes("BIN")) {
                            this.myInputs.set("bin", {
                                message: updatedMessage,
                                hasError: true,
                                pureOriginalId: pureOriginalBaseId ? pureOriginalBaseId : null
                            });
                        } else if (message.includes("INI")) {
                            this.myInputs.set("ini", {
                                message: updatedMessage,
                                hasError: true,
                                pureOriginalId: pureOriginalBaseId ? pureOriginalBaseId : null
                            });
                        } else {
                            this.myInputs.set("ini", {
                                message: message,
                                hasError: true,
                                pureOriginalId: pureOriginalBaseId ? pureOriginalBaseId : null
                            })
                        }

                    } else if (error.status === 500) {
                        this.myInputs.set("ini", {
                            message: "Internal Server Error.",
                            hasError: true,
                            pureOriginalId: null
                        })
                    } else {
                        this.myInputs.set("ini", {
                            message: 'Wrong BIN or INI file',
                            hasError: true,
                            pureOriginalId: null
                        });
                        console.error('Unexpected error:', error);
                    }
                }
            );
    }
}
