import {Component, EventEmitter, inject, input, OnInit, Output, signal} from '@angular/core';
import {PureOriginalsModel} from "../../models/PureOriginals-model";
import {SearchFilterModel} from "../../models/SearchFilter-model";

import {fadeInAnimation} from "../../animations/fadeInAnimation";
import {UpdatePureOriginalModel} from "../../models/UpdatePureOriginal-model";
import {SortDirectionValuesModel} from "../../models/SortDirectionValues-model";
import {AuthService} from "../../service/auth.service";
import {PureOriginalService} from "../../service/pure-original.service";
import {UploadFileDialogService} from "../../service/uploadFileDialog.service";

@Component({
    selector: 'app-pure-originals-table',
    templateUrl: './pure-originals-table.component.html',
    styleUrls: ['./pure-originals-table.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})


export class PureOriginalsTableComponent implements OnInit {

    displayedTableHeaders = input<string[]>([]);
    pureOriginals = input<PureOriginalsModel[]>([])
    displayedOriginalColumns = input<(keyof PureOriginalsModel)[]>([]);
    selectedFilters = input<SearchFilterModel[]>([])
    sortDirectionValues = input<SortDirectionValuesModel>()

    @Output() deletePureOriginalItemOutput = new EventEmitter();
    @Output() openPureOriginalDetailsOutput = new EventEmitter();
    @Output() downloadOriginalFile = new EventEmitter();
    @Output() editPureOriginalDetails = new EventEmitter();
    @Output() changeSortDirection = new EventEmitter();
    isModalOpened = signal<boolean>(false);

    authService = inject(AuthService);
    pureOriginalsService = inject(PureOriginalService);
    uploadFileService = inject(UploadFileDialogService);


    ngOnInit() {
        this.uploadFileService.reset$.subscribe(() => {
            this.resetPureOriginalsIniFile();
        });

    }

    deletePureOriginalItem(element: PureOriginalsModel, pureOriginalId: number, tableName: string) {
        return this.deletePureOriginalItemOutput.emit({element, pureOriginalId, tableName})
    }

    openPureOriginalDetails(pureOriginalId: number) {
        this.openPureOriginalDetailsOutput.emit(pureOriginalId);
    }

    editPureOriginalDetailsItem(element: UpdatePureOriginalModel) {
        this.editPureOriginalDetails.emit(element)
    }

    changePureOriginalsTableSortDirection(event: any) {
        this.changeSortDirection.emit(event);
    }
    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }

    openPureOriginalUploadModal(id: number) {
        this.pureOriginalsService.pureOriginalId.set(id);
        this.isModalOpened.set(true);
    }

    uploadIniFile($event: any) {
        const file = $event.valueOf()["fileToUpload"];
        this.pureOriginalsService.uploadIniFile(file, this.pureOriginalsService.pureOriginalId()).then();
    }

    resetPureOriginalsIniFile() {
        this.pureOriginalsService.refreshIniFile();
    }

    fileModalClosed() {
        this.isModalOpened.set(false);
    }
}
