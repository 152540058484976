<section class="main">
    <div class="table-title">
        <div class="head-buttons">
            <button class="r-btn" (click)="onRefreshPureOriginalsTable()"><i class="icon bi bi-arrow-clockwise"></i>
            </button>
            <button class="change-content-btn"
                    (click)="showMyClaimedFiles()">{{ !pureOriginalsService.myClaimedFiles() ? 'My Files' : 'All Originals' }}
            </button>
        </div>
        <div class="act-search-bar">
            <app-search-bar [value]="'Win Ols'" (searchValue)="onSearchInput($event)"></app-search-bar>
            <div class="filtering-buttons">
                <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i
                        class="bi bi-funnel"></i>
                    @if (filterCount() > 0) {
                        <p class="count-filter">{{ filterCount() }}</p>
                    }
                </button>
            </div>
        </div>
    </div>

    @if (openAdvanceSettings()) {

        <app-pure-originals-filters [displayedColumns]="pureOriginalsService.displayedOriginalColumns()"
                                    (pureOriginalsFilterClosed)="onAdvancedSettingClosed($event)"
                                    (selectedFilters)="selectFilters($event)"
                                    (filterCount)="updateFilterCount($event)"
        ></app-pure-originals-filters>

    }

    @defer (when !pureOriginalsService.tableIsLoading()) {
        @if (pureOriginalsService.pureOriginals().length > 0) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="onScroll()"
                 (scrolledUp)="onScrolledUp()">
                <app-pure-originals-table [pureOriginals]="pureOriginalsService.pureOriginals()"
                                          [sortDirectionValues]="pureOriginalsService.sortDirectionValues()"
                                          [displayedTableHeaders]="pureOriginalsService.displayedTableHeaders()"
                                          [displayedOriginalColumns]="pureOriginalsService.displayedOriginalColumns()"
                                          (deletePureOriginalItemOutput)="deletePureOriginalItem($event)"
                                          (changeSortDirection)="changeSortDirection($event)"
                                          (openPureOriginalDetailsOutput)="openPureOriginalDetails($event)"
                                          (editPureOriginalDetails)="editPureOriginalDetails($event)">

                </app-pure-originals-table>
            </div>
        } @else {
            <div>
                <app-empty-table></app-empty-table>
            </div>
        }


    } @placeholder (minimum 150ms) {
        <div *ngIf="pureOriginalsService.tableIsLoading()">
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

</section>
