import {Component, inject, OnInit} from '@angular/core';
import {PureOriginalResultsService} from "../../service/PureOriginalResults.service";
@Component({
  selector: 'app-pure-original-results-table',
  templateUrl: './pure-original-results-table.component.html',
  styleUrls: ['./pure-original-results-table.component.css']
})
export class PureOriginalResultsTableComponent implements OnInit {

  pureOriginalResultService = inject(PureOriginalResultsService)

  ngOnInit() {
    this.getAllPureOriginalResults()
    this.getTableKeysForPureOriginalsResults()
  }

  getTableKeysForPureOriginalsResults() {
   this.pureOriginalResultService.getTableKeysForPureOriginalResults()
  }

  getAllPureOriginalResults() {
    this.pureOriginalResultService.getPureOriginalResults();
  }
  onScroll() {
    this.pureOriginalResultService.triggerOnScroll();
  }
  onRefreshTable () {
    this.pureOriginalResultService.resetTable();
  }

}
