<div class="margin-wrapper">

  <div class="main">
    <div class="main-container-wrapper" [ngClass]="currentStep === UploadingSteps.STOCK_FILE_OPTIONS ? 'active' : ''">
      <div class="title">
        <h2 class="title-text">Match and Tune Files</h2>
        <span class="title-subtext">Use our Automatic Chiptuning tool to match file based on file detect.</span>
        <button class="button restart-btn" *ngIf="currentStep !== UploadingSteps.UPLOAD_FILE || error" (click)="restartFileUploadModal()"><i class="bi bi-arrow-clockwise"></i></button>
      </div>

      <div class="upload-file-wrapper">
        <ng-container *ngIf="currentStep === UploadingSteps.UPLOAD_FILE" >
          <div class="input-container">
            <app-upload-file inputText="Drag & Drop file here or click to browse" (file)="convertFileToBase64($event)" [progressWidth]="progressWidth" [error]="error" customBackground="var(--primary-clr)"></app-upload-file>
          </div>

        </ng-container>

        <ng-container *ngIf="currentStep === UploadingSteps.STOCK_FILE_OPTIONS" >

          <app-user-inputs [stockFileDTO]="stockFileDTO" (requestSent)="restartFileUploadAfterRequestIsSent($event)"></app-user-inputs>

        </ng-container>
      </div>

    </div>
  </div>
</div>
