export class WinOlsTransactionModel {

    id: string;
    customer: string;
    requestedModifications: string;
    status: string;
    trace: string;
    pureOriginalId: string;
    similarPureOriginalId: string;
    errorMessage: string;
    clientFile: string;
    generatedFile: string;
    availableVersions: string;
    masterIds: string;
    hashSha1Part1: string;
    hashSha1Part2: string;
    hashSha1Part3: string;
    hashSha1Part4: string;
    hashSha1Part5: string;
    hashSha1Part6: string;
    hashSha1Part7: string;
    hashSha1Part8: string;
    verApi: string;
    verWinOLS: string;
    language: string;
    filesize: string;
    md5: string;
    sh11: string;
    sha256: string;
    ecuStg: string;
    ecuProd: string;
    software: string;
    signature: string;
    cost: string;
    feedback: string;
    dateUploaded: string;

    constructor() {
        this.id = "id";
        this.customer = "customer";
        this.requestedModifications = "requestedModifications";
        this.status = 'status';
        this.trace = "trace";
        this.pureOriginalId = "pureOriginalId";
        this.similarPureOriginalId = "similarPureOriginalId";
        this.errorMessage = "errorMessage";
        this.clientFile = "clientFile";
        this.generatedFile = "generatedFile";
        this.availableVersions = "availableVersions";
        this.masterIds = "masterIds";
        this.hashSha1Part1 = "hashSha1Part1";
        this.hashSha1Part2 = "hashSha1Part2";
        this.hashSha1Part3 = "hashSha1Part3";
        this.hashSha1Part4 = "hashSha1Part4";
        this.hashSha1Part5 = "hashSha1Part5";
        this.hashSha1Part6 = "hashSha1Part6";
        this.hashSha1Part7 = "hashSha1Part7";
        this.hashSha1Part8 = "hashSha1Part8";
        this.verApi = "verApi";
        this.verWinOLS = "verWinOLS";
        this.language = "language";
        this.filesize = "filesize";
        this.md5 = "md5";
        this.sh11 = "sh11";
        this.sha256 = "sha256";
        this.ecuStg = "ecuStg";
        this.ecuProd = "ecuProd";
        this.software = "software";
        this.signature = 'signature';
        this.cost = "cost";
        this.feedback = "feedback";
        this.dateUploaded = "dateUploaded";
    }
}

