import {Component, ElementRef, inject, input, OnChanges, OnInit, signal, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-cpu-usage',
    templateUrl: './cpu-usage.component.html',
    styleUrls: ['./cpu-usage.component.css']
})
export class CpuUsageComponent implements OnInit, OnChanges {

    cpuUsage = input<number>();
    cpuCores = input<number>();
    progressWidth = signal<number>(0);

    el = inject(ElementRef);

    ngOnChanges (changes: SimpleChanges) {
        if (changes['cpuUsage'].currentValue !== changes['cpuUsage'].previousValue) {
            this.extractDiskSpaceValues(changes['cpuUsage'].currentValue);
        }
    }
    ngOnInit() {
        this.extractDiskSpaceValues(this.cpuUsage());
    }

    extractDiskSpaceValues(cpuUsage: number) {
        const progressBar = this.el.nativeElement.querySelector('.progress-bar-custom');

        this.progressWidth.set(cpuUsage);

        if (this.progressWidth() != null) {
            if (this.progressWidth() < 70 && this.progressWidth() > 0) {
                progressBar.style.setProperty('--pseudoEl', 'lightgreen');
            }
            if (this.progressWidth() >= 70) {
                progressBar.style.setProperty('--pseudoEl', 'orange');
            }
            if (this.progressWidth() >= 90) {
                progressBar.style.setProperty('--pseudoEl', 'red');
            }
        }
    }

}
