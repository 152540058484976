export class PureOriginalUserStatisticsModel {
    public pureOriginalId: string;
    public winOlsDbName: string;
    public winOlsReference: string;
    public actionDateTime: string;
    public dateUpdated: string;
    public dateUploaded: string;
    public dateExported: string;
    public ecuBuild: string;
    public ecuProducer: string;
    public isDeleted: string;
    public vehicleProducer: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public resellerProjectDetails: string;

    constructor() {
        this.pureOriginalId = "pureOriginalId";
        this.winOlsDbName = 'winOlsDbName';
        this.winOlsReference = "winOlsReference";
        this.actionDateTime = "actionDateTime";
        this.dateUpdated = "dateUpdated";
        this.dateUploaded = "dateUploaded";
        this.dateExported = "dateExported";
        this.ecuBuild = "ecuBuild";
        this.ecuProducer = "ecuProducer";
        this.vehicleProducer = "vehicleProducer";
        this.vehicleSeries = "vehicleSeries";
        this.vehicleModel = "vehicleModel";
        this.resellerProjectDetails = "resellerProjectDetails";
        this.isDeleted = "isDeleted";

    }
}
