import {Component, inject, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {LocalAuthService} from "../../service/localAuth.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../service/auth.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationService} from "../../service/Notification.service";

@Component({
  selector: 'app-two-factor-login-template',
  templateUrl: './two-factor-login-template.component.html',
  styleUrl: './two-factor-login-template.component.css'
})
export class TwoFactorLoginTemplateComponent {

  twoFactorCode!: string;
  private email: string;

  @ViewChild(NgForm)
  formDir!: NgForm

  localAuthService = inject(LocalAuthService);
  notificationService = inject(NotificationService);
  authService = inject(AuthService);
  route = inject(ActivatedRoute)
  constructor() {

    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      console.log(this.email);
    })
  }

  onSubmitForm() {
    if (this.formDir.invalid) return;

    const twoFactorCode = this.formDir.value["two-factor-code"].trim();

    this.localAuthService.signInWithMfa(this.email, twoFactorCode).subscribe(response => {
      if (response.status === 200 ) {
        this.authService.getTokenFromResponse(response.body.token);
      }
    }, (error => {
      this.handleErrorMessages(error);
    }));
    this.formDir.reset();
  }

  handleErrorMessages(error: HttpErrorResponse) {
      this.notificationService.showErrorMessage(error.error.message, 'Error occured!');
  }
}
