export class NumberOfAllTunings {
  public numberOfPartialMissingTunings: number;
  public numberOfMissingTunings: number;

  constructor(numberOfPartialMissingTunings: number, numberOfMissingTunings: number) {
    this.numberOfPartialMissingTunings = numberOfPartialMissingTunings;
    this.numberOfMissingTunings = numberOfMissingTunings;
  }

}
