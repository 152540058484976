<div class="main">
    <div class="main-container-wrapper">
        <div class="button-container" *ngIf="authService.isAdmin()">
            <button (click)="toggleInputRow()" class="add-row-btn"><i class="bi bi-plus-lg"></i>Row</button>
        </div>
        @defer (when !resellerTuningCombinationsService.isLoading()) {
            <div class="table-container">

                <table>
                    <tr class="header">
                        <th>ID</th>
                        <th>Tuning Options</th>
                        <th>Reseller Project Type</th>
                        <th>Actions</th>
                    </tr>

                    @if (resellerTuningCombinationsService.showNewInputRow()) {
                        <tr *ngIf="authService.isAdmin()">
                            <td>-</td>
                            <td>
                                <mat-form-field class="background-input">
                                    <mat-label>Correct Tuning Option</mat-label>
                                    <input type="text" matInput [formControl]="tuningOptions" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of resellerTuningCombinationsService.resellerTuningList()" [value]="option">
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="background-input">
                                    <mat-label>Correct Reseller Project Type</mat-label>
                                    <input type="text" matInput [formControl]="resellerProjectType"/>
                                </mat-form-field>
                            </td>
                            <td>
                                @if (!resellerTuningCombinationsService.isWaitingForResponse()) {
                                    <button (click)="saveNewRow()" class="row-btn"><i class="bi bi-check-lg"></i>
                                    </button>
                                    <button (click)="resellerTuningCombinationsService.closeRow()" class="row-btn"><i
                                            class="bi bi-x-lg"></i></button>
                                } @else {
                                    <div class="spinner-wrapper">
                                        <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                    </div>
                                }

                            </td>

                        </tr>
                    }


                    @for (element of resellerTuningCombinationsService.resellerTuningCombinations(); track $index) {

                        <tr class="body-columns">

                            <td>{{ element.id }}</td>

                            <td>
                                @if (resellerTuningCombinationsService.resellerTuningIndex() !== $index) {
                                    <span class="tag" (dblclick)="toggleEditView($index, element)">
                                        {{ element.tuningOptions }}</span>

                                } @else {

                                    <mat-form-field class="background-input" *ngIf="authService.isAdmin()">
                                        <input matInput type="text" [value]="element.tuningOptions"
                                               [formControl]="tuningOptions"
                                               (keyup)="saveEditedItem($event, element.tuningOptions)"
                                               (keyup.enter)="editResellerCombinations(element.id,element)"/>
                                    </mat-form-field>
                                }
                            </td>


                            <td>
                                @if (resellerTuningCombinationsService.resellerTuningIndex() !== $index) {
                                    <span class="tag" (dblclick)="toggleEditView($index, element)">
                                        {{ element.resellerProjectType }}</span>

                                } @else {

                                    <mat-form-field class="background-input" *ngIf="authService.isAdmin()">
                                        <input matInput type="text" [value]="element.resellerProjectType"
                                               [formControl]="resellerProjectType"
                                               (keyup)="saveEditedItem($event, element.resellerProjectType)"
                                               (keyup.enter)="editResellerCombinations(element.id, element)"/>
                                    </mat-form-field>
                                }
                            </td>
                            <td class="actions" *ngIf="authService.isAdmin()">
                                @if (resellerTuningCombinationsService.resellerTuningIndex() !== $index) {
                                    <button class="table-buttons" (click)="deleteItemFromDatabase(element.id)"><i
                                            class="bi bi-trash"></i></button>
                                    <button class="table-buttons" [disabled]="!element.id"
                                            (click)="toggleEditView($index, element)"><i
                                            class="bi bi-pencil"></i>
                                    </button>
                                } @else {
                                    @if (!resellerTuningCombinationsService.isWaitingForResponse()) {
                                        <button class="table-buttons"
                                                (click)="editResellerCombinations(element.id, element)"><i
                                                class="bi bi-check-lg"></i></button>
                                        <button class="table-buttons"
                                                (click)="resellerTuningCombinationsService.closeEditedItem()"><i
                                                class="bi bi-x-lg"></i>
                                        </button>
                                    } @else {
                                        <div class="spinner-wrapper">
                                            <mat-spinner style="max-width: 100%; max-height: 100%"></mat-spinner>
                                        </div>
                                    }

                                }
                            </td>
                        </tr>
                    } @empty {
                        <ng-container>
                            <tr>
                                <td>
                                    -
                                </td>
                                <td style="min-width: 100%">
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    No Results
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    }

                </table>

            </div>
        } @placeholder (minimum 150ms) {
            <div class="skeleton-wrapper">
                <app-skeleton-table></app-skeleton-table>
            </div>
        }
    </div>
</div>

