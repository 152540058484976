import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "../service/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localAuthService: AuthService) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let token = localStorage.getItem('_accessToken');

    if (token != null) {
      this.localAuthService.accessToken = token;
      if (this.localAuthService.isExpired(token)) {
        this.localAuthService.logout();
        console.log('Token is valid');
      }
    } else {
      return next.handle(req);
    }
    const req1 = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });

    return next.handle(req1);
  }
}
