<section class="main">
  <!--  <div class="table-title">-->
  <!--    <div class="head-button">-->
  <!--      <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>-->
  <!--    </div>-->
  <!--    <div class="act-search-bar">-->
  <!--      <app-search-bar (searchValue)="onSearchInput()"></app-search-bar>-->
  <!--      <div class="filtering-buttons">-->
  <!--        <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i-->
  <!--          class="bi bi-funnel"></i></button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->


  <!--  <div *ngIf="openAdvanceSettings">-->
  <!--    <app-advanced-search-bar [displayedColumns]="displayedOriginalColumns"-->
  <!--                             (advancedSettingClosed)="onAdvancedSettingClosed($event)"-->
  <!--                             (selectedFilters)="selectFilters($event)"></app-advanced-search-bar>-->
  <!--  </div>-->
  <div class="title">
    <h2 (click)="goBackTwoSteps()">Ecu Details Page</h2>
    <p>></p>
    <h2 (click)="goBackOneStep()" *ngIf="from == 1 && to == 1">Completely Matched Stats</h2>
    <h2 (click)="goBackOneStep()" *ngIf="from < 1 && to <= 1">Stats By Hit Rate</h2>
    <p>></p>
    <p *ngIf="from == 1 && to == 1">Table of <span>{{this.ecuBuild}}</span> stats</p>
    <p *ngIf="from < 1 && to <= 1 ">Stats sorted by <span>{{from * 100}}% - {{to * 100}}%</span> Hit Rate and
      <span>{{this.ecuBuild}}</span> ecu build</p>
  </div>
    <div class="table-container"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         (scrolled)="onScroll()" *ngIf="pureOriginalResultsService.pureOriginalMatching().length > 0">

      <table>
        <tr class="header">
          <th *ngFor="let column of displayedOriginalColumns">{{column | shorten: 25}}</th>
          <th class="actions-header">Actions</th>
        </tr>


        <tr *ngFor="let element of pureOriginalResultsService.pureOriginalMatching(); let i = index"
            class="body-columns">

          <td *ngFor="let column of displayedOriginalColumns">{{element[column] | shorten: 1000}}</td>


          <td class="actions">
            <!--                  <button class="table-buttons" (click)="deletePureOriginalItem(+element.pureOriginalId, 'pure_original_base')"><i class="bi bi-trash"></i></button>-->
            <button class="table-buttons" [disabled]="isDownloadInProgress(+element.pureOriginalMatchingDetailsId)"
                    (click)="prepareFileToDownload(+element.pureOriginalMatchingDetailsId, element.pureOriginalBaseWinOlsRef)">
              <i class="bi bi-file-earmark-arrow-down"></i>

            </button>
            <button class="table-buttons" [disabled]="!element.pureOriginalBaseId" (click)="openPureOriginalDetails(+element.pureOriginalBaseId)"><i class="bi bi-eye"></i></button>

          </td>
        </tr>

      </table>


    </div>
    <div *ngIf="pureOriginalResultsService.pureOriginalMatching().length < 1">
      <app-skeleton-table></app-skeleton-table>
    </div>



</section>

