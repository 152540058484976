import {Component, EventEmitter, inject, Inject, Input, OnInit, Output, signal} from '@angular/core';
import {SearchFilterModel} from '../../models/SearchFilter-model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LocalStorageService} from '../../service/LocalStorageService';
import {PureOriginalService} from "../../service/pure-original.service";
import {EcuBuildNameModel} from "../../models/EcuBuildName-model";
import {CorrectTuningNames, TuningNamesTablesService} from "../../service/Tuning-names-tables.service";

@Component({
    selector: 'app-pure-originals-filters',
    templateUrl: './pure-originals-filters.html',
    styleUrls: ['./pure-originals-filters.css']
})
export class PureOriginalsFilters implements OnInit {

    public selectedOptions: SearchFilterModel[] = [];
    public groupKey: string = '';
    public tuningNames = signal<string[]>([]);

    @Input() displayedColumns: undefined | string[];
    @Output() pureOriginalsFilterClosed = new EventEmitter<boolean>();
    @Output() selectedFilters = new EventEmitter<SearchFilterModel[]>();
    @Output() selectedFiltersChange = new EventEmitter<SearchFilterModel[]>();
    @Output() filterCount = new EventEmitter<number>();

    private localStorageService = inject(LocalStorageService);
    private tuningNamesTableService = inject(TuningNamesTablesService);
    private pureOriginalService = inject(PureOriginalService)

    searchFilterForm = new FormGroup({});

    constructor(@Inject('LOCAL_STORAGE_KEY') private pureOriginalsFilter: string) {
    }

    ngOnInit(): void {
        this.getListOfCorrectTuningNames();
        const savedFilters = this.localStorageService.getFilters(this.pureOriginalsFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.searchFilterForm = this.localStorageService.createFormGroupFromFilters(savedFilters, this.displayedColumns || []);
            Object.keys(this.searchFilterForm.controls).forEach(key => {
                const group = this.searchFilterForm.get(key) as FormGroup;
                if (group.get('option')?.value === 'hitRate') {
                    group.get('value')?.clearValidators();
                } else {
                    group.get('value')?.setValidators(Validators.required);
                }
                group.get('value')?.updateValueAndValidity();


                group.get('option')?.valueChanges.subscribe(selected => {
                    if (selected === 'hitRate') {
                        group.get('value')?.clearValidators();
                    } else {
                        group.get('value')?.setValidators(Validators.required);
                    }
                    group.get('value')?.updateValueAndValidity();
                });
            });
            this.selectedFilters.emit(savedFilters);
            this.filterCount.emit(savedFilters.length);
        }
    }

    getListOfCorrectTuningNames() {
        this.tuningNamesTableService.getListOfCorrectTuningNames().subscribe((response) => {
            this.tuningNames.set(response.correctTuningNames)
        })
    }

    addFormControl() {
        const control = new FormGroup({
            'option': new FormControl(this.displayedColumns![0]),
            'value': new FormControl('', Validators.required),
            'hitRateMin': new FormControl(50),
            'hitRateMax': new FormControl(100)
        });
        control.get('option')?.valueChanges.subscribe(selected => {
            if (selected === 'hitRate') {

                control.get('value')?.clearValidators();
            } else {

                control.get('value')?.setValidators(Validators.required);
            }

            control.get('value')?.updateValueAndValidity();
        });
        this.searchFilterForm.addControl(`control-${Object.keys(this.searchFilterForm.controls).length + 1}`, control);
    }

    clearFilters() {
        this.localStorageService.clearFilters(this.pureOriginalsFilter);
        const controlKeys = Object.keys(this.searchFilterForm.controls);
        controlKeys.forEach(key => {
            this.searchFilterForm.removeControl(key);
        });
        this.updateFilterCount();
        this.pureOriginalService.getPureOriginals()

    }

    closeModal() {
        this.pureOriginalsFilterClosed.emit(false);
    }

    onSubmit() {
        console.log(this.searchFilterForm.value)
        this.selectedOptions = [];
        for (const group in this.searchFilterForm.controls) {
            const value = this.searchFilterForm.get(group)?.value.value as string;
            const trimmedValue = value.trim();
            this.selectedOptions.push({
                option: this.searchFilterForm.get(group)?.value.option,
                value: trimmedValue,
                hitRateMin: this.searchFilterForm.get(group)?.value.hitRateMin,
                hitRateMax: this.searchFilterForm.get(group)?.value.hitRateMax
            });
        }
        this.localStorageService.saveFilters(this.pureOriginalsFilter, this.selectedOptions);
        this.selectedFilters.emit(this.selectedOptions);
        this.pureOriginalsFilterClosed.emit(false);
        this.updateFilterCount();
    }

    deleteSearchBar(key: string) {
        this.searchFilterForm.removeControl(key);

        const updatedFilters: SearchFilterModel[] = [];
        for (const groupKey in this.searchFilterForm.controls) {
            const filterGroup = this.searchFilterForm.get(groupKey)?.value;
            updatedFilters.push({
                option: filterGroup.option,
                value: filterGroup.value,
                hitRateMin: filterGroup.hitRateMin,
                hitRateMax: filterGroup.hitRateMax
            });
        }
        this.localStorageService.saveFilters(this.pureOriginalsFilter, updatedFilters);
        this.updateFilterCount();
    }

    hasFormControls(): boolean {
        return Object.keys(this.searchFilterForm.controls).length > 0;
    }

    updateFilterCount() {
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
    }
}

