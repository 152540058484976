@defer (when !isLoading()) {
    <ng-container>
        @for (data of systemInfoData(); track $index) {
            <div class="server-stats-container">
        <span class="server-name">Server: <span class="server-name"
                                                [ngClass]="!data.online ? 'offline': 'online'">{{ data.serverName }}</span></span>
                <div class="progress-bar-container">
                    <app-heap-server-memory-component [freeHeapMemory]="data.freeHeapMemory"
                                                      [maxHeapMemory]="data.maxHeapMemory"></app-heap-server-memory-component>
                </div>
                <div class="progress-bar-container">
                    <app-cpu-usage [cpuUsage]="data.cpuUsage" [cpuCores]="data.numberOfCpuCores"></app-cpu-usage>
                </div>

            </div>
        }
    </ng-container>

} @placeholder (minimum 150ms) {
    <div>
        <app-server-stats-placeholder></app-server-stats-placeholder>

    </div>
}
