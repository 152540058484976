import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class DeleteFileFromDatabaseService {
    private url = `${this.API_URL}`;

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    deleteFileFromDatabase(id: number, tableName: string) {
        return this.httpClient.post(`${this.url}/deleteRecords`, {}, {
            params: {
                recordsRequestId: id,
                tableName: tableName
            },
            observe: "response"
        })
    }
}

