<div mat-dialog-title class="title">
    <h2>Edit {{ data['text'] }} Master Item</h2>
</div>
<div class="underline"></div>
<mat-dialog-content class="dialog-content" [formGroup]="updateMasterItemForm" (keydown.enter)="confirmedOption()">
    <div class="table-container">
        <div class="table-wrapper">
            <app-ecu-build-names-table [ecuBuild]="ecuBuild"
                                       [searchQueryCorrectEcuNamesInput]="searchQuery"
                                       (correctEcuBuildName)="getCorrectEcuName($event)"></app-ecu-build-names-table>
        </div>
    </div>

    <div class="form-wrapper">
        <div class="winolsRef">{{ data['text'] }}
            <i (click)="getWinOlsToClipboard()" class="bi bi-clipboard" ngbTooltip="Copy"></i></div>
        <mat-form-field>
            <mat-label>ID</mat-label>
            <input matInput [value]="data.id" readonly/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Uploaded By</mat-label>
            <input matInput formControlName="uploadedBy">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Client Name</mat-label>
            <input matInput formControlName="clientName">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Client Number</mat-label>
            <input matInput formControlName="clientNumber">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Client Licence Place</mat-label>
            <input matInput formControlName="clientLicencePlace">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Ecu Use</mat-label>
            <input matInput formControlName="ecuUse">
        </mat-form-field>

        <div class="mat-form">
            <mat-form-field class="input-with-button">
                <mat-label>Ecu Producer</mat-label>
                <input matInput formControlName="ecuProducer" id="secondDialogElement"/>
                <mat-error *ngIf="updateMasterItemForm.controls['ecuProducer']?.hasError('valid')" class="error-position">
                    Please enter a valid Ecu Producer.
                </mat-error>
                <mat-error *ngIf="updateMasterItemForm.get('ecuProducer')?.hasError('required')">
                    Ecu Producer input is required.
                </mat-error>
                <button (click)="addNewEcuProducer()" class="add-row-button" *ngIf="true"><i class="bi bi-plus-lg"></i></button>
            </mat-form-field>
        </div>


        <div class="mat-form">
            <mat-form-field class="input-with-button">
                <mat-label>Ecu Build</mat-label>
                <input matInput formControlName="ecuBuild" id="firstDialogElement"/>
                <mat-error *ngIf="updateMasterItemForm.controls['ecuBuild'].hasError('valid')" class="error-position">
                    Please enter a valid Ecu Build.
                </mat-error>
                <mat-error *ngIf="updateMasterItemForm.get('ecuBuild').hasError('required')">
                    Ecu Build input is required.
                </mat-error>
                <button (click)="addNewEcu()" class="add-row-button" *ngIf="true"><i class="bi bi-plus-lg"></i></button>
            </mat-form-field>
        </div>


        <mat-form-field>
            <mat-label>Ecu ProdNr</mat-label>
            <input matInput formControlName="ecuProdNr">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Ecu Software Version</mat-label>
            <input matInput formControlName="ecuSoftwareVersion">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Ecu Software Version Version</mat-label>
            <input matInput formControlName="ecuSoftwareVersionVersion">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Build</mat-label>
            <input matInput formControlName="vehicleBuild">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Vehicle Characteristic</mat-label>
            <input matInput formControlName="vehicleCharacteristic">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Model</mat-label>
            <input matInput formControlName="vehicleModel">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Model Year</mat-label>
            <input matInput formControlName="vehicleModelYear">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Type</mat-label>
            <input matInput formControlName="vehicleType">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Producer</mat-label>
            <input matInput formControlName="vehicleProducer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vehicle Series</mat-label>
            <input matInput formControlName="vehicleSeries">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Name</mat-label>
            <input matInput formControlName="engineName">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Displacement</mat-label>
            <input matInput formControlName="engineDisplacement">
        </mat-form-field>

        <mat-form-field>
            <mat-label>ecuStgNr</mat-label>
            <input matInput formControlName="ecuStgNr">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Ecu Processor</mat-label>
            <input matInput formControlName="ecuProcessor">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Type</mat-label>
            <input matInput formControlName="engineType">
            <mat-error *ngIf="updateMasterItemForm.get('engineType').hasError('required')">
                Engine Type is required field.
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Producer</mat-label>
            <input matInput formControlName="engineProducer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine MaxTorque</mat-label>
            <input matInput formControlName="engineMaxTorque">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Emission</mat-label>
            <input matInput formControlName="engineEmission">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Emission Standard</mat-label>
            <input matInput formControlName="engineEmissionStandard">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Gear</mat-label>
            <input matInput formControlName="engineGear">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Project Created By</mat-label>
            <input matInput formControlName="projectCreatedBy">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Project Changed By</mat-label>
            <input matInput formControlName="projectChangedBy">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Output Ps</mat-label>
            <input matInput formControlName="outputPs">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Output KW</mat-label>
            <input matInput formControlName="outputKw">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Engine Transmission</mat-label>
            <input matInput formControlName="engineTransmission">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Reading Hardware</mat-label>
            <input matInput formControlName="readingHardware">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Reseller Project Details</mat-label>
            <input matInput formControlName="resellerProjectDetails">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vin</mat-label>
            <input matInput formControlName="vin">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Additional Info</mat-label>
            <input matInput formControlName="additionalInfo">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Reseller Project Type</mat-label>
            <input matInput formControlName="resellerProjectType">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Project Comment</mat-label>
            <input matInput formControlName="projectComment">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Version  Comment</mat-label>
            <input matInput formControlName="versionComment">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Software Number</mat-label>
            <input matInput formControlName="softwareNumber">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Project Type</mat-label>
            <input matInput formControlName="projectType">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def1</mat-label>
            <input matInput formControlName="userDef1">
        </mat-form-field>
        <mat-form-field>
            <mat-label>User Def2</mat-label>
            <input matInput formControlName="userDef2">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def3</mat-label>
            <input matInput formControlName="userDef3">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def4</mat-label>
            <input matInput formControlName="userDef4">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def5</mat-label>
            <input matInput formControlName="userDef5">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def6</mat-label>
            <input matInput formControlName="userDef6">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def7</mat-label>
            <input matInput formControlName="userDef7">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def11</mat-label>
            <input matInput formControlName="userDef11">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def12</mat-label>
            <input matInput formControlName="userDef12">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def13</mat-label>
            <input matInput formControlName="userDef13">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def14</mat-label>
            <input matInput formControlName="userDef14">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def15</mat-label>
            <input matInput formControlName="userDef15">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def16</mat-label>
            <input matInput formControlName="userDef16">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def17</mat-label>
            <input matInput formControlName="userDef17">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def18</mat-label>
            <input matInput formControlName="userDef18">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def19</mat-label>
            <input matInput formControlName="userDef19">
        </mat-form-field>

        <mat-form-field>
            <mat-label>User Def20</mat-label>
            <input matInput formControlName="userDef20">
        </mat-form-field>
    </div>
</mat-dialog-content>

<div mat-dialog-actions class="button-actions">
    <button mat-raised-button class="dialog-btn" (click)="canceledOption()">Close</button>
    <button mat-raised-button class="dialog-save-btn" [disabled]="updateMasterItemForm.invalid"
            (click)="confirmedOption()">Save
    </button>
</div>
