import {Component, inject, OnInit, signal} from '@angular/core';
import {ResellerTuningCombinationsService} from "../service/reseller-tuning-combinations.service";
import {FormControl, Validators} from "@angular/forms";
import {ResellerTuningCombinationsModel} from "../models/Reseller-tuning-combinations-model";
import {AuthService} from "../service/auth.service";


@Component({
    selector: 'app-reseller-tuning-combinations',
    templateUrl: './reseller-tuning-combinations.component.html',
    styleUrl: './reseller-tuning-combinations.component.css'
})
export class ResellerTuningCombinationsComponent implements OnInit {
    tuningOptions = new FormControl("", Validators.required);
    resellerProjectType = new FormControl("", Validators.required);
    editingText = signal<string>('');
    oldResellerValue = signal<string>("");
    newResellerValue = signal<string>("");

    resellerTuningCombinationsService = inject(ResellerTuningCombinationsService);
    authService = inject (AuthService);


    ngOnInit() {
        this.resellerTuningCombinationsService.resetTableValues();
        this.allResellerCombinations();
    }


    allResellerCombinations() {
        this.resellerTuningCombinationsService.getAllResellerCombinations();
    }

    saveNewRow() {
        const reseller = {
            id: 0,
            tuningOptions: this.tuningOptions.value,
            resellerProjectType: this.resellerProjectType.value,
        }
        this.resellerTuningCombinationsService.addNewResellerOptions(reseller);
        this.resellerTuningCombinationsService.closeRow();
    }


    toggleEditView(index: number, resellerCombinations: ResellerTuningCombinationsModel) {
        this.resellerTuningCombinationsService.toggleEditView(index);
        this.tuningOptions.setValue(resellerCombinations.tuningOptions || '');
        this.resellerProjectType.setValue(resellerCombinations.resellerProjectType || '');
    }

    saveEditedItem(event: KeyboardEvent, oldResellerValue: any) {
        const target = event.target as HTMLInputElement;
        this.newResellerValue.set(target.value);
        this.editingText.set(target.value);
        this.oldResellerValue.set(oldResellerValue)
    }

    editResellerCombinations(id: number, element: ResellerTuningCombinationsModel) {
        const updatedResellerOption: ResellerTuningCombinationsModel = {
            id,
            tuningOptions: this.tuningOptions.value,
            resellerProjectType: this.resellerProjectType.value
        };
        this.resellerTuningCombinationsService.editResellerOptions(id, updatedResellerOption)
    }


    deleteItemFromDatabase(id: number) {
        this.resellerTuningCombinationsService.deleteItemFromDatabase(id);
    }


    toggleInputRow() {
        this.tuningOptions.reset('');
        this.resellerProjectType.reset('');
        this.resellerTuningCombinationsService.toggleNewInputRow();
    }
}