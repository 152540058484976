<div (click)="closeModal()" class="modal-wrapper">
</div>
<div class="upload-file">
    <div class="upload-modal-title">
        <h2 class="title-text">{{ title() }}</h2>
        <button (click)="closeModal()" class="close-btn"><i class="bi bi-x-lg"></i></button>
    </div>
    <div>
        <ng-content class="content"></ng-content>
    </div>
</div>

