import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {PureOriginalResultsService} from "../../service/PureOriginalResults.service";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
@Component({
  selector: 'app-pure-original-results-table',
  templateUrl: './pure-original-results-table.component.html',
  styleUrls: ['./pure-original-results-table.component.css']
})
export class PureOriginalResultsTableComponent implements OnInit {

  pureOriginalResultService = inject(PureOriginalResultsService);
  elRef = inject(ElementRef);
  @ViewChild(InfiniteScrollDirective) infinitiveScrollDirective: {
    disposeScroller: { unsubscribe: () => void; };
    setup: () => void;
  };

  ngOnInit() {
    this.getAllPureOriginalResults()
    this.getTableKeysForPureOriginalsResults()
  }

  getTableKeysForPureOriginalsResults() {
   this.pureOriginalResultService.getTableKeysForPureOriginalResults()
  }

  getAllPureOriginalResults() {
    this.pureOriginalResultService.getPureOriginalResults();
  }
  onScroll() {
    this.pureOriginalResultService.triggerOnScroll();
  }
  onRefreshTable () {
    this.pureOriginalResultService.resetTable();
  }

  changePureOriginalResultsTableSortDirection(event: any) {
    const tableElement = this.elRef.nativeElement.querySelector(".table-container");

    if (tableElement) {
      tableElement.scrollTop = 0;
    }

    this.pureOriginalResultService.changePureOriginalResultsTableSortDirection(event.valueOf());
  }

}
