// import the required animation functions from the angular animations module
import { trigger, animate, transition, style } from '@angular/animations';

export const fadeInAnimation =
  trigger('fadeInAnimation', [

    // route 'enter' transition
    transition(':enter', [

      style({ opacity: 0 }),

      animate('.3s', style({ opacity: 1 }))
    ]),
  ]);
