import {Component, Inject} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";

@Component({
  selector: 'app-mfa-disable-confirmation-dialog',
  standalone: true,
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle
    ],
  templateUrl: './mfa-disable-confirmation-dialog.component.html',
  styleUrl: './mfa-disable-confirmation-dialog.component.css'
})
export class MfaDisableConfirmationDialogComponent {

    constructor(public dialogRef: MatDialogRef<MfaDisableConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    confirmedOption () {
        this.dialogRef.close(true);
    }

    canceledOption () {
        this.dialogRef.close(false);
    }
}
