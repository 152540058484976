import { Component } from '@angular/core';
import {fadeInAnimation} from "../animations/fadeInAnimation";

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class AdminPanelComponent {

}
