import {Component, EventEmitter, inject, input, OnInit, Output, signal} from '@angular/core';
import {IdentifyStockFileModel} from "../../models/IdentifyStockFile-model";
import {CarImageService} from "../../service/CarImage.service";
import {FileUploadService} from "../../service/fileUpload.service";
import {TuningsService} from "../../service/Tunings.service";
import {EcuNamesService} from "../../service/Ecu-names.service";
import {FormControl, Validators} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {NotificationService} from "../../service/Notification.service";

export interface CorrectEcuBuildModel {
    correctEcuBuildNames: string;
}

@Component({
    selector: 'app-user-inputs',
    templateUrl: './user-inputs.component.html',
    styleUrls: ['./user-inputs.component.css']
})
export class UserInputsComponent implements OnInit {

    imageIsLoading = signal<boolean>(false);
    stockFileDTO = input<IdentifyStockFileModel>();

    public filesToDownload = signal<number[]>([]);
    @Output() requestSent = new EventEmitter<boolean>();

    public ecuBuildNameControl = new FormControl<string | CorrectEcuBuildModel>('', [Validators.required]);
    public filteredOptions: Observable<CorrectEcuBuildModel[]>;


    ecuNamesService = inject(EcuNamesService);
    carImageService = inject(CarImageService);
    fileUploadService = inject(FileUploadService);
    tuningService = inject(TuningsService);
    notificationService = inject(NotificationService);

    ngOnInit() {
        if (this.stockFileDTO().identified) {
            this.getCarImage();

            this.ecuBuildNameControl = null;

            this.getAvailableOptions();
        } else {
            this.getCorrectedEcuNameOptions();
            this.tuningService.getTuningOptions().then();
        }
    }

    getAvailableOptions() {
        const numOptions = this.stockFileDTO().availableOptions.length;
        if (numOptions > 0) {
            for (let option of this.stockFileDTO().availableOptions) {
                this.tuningService.selectedOptions.push({key: option, value: false, disabled: false})
            }
        }
    }

    getCorrectedEcuNameOptions() {
        this.ecuNamesService.getCorrectEcuNamesOptions().then(() => {
            this.initializeAutocomplete();
        });
    }

    initializeAutocomplete() {
        this.filteredOptions = this.ecuBuildNameControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                const name = typeof value === 'string' ? value : value?.correctEcuBuildNames;
                return name ? this._filter(name as string) : this.ecuNamesService.correctEcuBuildNames().slice();
            }),
        );
    }


    displayFn(user: CorrectEcuBuildModel): string {
        return user && user.correctEcuBuildNames ? user.correctEcuBuildNames : '';
    }

    private _filter(name: string): CorrectEcuBuildModel[] {
        const filterValue = name;
        return this.ecuNamesService.correctEcuBuildNames().filter(option => option.correctEcuBuildNames.toLowerCase().startsWith(filterValue.toLowerCase()));
    }

    getCarImage() {
        this.startLoadingImage();
        this.carImageService.getImage({
            make: this.stockFileDTO().vehicleProducer,
            modelFamily: this.stockFileDTO().vehicleSeries,
            modelYear: this.stockFileDTO().vehicleModelYear,
            modelRange: this.stockFileDTO().vehicleModel,
            width: '900'
        })
    }

    startLoadingImage() {
        this.imageIsLoading.set(true);

        setTimeout(() => {
            this.imageIsLoading.set(false);
        }, 1000)

    }

    setOptionToActive($event: any) {
        const option = $event.valueOf();
        option.value = !option.value;

        if (option.key === 'Stage 1' && option.value) {
            const stage2 = this.tuningService.selectedOptions.find(option => option.key === 'Stage 2');
            const stage3 = this.tuningService.selectedOptions.find(option => option.key === 'Stage 3');

            if (stage2) {
                stage2.disabled = true;
            }
            if (stage3) {
                stage3.disabled = true;
            }
        }
    }

    requestFileTune($event: any) {
        const id = this.stockFileDTO().pureOriginalId;
        const vehicleName = this.stockFileDTO().vehicleProducer + " " + this.stockFileDTO().vehicleSeries + " " + this.stockFileDTO().vehicleModelYear;

        this.filesToDownload().push(id);
        let toastObj: any;

        if (this.stockFileDTO().identified) {
            toastObj = this.notificationService.showInfoMessage(`Preparing your ${vehicleName} file, some files take longer that 2 minutes to process`, 'Preparing..');
        } else {
            toastObj = this.notificationService.showInfoMessage(`Preparing your ${this.ecuBuildNameControl.value['correctEcuBuildNames']} file, some files take longer that 2 minutes to process`, 'Preparing..');
        }

        const blobFileName: string = $event.valueOf();

        let requestedTunings: string[] = [];

        this.tuningService.selectedOptions.forEach(item => {
            if (item.key && item.value) {
                requestedTunings.push(item.key);
            }
        });

        this.requestSent.emit(true);

        if (this.stockFileDTO().identified) {

            this.fileUploadService.requestFileTune(blobFileName, requestedTunings).subscribe(response => {

                const index = this.filesToDownload().indexOf(id);

                this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

                if (response.status === 200) {

                    this.notificationService.clear(toastObj.toastId);

                    window.location.href = response.body['url'] as string;

                    this.notificationService.showSuccessMessage(`<div><span>Your file ${vehicleName} is downloaded</span></div>`, "Success");

                }
            }, error => {
                this.notificationService.showErrorMessage(`${error.error.body}`, 'Error occured!');
            })
        } else {

            this.fileUploadService.requestManualFileTune(blobFileName, this.ecuBuildNameControl.value['correctEcuBuildNames'], requestedTunings).subscribe(response => {

                const index = this.filesToDownload().indexOf(id);

                this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

                if (response.status === 200) {

                    this.notificationService.clear(toastObj.toastId);

                    this.parseResponseMessage(response.body['url'], response.body['message']);
                }
            }, error => {
                this.notificationService.showErrorMessage(`${error.error.body}`, 'Error occured!');
            })
        }

    }

    parseResponseMessage(downloadLink: string, message: string) {


        if (message.startsWith("Successful")) {
            window.location.href = downloadLink;
            this.notificationService.showSuccessMessage(`<div><span>${message}</span></div>`, "Success", {positionClass: 'toast-top-right', timeOut: 10000});
        }
        if (message.startsWith("Partially")) {
            window.location.href = downloadLink;
            this.notificationService.showWarningMessage(`<div><span>${message}</span></div>`, "Success", {positionClass: 'toast-top-right', timeOut: 10000});
        }
        if (message.startsWith("Failed")) {
            this.notificationService.showErrorMessage(`<div><span>${message}</span><hr/></div>`, "Success");
        }
    }

}
