import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {EcuNamesService} from "../../service/Ecu-names.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {of} from "rxjs";

@Component({
    selector: 'app-add-dialog',
    templateUrl: './add-dialog.component.html',
    styleUrls: ['./add-dialog.component.css']
})
export class AddDialogComponent implements OnInit {

    addNewEcuForm = new FormGroup(
        {
            id: new FormControl(0),
            correctEcuBuildNames: new FormControl(null, [Validators.required], [this.ecuBuildValidator.bind(this)]),
            connectedEcuBuildNames: new FormControl(null),
            acceptedEcuBuildNames: new FormControl(null, Validators.required),
            correctEcuProducer: new FormControl(null, Validators.required)
        }
    )

    constructor(private ecuService: EcuNamesService, public dialogRef: MatDialogRef<AddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.addNewEcuForm.patchValue({
            correctEcuBuildNames: this.data.ecuBuildValue,
        })
    }


    ecuBuildValidator(control: AbstractControl) {
        const validationResponse = this.ecuService.checkIfEcuBuildIsInDatabase(control.value);
        return of(validationResponse);
    }


    confirmedOption(ecu: { id: number, correctEcuBuildNames: string, connectedEcuBuildNames: string, acceptedEcuBuildNames: string }) {
        this.dialogRef.close({closed: true, ecu: ecu});
    }

    canceledOption() {
        this.dialogRef.close({closed: false, ecuName: null});
    }

    saveNewRow() {
        const newRow = {
            id: 0,
            correctEcuBuildNames: this.addNewEcuForm.get('correctEcuBuildNames')?.value,
            connectedEcuBuildNames: this.addNewEcuForm.get('connectedEcuBuildNames')?.value,
            acceptedEcuBuildNames: this.addNewEcuForm.get('acceptedEcuBuildNames')?.value,
            correctEcuProducer: this.addNewEcuForm.get('correctEcuProducer')?.value
        }

        console.log(newRow);
        this.ecuService.addNewEcuBuildItem(newRow);
        this.canceledOption()

    }
}
