import {
    Component,
    EventEmitter,
    inject, input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {EcuNamesService} from "../../service/Ecu-names.service";
import {EcuBuildNameModel} from "../../models/EcuBuildName-model";
import {fadeInAnimation} from "../../animations/fadeInAnimation";
import {EcuProducersService} from "../../service/ecu-producers.service";


@Component({
    selector: 'app-ecu-build-names-table',
    templateUrl: './ecu-build-names-table.component.html',
    styleUrls: ['./ecu-build-names-table.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class EcuBuildNamesTableComponent implements OnInit, OnChanges {

    public displayedColumns: string[] = ['id', 'correctEcuBuildNames', 'acceptedEcuBuildNames', 'actions'];

    ecuBuild = input<EcuBuildNameModel>();
    searchQueryCorrectEcuNamesInput = input<string>('');
    @Output() correctEcuBuildName = new EventEmitter<string>();

    public ecuNamesService = inject(EcuNamesService);
    public ecuProducersService = inject(EcuProducersService);
    ngOnInit() {
        this.ecuNamesService.searchQuery.set('');
        this.getEcuProducersList();
        this.getEcuNames()
        if (this.searchQueryCorrectEcuNamesInput) {
            this.searchEcuNames(this.searchQueryCorrectEcuNamesInput());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (this.ecuBuild()) {
                this.ecuNamesService.ecuNames.update((ecuNames) => [this.ecuBuild(), ...ecuNames]);
            }
            this.searchEcuNames(this.searchQueryCorrectEcuNamesInput());
        }
    }

    getEcuNames() {
        this.ecuNamesService.getEcuBuildNames()
    }

    getEcuProducersList() {
        this.ecuProducersService.getAllCorrectEcuProducers();
    }
    toggleInputRow(): void {
        this.ecuNamesService.showInputRow.set(!this.ecuNamesService.showInputRow());
        this.ecuNamesService.resetNewRowValue();
    }

    addNewRow(): void {
        this.ecuNamesService.addNewEcuBuildItem(this.ecuNamesService.newRow());
    }

    deleteRow() {
        this.ecuNamesService.showInputRow.set(false);
    }

    deleteItemFromDatabase(ecu: EcuBuildNameModel) {
        this.ecuNamesService.deleteEcuBuildItem(ecu);
    }

    editEcu(ecuIndex: number) {
        this.ecuNamesService.ecuToEditIndex.set(ecuIndex);
    }

    saveEditedItem(ecuBuild: EcuBuildNameModel, id: number) {
        this.ecuNamesService.updateEcuBuild(ecuBuild, id);
    }

    searchForCorrectEcuNames(event: any) {
        this.searchEcuNames(event.target.value);
    }

    searchForConnectedEcuNames(event: any) {
        this.searchEcuNames(event.target.value);
    }

    searchForAcceptedEcuNames(event: any) {
        this.searchEcuNames(event.target.value);
    }

    searchEcuNames(searchQuery: string) {
        this.ecuNamesService.searchEcuNames(searchQuery);
    }

    getCorrectEcuBuildName(event: any) {
        this.correctEcuBuildName.emit(event.target.innerHTML);
    }


}

