import {Component, inject, OnInit} from '@angular/core';
import {UserStatisticsService} from "../../../service/User-statistics-service";
import {AuthService} from "../../../service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-all-users-statistics',
    templateUrl: './all-users-statistics.component.html',
    styleUrl: './all-users-statistics.component.css'
})
export class AllUsersStatisticsComponent implements OnInit {


    userStatisticsService = inject(UserStatisticsService);
    authService = inject(AuthService);
    router = inject(Router);
    route = inject(ActivatedRoute);

    ngOnInit() {
        this.getTodayStatistics()
        this.userStatisticsService.getAllTableHeaderUsersNames();
        this.userStatisticsService.getUserStatistics()
        this.userStatisticsService.resetTableHeadersUserNames()
    }

    getTodayStatistics() {
        this.userStatisticsService.endDate = new Date().toISOString();
        let d = new Date();
        d.setUTCHours(0, 0, 0, 0);
        this.userStatisticsService.startDate = d.toISOString();
    }

    navigateToUserDetailsPage(userId: number) {
        this.router.navigate([`../${userId}`], {relativeTo: this.route,
            queryParams: {
                startDate: this.userStatisticsService.startDate,
                endDate: this.userStatisticsService.endDate
            }
        });
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin();
    }

    onScroll() {
        this.userStatisticsService.increasePageNumber();
        this.userStatisticsService.getUserStatisticsOnScroll();
    }
}
