import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-cpu-details-component',
  templateUrl: './cpu-details-component.component.html',
  styleUrls: ['./cpu-details-component.component.css']
})
export class CpuDetailsComponentComponent implements OnInit {


  @Input() cpuUsage: number;
  @Input() cpuCores: number;

  public strokeDashOffset: number;
  public strokeDashArray: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }
  ngOnInit() {
    this.getSystemInfoDetails();
  }
  getSystemInfoDetails() {
    this.getCirclePercentage(this.cpuUsage, this.cpuCores);
  }
  getCirclePercentage(cpuUsage: number, cpuCores: number) {

    const element = this.el.nativeElement.querySelector('.percentage');
    const coresEl = this.el.nativeElement.querySelector('.coreNumber')

    if (element != null) {
      this.renderer.setProperty(element, 'innerHTML', (cpuUsage.toFixed(1) + " " + '%'));
    }
    if (coresEl != null) {
      this.renderer.setProperty(coresEl, 'innerHTML', (cpuCores) + '-core')
    }

    this.strokeDashArray = 68 * Math.PI * 2;
    this.strokeDashOffset = this.strokeDashArray - ((cpuUsage / 100) * this.strokeDashArray);
  }


}
