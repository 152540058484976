<div class="main">
    @defer (when !pureOriginalService.tableIsLoading()) {
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="pureOriginalService.onScrollFaultyPureOriginals()">

            <app-pure-originals-table [pureOriginals]="pureOriginalService.faultyPurOriginals()"
                                      [sortDirectionValues]="pureOriginalService.sortDirectionValues()"
                                      [displayedOriginalColumns]="pureOriginalService.displayedOriginalColumns()"
                                      [displayedTableHeaders]="pureOriginalService.displayedTableHeaders()"
                                      (changeSortDirection)="changeSortDirection($event)"
                                      (openPureOriginalDetailsOutput)="openPureOriginalDetails($event)"
                                      (editPureOriginalDetails)="editPureOriginalDetails($event)"
                                      (deletePureOriginalItemOutput)="deleteFaultyPureOriginals($event)"


            ></app-pure-originals-table>
        </div>
    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }
</div>

