import {Component, HostListener} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {StatisticsService} from "../../service/statistics.service";

@Component({
  selector: 'app-sidebar-list',
  templateUrl: './sidebar-list.component.html',
  styleUrls: ['./sidebar-list.component.css']
})
export class SidebarListComponent {


  public isTableListOpened: boolean = false;
  public isEcuNamesListOpened: boolean = false;
  public isAnalyticsListOpened: boolean = false;
  public isEcuNamesOpened: boolean = false;
  public isActionListOpened: boolean = false;
  public isCustomFunctionsListOpened: boolean = false;


  constructor(private authService: AuthService, private statisticService: StatisticsService) {
  }


  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  isModerator() {
    return this.authService.isModerator();
  }

  restartNavCollapsedCols() {
    this.isTableListOpened = false;
    this.isAnalyticsListOpened = false;
    this.isActionListOpened = false;
  }

  closeNavMenuAfterClickOnLink() {
    if (this.isSmallScreen()) {
      this.closeNavBar();
    }
  }

  closeNavBar() {
    this.statisticService.openNavbarMenu();
    this.restartNavCollapsedCols();
  }


  openNestedListForTables() {
    this.isTableListOpened = !this.isTableListOpened
  }

  openNestedListForEcuNames() {
    this.isEcuNamesListOpened = !this.isEcuNamesListOpened;
    this.isEcuNamesOpened = !this.isEcuNamesOpened;
  }
  openNestedListForCustomFunctions () {
    this.isCustomFunctionsListOpened = !this.isCustomFunctionsListOpened;
  }

  openNestedListForAnalytics() {
    this.isAnalyticsListOpened = !this.isAnalyticsListOpened
  }

  openNestedListForActions() {
    this.isActionListOpened = !this.isActionListOpened
  }

  @HostListener('window:resize', ['$event'])
  isSmallScreen(): boolean {
    return window.innerWidth <= 1115
  }
}
