
<div class="skeleton-wrapper">
<div class="skeleton-matching-container">
  <div class="skeleton-matching-successful">
    <div class="skeleton-matching-title">
      <div class="title-content skeleton"></div>
    </div>
    <div class="skeleton-matching-body">
      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>

      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>

      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>
        <div class="skeleton-matching-card">
            <div class="card-title skeleton"></div>
            <div class="card-subtitle skeleton"></div>
            <div class="card-footer skeleton"></div>
        </div>
    </div>
  </div>

  <div class="skeleton-matching-successful">
    <div class="skeleton-matching-title">
      <div class="title-content skeleton"></div>
    </div>
    <div class="skeleton-matching-body">

      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>
        <div class="skeleton-matching-card">
            <div class="card-title skeleton"></div>
            <div class="card-subtitle skeleton"></div>
            <div class="card-footer skeleton"></div>
        </div>
      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>

      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>
    </div>
  </div>
  <div class="skeleton-matching-successful">
    <div class="skeleton-matching-title">
      <div class="title-content skeleton"></div>
    </div>
    <div class="skeleton-matching-body">
      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>
        <div class="skeleton-matching-card">
            <div class="card-title skeleton"></div>
            <div class="card-subtitle skeleton"></div>
            <div class="card-footer skeleton"></div>
        </div>
      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>

      <div class="skeleton-matching-card">
        <div class="card-title skeleton"></div>
        <div class="card-subtitle skeleton"></div>
        <div class="card-footer skeleton"></div>
      </div>
    </div>
  </div>
</div>
</div>