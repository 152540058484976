<section class="main">
    <div class="head-button">
        <button class="r-btn" (click)="backToMainTable()"><i class="icon bi bi-arrow-left"></i></button>
        <span class="head-text">Back</span>
    </div>
    <div>
        <h2 class="main-title">User Statistics details</h2>
    </div>
    <div class="table-container"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         (scrolled)="onScroll()">
        <table>
            <tr class="header">
                <th>Id</th>
                <th>Action Type</th>
                <th>Status</th>
                <th>WinOls Reference</th>
                <th>Work Time</th>
                <th>Pure Original Id</th>
                <th>Matching Id</th>
                <th>Master Id</th>
                <th>Version Name</th>
                <th>Version Comment</th>
            </tr>
            @for (element of this.userStatisticsService.userStatisticsDetails(); track $index) {
                <tr class="body-columns">
                    <td>{{ element.id }}</td>
                    <td>{{ element.actionType }}</td>
                    <td>{{ element.status }}</td>
                    <td>{{ element.winOlsReference }}</td>
                    <td>{{ element.workTime }}</td>
                    <td>
                        <ng-container>
                               <span *ngIf="element.pureOriginalId; else pureOriginal">
                        <a class="similar" (click)="navigateToPureOriginalId(element.pureOriginalId)">
                            {{ element.pureOriginalId }}
                        </a>
                       </span>
                        </ng-container>
                        <ng-template #pureOriginal>
                            {{ element.pureOriginalId }}
                        </ng-template>

                    </td>

                    <td>
                        <ng-container>
                               <span *ngIf="element.pureOriginalMatchingId; else matchingId">
                        <a class="similar" (click)="navigateToMatchingId(element.pureOriginalId)">
                            {{ element.pureOriginalMatchingId }}
                        </a>
                     </span>
                        </ng-container>
                        <ng-template #matchingId>
                            {{ element.pureOriginalMatchingId }}
                        </ng-template>
                    </td>

                    <td>
                        <ng-container>
                              <span *ngIf="element.masterId; else masterId">
                        <a class="similar" (click)="navigateToMasterId(element.masterId)">
                            {{ element.masterId }}
                        </a>
                          </span>
                        </ng-container>
                        <ng-template #masterId>
                            {{ element.masterId }}
                        </ng-template>
                    </td>
                    <td>{{ element.versionName }}</td>
                    <td>{{ element.versionComment }}</td>

                </tr>
            }
            <tr>
            </tr>
        </table>
    </div>
</section>

