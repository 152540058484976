import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MasterResultsModel} from "../models/Master-Results-model";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AllMastersDatabaseService} from "./all-masters-database.service";
import {NotificationService} from "./Notification.service";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";


@Injectable({
    providedIn: "root"
})
export class MastersResultsService {

    private url = `${this.API_URL}`;
    public masterResults = signal<MasterResultsModel[]>([]);
    public displayedTableHeaders = signal<string[]>([]);
    public displayedMasterResultsColumns = signal<(keyof MasterResultsModel)[]>([]);
    public allMasterItems = signal<MasterResultsModel>(new MasterResultsModel());
    public isLoading = signal<boolean>(false);
    public pageNumber = signal<number>(0);
    private pageSize = signal<number>(50);
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "zipid", direction: "desc"});
    deleteFileFromDatabaseService = inject(DeleteFileFromDatabaseService);
    dialog = inject(MatDialog);
    router = inject(Router);
    allMasterDatabaseService = inject(AllMastersDatabaseService);
    notificationService = inject(NotificationService);
    httpClient = inject(HttpClient);

    constructor(@Inject('API_URL') private API_URL: string) {
    }

    triggerAllMasterResultsRequest() {
        return this.httpClient.get<{content:MasterResultsModel[]}>(`${this.url}/uploadingResults/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction,
            }
        })

    }

    getAllMasterResults() {
        this.isLoading.set(true);
        this.triggerAllMasterResultsRequest().subscribe(response => {
            this.masterResults.set(response['content'])
            this.isLoading.set(false);
        })

    }

    getAllTableHeaderNames() {
        let objectKeys = Object.keys(this.allMasterItems());
        for (let key of objectKeys) {
            this.displayedMasterResultsColumns().push(key as keyof MasterResultsModel)
        }
        this.displayedTableHeaders.set([...this.displayedMasterResultsColumns(), "actions"]);

    }

    onRefreshTable() {
        this.pageNumber.set(0);
        this.masterResults.set([]);
        this.getAllMasterResults();
    }

    restartTableValue() {
        this.pageNumber.set(0);
        this.masterResults.set([]);
        this.masterResults.set([])
        this.displayedMasterResultsColumns.set([]);
        this.displayedTableHeaders.set([]);
    }

    onScroll() {
        this.increasePageNumber();
        this.triggerAllMasterResultsRequest().subscribe((response) => {
            this.masterResults.update((masterResults) => [...masterResults, ...response['content']])
        })
    }

    increasePageNumber() {
        this.pageNumber.update((pageNumber) => pageNumber + 1);
    }

    changeSortDirection(sortEvent: SortDirectionValuesModel, tableElement: HTMLElement) {
        this.pageNumber.set(0);
        this.sortDirectionValues.set(sortEvent);
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        this.triggerAllMasterResultsRequest().subscribe(value => {
            this.masterResults.set([...value["content"]]);
        });
    }


    openMasterDetailsItem(id: number) {

        this.allMasterDatabaseService.masterExists(id).subscribe(response => {
            if (response.status === 200) {
                this.router.navigate([`/app/master-details/${id}`]);
            }
        }, error => {
            this.notificationService.showErrorMessage(`<div>Master item ${id} doesn't exists in database.</div>`, `${error.error['message']}`,
            );
        })
    }

    deleteMasterRecord(id: number, tableName: string) {

        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteFileFromDatabaseService.deleteFileFromDatabase(+id, tableName).subscribe((response) => {
                    if (response.status === 200) {
                        console.log(id)
                        this.masterResults.update((masterResults) => masterResults.filter((master) => +master.zipid !== id))
                        console.log(response.body)
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                });
            }
        })
    }
    isTableEmpty() {
        return this.masterResults().length === 0;
    }
}
