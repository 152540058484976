<div class="details-container-wrapper">
    <div class="details-container">
        <div class="head-button">
            <button class="r-btn" (click)="backToMainTable()"><i class="icon bi bi-arrow-left"></i></button>
            <span class="head-text">Back</span>
        </div>

        <div class="og-details">
            @if (pureOriginalService.errorMessage()) {
                <span>{{pureOriginalService.errorMessage()}}</span>
            } @else {
                <div class="cards-tuning-wrapper">
                    <div class="claim-file-container">
                        <div class="status-container" [ngClass]="{
                                'done-bg': pureOriginalService.pureOriginalDetails().status === 'DONE',
                                'waiting-bg': pureOriginalService.pureOriginalDetails().status === 'WAITING',
                                'in-progress-bg': pureOriginalService.pureOriginalDetails().status === 'IN_PROGRESS',
                                'error-text-bg': pureOriginalService.pureOriginalDetails().status === 'ERROR'
                           }">
                            <p [ngClass]="{
                                'done': pureOriginalService.pureOriginalDetails().status === 'DONE',
                                'waiting': pureOriginalService.pureOriginalDetails().status === 'WAITING',
                                'in-progress': pureOriginalService.pureOriginalDetails().status === 'IN_PROGRESS',
                                'error-text': pureOriginalService.pureOriginalDetails().status === 'ERROR'
                           }">{{pureOriginalService.pureOriginalDetails().status}}</p>
                        </div>
                        <div class="claim-btn-container">
                        <app-claim-file [pureOriginalId]="pureOriginalId()" [pureOriginalUser]="pureOriginalUser()"
                                        (checkIfButtonIsDisabled)="getDisabledButton($event)"></app-claim-file>
                        </div>
                    </div>
                    <app-pure-original-matching-details [pureOriginalId]="pureOriginalId()"
                                                        [pureOriginalMatchingId]="pureOriginalMatchingId()"
                                                        (isFileModalOpened)="openFileModal($event)"
                                                        (versionName)="getVersionName($event)"
                                                        [isClaimedFile]="disabled()"
                                                        [isImpossibleTuningUploaded]="isImpossibleTuningUploaded()"></app-pure-original-matching-details>
                </div>
                <div class="cards-wrapper">
                    <app-pure-originals-info [pureOriginalId]="pureOriginalId()"
                                             (isFileModalOpened)="openFileModal($event)"
                                             (pureOriginalUser)="getPureOriginalUser($event)"
                                             [buttonIsDisabled]="disabled()"></app-pure-originals-info>
                </div>
            }

        </div>

        @if (isFileModalOpened()) {
            <app-file-upload-modal [pureOriginalBaseId]="pureOriginalId().toString()" [versionName]="versionName()"
                                   [isFileModalOpened]="isFileModalOpened()"
                                   (isFileModalClosed)="closeModal($event)"></app-file-upload-modal>
        }

    </div>

</div>

