import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from "../../service/statistics.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-number-of-files-in-queue',
    templateUrl: './number-of-files-in-queue.component.html',
    styleUrls: ['./number-of-files-in-queue.component.css']
})

export class NumberOfFilesInQueueComponent implements OnInit, OnDestroy {

    public statisticService = inject(StatisticsService);
    private subscription: Subscription;

    ngOnInit() {
        this.subscription = this.statisticService.numberOfFilesInQueue();
    }

    ngOnDestroy () {
        if (this.statisticService.intervalId) {
            clearInterval(this.statisticService.intervalId);
        }
        this.subscription.unsubscribe();
    }
}
