import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AllUsersStatisticsModel} from "../models/AllUsersStatisticsModel";
import {AllUserStatisticsDetailsModel} from "../models/AllUserStatisticsDetailsModel";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})

export class UserStatisticsService {
    private url: string = `${this.API_URL}`;

    isLoading = signal<boolean>(false);
    public userStatistics = signal<AllUsersStatisticsModel[]>([]);
    userStatisticsItem = signal<AllUsersStatisticsModel>({
        firstName: '',
        masterCount: 0,
        pureOriginalCount: 0,
        tuningCount: 0,
        lastName: ''
    } as AllUsersStatisticsModel);
    userStatisticsDetailsItem = signal<AllUserStatisticsDetailsModel>({
        id: 0,
        pureOriginalId: 0,
        pureOriginalMatchingId: 0,
        versionName: '',
        versionComment: '',
        masterId: 0,
        workTime: '',
        actionType: '',
        status: '',
        winOlsReference: ''
    } as AllUserStatisticsDetailsModel)
    userStatisticsDetails = signal<AllUserStatisticsDetailsModel[]>([]);
    displayedTableHeaders = signal<string[]>([]);
    displayedUsersColumns = signal<(keyof AllUsersStatisticsModel)[]>([]);
    displayedUserDetailsColumn = signal<(keyof AllUserStatisticsDetailsModel)[]>([])

    isDateRangePickerModalOpened = signal<boolean>(false);
    public selectedTimeFrame = signal<string | undefined>('');
    userId: number;

    public startDate: string;
    public endDate: string;

    pageNumber = signal<number>(0);
    pageSize = signal<number>(50);


    private datePipe = inject(DatePipe);
    router = inject(Router);


    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    getAllTableHeaderUsersNames() {
        let objectKeys = Object.keys(this.userStatisticsItem());
        for (let key of objectKeys) {
            this.displayedUsersColumns().push(key as keyof AllUsersStatisticsModel);
        }
    }

    resetTableHeadersUserNames() {
        this.displayedUsersColumns.set([]);
        this.displayedTableHeaders.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
    }

    resetTableUserDetails() {
        this.displayedUserDetailsColumn.set([]);
        this.displayedTableHeaders.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
    }


    public getUserStatistics() {
        this.isLoading.set(true);
        this.httpClient.get<AllUsersStatisticsModel[]>(`${this.url}/userStatistics/all`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
            }
        }).subscribe(response => {
            this.userStatistics.set(response);
            this.isLoading.set(false)
        })
    }

    getUserStatisticsOnScroll() {
        this.isLoading.set(true);
        this.httpClient.get<AllUsersStatisticsModel[]>(`${this.url}/userStatistics/all`, {
            params: {
                'start date': this.startDate,
                'end date': this.endDate,
            }
        }).subscribe(response => {
            this.userStatistics.update((statistics) => [...statistics, ...response["content"]]);
            this.isLoading.set(false);
        });
    }

    increasePageNumber() {
        this.pageNumber.update((prevNum) => prevNum + 1);
    }

    public getUserStatisticsDetails(userId: number) {
        this.isLoading.set(true);
        this.httpClient.get<AllUserStatisticsDetailsModel[]>(`${this.url}/userStatistics/userId`, {
            params: {
                userId: userId,
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            }
        }).subscribe(response => {
            this.userStatisticsDetails.set(response["content"]);
            this.isLoading.set(false);
        })
    }

    getUserStatisticsDetailsOnScroll(userId:number) {
        this.httpClient.get<AllUserStatisticsDetailsModel[]>(`${this.url}/userStatistics/userId`, {
            params: {
                userId: userId,
                'start date': this.startDate,
                'end date': this.endDate,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            }
        }).subscribe(response => {
            this.userStatisticsDetails.update((details) => [...details, ...response["content"]]);
        })
    }

    getAllTableHeaderUserDetails() {
        let objectKeys = Object.keys(this.userStatisticsDetailsItem());
        for (let key of objectKeys) {
            this.displayedUserDetailsColumn().push(key as keyof AllUserStatisticsDetailsModel);
        }
    }


    closeDateRangePickerModal($event: any) {
        this.isDateRangePickerModalOpened.set($event.valueOf());
    }

    async submitUserFormDate($event: any) {
        const startDateFormValue = $event.valueOf().startDate;
        const endDateFormValue = $event.valueOf().endDate;

        this.startDate = new Date(startDateFormValue).toISOString();
        this.endDate = new Date(endDateFormValue).toISOString();
        this.getUserStatistics()
        const startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
        const endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
        this.selectedTimeFrame.set(startDate + ' - ' + endDate);
    }


    openDateRangePickerModal() {
        this.isDateRangePickerModalOpened.set(true);
    }

}


