import {Component, ElementRef, Inject, inject, OnInit, signal, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {FileService} from "../service/FileService.service";
import {fadeInAnimation} from "../animations/fadeInAnimation";
import {CurrentPartner, PartnersService} from "../service/Partners.service";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {LocalStorageService} from "../service/LocalStorageService";

@Component({
    selector: 'app-file-service-table',
    templateUrl: './file-service-table.component.html',
    styleUrls: ['./file-service-table.component.css'],
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''}
})
export class FileServiceTableComponent implements OnInit {

    public filterCount = signal<number>(0);

    fileService = inject(FileService);
    partnersService = inject(PartnersService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    localStorageService =inject(LocalStorageService)

    elRef = inject(ElementRef);
    @ViewChild(InfiniteScrollDirective) infiniteScrollDirective: { disposeScroller: { unsubscribe: () => void; }; setup: () => void; };


    constructor(@Inject('LOCAL_FILE_KEY') private fileServiceFilter: string) {
    }
    ngOnInit() {
        this.partnersService.currentPartner$.subscribe(() => {
            this.onRefreshTable();
        });

        this.restartTableValue();
        this.getTableHeaderNames();

        const savedFilters = this.localStorageService.getFilters(this.fileServiceFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.fileService.selectedFilters.set(savedFilters);
            this.updateFilterCount(savedFilters.length);
            this.getFilteredRecords(savedFilters);
        } else {
            this.route.queryParams.subscribe(value => {
                this.fileService.status.set(value['status']);
                this.fileService.startDate.set(value['startDate']);
                this.fileService.endDate.set(value['endDate']);

                switch (this.partnersService.currentPartner) {
                    case CurrentPartner.DynoChipTuningFiles: {
                        this.getAllFileServiceRecords();
                        break;
                    }
                    case CurrentPartner.ATMChipTunings: {
                        this.getAllImportsForPartners();
                        break;
                    }
                }
            });
        }
    }

    getFilteredRecords(filters: SearchFilterModel[]) {
        this.fileService.fileServiceTableIsLoading.set(true);
        this.fileService.triggerListImportsFilteredRequest(filters).subscribe(response => {
            this.fileService.fileServiceRecords.set(response);
            this.fileService.fileServiceTableIsLoading.set(false);
        });
    }


    restartTableValue() {
        this.fileService.restartTableValue();
    }

    getAllFileServiceRecords() {
        this.fileService.getAllFileServiceRecords();
    };

    getAllImportsForPartners() {
        this.fileService.getAllImportsForPartners()
    }

    getTableHeaderNames() {
        this.fileService.getTableHeaderNames();
    };

    deleteItemFromTable(id: number, tableName: string) {
        this.fileService.deleteItemFromTable(id, tableName)
    }

    downloadFileFromTable(importId: number, fileName: string) {
        this.fileService.downloadFileFromTable(importId, fileName);
    }

    onRefreshTable() {
        this.fileService.onRefreshTable()
    }

    openPureOriginalMatchingDetails(id: number) {
        this.router.navigate(['app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id}})
    }

    openAdvancedSearchSettings() {
        this.fileService.openAdvancedSearchSettings();
    }

    selectFilters(event: SearchFilterModel[]) {
        this.fileService.changeSelectFilters(event);


    }

    onAdvancedSettingClosed($event: boolean) {
        this.fileService.closeAdvancedSettingsModal($event.valueOf());
    }

    onSearchInput($event: any) {
        this.fileService.onSearchInput($event.valueOf());
    }
    onScroll () {
        this.fileService.onScroll();
    }
    onScrolledUp () {
        this.infiniteScrollDirective.disposeScroller.unsubscribe();
        this.infiniteScrollDirective.setup();
    }
    changeFileServiceTableSortDirection ($event: any) {
        const tableElement: HTMLElement = this.elRef.nativeElement.querySelector('.table-container');
        this.fileService.changeFileServiceTableSortDirection($event, tableElement);
    }
    isTableEmpty () {
        return this.fileService.isTableEmpty();
    }
    updateFilterCount(count: number) {
        this.filterCount.set(count);
    }
}
