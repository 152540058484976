<div class="main">
  <div class="bg">
    <div class="verification-wrapper">
      <div class="verification-container">
        <div class="logo">
          <img src="assets/logo-black.svg" alt="lg">
        </div>
        <div class="verification-content">
          <h1 class="verification-title">
            Hi {{firstName}},
          </h1>
          <h1 class="middle-text">We are happy you are signed up to DynoChipTuning. To start exploring the DynoChipTuning app please confirm your email address.</h1>
          <span class="middle-text">Details about verification are on <span class="middle-text-email">{{email}}</span></span>

        </div>
        <div class="team-text">
          <h2 class="verification-sub-title">Welcome to team</h2>
          <h2 class="verification-sub-title">The DynoChipTuning</h2>
        </div>
      </div>
    </div>
  </div>
</div>
