import { Component } from '@angular/core';

@Component({
  selector: 'app-pure-original-info-skeleton',
  templateUrl: './pure-original-info-skeleton.component.html',
  styleUrls: ['./pure-original-info-skeleton.component.css']
})
export class PureOriginalInfoSkeletonComponent {

}
