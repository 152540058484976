<!--<div class="content">-->
<!--    <div class="content-title skeleton"></div>-->
<!--    <div class="subtitle skeleton"></div>-->

    <div class="qr-container skeleton"></div>


<!--    <div class="qr-desc skeleton"></div>-->
<!--    <div class="form skeleton"></div>-->
<!--</div>-->
