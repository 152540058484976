<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="onRefreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>
        <!--        <div class="act-search-bar">-->
        <!--            <app-search-bar (searchValue)="onSearchInput($event)" [value]="'WinOls'"-->
        <!--                            [newInputValue]="allMasterService.searchString()"></app-search-bar>-->
        <!--            <div class="filtering-buttons">-->
        <!--                <button class="custom-btn" (click)="openAdvancedSearchSettings()"><span>Filters</span><i-->
        <!--                        class="bi bi-funnel"></i></button>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

    <!--    @if (allMasterService.openAdvanceSettings()) {-->
    <!--        <div>-->
    <!--            <app-advanced-search-bar [displayedColumns]="allMasterService.displayedColumns()"-->
    <!--                                     (advancedSettingClosed)="onAdvancedSettingClosed($event)"-->
    <!--                                     (selectedFilters)="selectFilters($event)"></app-advanced-search-bar>-->
    <!--        </div>-->
    <!--    }-->

    @defer (when !winOlsTransactionService.isLoading()) {

        @if (!isTableEmpty()) {
            <div class="table-container"
                 infinite-scroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 (scrolled)="winOlsTransactionService.onScroll()"
            >
                <app-win-ols-transaction-table [winOlsTransactions]="winOlsTransactionService.winOlsTransactions()"
                                               [sortDirectionValues]="winOlsTransactionService.sortDirectionValues()"
                                               [displayedTableHeaders]="winOlsTransactionService.displayedTableHeaders()"
                                               [displayedColumns]="winOlsTransactionService.displayedColumns()"
                                               (changeSortDirection)="changeSortDirection($event)"
                                               (downloadFileFromTable)="downloadFileFromTable($event)"
                >
                </app-win-ols-transaction-table>

            </div>
        } @else {
            <div class="empty-table-wrapper">
                <div class="empty-table">NO RESULTS</div>
            </div>
        }


    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }


</section>

