import {Component, HostListener} from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AutomaticChipTuningDashboard';

  constructor() {
  }


  @HostListener('window:resize', ['$event'])
  isMobile(): boolean {
    return window.innerWidth < 768
  }

}
