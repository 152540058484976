import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StatisticsModel} from "../models/statistics-model";
import {SystemInfoModel} from "../models/SystemInfo-model";
import {NumberOfFilesInQueueModel} from "../models/NumberOfFilesInQueue-model";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {CurrentPartner, PartnersService} from "./Partners.service";
import {PartnerStatisticsService} from "./PartnerStatistics.service";
import {firstValueFrom} from "rxjs";
import {WinOlsTransactionService} from "./winols-transaction.service";


@Injectable({
    providedIn: 'root'
})
export class StatisticsService {

    private url = `${this.API_URL}`;

    public isOpened = signal<boolean>(false);
    public isThemeChanged = signal<boolean>(false);
    public statisticRecords = signal<StatisticsModel>(new StatisticsModel());
    public numberOfFiles = signal<NumberOfFilesInQueueModel>(null);
    public isNumberOfFilesInQueueLoading = signal<boolean>(false);
    public isDateRangePickerOpened = signal<boolean>(false);
    public successful = signal<number | undefined>(0);
    public partlyTuned = signal<number | undefined>(0);
    public total = signal<number | undefined>(0);
    public failed = signal<number | undefined>(0);
    public currentPartner = signal<string>('');
    public selectedTimeFrame = signal<string | undefined>('');
    public isLoading = signal<boolean>(false);
    public systemInfoData = signal<SystemInfoModel[]>([]);
    public isServerStatsModalOpened = signal<boolean>(false);
    public startDate: Date;
    public endDate: Date;
    public intervalId: any;

    private httpClient = inject(HttpClient);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private datePipe = inject(DatePipe);
    private partnersService = inject(PartnersService);
    private partnersStatisticsService = inject(PartnerStatisticsService);
    winOlsService = inject(WinOlsTransactionService);

    constructor(@Inject('API_URL') private API_URL: string) {
        this.triggerIntervalEvery10Seconds();
    }

    openNavbarMenu() {
        this.isOpened.set(!this.isOpened());
    }

    async getStatisticsByTimeStamp(startDate: string, endDate: string) {
        const response = await firstValueFrom(this.httpClient.get<StatisticsModel>(`${this.url}/statistics/statisticsRecordsByTimestamp`, {
            params: {
                'start date': startDate,
                'end date': endDate
            }
        }))

        this.statisticRecords.set(response as StatisticsModel);
        return response;
    }

    async getStatisticsByTimeStampFromPartners(startDate: string, endDate: string) {
        try {
            const value = await firstValueFrom(
                this.partnersStatisticsService.getStatisticsByTimeStamp(startDate, endDate)
            );
            this.statisticRecords.set(value as StatisticsModel);
        } catch (error) {
            console.error('Error fetching partner statistics:', error);
        }
    }

    getSystemInfos() {
        this.isLoading.set(true);
        this.httpClient.get<SystemInfoModel[]>(`${this.url}/systemInfos`).subscribe(value => {
            this.systemInfoData.set(value as SystemInfoModel[]);
            this.isLoading.set(false);
        })
    }

    numberOfFilesInQueue() {
        if (this.numberOfFiles() == null) {
            this.isNumberOfFilesInQueueLoading.set(true);
        }
        return this.httpClient.get<NumberOfFilesInQueueModel>(`${this.url}/statistics/numberOfFilesInQueue`).subscribe(response => {
            this.numberOfFiles.set(response);
            this.isNumberOfFilesInQueueLoading.set(false);
        });
    }

    triggerIntervalEvery10Seconds() {
        this.intervalId = setInterval(() => {
            this.numberOfFilesInQueue();
        }, 10000)
    }

    async submitFormDate($event: any) {
        const startDateFormValue = $event.valueOf().startDate;
        const endDateFormValue = $event.valueOf().endDate;

        this.startDate = new Date(startDateFormValue);
        this.endDate = new Date(endDateFormValue);

        await this.getStatistics(this.startDate, this.endDate);

        this.winOlsService.startDate = this.startDate;
        this.winOlsService.endDate = this.endDate;

        this.winOlsService.getWinOlsStatistics(this.winOlsService.startDate, this.winOlsService.endDate).then();

        const startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
        const endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
        this.selectedTimeFrame.set(startDate + ' - ' + endDate);
    }


    async getStatistics(startDate: Date, endDate: Date) {
        try {
            switch (this.partnersService.currentPartner) {
                case CurrentPartner.DynoChipTuningFiles: {
                    await this.getStatisticsByTimeStamp(startDate.toISOString(), endDate.toISOString());
                    break;
                }
                case CurrentPartner.ATMChipTunings: {
                    await this.getStatisticsByTimeStampFromPartners(startDate.toISOString(), endDate.toISOString());
                    break;
                }
            }

            let success = 0;
            let fail = 0;
            let part = 0;
            let total = 0;

            this.statisticRecords.update(statisticRecords => {
                statisticRecords.statistics.map(stats => {
                    success += stats['Successful'];
                    fail += stats['Failed'];
                    part += stats['Partially tuned'];
                });
                total = success + fail + part;
                return statisticRecords;
            });

            this.successful.set(success);
            this.failed.set(fail);
            this.partlyTuned.set(part);
            this.total.set(total);
        } catch (error) {
            console.error('An error occurred while fetching statistics:', error);
        }
    }

    async getStatisticsByDatePicker($event: any) {
        this.startDate = $event.valueOf().startDate;
        this.endDate = $event.valueOf().endDate;
        this.currentPartner = $event.valueOf().currentPartner;

        await this.getStatistics(this.startDate, this.endDate);
    }


    openDateRangePickerModal() {
        this.isDateRangePickerOpened.set(true);
    }

    closeDateRangePickerModal($event: any) {
        this.isDateRangePickerOpened.set($event.valueOf());
    }

    openFileServiceByStatus(status: string) {
        this.router.navigate(['/app/file-service'], {
            queryParams: {
                startDate: this.startDate.toISOString(),
                endDate: this.endDate.toISOString(),
                status: status
            }, relativeTo: this.route
        });
    }

}
