import {Component, EventEmitter, inject, input,  Output} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-user-modal-component',
  templateUrl: './user-modal-component.component.html',
  styleUrls: ['./user-modal-component.component.css']
})
export class UserModalComponentComponent {

  public isUserSettingsOpened = input<boolean>(false);
  @Output()  closeUserSettingsModal = new EventEmitter<boolean>();

 public authService = inject(AuthService);
 public userService = inject(UserService);
 private router = inject(Router);


  openUserSettingsPage(id?: number) {
    if (this.authService.isAdmin()) {
      this.router.navigate(['/app/user-settings'], {queryParams: {id: id}});
    } else {
      this.router.navigate(['/app/user-settings']);
    }
    this.closeUserSettings()
  }


  logout() {
    this.authService.logout();
    this.closeUserSettings()
  }

  closeUserSettings() {
    this.closeUserSettingsModal.emit(false);
  }
}
