<div class="button-wrapper">
    <button class="button-85" role="button"
            (click)="claimFile()"
            *ngIf="!pureOriginalUser || isClaimed()"
            [ngClass]="!isClaimed() ? 'active' : ''">
        {{ !isClaimed() ? "CLAIM" : "UN CLAIM" }}
    </button>
    <span class="user-name"
          [ngClass]="pureOriginalUser?.userId != userService?.loggedUser()?.id  &&  pureOriginalUser ? 'fade-in' : ''"
          *ngIf="pureOriginalUser?.userId != userService?.loggedUser()?.id">Claimed By: {{ pureOriginalUser?.firstName + " " + pureOriginalUser?.lastName }}</span>
</div>
