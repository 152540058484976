import { Component } from '@angular/core';

@Component({
  selector: 'app-mfa-template-placeholder',
  templateUrl: './mfa-template-placeholder.component.html',
  styleUrl: './mfa-template-placeholder.component.css'
})
export class MfaTemplatePlaceholderComponent {

}
