<section class="main">
    <div class="table-title">
        <div class="head-button">
            <button class="r-btn" (click)="refreshTable()"><i class="icon bi bi-arrow-clockwise"></i></button>
        </div>
    </div>
    @defer (when !masterResultsService.isLoading()) {

        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="masterResultsService.onScroll()">

            <table mat-table [dataSource]="masterResultsService.masterResults()"
                   multiTemplateDataRows matSort matSortDisableClear
                   [matSortDirection]="masterResultsService.sortDirectionValues().direction"
                   [matSortActive]="masterResultsService.sortDirectionValues().active"
                   (matSortChange)="changeMasterSortDirection($event)"
            >

                @for (column of masterResultsService.displayedMasterResultsColumns(); track $index) {

                    <ng-container [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element[column] | shorten: 10000 }}
                        </td>
                    </ng-container>
                }

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>

                    <td mat-cell *matCellDef="let element">
                        @if (element.originalid) {
                            <button class="table-buttons" [disabled]="!element.originalid"
                                    (click)="openMasterDetailsItem(+element.originalid)"><i
                                    class="bi bi-car-front-fill"></i>
                            </button>
                        }

                        <button class="table-buttons"
                                (click)="deleteMasterRecord(+element.zipid, 'UPLOADING_RESULTS')"><i
                                class="bi bi-trash"></i></button>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="masterResultsService.displayedTableHeaders()"></tr>
                <tr mat-row *matRowDef="let element; columns: masterResultsService.displayedTableHeaders()"
                    class="body-columns"></tr>


            </table>
        </div>
    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

</section>


