import {Component, HostListener, inject, Inject, OnInit} from '@angular/core';
import {StatisticsService} from "../service/statistics.service";
import {AuthService} from "../service/auth.service";
import {UserService} from "../service/user.service";

@Component({
    selector: 'app-sidebar-component',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    public statisticService = inject(StatisticsService);
    public authService = inject(AuthService);
    public userService = inject(UserService);

    constructor(@Inject('PRODUCTION') public PRODUCTION: boolean) {
    }

    ngOnInit() {
        this.userService.getLoggedUser();
        if (this.authService.isAdmin()) {
            this.userService.getAllUsers();
        }
    }

    shrinkSidebarNavigation() {
        return this.statisticService.openNavbarMenu();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    getChangedThemeProperty($event: any) {
        setTimeout(() => {
            this.statisticService.isThemeChanged.set($event.valueOf());
        }, 0);
    }

    @HostListener('window:resize', ['$event'])
    isSmallScreen(): boolean {
        return window.innerWidth <= 1115
    }

}
