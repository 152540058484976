import {
    Component,
    EventEmitter, inject,
    input,
    OnInit,
    Output,
} from '@angular/core';
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";
import {UploadFileDialogService} from "../../service/uploadFileDialog.service";

@Component({
    selector: 'app-upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrl: './upload-file-dialog.component.css'
})
export class UploadFileDialogComponent {

    title = input<string>();


    @Output() public isFileModalClosed = new EventEmitter<boolean>;

    private allMasterService = inject(AllMastersDatabaseService);

    private uploadFIleService = inject(UploadFileDialogService)

    @Output() closeDialog = new EventEmitter<boolean>();


    closeModal() {
        this.isFileModalClosed.emit(false)
        this.uploadFIleService.triggerReset()
    }

}

