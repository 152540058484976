import {Component, inject, OnInit} from '@angular/core';
import {StatisticsService} from "../../service/statistics.service";


@Component({
  selector: 'app-system-info-chart',
  templateUrl: './system-info-chart.component.html',
  styleUrls: ['./system-info-chart.component.css']
})
export class SystemInfoChartComponent implements OnInit{

  public statisticService = inject(StatisticsService);



  ngOnInit() {
    this.statisticService.isLoading.set(true);
    this.getSystemInfos();
    this.statisticService.isLoading.set(false);
  }

  getSystemInfos() {
    this.statisticService.getSystemInfos();

  }
}

