import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocalAuthService} from "../../service/localAuth.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {matchValidator} from "./passwordValidator";

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnChanges {

    @Input() params: string;
    public isLoading: boolean = false;
    private token: string;

    constructor(private localAuthService: LocalAuthService, private toastr: ToastrService, private router: Router) {
    }

    resetFormGroup = new FormGroup({
        newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(`^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+{}\\":;'?/<>,.]).{8,}$`), matchValidator('confirmNewPassword', true)]),
        confirmNewPassword: new FormControl('', [Validators.required, matchValidator('newPassword')]),
    })

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.token = this.params;
        }
    }

    resetPasswordHandler() {

        this.isLoading = true;
        this.localAuthService.resetPassword(this.token, this.resetFormGroup.value.newPassword, this.resetFormGroup.value.confirmNewPassword).subscribe(response => {
            if (response.status == 200) {
                this.isLoading = false;
                this.toastr.success(response.body["message"]);
                setTimeout(() => {
                    this.router.navigate(["/login"])
                }, 2000)
            }
        }, error => {
            if (error.status == 409) {
                this.isLoading = false;
                this.toastr.error(error.error.message);
                this.resetFormGroup.reset();
            }
            if (error.status == 400) {
                this.isLoading = false;
                this.toastr.error(error.error.message);
                this.resetFormGroup.reset();
            }
        })
    }

}
