<div class="tuning-details-wrapper">
    @if (pureOriginalsService.tableIsLoading()) {
        <app-pure-original-matching-details-skeleton></app-pure-original-matching-details-skeleton>
    }
    <div
            class="tuning-details-container"
            *ngIf="!pureOriginalsService.tableIsLoading()"
    >
        @if (pureOriginalsService.getSuccessfullyTunedMatchingDetails().length > 0) {
            <h2>Completed</h2>
        }
        @for (completedTuning of
            pureOriginalsService.sortedListByTuningNames(true); track $index) {

            <div
                    class="tuning-details"
                    [ngClass]="{
        'highlight-card':
          completedTuning.tuningName === versionNameParam() ||
          pureOriginalMatchingId() ===
            +completedTuning.pureOriginalMatchingDetailsId,
        'highlighted-queue-card': checkIfVersionIsInQueue(
          completedTuning.tuningName
        )
      }"
            >
                @if (checkIfVersionIsInQueue(completedTuning.tuningName)) {
                    <div class="queue-container">
                        <span>In Queue</span>
                    </div>
                }
                <div class="main-content-wrapper">
                    <div class="corners-wrapper">
                        <div class="left-corner-content">
                            <div
                                    class="top-left-corner"
                                    [ngClass]="+completedTuning.hitRate == 1 ? 'green' : ''"
                            ></div>
                            <div class="checkbox-wrapper">
                                <label>
                                    <input
                                            type="checkbox"
                                            [disabled]="isDisabledCheckbox(completedTuning.tuningName)"
                                            [checked]="completedTuning.enabled"
                                            (change)="
                    changeTuningStatus(
                      $event,
                      completedTuning.pureOriginalMatchingDetailsId,
                      completedTuning.tuningName
                    )
                  "
                                            class="custom-checkbox"
                                    />
                                    <span class="custom-checkmark"></span>
                                </label>
                                @if (isClaimedFile()) {
                                    <span class="tooltip-checkbox">Please claim the file</span>
                                }
                            </div>
                        </div>
                        <div class="right-corner-content">
                            <div class="top-right-corner">
                                <div class="main-content-header">
                                    <span class="tuning">Times requested:</span>
                                    <span class="tuning">{{ completedTuning.timesRequested }}</span>
                                </div>
                            </div>
                            <div class="main-content-header">
                                <div class="button-name-wrapper">
                                    <div class="tuning-name-button">
                                        <button
                                                (click)="
                      masterDetails(
                        +completedTuning.masterId,
                        completedTuning.tuningName
                      )
                    "
                                        >
                                            {{ completedTuning.versionWinOLSReference | shorten : 20 }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tuning-container">
                        <div class="tuning-title-name">
                            <span class="tuning-title"></span>
                            <span class="tuning-title">{{
                                    completedTuning.tuningName | shorten : 27
                                }}</span>

                            <div class="tuning-comment-container">
              <span class="tuning-comment">{{
                      completedTuning.versionComment | shorten : 30
                  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-container-position">
                    <div class="buttons-container">
                        <div class="update-button-container">
                            <div class="buttons">
                                <button
                                        class="r-btn"
                                        (click)="openFileUploadModal(completedTuning.tuningName)"
                                        [disabled]="isClaimedFile()"
                                        [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                >
                                    <span class="btn-text-update">Update</span>
                                </button>
                            </div>
                        </div>
                        <div class="buttons-delete">
                            <button
                                    class="r-btn"
                                    (click)="
                deleteVersionTuning(
                  'pureOriginalVersions',
                  +completedTuning.pureOriginalMatchingDetailsId,
                  'PURE_ORIGINAL_MATCHING'
                )
              "
                                    [disabled]="
                isClaimedFile() ||
                pureOriginalsService.isDeletingMap()[
                  +completedTuning.pureOriginalMatchingDetailsId
                ]
              "
                                    [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                            >
                                <span class="btn-text-delete">Delete</span>
                            </button>
                        </div>
                    </div>
                    <div class="download-buttons-container">
                        <button
                                (click)="
              downloadVersionFile(
                +completedTuning.pureOriginalMatchingDetailsId,
                completedTuning.versionWinOLSReference,
                'PURE_ORIGINAL_MATCHING'
              )
            "
                                [disabled]="
              isDownloadInProgress(
                +completedTuning.pureOriginalMatchingDetailsId
              )
            "
                        >
            <span class="btn-text"
            >Download<i class="bi bi-arrow-down-short"></i
            ></span>
                        </button>
                    </div>
                </div>
            </div>
        }
        @if (pureOriginalsService.getPartiallyTunedMatchingDetails().length > 0) {
            <h2>Partially Tuned</h2>
        }
        @for (partialTuning of
            pureOriginalsService.sortedListByTuningNames(false); track $index) {
            <div
                    class="tuning-details"
                    [ngClass]="{
        'highlight-card':
          partialTuning.tuningName === versionNameParam() ||
          pureOriginalMatchingId() ===
            +partialTuning.pureOriginalMatchingDetailsId,
        'highlighted-queue-card': checkIfVersionIsInQueue(
          partialTuning.tuningName
        )
      }"
            >
                @if (checkIfVersionIsInQueue(partialTuning.tuningName)) {
                    <div class="queue-container">
                        <span>In Queue</span>
                    </div>
                }

                <div class="main-content-wrapper">
                    <div class="corners-wrapper">
                        <div class="left-corner-content">
                            <div
                                    class="top-left-corner"
                                    [ngClass]="+partialTuning.hitRate < 1 ? 'orange' : ''"
                            ></div>
                            <div class="checkbox-wrapper">
                                <label>
                                    <input
                                            type="checkbox"
                                            [disabled]="isDisabledCheckbox(partialTuning.tuningName)"
                                            [checked]="partialTuning.enabled"
                                            (change)="
                    changeTuningStatus(
                      $event,
                      partialTuning.pureOriginalMatchingDetailsId,
                      partialTuning.tuningName
                    )
                  "
                                            [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                            class="custom-checkbox"
                                    />
                                    <span class="custom-checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="right-corner-content">
                            <div class="top-right-corner">
                                <div class="main-content-header">
                                    <span class="tuning">Times requested:</span>
                                    <span class="tuning">{{ partialTuning.timesRequested }}</span>
                                </div>
                                <div class="main-content-header">
                                    <div class="button-name-wrapper">
                                        <div class="tuning-name-button">
                                            <button
                                                    (click)="
                        masterDetails(
                          +partialTuning.masterId,
                          partialTuning.tuningName
                        )
                      "
                                            >
                                                {{ partialTuning.versionWinOLSReference | shorten : 20 }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tuning-title-name">
          <span class="tuning-title">{{
                  partialTuning.tuningName | shorten : 20
              }}</span>
                    </div>
                    <div class="tuning-comment-wrapper">
                        <div class="tuning-comment-container">
            <span class="tuning-comment color">{{
                    partialTuning.versionComment | shorten : 20
                }}</span>
                        </div>
                        <div
                                class="percentage-container"
                                [ngClass]="+partialTuning.hitRate < 1 ? 'orange' : ''"
                        >
            <span class="number">{{
                    (+partialTuning.hitRate * 100).toFixed(2)
                }}</span>
                            <i class="bi bi-percent number"></i>
                        </div>
                    </div>
                </div>
                <div class="button-container-position">
                    <div class="buttons-container">
                        <div class="upload-button-container">
                            <div class="buttons">
                                @if (+partialTuning.hitRate < 1) {
                                    <button
                                            class="r-btn"
                                            (click)="openFileUploadModal(partialTuning.tuningName)"
                                            [disabled]="isClaimedFile()"
                                            [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                    >
                                        <span class="btn-text-update">Upload</span>
                                    </button>
                                }
                            </div>
                        </div>

                        <div class="buttons-delete">
                            <button
                                    class="r-btn"
                                    (click)="
                deleteVersionTuning(
                  'pureOriginalVersions',
                  +partialTuning.pureOriginalMatchingDetailsId,
                  'PURE_ORIGINAL_MATCHING'
                )
              "
                                    [disabled]="
                isClaimedFile() ||
                pureOriginalsService.isDeletingMap()[
                  +partialTuning.pureOriginalMatchingDetailsId
                ]
              "
                                    [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                            >
                                <span class="btn-text-delete">Delete</span>
                            </button>
                        </div>
                    </div>

                    <div class="download-buttons-container">
                        <button
                                (click)="
              downloadVersionFile(
                +partialTuning.pureOriginalMatchingDetailsId,
                partialTuning.versionWinOLSReference,
                'PURE_ORIGINAL_MATCHING'
              )
            "
                                [disabled]="
              isDownloadInProgress(+partialTuning.pureOriginalMatchingDetailsId)
            "
                        >
            <span class="btn-text"
            >Download<i class="bi bi-arrow-down-short"></i
            ></span>
                        </button>
                    </div>
                </div>
            </div>
        }
        @if (pureOriginalsService.pureOriginalMatchingDetails().pureOriginalMissingVersions.length
        > 0) {
            <h2>Missing Tunings</h2>
        }
        @for (missing of
            pureOriginalsService.pureOriginalMatchingDetails().pureOriginalMissingVersions;
            track $index) {

            <div
                    class="tuning-details"
                    [ngClass]="{
        'highlight-card': missing.tuningName === versionNameParam(),
        'highlighted-queue-card': checkIfVersionIsInQueue(missing.tuningName)
      }"
            >
                @if (checkIfVersionIsInQueue(missing.tuningName)) {
                    <div class="queue-container">
                        <span>In Queue</span>
                    </div>
                }
                <div class="main-content-wrapper">
                    <div class="corners-wrapper">
                        <div class="left-corner-content">
                            <div class="top-left-corner-missing"></div>
                            <div class="checkbox-wrapper">
                                <label>
                                    <input
                                            type="checkbox"
                                            [disabled]="isDisabledCheckbox(missing.tuningName)"
                                            [checked]="missing.enabled"
                                            (change)="
                    changeTuningStatus($event, missing.id, missing.tuningName)
                  "
                                            class="custom-checkbox"
                                            [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                    />
                                    <span class="custom-checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="right-corner-content">
                            <div class="top-right-corner">
                                <div class="main-content-header">
                                    <span class="tuning">Times Requested:</span>
                                    <span class="tuning">{{ missing.timesRequested }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tuning-container">
                        <div class="tuning-title-name">
            <span class="tuning-title-missing">{{
                    missing.tuningName | shorten : 20
                }}</span>
                        </div>
                        <div class="missing-percentage-container">
                            <span class="numbers">0</span>
                            <i class="bi bi-percent"></i>
                        </div>
                    </div>
                </div>

                <div class="button-container-position">
                    <div class="missing-buttons-container">
                        <div class="missing-button-upload">
                            <div class="buttons">
                                <button
                                        class="r-btn"
                                        (click)="openFileUploadModal(missing.tuningName)"
                                        [disabled]="isClaimedFile()"
                                        [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                >
                                    <span class="btn-text-update">Upload</span>
                                </button>
                            </div>
                        </div>
                        <div class="buttons-delete">
                            <button
                                    class="r-btn"
                                    (click)="
                deleteVersionTuning(
                  'pureOriginalMissingVersions',
                  +missing.id,
                  'PURE_ORIGINAL_MISSING_VERSIONS'
                )
              "
                                    [disabled]="
                isClaimedFile() ||
                pureOriginalsService.isDeletingMap()[+missing.id]
              "
                                    [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                            >
                                <span class="btn-text-delete">Delete</span>
                            </button>
                        </div>
                    </div>
                    <div class="download-buttons-container">
                        <button
                                (click)="
              downloadMissingTuning(
                +missing.id,
                pureOriginalsService.pureOriginalDetails().winOlsReference,
                'PURE_ORIGINAL_MISSING_VERSIONS'
              )
            "
                                [disabled]="isDownloadInProgress(+missing.id)"
                        >
            <span class="btn-text"
            >Download <i class="bi bi-arrow-down-short"></i
            ></span>
                        </button>
                    </div>
                </div>
            </div>
        }
        @if (pureOriginalsService.pureOriginalMatchingDetails().pureOriginalImpossibleTunings.length
        > 0) {
            <h2>Impossible Tunings</h2>
        }
        @for (impossible of
            pureOriginalsService.pureOriginalMatchingDetails().pureOriginalImpossibleTunings;
            track $index) {
            <div class="tuning-details">
                <div class="main-content-wrapper">
                    <div class="top-left-corner-grey"></div>
                    <div class="checkbox-wrapper-impossible">
                        <label>
                            <input
                                    type="checkbox"
                                    [disabled]="isDisabledCheckbox(impossible.tuningName)"
                                    [checked]="impossible.enabled"
                                    [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                                    (change)="
                changeTuningStatus($event, impossible.id, impossible.tuningName)
              "
                                    class="custom-checkbox-impossible"
                            />
                            <span class="custom-x"></span>
                        </label>
                    </div>
                    <div class="tuning-title-name-impossible">
                        <span class="tuning-title">{{ impossible.tuningName }}</span>
                    </div>
                </div>
                <div class="impossible-button-container">
                    <div class="impossible-buttons">
                        <button
                                class="r-btn"
                                (click)="
              deleteVersionTuning(
                'pureOriginalImpossibleTunings',
                +impossible.id,
                'PURE_ORIGINAL_MISSING_VERSIONS'
              )
            "
                                [disabled]="
              isClaimedFile() ||
              pureOriginalsService.isDeletingMap()[+impossible.id]
            "
                                [matTooltip]="isClaimedFile() ? 'Please claim the file' : ''"
                        >
                            <span class="btn-text-impossible">Delete</span>
                        </button>
                    </div>
                </div>
            </div>
        }
        @if (pureOriginalsService.newVersions().length > 0) {
            <h2>New Tunings</h2>
        }
        @for (version of pureOriginalsService.newVersions(); track $index) {
            <div class="tuning-details">
                <div class="queue-container">
                    <span>In Queue</span>
                </div>
                <div class="new-version-wrapper">
                    <div class="new-version-title">
                        <span>{{ version | shorten : 20 }}</span>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
