import {Component, EventEmitter, inject, Inject, Input, OnInit, Output} from '@angular/core';
import { SearchFilterModel } from '../../models/SearchFilter-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../service/LocalStorageService';
import {PureOriginalService} from "../../service/pure-original.service";

@Component({
    selector: 'app-pure-originals-filters',
    templateUrl: './pure-originals-filters.html',
    styleUrls: ['./pure-originals-filters.css']
})
export class PureOriginalsFilters implements OnInit {

    public selectedOptions: SearchFilterModel[] = [];
    public groupKey: string = '';

    @Input() displayedColumns: undefined | string[];
    @Output() pureOriginalsFilterClosed = new EventEmitter<boolean>();
    @Output() selectedFilters = new EventEmitter<SearchFilterModel[]>();
    @Output() selectedFiltersChange = new EventEmitter<SearchFilterModel[]>();
    @Output() filterCount = new EventEmitter<number>();

    private localStorageService = inject(LocalStorageService);
    private pureOriginalService= inject(PureOriginalService)

    searchFilterForm = new FormGroup({});

    constructor(@Inject('LOCAL_STORAGE_KEY') private pureOriginalsFilter: string) {}

    ngOnInit(): void {
        const savedFilters = this.localStorageService.getFilters(this.pureOriginalsFilter);
        if (savedFilters && savedFilters.length > 0) {
            this.searchFilterForm = this.localStorageService.createFormGroupFromFilters(savedFilters, this.displayedColumns || []);
            this.selectedFilters.emit(savedFilters);
            this.filterCount.emit(savedFilters.length);
        }
    }

    addFormControl() {
        const control = new FormGroup({
            'option': new FormControl(this.displayedColumns![0]),
            'value': new FormControl('', Validators.required)
        });
        this.searchFilterForm.addControl(`control-${Object.keys(this.searchFilterForm.controls).length + 1}`, control);
    }

    clearFilters() {
        this.localStorageService.clearFilters(this.pureOriginalsFilter);
        const controlKeys = Object.keys(this.searchFilterForm.controls);
        controlKeys.forEach(key => {
            this.searchFilterForm.removeControl(key);
        });
        this.updateFilterCount();
        this.pureOriginalService.getPureOriginals()

    }

    closeModal() {
        this.pureOriginalsFilterClosed.emit(false);
    }

    onSubmit() {
        this.selectedOptions = [];
        for (const group in this.searchFilterForm.controls) {
            const value = this.searchFilterForm.get(group)?.value.value as string;
            const trimmedValue = value.trim();
            this.selectedOptions.push({
                option: this.searchFilterForm.get(group)?.value.option,
                value: trimmedValue
            });
        }
        this.localStorageService.saveFilters(this.pureOriginalsFilter, this.selectedOptions);
        this.selectedFilters.emit(this.selectedOptions);
        this.pureOriginalsFilterClosed.emit(false);
        this.updateFilterCount();
    }

    deleteSearchBar(key: string) {
        this.searchFilterForm.removeControl(key);
        this.updateFilterCount();
    }

    hasFormControls(): boolean {
        return Object.keys(this.searchFilterForm.controls).length > 0;
    }

     updateFilterCount() {
        this.filterCount.emit(Object.keys(this.searchFilterForm.controls).length);
    }
}

