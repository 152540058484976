
<div class="login">
  <form class="form" [formGroup]="userLoginForm">
    <div class="form-title">
      <h2>Login</h2>
      <span>Please enter your details.</span>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Email" formControlName="email"/>

      <div class="error-message" *ngIf="userLoginForm.get('email')?.invalid && (userLoginForm.get('email')?.touched || userLoginForm.get('email')?.dirty)">
        <div *ngIf="userLoginForm.get('email').hasError('email')">
          Email must contain &#64; symbol.

        </div>
        <div *ngIf="userLoginForm.get('email').hasError('required')">
          Required field.
        </div>
      </div>
    </div>
    <div class="form-group">
      <input class="form-control" type="password" placeholder="Password" formControlName="password"/>
        <span class="forgot-password-text" (click)="forgotPasswordHandler()">Forgot Password?</span>

      <div class="error-message" *ngIf="userLoginForm.get('password')?.invalid && (userLoginForm.get('password')?.touched || userLoginForm.get('password')?.dirty)">
        <div *ngIf="userLoginForm.get('password').hasError('required')">
          Required field.
        </div>
        <div *ngIf="userLoginForm.get('password').hasError('minLength')">
          Password must be at least 8 characters long.
        </div>
        <div *ngIf="userLoginForm.get('password').hasError('pattern')">
          Password must contain capital letter, symbol and number.
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="custom-btn" (click)="loginUser()" [disabled]="!userLoginForm.valid || isClicked">Login</button>
      <p (click)="switchMode()" class="switch-button"><span class="connector">or</span><span class="main-text">Sign Up</span></p>
    </div>
  </form>
</div>
