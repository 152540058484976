import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserRegisterService} from "../../service/userRegister.service";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {EmailValidatorService} from "../../service/EmailValidator.service";
import {matchValidator} from "../../reset-password/reset-password-form/passwordValidator";

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent {

  public isEmailInputTouched: boolean = false;

  constructor(private router: Router, private registerService: UserRegisterService, private toastr: ToastrService, private emailValidationService: EmailValidatorService) {

  }

  userSignUpForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email], [this.emailValidationService.emailValidator()]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,}$")]),
    confirmNewPassword: new FormControl('', [Validators.required, matchValidator('newPassword')]),
    phoneNumber: new FormControl('', Validators.required)
  })

  signUpUser() {
    this.registerService.registerUser({
      firstName: this.userSignUpForm.value.firstName.trim(),
      lastName: this.userSignUpForm.value.lastName.trim(),
      email: this.userSignUpForm.value.email,
      password: this.userSignUpForm.value.newPassword,
      phoneNumber: this.userSignUpForm.value.phoneNumber
    }).subscribe(response => {
      if (response.status == 201) {
        this.router.navigate(['verification'], {
          queryParams: {
            firstName: this.userSignUpForm.value.firstName,
            email: this.userSignUpForm.value.email
          }, skipLocationChange: true
        });
      }
    }, (error: HttpErrorResponse) => {
      this.handleRegisterFormErrors(error.status);
    })

  }

  handleRegisterFormErrors(responseError: number) {
    if (responseError == 400) {
      this.toastr.error("Bad Request", "Sing Up failed!", {positionClass: 'toast-top-right'});
    }
    if (responseError == 409) {
      //   ASYNC VALIDATOR ZA EMAIL

      this.toastr.error('Email already exists in DB', 'Sign Up Failed', {positionClass: 'toast-top-right'});
    }
    if (responseError == 500) {
      this.toastr.error('Internal Error with email sender service.', 'Sign up Failed', {positionClass: 'toast-top-right'})
    }
  }

  onBlurMethod() {
    this.isEmailInputTouched = true;
  }

  switchMode() {
    this.router.navigate(['/login'])
  }


}
