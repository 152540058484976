import {inject, Injectable, signal} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from "@angular/forms";
import {
    CustomPureOriginalUploadValidations
} from "../pure-originals/pure-originals-upload/pure-originals-upload.component";
import {FileUploadService} from "./fileUpload.service";
import {NotificationService} from "./Notification.service";

@Injectable({
    providedIn: 'root'
})
export class PureOriginalsUploadService {

    public binFile = signal<File | null>(null);
    public iniFile = signal<File | null>(null);
    public hasAnyError = signal<boolean>(false);
    public isUploading = signal<boolean>(false);
    public resetSignal = signal<boolean>(false);

    winOls = new FormControl('', [Validators.required, this.olsValidator]);

    myInputs = new Map<string, CustomPureOriginalUploadValidations>([
        ["ini", {
            message: '',
            hasError: false,
            pureOriginalId: null
        }],
        ["bin", {
            message: '',
            hasError: false,
            pureOriginalId: null,

        }],
        ["winOls", {
            message: '',
            hasError: false,
            pureOriginalId: null
        }]
    ]);

    olsValidator(control: FormControl): ValidationErrors | null {
        const value = control.value;
        if (value && !value.endsWith('.ols')) {
            return {'olsFormat': true};
        }

        return null;
    }

    fileUploadService = inject(FileUploadService)
    notificationService = inject(NotificationService);

    checkForChanges() {
        this.hasAnyError.set(false);
        this.winOls.valueChanges.subscribe((value) => {
            function otherFormsHasNoErrors(myInputs: Map<string, CustomPureOriginalUploadValidations>) {
                return !myInputs.get('ini').hasError || !myInputs.get('bin').hasError;
            }

            if (value.length >= 1 && otherFormsHasNoErrors(this.myInputs)) {
                this.hasAnyError.set(false);
                this.myInputs.set('winOls', {
                    message: '',
                    hasError: false,
                    pureOriginalId: null
                });
            }
        });
    }

    refreshUpload() {
        this.winOls.setValue('');
        this.hasAnyError.set(false);
        this.iniFile.set(null);
        this.binFile.set(null);
        this.resetSignal.set(true);
        this.myInputs = new Map<string, any>([
            ["ini", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }],
            ["bin", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }],
            ["winOls", {
                message: '',
                hasError: false,
                pureOriginalId: null
            }]
        ]);
    }

    uploadBin(file: File) {
        if (file) {
            this.hasAnyError.set(false);
            this.myInputs.set('bin', {
                message: '',
                hasError: false,
                pureOriginalId: null
            })
            this.binFile.set(file);
        }
    }

    uploadIni(file: File) {
        if (file) {
            this.hasAnyError.set(false);
            this.myInputs.set('ini', {
                message: '',
                hasError: false,
                pureOriginalId: null
            })
            this.iniFile.set(file);
        }
    }

    uploadAllFiles() {
        this.isUploading.set(true);
        this.fileUploadService.uploadPureOriginalFile(this.winOls.value, this.binFile(), this.iniFile())
            .subscribe(
                (response) => {
                    this.isUploading.set(false);
                    if (response.status === 200) {
                        this.notificationService.showSuccessMessage(`<div><span>Your file is uploaded</span></div>`, "Success");
                    }
                    this.refreshUpload();
                },
                (error) => {
                    this.hasAnyError.set(true);
                    this.isUploading.set(false);
                    if (error.status === 409) {
                        const message = error.error["status"];
                        const pureOriginalBaseId = error.error.pureOriginalBaseId;
                        this.errorMessages(message, pureOriginalBaseId);
                    } else if (error.status === 500) {
                        this.myInputs.set("ini", {
                            message: "Internal Server Error.",
                            hasError: true,
                            pureOriginalId: null
                        })
                    }
                    else {
                        this.myInputs.set("ini", {
                            message: 'Wrong BIN or INI file',
                            hasError: true,
                            pureOriginalId: null
                        });
                        console.error('Unexpected error:', error);
                    }
                }
            );
    }

    errorMessages(message: string, pureOriginalBaseId: number) {
        console.log(message);
        if (message.startsWith("MISSING_ATTRIBUTES") || message.startsWith("WRONG_ECU_BUILD")) {
            this.myInputs.set("ini", {
                message: message,
                hasError: true,
                pureOriginalId: null
            })
            return;
        }
        if (message.startsWith("WRONG_ECU_PRODUCER:")) {
            this.myInputs.set("ini", {
                message: message,
                hasError: true,
                pureOriginalId: null
            });
            return;
        }
        switch (message) {
            case 'WinOLS_EXISTS':
                this.myInputs.set("winOls", {
                    message: 'WinOLS already exist.',
                    hasError: true,
                    pureOriginalId: pureOriginalBaseId
                });
                break;
            case 'INVALID_INI_FILE_EXTENSION':
                this.myInputs.set("ini", {
                    message: "Invalid INI file extension.",
                    hasError: true,
                    pureOriginalId: null
                })
                break;
            case 'INI_FILE_SIZE_IS_GREATER_THAN_6KB':
                this.myInputs.set("ini", {
                    message: "INI file size is greater than 6KB.",
                    hasError: true,
                    pureOriginalId: null
                })
                break;
            case 'EMPTY_INI':
                this.myInputs.set("ini", {
                    message: 'Empty INI.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'ECU_PRODUCER_MISSING':
                this.myInputs.set("ini", {
                    message: 'Ecu producer missing.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;

            case 'ECU_BUILD_MISSING':
                this.myInputs.set("ini", {
                    message: 'Ecu Build Missing.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'ERROR_PARSING_ATTRIBUTE: EngineDisplacement':
                this.myInputs.set("ini", {
                    message: 'Wrong parsing Engine Displacement.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'ERROR_PARSING_ATTRIBUTE: EngineMaxTorque':
                this.myInputs.set("ini", {
                    message: 'Wrong parsing Engine MaxTorque.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'ERROR_PARSING_ATTRIBUTE: OutputPS':
                this.myInputs.set("ini", {
                    message: 'Wrong parsing OutputPS.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'ERROR_PARSING_ATTRIBUTE: OutputKW':
                this.myInputs.set("ini", {
                    message: 'Wrong parsing OutputKW.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'EMPTY_BIN':
                this.myInputs.set("bin", {
                    message: 'Empty BIN.',
                    hasError: true,
                    pureOriginalId: null
                });
                break;
            case 'INVALID_BIN_FILE_EXTENSION':
                this.myInputs.set("bin", {
                    message: "Invalid bin file extension.",
                    hasError: true,
                    pureOriginalId: null
                })
                break;
            case 'BIN_FILE_SIZE_IS_LESS_THAN_6KB':
                this.myInputs.set("bin", {
                    message: "Bin file size is less than 6KB.",
                    hasError: true,
                    pureOriginalId: null
                })
                break;
            case 'PURE_ORIGINAL_BIN_EXISTS':
                this.myInputs.set("bin", {
                    message: 'This Pure original BIN already exist.',
                    hasError: true,
                    pureOriginalId: pureOriginalBaseId
                });
                break;
            default:
                console.error('Unknown error:', message);
        }
    }
}
