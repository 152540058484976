import {Component, inject} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PureOriginalService} from "../../service/pure-original.service";
import {AllMastersDatabaseService} from "../../service/all-masters-database.service";

@Component({
    selector: 'app-search-pure-originals',
    templateUrl: './search-pure-originals.component.html',
    styleUrls: ['./search-pure-originals.component.css']
})
export class SearchPureOriginalsComponent {

    pureOriginalService = inject(PureOriginalService);
    allMasterService = inject(AllMastersDatabaseService);
    router = inject(Router)

    searchPureOriginalForm = new FormGroup({
        searchString: new FormControl('', [Validators.required])
    })


    constructor() {
        this.searchPureOriginalForm.controls['searchString'].valueChanges.subscribe((value) => {
            this.onSearchInput(value);
        });
        this.restartPureOriginalList();
        this.restartMasterList();
    }


    onSearchInput(searchString: string) {
        if (searchString.length < 3) {
            this.restartPureOriginalList();
            this.restartMasterList();
            return;
        }

        this.pureOriginalService.pageNumber.set(0);
        this.pureOriginalService.pageSize.set(5);
        this.allMasterService.pageNumber.set(0);
        this.allMasterService.pageSize.set(5)

        if (searchString.toLowerCase().includes('po')) {
            this.pureOriginalService.getPureOriginalsByWinOlsDbName(searchString.toLowerCase()).subscribe(response => {
                this.pureOriginalService.searchedResults.set(response['content']);
            });
        } else {
            this.allMasterService.getHomeSearchResultsByWinOls(searchString.toLowerCase());
        }
    }


    restartPureOriginalList() {
        this.pureOriginalService.searchedResults.set([]);
    }

    restartMasterList() {
        this.allMasterService.searchedResults.set([]);
    }

    openPureOriginalDetails(id: number) {
        this.router.navigate(['/app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: id}});
        this.searchPureOriginalForm.value.searchString = '';
        this.restartPureOriginalList();
    }

    openPureOriginalMatchingDetails(id: number) {
        this.router.navigate([`app/master-details/${id}`]);
        this.searchPureOriginalForm.value.searchString = '';
        this.restartMasterList()
    }
}
