<div class="dialog-content">
    <div mat-dialog-title class="dialog-title">
        <span>Enable MFA</span><button (click)="canceledOption()"><i class="bi bi-x-lg"></i></button></div>
    <div class="underline"></div>
    <div mat-dialog-content class="dialog-text">{{data}}</div>
    <div mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="confirmedOption()">Ok</button>
        <button mat-raised-button class="dialog-btn" (click)="canceledOption()">Cancel</button>
    </div>
</div>
