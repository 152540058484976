export class FileServiceRecordModel {
  public clientnumber: string;
  public resellerprojectdetails: string;
  public status: string;
  public pureoriginalid: string;
  public processingtime: string;
  public dateuploaded: string;
  public blobFileName: string
  public clientlicenceplace: string
  public clientname: string;
  public comment1: string;
  public comment2: string;
  public credittype: string;
  public ecubuild: string;
  public ecuprocessor: string;
  public ecuprodnr: string;
  public ecuproducer: string;
  public ecusoftwareversion: string;
  public ecusoftwareversionversion: string;
  public ecustgnr: string;
  public ecuuse: string;
  public enginedisplacement: string;
  public engineemission: string;
  public engineemmisionstandard: string;
  public enginegear: string;
  public enginemaxtorque: string;
  public enginename: string;
  public engineproducer: string;
  public enginetransmission: string;
  public enginetype: string;
  public extramanualtuningrequired: string;
  public filename: string;
  public importIdString: string;
  public importid: string;
  public outputkw: string;
  public outputps: string;
  public projectchangedby: string;
  public projectchangedbydate: string;
  public projectcreatedby: string;
  public projectcreatedbydate: string;
  public projectfile: string;
  public projectfolder: string;
  public projectfoldercloud: string
  public projectsize: string;
  public projecttype: string;
  public pureOriginal: string;
  public readinghardware: string;
  public requiredtuningbytesvsactualtunedbytes: string;
  public resellercredits: string;
  public resellerprojecttype: string;
  public softwarenumber: string;
  public uploadedby: string;
  public userdef1: string;
  public userdef2: string;
  public userdef3: string;
  public userdef4: string;
  public userdef5: string;
  public vehiclebuild: string;
  public vehiclecharacteristic: string;
  public vehiclemodel: string;
  public vehiclemodelyear: string;
  public vehicleproducer: string;
  public vehicleseries: string;
  public vehicletype: string;
  public vehiclevin: string;
  public vin: string;
  public dateOfUpload: string;


  constructor() {
    this.clientnumber = "clientnumber";
    this.resellerprojectdetails = "resellerprojectdetails";
    this.status = "status";
    this.processingtime = "processingtime";
    this.dateuploaded = "dateuploaded";
    this.pureoriginalid = "pureoriginalid";
    this.blobFileName = "blobFileName";
    this.clientlicenceplace = "clientlicenceplace";
    this.clientname = "clientname";
    this.comment1 = "comment1";
    this.comment2 = "comment2";
    this.credittype = "credittype";
    this.ecubuild = "ecubuild";
    this.ecuprocessor = "ecuprocessor";
    this.ecuprodnr = "ecuprodnr";
    this.ecuproducer = "ecuproducer";
    this.ecusoftwareversion = "ecusoftwareversion";
    this.ecusoftwareversionversion = "ecusoftwareversionversion";
    this.ecustgnr = "ecustgnr";
    this.ecuuse = "ecuuse";
    this.enginedisplacement = "enginedisplacement";
    this.engineemission = "engineemission";
    this.engineemmisionstandard = "engineemmisionstandard";
    this.enginegear = "enginegear";
    this.enginemaxtorque = "enginemaxtorque";
    this.enginename = "enginename";
    this.engineproducer = "engineproducer";
    this.enginetransmission = "enginetransmission";
    this.enginetype = "enginetype";
    this.extramanualtuningrequired = "extramanualtuningrequired";
    this.filename = "filename";
    this.importIdString = "importIdString";
    this.importid = "importid";
    this.outputkw = "outputkw";
    this.outputps = "outputps";
    this.processingtime = "processingtime";
    this.projectchangedby = "projectchangedby";
    this.projectchangedbydate = "projectchangedbydate";
    this.projectcreatedby = "projectcreatedby";
    this.projectcreatedbydate = "projectcreatedbydate";
    this.projectfile = "projectfile";
    this.projectfolder = "projectfolder";
    this.projectfoldercloud = "projectfoldercloud";
    this.projectsize = "projectsize";
    this.projecttype = "projecttype";
    this.pureOriginal = "pureOriginal";
    this.readinghardware = "readinghardware";
    this.requiredtuningbytesvsactualtunedbytes = "requiredtuningbytesvsactualtunedbytes";
    this.resellercredits = "resellercredits";
    this.resellerprojecttype = "resellerprojecttype";
    this.softwarenumber = "softwarenumber";
    this.uploadedby = "uploadedby";
    this.userdef1 = "userdef1";
    this.userdef2 = "userdef2";
    this.userdef3 = "userdef3";
    this.userdef4 = "userdef4";
    this.userdef5 = "userdef5";
    this.vehiclebuild = "vehiclebuild";
    this.vehiclecharacteristic = "vehiclecharacteristic";
    this.vehiclemodel = "vehiclemodel";
    this.vehiclemodelyear = "vehiclemodelyear";
    this.vehicleproducer = "vehicleproducer";
    this.vehicleseries = "vehicleseries";
    this.vehicletype = "vehicletype";
    this.vehiclevin = "vehiclevin";
    this.vin = 'vin';
    this.dateOfUpload = "dateOfUpload";
  }


}
