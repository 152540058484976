import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-registration-template',
  templateUrl: './registration-template.component.html',
  styleUrls: ['./registration-template.component.css']
})
export class RegistrationTemplateComponent {
  public firstName: string;
  public email: string;
  constructor(private router: Router, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(value => {
      this.firstName = value['firstName']
      this.email = value['email'];
    })
  }
}
