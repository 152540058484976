<div class="login">
    <form class="form" [formGroup]="resetFormGroup">
        <div class="form-title">
            <h2>Forgot Password?</h2>
            <span>Please enter your new password.</span>
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="Enter new password" formControlName="newPassword"/>
            <div class="error-message"
                 *ngIf="resetFormGroup.get('newPassword')?.invalid && (resetFormGroup.get('newPassword')?.touched || resetFormGroup.get('newPassword')?.dirty)">
                <div *ngIf="resetFormGroup.get('newPassword').hasError('required')">
                    Required field.
                </div>
                <div *ngIf="resetFormGroup.get('newPassword').hasError('minLength')">
                    Password must be at least 8 characters long.
                </div>
                <div *ngIf="resetFormGroup.get('newPassword').hasError('pattern')">
                    Password must contain capital letter, symbol and number.
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="Confirm password"
                   formControlName="confirmNewPassword"/>
            <div class="error-message"
                 *ngIf="resetFormGroup.get('confirmNewPassword')?.invalid && (resetFormGroup.get('confirmNewPassword')?.touched || resetFormGroup.get('confirmNewPassword')?.dirty)">
                <div *ngIf="resetFormGroup.get('confirmNewPassword').hasError('required')">
                    Required field.
                </div>
                <div *ngIf="resetFormGroup.get('confirmNewPassword').hasError('matching')">
                    Incorrect password.
                </div>
            </div>
        </div>
        <div class="action-buttons">
            <button class="custom-btn" (click)="resetPasswordHandler()" [disabled]="resetFormGroup.invalid || isLoading">
                <ng-container *ngIf="!isLoading; else loadingContent">Reset Password</ng-container>
                <ng-template #loadingContent>
                    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                </ng-template>
            </button>
        </div>
    </form>
</div>
