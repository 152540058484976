import {inject, Inject, Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {WinOlsTransactionModel} from "../models/WinOlsTransaction-model";
import {NotificationService} from "./Notification.service";
import { Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {WinOlsStatisticModel} from "../models/WinOlsStatisticModel";



@Injectable({
    providedIn: 'root'
})
export class WinOlsTransactionService {

    private url: string = `${this.API_URL}`;

    winOlsTransactions = signal<WinOlsTransactionModel[]>([]);
    winOlsTransactionItem = signal<WinOlsTransactionModel>(new WinOlsTransactionModel());
    public winOlsStatistic = signal<WinOlsStatisticModel[]>([]);
    displayedTableHeaders = signal<string[]>([]);
    displayedColumns = signal<(keyof WinOlsTransactionModel)[]>([]);
    pageNumber = signal<number>(0);
    pageSize = signal<number>(50);
    sortAttribute = signal<string>('id');
    sortDirection = signal<string>('desc');
    isLoading = signal<boolean>(false);
    sortDirectionValues = signal<SortDirectionValuesModel>({active: "id", direction: "desc"});
    public selectedTimeFrame = signal<string | undefined>('');
    filesToDownload = signal<number[]>([]);
    public total = signal<number | undefined>(0);
    public preCheck = signal<number | undefined>(0);
    public requested = signal<number | undefined>(0);
    public solutionOffered = signal<number | undefined>(0);
    public noAvailableOptions = signal<number | undefined>(0);
    public completed = signal<number | undefined>(0);
    public error = signal<number | undefined>(0);
    public status!: string;
    public startDate: Date;
    public endDate: Date;

    notificationService = inject(NotificationService);
    router = inject(Router);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    getAllTableHeaderNames() {
        let objectKeys = Object.keys(this.winOlsTransactionItem());
        for (let key of objectKeys) {
            this.displayedColumns().push(key as keyof WinOlsTransactionModel);
        }
        this.displayedTableHeaders.set([...this.displayedColumns(), 'actions']);
    }

    triggerAllWinOlsRequest() {
        return this.httpClient.get<WinOlsTransactionModel[]>(`${this.url}/winols/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        })
    }

    getAllWinOlsTransactions() {
        this.isLoading.set(true);
        this.triggerAllWinOlsRequest().subscribe(response => {
            this.winOlsTransactions.set([...response["content"]])
            this.isLoading.set(false);
        })
    }

    getWinOlsTransactionsOnScroll() {
        this.triggerAllWinOlsRequest().subscribe(response => {
            this.winOlsTransactions.update((transactions) => [...transactions, ...response["content"]]);
            this.isLoading.set(false);
        })
    }


    onScroll() {
        this.increasePageNumber();
        if (this.startDate && this.endDate && this.status) {
            this.getWinOlsTransactionsByDateRangeAndStatusOnScroll();
        } else {
            this.getWinOlsTransactionsOnScroll();
        }
    }

    increasePageNumber() {
        this.pageNumber.update((prevNum) => prevNum + 1);
    }

    changeSortDirection(sortDirection: SortDirectionValuesModel) {
        this.sortDirectionValues.set(sortDirection);
        this.resetPageNumber();
        this.getAllWinOlsTransactions();
    }

    resetPageNumber() {
        this.pageNumber.set(0);
    }

    downloadFileFromTable(trace: string, id: number, route: string) {
        this.filesToDownload.update((prevIds) => [...prevIds, id]);

        const toastObj = this.notificationService.showInfoMessage(`Preparing your file with id: ${id}`, 'Preparing..');

        this.httpClient.post<{ url: string, message: string }>(`${this.url}/winols/${route}`, {}, {
            params: {
                trace: trace
            },
            observe: "response"
        }).subscribe(response => {

            const index = this.filesToDownload().indexOf(id);

            this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

            if (response.status === 200) {
                this.notificationService.clear(toastObj.toastId);

                window.location.href = response.body.url;

                this.notificationService.showSuccessMessage(`<div><span>Your file with ID ${id} is downloaded</span></div>`, "Success");

            } else {
                this.notificationService.showErrorMessage('Something went wrong with file.', 'Failed');
            }
        })
    }

    isDownloadInProgress(id: number) {
        return this.filesToDownload().includes(id);
    }

    openPureOriginalMatchingDetails(pureOriginalId: number) {
        this.router.navigate(['app/pure-originals/pure-original-details'], {queryParams: {pureOriginalId: pureOriginalId}});
    }


    onRefreshTable() {
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.sortDirectionValues.set({active: "id", direction: "desc"});
        this.winOlsTransactions.set([]);
        this.getAllWinOlsTransactions();
    }

    resetTableHeaders() {
        this.displayedColumns.set([]);
        this.displayedTableHeaders.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.displayedColumns.set([])
    }


    async getWinOlsTransactionsByDateRange(startDate: Date, endDate: Date) {
        const response = await firstValueFrom(this.httpClient.get<WinOlsStatisticModel[]>(`${this.url}/winols/dateRange`, {
            params: {
                'start date': startDate.toISOString(),
                'end date': endDate.toISOString(),
            }
        }))
        this.winOlsStatistic.set(response as WinOlsStatisticModel[]);
        return response;
    }

    getWinOlsDataRangeByStatus(startDate: string, endDate: string, status: string) {

        return this.httpClient.get<WinOlsTransactionModel[]>(`${this.url}/winols/statusAndDateRange`, {
            params: {
                'start date': startDate,
                'end date': endDate,
                'status': status,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                'sortAttribute': this.sortAttribute(),
                'sortDirection': this.sortDirection()
            }
        }).subscribe((response) => {
            this.winOlsTransactions.set(response["content"]);
        })
    }

    getWinOlsTransactionsByDateRangeAndStatusOnScroll() {
        const startDate = this.startDate.toISOString();
        const endDate = this.endDate.toISOString();
        this.httpClient.get<WinOlsTransactionModel[]>(`${this.url}/winols/statusAndDateRange`, {
            params: {
                'start date': startDate,
                'end date': endDate,
                'status': this.status,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                'sortAttribute': this.sortAttribute(),
                'sortDirection': this.sortDirection()
            }
        }).subscribe(response => {
            this.winOlsTransactions.update((transactions) => [...transactions, ...response["content"]]);
            this.isLoading.set(false);
        });
    }


    async getWinOlsStatistics(startDate: Date, endDate: Date) {

        await this.getWinOlsTransactionsByDateRange(startDate, endDate);

        let preCheck = 0;
        let requested = 0;
        let solutionOffered = 0;
        let noAvailableOptions = 0;
        let completed = 0;
        let error = 0;
        let total = 0;

        this.winOlsStatistic().forEach(winOlsStatistics => {
            switch (winOlsStatistics.winOlsTransactionStatus) {
                case 'PRE_CHECK':
                    preCheck += winOlsStatistics.count;
                    break;
                case 'REQUESTED':
                    requested += winOlsStatistics.count;
                    break;
                case 'SOLUTION_OFFERED':
                    solutionOffered += winOlsStatistics.count;
                    break;
                case 'NO_AVAILABLE_OPTIONS':
                    noAvailableOptions += winOlsStatistics.count;
                    break;
                case 'COMPLETED':
                    completed += winOlsStatistics.count;
                    break;
                case 'ERROR':
                    error += winOlsStatistics.count;
                    break;
                case 'TOTAL':
                    total += winOlsStatistics.count;
                    break;
                default:
                    break;
            }

        });
        this.preCheck.set(preCheck);
        this.requested.set(requested);
        this.solutionOffered.set(solutionOffered);
        this.noAvailableOptions.set(noAvailableOptions);
        this.completed.set(completed);
        this.error.set(error);
        this.total.set(preCheck + requested + solutionOffered + noAvailableOptions + completed + error);
    }

    async getWinOlsByDatePicker($event: any) {
        this.startDate = $event.valueOf().startDate;
        this.endDate = $event.valueOf().endDate;
        this.status = $event.valueOf().status;

        await this.getWinOlsStatistics(this.startDate, this.endDate);
    }

}






