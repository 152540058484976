<div class="main">
  <div class="bg">
    <div class="verification-wrapper">
      <div class="verification-container">

        <div class="logo">
          <img src="assets/logo-black.svg" alt="lg">
        </div>
        <div class="verification-content">
          <h1 class="middle-text">{{mainText}}</h1>
          <button class="login-btn" (click)="login()">Log in</button>
        </div>
        <div class="response-bar" [ngClass]="{
            'success' : responseStatusCode == 201,
            'invalidUuid' : responseStatusCode == 404,
            'userActive' : responseStatusCode == 409,
            'internalError' : responseStatusCode == 500
         }">{{response}}</div>
      </div>
    </div>
  </div>
</div>
