import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {PureOriginalsMatchingDetailsModel} from "../models/PureOriginalsMatchingDetails-model";
import {PureOriginalsModel} from "../models/PureOriginals-model";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {AllPureOriginalVersionsModel} from "../models/AllPureOriginalVersions-model";
import {PureOriginalsDetailsModel} from "../models/PureOriginalsDetails-model";
import {UpdatePureOriginalModel} from "../models/UpdatePureOriginal-model";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {NotificationService} from "./Notification.service";
import {
    EditPureOriginalDialogComponent
} from "../pure-originals/edit-pure-original-dialog/edit-pure-original-dialog.component";
import {DownloadFileFromDatabaseService} from "./DownloadFileFromDatabase.service";
import {Location} from "@angular/common";
import {CarImageService} from "./CarImage.service";
import {firstValueFrom} from "rxjs";
import {RestartDialogComponent} from "../helpers/restart-dialog/restart-dialog.component";
import {FileUploadService} from "./fileUpload.service";
import {
    CustomPureOriginalsUploadFileValidations
} from "../pure-originals/pure-original-details/pure-originals-info/pure-originals-info.component";
import {
    PureOriginalHistoryDialogComponent
} from "../pure-originals/pure-original-details/pure-original-history-dialog/pure-original-history-dialog.component";
import {PureOriginalHistoryModel} from "../models/PureOriginalHistory-model";
import {
    PureOriginalStatusEnum
} from "../pure-originals/pure-original-table-wrapper/pure-original-table-wrapper.component";
import {PureOriginalFilterHeadersModel} from "../models/PureOriginalFilterHeaders-model";


@Injectable({
    providedIn: "root"

})

export class PureOriginalService {

    private url = `${this.API_URL}`;

    public pureOriginals = signal<PureOriginalsModel[]>([]);
    public faultyPurOriginals = signal<PureOriginalsModel[]>([])
    public displayedOriginalColumns = signal<(keyof PureOriginalsModel)[]>([]);
    public columnsToFilter: string[] = Object.keys(new PureOriginalFilterHeadersModel({}));
    public allPureOriginal = signal<PureOriginalsModel>(new PureOriginalsModel)
    public displayedTableHeaders = signal<string[]>([]);
    public tableIsLoading = signal<boolean>(false);
    public pageNumber = signal<number>(0);
    public pageSize = signal<number>(50);
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "id", direction: 'desc'});
    public searchedResults = signal<PureOriginalsModel[]>([]);
    public selectedFilters = signal<SearchFilterModel[]>([]);
    public searchString = signal<string>('');
    public myClaimedFiles = signal<boolean>(false);

    public filesToDownload = signal<number[]>([]);
    public isDeletingMap = signal<{ [key: number]: boolean }>({} as { [key: number]: boolean });
    public intervalId: any;
    public image: any;
    public imageIsLoading = signal<boolean>(false);
    public pureOriginalId = signal<number>(-1);
    errorMessages = signal<string>('');
    hasError = signal<boolean>(false);
    errorMessage = signal<string>('');

    public pureOriginalDetails = signal<PureOriginalsDetailsModel>({} as PureOriginalsDetailsModel);
    public pureOriginalMatchingDetails = signal<AllPureOriginalVersionsModel>(null);
    public pureOriginalMatchingInQueue = signal<string[]>([]);
    public newVersions = signal<string[]>([]);


    private httpClient = inject(HttpClient);
    private dialog = inject(MatDialog);
    private downloadFileFromDatabaseService = inject(DownloadFileFromDatabaseService);
    private deleteFileFromDatabaseService = inject(DeleteFileFromDatabaseService);
    private notificationService = inject(NotificationService);
    private location = inject(Location);
    private carImageService = inject(CarImageService);
    private fileUploadService = inject(FileUploadService);
    public numberOfClaimedFiles = signal<number>(0);
    public isButtonDisabled = signal<boolean>(false);
    public progressWidth = signal<number>(0);
    public resetSignal = signal<boolean>(false);
    public inputText = "Drag & Drop INI file";
    public faultyEcuBuild = signal<string>("");
    public pureOriginalStatus = signal<PureOriginalStatusEnum | null>(null);

    public numberOfProcessedFiles = signal<number>(0);

    public myInputs = new Map<string, CustomPureOriginalsUploadFileValidations>([
        ["ini", {
            message: '',
            hasError: false,
        }]
    ])


    constructor(@Inject('API_URL') private API_URL: string, @Inject('LOCAL_STORAGE_KEY') private pureOriginalsFilter: string) {
    }

    restartTableValues() {
        this.displayedOriginalColumns.set([]);
        this.displayedTableHeaders.set([]);
        this.pureOriginals.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.pureOriginalStatus.set(null);
    }


    triggerBackFunction() {
        this.location.back();
        this.clearInterval();
    }

    triggerGetPureOriginalsRequest() {
        return this.httpClient.get<PureOriginalsModel[]>(`${this.url}/pureOriginals/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction,
            }
        })
    }

    getPureOriginals() {
        this.tableIsLoading.set(true);
        this.triggerGetPureOriginalsRequest().subscribe((response) => {
            this.pureOriginals.set(response["content"]);
            this.tableIsLoading.set(false);
        })
    }

    getTableHeaderNamesForPureOriginals() {

        let objectKeys = Object.keys(this.allPureOriginal()).filter(key => key !== "status");
        for (let key of objectKeys) {
            this.displayedOriginalColumns().push(key as keyof PureOriginalsModel);
        }
        this.displayedTableHeaders.set([...this.displayedOriginalColumns(), 'actions']);
    }

    getMyClaimFiles() {
        this.tableIsLoading.set(true);
        this.httpClient.get<PureOriginalsModel[]>(`${this.url}/pureOriginals/getMyClaimedFiles`).subscribe((response) => {
            this.tableIsLoading.set(false);
            this.pureOriginals.set(response)
            this.numberOfClaimedFiles.set(response.length);
        });
    }

    getAdvancedPureOriginals(filteredOptions: SearchFilterModel[]) {
        return this.httpClient.post<{
            content: PureOriginalsModel[]
        }>(`${this.url}/pureOriginals/listAllPureOriginalFiltered`, filteredOptions, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction,
            }
        })
    }

    searchByWinOls(winOls: string) {
        return this.httpClient.get<{
            content: PureOriginalsModel[]
        }>(`${this.url}/pureOriginals/searchByWinOls`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                winOls: winOls,
            }
        })
    }

    getPureOriginalsByWinOlsDbName(winOls: string) {
        return this.httpClient.get<{
            content: PureOriginalsModel[]
        }>(`${this.url}/pureOriginals/searchByWinOlsDbName`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                winOlsDbName: winOls,
            }
        })
    }


    async getPureOriginalDetails(id: number): Promise<PureOriginalsDetailsModel> {
        this.imageIsLoading.set(true);
        const response = await firstValueFrom(this.httpClient.get<PureOriginalsDetailsModel>(`${this.url}/pureOriginals/getPureOriginalDetails/${id}`));
        this.pureOriginalDetails.set(response);
        await this.loadImageDetails();
        return response;
    }

    async loadImageDetails() {
        const details = this.pureOriginalDetails();
        this.carImageService.getImage({
            make: details.vehicleProducer,
            modelFamily: details.vehicleSeries,
            modelYear: details.vehicleModelYear,
            modelRange: details.vehicleModel,
            width: '1000'
        })
        this.imageIsLoading.set(false);
    }

    getPureOriginalMatchingDetails(pureOriginalId: number) {
        if (this.pureOriginalMatchingDetails() == null) {
            this.tableIsLoading.set(true);
        }
        return this.httpClient.get<AllPureOriginalVersionsModel>(`${this.url}/pureOriginals/pureOriginalMatchingDetails/${pureOriginalId}`).subscribe(response => {
            this.pureOriginalMatchingDetails.set(response as AllPureOriginalVersionsModel);
            this.checkForNewVersionsInQueue();
            setTimeout(() => {
                this.tableIsLoading.set(false);
            }, 500)
        })
    }

    triggerOnScroll() {
        if (this.selectedFilters().length > 0) {
            this.increasePageNumber();
            this.getAdvancedPureOriginals(this.selectedFilters()).subscribe(response => {
                this.pureOriginals.update((pureOriginals) => [...pureOriginals, ...response['content']]);
            });
        } else if (this.searchString().length >= 3) {
            this.increasePageNumber();
            this.searchByWinOls(this.searchString()).subscribe(response => {
                this.pureOriginals.update((pureOriginals) => [...pureOriginals, ...response["content"]]);
            });
        } else if (this.pureOriginalStatus()) {
            this.increasePageNumber();
            this.triggerFilterPureOriginalByStatusReq(this.pureOriginalStatus()).subscribe((response) =>
                this.pureOriginals.update((pureOriginals) => [...pureOriginals, ...response["content"]]));
        } else {
            this.increasePageNumber();
            this.triggerGetPureOriginalsRequest().subscribe(response => {
                this.pureOriginals.update((pureOriginals) => [...pureOriginals, ...response["content"]]);
            });
        }
    }


    onRefreshPureOriginalsTable() {
        if (this.myClaimedFiles()) {
            this.getMyClaimFiles();
            return;
        }
        const savedFiltersTable = localStorage.getItem(this.pureOriginalsFilter);

        if (savedFiltersTable) {
            const filtersFromLocalStorage = JSON.parse(savedFiltersTable) as SearchFilterModel[];
            this.selectedFilters.set(filtersFromLocalStorage);
            this.pureOriginals.set([]);
            this.pageNumber.set(0);
            this.getAdvancedPureOriginals(this.selectedFilters()).subscribe(response => {
                this.pureOriginals.set(response['content']);
            });
        } else {
            this.pageNumber.set(0);
            this.pureOriginals.set([]);
            this.getPureOriginals();
        }
    }

    onSearchInput() {
        if (this.searchString().length >= 3) {
            this.tableIsLoading.set(true);
            this.searchByWinOls(this.searchString()).subscribe(value => {
                this.tableIsLoading.set(false);
                this.pureOriginals.set(value['content']);
            });
        }
        if (this.searchString().length === 0 && !this.pureOriginalStatus()) {
            this.getPureOriginals();
        }
        if (this.searchString().length === 0 && this.pureOriginalStatus()) {
            this.filterPureOriginalsByStatus(this.pureOriginalStatus());
        }
    }

    triggerFilterPureOriginalByStatusReq(status: PureOriginalStatusEnum) {
        return this.httpClient.get<PureOriginalsModel[]>(`${this.url}/pureOriginals/byStatus/${status}`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize()
            }
        });
    }

    filterPureOriginalsByStatus(status: PureOriginalStatusEnum) {
        this.triggerFilterPureOriginalByStatusReq(status).subscribe((response) => this.pureOriginals.set([...response["content"]]));
    }

    checkForNewVersionsInQueue() {
        this.newVersions.set([]);
        this.pureOriginalMatchingInQueue.set([]);
        for (let versionName of this.pureOriginalMatchingDetails().pureOriginalVersionsInQueue) {
            if (this.pureOriginalMatchingDetails().pureOriginalVersions.some((version) => version.tuningName === versionName)) {
                this.pureOriginalMatchingInQueue().push(versionName);
            } else if (this.pureOriginalMatchingDetails().pureOriginalMissingVersions.some((version) => version.tuningName === versionName)) {
                this.pureOriginalMatchingInQueue().push(versionName);
            } else if (this.pureOriginalMatchingDetails().pureOriginalImpossibleTunings.some((version) => version.tuningName === versionName)) {
                this.pureOriginalMatchingInQueue().push(versionName);
            } else {
                this.newVersions().push(versionName);
            }
        }
    }

    showMyClaimedFiles() {
        this.myClaimedFiles.set(!this.myClaimedFiles());
        this.pageNumber.set(0);
        if (this.myClaimedFiles()) {
            this.getMyClaimFiles();
        } else {
            this.getPureOriginals();
        }
    }

    changeSortDirection(sortEvent: SortDirectionValuesModel, tableElement: HTMLElement) {
        this.pageNumber.set(0);
        this.sortDirectionValues.set(sortEvent);
        if (tableElement) {
            tableElement.scrollTop = 0;
        }
        this.triggerGetPureOriginalsRequest().subscribe(value => {
            this.pureOriginals.set([...value["content"]]);
        });
    }

    updatePureOriginalTableDetails(id: number, pureOriginal: UpdatePureOriginalModel) {
        return this.httpClient.post<PureOriginalsDetailsModel>(`${this.url}/pureOriginals/updatePureOriginal/${id}`, pureOriginal, {observe: "response"});
    }

    editPureOriginalDetails(id: number, element: PureOriginalsModel) {
        const dialogRef = this.dialog.open(EditPureOriginalDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {id, text: `${element.id}`, content: element}
        });

        dialogRef.afterClosed().subscribe((data: { closed: boolean, content: UpdatePureOriginalModel }) => {
            if (data.closed) {
                this.updatePureOriginalTableDetails(id, data.content).subscribe(response => {
                    if (response.status === 200) {
                        this.pureOriginals.update((pureOriginals) => pureOriginals.map((pureOriginal) => (pureOriginal.id === element.id) ? response.body : pureOriginal));
                        this.notificationService.showSuccessMessage(`${element.id} successfully updated`, "Pure Original Updated");
                    } else if (response.status === 409) {
                        this.notificationService.showErrorMessage("Incorrect EcuBuild name", "Wrong Ecu Build")
                    } else {
                        this.notificationService.showErrorMessage("Something went wrong", "Error Occurred");
                    }

                }, error => {
                    this.errorUploadPureOriginal(error)
                })
            }
        });
    }

    async updatePureOriginalDetails(id: string, editItem: PureOriginalsDetailsModel) {
        const dialogRef = this.dialog.open(EditPureOriginalDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {text: `${id}`, id, content: editItem}
        });

        const result = await firstValueFrom(dialogRef.afterClosed());

        if (result?.closed) {
            await this.editPureOriginalDetailsOnClick(+id, result.content);

            return result.content;
        } else {
            return editItem;
        }
    }

    async editPureOriginalDetailsOnClick(id: number, pureOriginalDetail: UpdatePureOriginalModel): Promise<PureOriginalsDetailsModel> {
        try {
            const response = await firstValueFrom(this.updatePureOriginalTableDetails(id, pureOriginalDetail));

            if (response.status === 200) {
                this.pureOriginalDetails.set(response.body);
                this.notificationService.showSuccessMessage(`${id} successfully updated`, "Pure Original Updated");
                return response.body;
            } else {
                this.notificationService.showErrorMessage("Something went wrong", "Error Occurred");
                throw new Error("Error occurred during update");
            }
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 409:
                        const errorMessage = error.error?.message;
                        this.notificationService.showErrorMessage(errorMessage, "Error Occurred");
                        break;
                    case 404:
                        this.notificationService.showErrorMessage("Pure Original by ID not found", "Error Occurred");
                        break;
                    default:
                        this.notificationService.showErrorMessage("Something went wrong", "Error Occurred");
                        break;
                }
            } else {
                this.notificationService.showErrorMessage("An unexpected error occurred", "Error Occurred");
            }
            throw error;
        }
    }

    deletePureOriginalItem(id: number, tableName: string, pureOriginalDetailsOpened?: boolean) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.downloadFileFromDatabaseService.downloadFileAndDeleteFromDatabase(id, "DOWNLOAD_AND_DELETE_PURE").subscribe((response) => {
                        if (response.status === 200) {
                            if (response.body != null && response.body.url != null) {
                                window.location.href = response.body.url;
                            }
                            if (pureOriginalDetailsOpened) {
                                setTimeout(() => {
                                    this.location.back();
                                }, 2500)
                            }
                            this.pureOriginals.update((pureOriginals) => pureOriginals.filter((pureOriginal) => +pureOriginal.id !== id));
                            this.notificationService.showSuccessMessage('Successfully deleted', `${id}`);
                        }
                    }, error => {
                        this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                    });
                }
            }
        )
    }

    startIntervalForEvery5Seconds(pureOriginalId: number) {
        this.intervalId = setInterval(() => {
            this.getPureOriginalMatchingDetails(pureOriginalId);
            this.checkForNewVersionsInQueue();
        }, 5000)
    }

    clearInterval() {
        clearInterval(this.intervalId);
    }

    deleteVersion(objectName: string, id: number, tableName: string,) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteFileFromDatabaseService.deleteFileFromDatabase(id, tableName).subscribe(response => {
                    if (response.status === 200) {
                        const index = this.pureOriginalMatchingDetails()[`${objectName}`].findIndex((index) => +index.pureOriginalMatchingDetailsId === id);
                        this.pureOriginalMatchingDetails()[`${objectName}`].splice(index, 1);
                        this.notificationService.showSuccessMessage(`Version with ${id} id successfully deleted.`, 'Successfully deleted');
                    } else {
                        this.notificationService.showErrorMessage("Something went wrong", "Error occurred!");
                    }

                });
            }
        })

    }

    getSuccessfullyTunedMatchingDetails() {
        return this.pureOriginalMatchingDetails().pureOriginalVersions.filter(value => value.hitRate == '1');
    }

    getPartiallyTunedMatchingDetails() {
        return this.pureOriginalMatchingDetails().pureOriginalVersions.filter(value => value.hitRate != '1');
    }

    sortListOfTunings(pureOriginalList: string[], unsortedResults: PureOriginalsMatchingDetailsModel[]) {

        const priorityListOfTunings = ['Stage 1', 'Stage 2', 'Vmax', 'DPF', 'OPF', 'EGR'];

        const newPrioritySortedList = pureOriginalList.filter(tuning => priorityListOfTunings.includes(tuning));

        const sortedTuningsInSortedList = newPrioritySortedList.sort((a, b) => priorityListOfTunings.indexOf(a) - priorityListOfTunings.indexOf(b));

        const newTuningList = pureOriginalList.filter(tuning => !priorityListOfTunings.includes(tuning)).sort();

        const finalSortedList = sortedTuningsInSortedList.concat(newTuningList);
        return unsortedResults.sort((a, b) => finalSortedList.indexOf(a.tuningName) - finalSortedList.indexOf(b.tuningName));
    }

    sortedListByTuningNames(isSuccessfullyLoaded: boolean) {
        let sortedList: PureOriginalsMatchingDetailsModel[];
        let tuningNamesOfLoadedTunings: string[];
        if (isSuccessfullyLoaded) {
            sortedList = this.getSuccessfullyTunedMatchingDetails();
        } else {
            sortedList = this.getPartiallyTunedMatchingDetails();
        }

        tuningNamesOfLoadedTunings = sortedList.map(item => item.tuningName.toString())
        return this.sortListOfTunings(tuningNamesOfLoadedTunings, sortedList);
    }

    repeatPureOriginalsProcess(id: number) {
        const dialogRef = this.dialog.open(RestartDialogComponent, {
            width: "350px",
            data: "Are you sure you want to restart Pure Originals?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.httpClient.post<any>(`${this.url}/pureOriginals/repeatPureOriginalProcess`, {
                    id: id,
                }, {observe: "response",}).subscribe(response => {
                    if (response.status === 200) {
                        this.notificationService.showSuccessMessage(`${response.body.message}`, "Success");
                    } else if (response.status === 400) {
                        this.notificationService.showErrorMessage(`${response.body.message}`, "Bad Request");
                    } else if (response.status === 404) {
                        this.notificationService.showErrorMessage("Pure Original not found", "Not Found");
                    }
                })
            }
        })
    }

    getFaultyPureOriginals() {
        this.tableIsLoading.set(true);
        return this.httpClient.get<{ content: PureOriginalsModel[] }>(`${this.url}/pureOriginals/faultyPureOriginals`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction,
            }
        }).subscribe(response => {
            this.faultyPurOriginals.update((faultyPureOriginals) => [...faultyPureOriginals, ...response["content"]])
            this.tableIsLoading.set(false);
        })
    }


    updateFaultyPureOriginals(id: number, element: PureOriginalsModel) {
        const dialogRef = this.dialog.open(EditPureOriginalDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {id, text: `${element.id}`, content: element}
        });
        dialogRef.afterClosed().subscribe((data: { closed: boolean, content: UpdatePureOriginalModel }) => {
            if (data.closed) {
                this.updatePureOriginalTableDetails(id, data.content).subscribe(response => {
                    if (response.status === 200) {
                        this.faultyPurOriginals.update((faultyPureOriginals) => faultyPureOriginals.filter((pureOriginal) => (+pureOriginal.id !== id)));
                        this.notificationService.showSuccessMessage(`${element.id} successfully updated`, "Pure Original Updated");
                    } else if (response.status === 409) {
                        this.notificationService.showErrorMessage("Incorrect EcuBuild name", "Wrong Ecu Build")
                    } else {
                        this.notificationService.showErrorMessage("Something went wrong", "Error Occurred");
                    }

                }, error => {
                    this.errorUploadPureOriginal(error)
                })
            }
        });
    }


    getSortFaultyPureOriginals() {
        this.tableIsLoading.set(true);
        return this.httpClient.get<{ content: PureOriginalsModel[] }>(`${this.url}/pureOriginals/faultyPureOriginals`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction,
            }
        }).subscribe(response => {
            this.faultyPurOriginals.set(response["content"])
            this.tableIsLoading.set(false)
        })
    }


    changeFaultySortDirection(sortEvent: SortDirectionValuesModel) {
        this.pageNumber.set(0);
        this.sortDirectionValues.set(sortEvent);
        this.getSortFaultyPureOriginals();

    }

    restartFaultyTableValues() {
        this.displayedOriginalColumns.set([]);
        this.faultyPurOriginals.set([]);
        this.displayedTableHeaders.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
    }


    onScrollFaultyPureOriginals() {
        this.increasePageNumber();
        this.getFaultyPureOriginals();
    }

    deleteFaultyPureOriginals(id: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.deleteFileFromDatabaseService.deleteFileFromDatabase(id, tableName).subscribe((response) => {
                        if (response.status === 200) {
                            this.faultyPurOriginals.update((faultyPureOriginals) => faultyPureOriginals.filter((pureOriginal) => +pureOriginal.id !== id));

                            this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                        }
                    }, error => {
                        this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                    });
                }
            }
        )
    }

    getNumberOfProcessedFilesInLast24Hours() {
        return this.httpClient.get(`${this.url}/pureOriginals/getNumberOfProcessedFilesInLast24Hours`).subscribe((response) =>
            this.numberOfProcessedFiles.set(response as number));
    }

    claimPureOriginalFile(pureOriginalBaseId: number) {
        return this.httpClient.get<PureOriginalsDetailsModel>(`${this.url}/pureOriginals/claimPureOriginalFile/${pureOriginalBaseId}`);
    }

    unclaimPureOriginalFile(pureOriginalBaseId: number) {
        return this.httpClient.get<PureOriginalsDetailsModel>(`${this.url}/pureOriginals/unClaimPureOriginalFile/${pureOriginalBaseId}`);
    }

    getPureOriginalsByVersionId(versionId: number) {
        return this.httpClient.get<PureOriginalsMatchingDetailsModel[]>(`${this.url}/pureOriginals/${versionId}`);
    }

    getPartiallyTunedPureOriginalsByVersionId(versionId: number) {
        return this.httpClient.get<PureOriginalsMatchingDetailsModel[]>(`${this.url}/pureOriginals/partiallyTunedVersions/${versionId}`);
    }

    changeImpossibleTunings(versionId: string, enabled: boolean) {
        return this.httpClient.post<AllPureOriginalVersionsModel>(`${this.url}/pureOriginals/updateTuningStatus`, {
            id: versionId,
            enabled: enabled
        });
    }

    increasePageNumber() {
        this.pageNumber.update((pageNumber) => pageNumber + 1);
    }

    downloadFileFromDatabase(id: number, winOls: string, tableName: string) {

        this.filesToDownload.update((prevIds) => [...prevIds, id]);
        const toastObj = this.notificationService.showInfoMessage(`Preparing your ${winOls} file...`, 'Preparing..');

        this.downloadFileFromDatabaseService.downloadFileFromDatabase(id, tableName).subscribe(response => {
            const index = this.filesToDownload().indexOf(id);
            this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

            if (response.status === 200) {
                this.notificationService.clear(toastObj.toastId);
                window.location.href = response.body.url;
                this.notificationService.showSuccessMessage(`<div><span>Your file ${winOls} is downloaded</span></div>`, "Success");
            }

        }, error => {
            this.notificationService.clear(toastObj.toastId);
            this.notificationService.showErrorMessage(`${error.error['message']}`, 'Failed');
        });
    }

    isDownloadInProgress(id) {
        return this.filesToDownload().includes(id);
    }

    resendPureOriginalByEcuBuildToQueue(ecuBuild: string) {
        this.isButtonDisabled.set(true);
        return this.httpClient.post<{
            id: number,
            message: string
        }>(`${this.url}/pureOriginals/resendPureOriginalByEcuBuildToQueue`, {ecuBuild: ecuBuild}, {observe: "response"}).subscribe(response => {
            if (response.status === 200) {
                this.notificationService.showSuccessMessage(`${response.body.message}`, `Resend Pure Original Finished.`);
            }
            this.isButtonDisabled.set(false);
        }, (error) => {
            this.notificationService.showErrorMessage(`${error.error.body}`, 'Error occured!');
        })

    }


    async uploadIniFile(file: File, pureOriginalId: number): Promise<PureOriginalsDetailsModel> {
        if (file) {
            this.myInputs.set('ini', {
                message: '',
                hasError: false,
            });
        }
        return new Promise((resolve, reject) => {
            this.fileUploadService.uploadPureOriginalIniFile(file, pureOriginalId).subscribe(
                (response) => {
                    if (response.type === HttpEventType.UploadProgress && response.total) {
                        this.progressWidth.set(Math.round((response.loaded / response.total) * 100));
                    } else if (response.type === HttpEventType.Response) {
                        if (response.status === 200) {
                            const body = response.body as {
                                pureOriginalRecords: PureOriginalsDetailsModel;
                                message: string;
                            };
                            this.pureOriginalDetails.set(body.pureOriginalRecords);
                            this.notificationService.showSuccessMessage(
                                `<div><span>Pure Original Ini file uploaded</span></div>`,
                                "Success"
                            );
                            this.progressWidth.set(0);
                            // this.refreshIniFile();

                            if (this.myClaimedFiles()) {
                                this.getMyClaimFiles();
                            } else {
                                this.getPureOriginals();
                            }

                            this.getSortFaultyPureOriginals();
                            this.errorMessages.set('');
                            this.hasError.set(false);
                            resolve(body.pureOriginalRecords);
                        } else {
                            reject(`Unexpected status code: ${response.status}`);
                        }
                    }
                },
                (error) => {
                    this.myInputs.set('ini', {
                        message: error.error?.message,
                        hasError: true,
                    });
                    this.errorUploadMessage(error);
                    reject(error);
                    this.progressWidth.set(0);

                }
            );

            this.refreshIniFile();

        });
    }


    refreshIniFile() {
        this.resetSignal.set(true);
        this.myInputs = new Map<string, CustomPureOriginalsUploadFileValidations>([
            ["ini", {
                message: '',
                hasError: false,
            }],
        ]);
    }


    errorUploadPureOriginal(error: any) {
        this.hasError.set(true);
        switch (error.status) {
            case 404:
                this.notificationService.showErrorMessage('wrong ID', 'error');
                break;
            case 409:
                this.notificationService.showErrorMessage('Incorrect Ecu Build', 'error');
                break;
            default:
                this.notificationService.showErrorMessage('An unexpected error occurred', 'error');
                console.error('Unexpected error:', error);
        }
    }


    errorUploadMessage(error: any) {
        this.hasError.set(true);
        switch (error.status) {
            case 400:
                this.notificationService.showErrorMessage('Bad Request', 'error');
                break;
            case 404:
                this.notificationService.showErrorMessage('PureOriginal file by ID not found', 'error');
                break;
            case 409:
                this.errorIniUpload(error.error["message"]);
                break;
            case 503:
                this.notificationService.showErrorMessage('Service unavailable', 'error');
                break;
            default:
                this.notificationService.showErrorMessage('An unexpected error occurred', 'error');
                console.error('Unexpected error:', error);
        }
    }

    errorIniUpload(message: string) {
        if (message.startsWith('WRONG_ECU_BUILD')) {
            this.notificationService.showErrorMessage('Wrong EcuBuild.', 'Error Occurred!');
            return;
        } else if (message.startsWith('WRONG_ECU_PRODUCER')) {
            this.notificationService.showErrorMessage('Wrong EcuProducer.', 'Error Occurred!');
            return;
        } else if (message.startsWith('MISSING_ATTRIBUTES')) {
            const cleanedMessage = message.replace('MISSING_ATTRIBUTES: ', '');
            this.notificationService.showErrorMessage("Missing attributes: " + cleanedMessage, 'Error Occurred!');
            return;
        }
        switch (message) {
            case 'EMPTY_INI_IN_UPLOAD':
                this.notificationService.showErrorMessage('Empty INI is uploaded.', 'Error Occurred!');
                break;
            case 'INI_FILE_SIZE_IS_GREATER_THAN_6KB':
                this.notificationService.showErrorMessage('INI file size is greater than 6KB.', 'Error Occurred!');
                break;
            case 'ECU_BUILD_MISSING':
                this.notificationService.showErrorMessage('Ecu Build Missing.', 'Error Occurred!');
                break;
            case 'ECU_PRODUCER_MISSING':
                this.notificationService.showErrorMessage('Ecu Producer Missing.', 'Error Occurred!');
                break;
            case 'ERROR_PARSING_ATTRIBUTES':
                this.notificationService.showErrorMessage('Error parsing attributes.', 'Error Occurred!');
                break;
            default:
                this.notificationService.showErrorMessage(message, 'Error Occurred!');
                console.error('Unknown error:', message);
        }
    }

    triggerFaultyPureOriginalsByFaultyEcuBuildsReq() {
        return this.httpClient.get<{
            content: PureOriginalsModel[]
        }>(`${this.url}/pureOriginals/faultyPureOriginalsByFaultyEcuBuild`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                ecuBuild: this.faultyEcuBuild()
            }
        })
    }

    getFaultyPureOriginalsByFaultyEcuBuilds() {
        this.tableIsLoading.set(true);
        this.triggerFaultyPureOriginalsByFaultyEcuBuildsReq().subscribe((response) => {
            this.pureOriginals.set(response.content);
            this.tableIsLoading.set(false);
        })
    }

    onScrollFaultyPureOriginalsByFaultyEcuBuildsTable() {
        this.increasePageNumber();
        this.triggerFaultyPureOriginalsByFaultyEcuBuildsReq().subscribe((response) => this.pureOriginals.update((pureOriginals) => [...pureOriginals, ...response.content]))
    }

    updatePureOriginalsByFaultyEcuBuilds(ecuBuild: string, pureOriginalIds: number[]) {

        console.log(ecuBuild, pureOriginalIds);
        return this.httpClient.post<{
            pureOriginalIds: number[],
            message: string
        }>(`${this.url}/pureOriginals/updateFaultyPureOriginals`, pureOriginalIds, {
            params: {ecuBuild: ecuBuild},
            observe: "response"
        });
    }

    getValidEcuProducerByEcuBuild(ecuBuild: string) {
        console.log(ecuBuild, "ECU BUILD IN SERVICE");
        return this.httpClient.get<string>(`${this.url}/ecuBuildNames/validEcuProducer/${ecuBuild}`, {observe: "response"});
    }

    getPureOriginalHistory(pureOriginalId: number) {
        return this.httpClient.get<PureOriginalHistoryModel[]>(`${this.url}/pureOriginals/pureOriginal/history/${pureOriginalId}`, {observe: "response"});
    }

    showPureOriginalHistory(pureOriginalId: number, pureOriginalHistory: PureOriginalHistoryModel[]) {
        this.dialog.open(PureOriginalHistoryDialogComponent, {
            width: "70%",
            maxWidth: "100%",
            minHeight: "550px",
            data: {text: `${pureOriginalId}`, pureOriginalId, content: pureOriginalHistory}
        });
    }
}
