import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ResellerTuningCombinationsModel} from "../models/Reseller-tuning-combinations-model";
import {NotificationService} from "./Notification.service";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";


@Injectable({
    providedIn: 'root'
})
export class ResellerTuningCombinationsService {

    private url = `${this.API_URL}`;

    resellerTuningCombinations = signal<ResellerTuningCombinationsModel[]>([])
    isLoading = signal<boolean>(false)
    showNewInputRow = signal<boolean>(false);
    isWaitingForResponse = signal<boolean>(false);
    resellerTuningIndex = signal<number>(-1);
    resellerTuningList = signal<string[]>([]);
    resellerProjectTypeList = signal<string[]>([]);
    public newResellerRow = signal<ResellerTuningCombinationsModel>({} as ResellerTuningCombinationsModel);


    notificationService = inject(NotificationService);
    dialog = inject(MatDialog);


    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }


    resetTableValues() {
        this.resellerTuningCombinations.set([]);
    }

    getAllResellerCombinations() {
        this.isLoading.set(true);
        this.httpClient.get<ResellerTuningCombinationsModel[]>(`${this.url}/resellerTuningCombinations`).subscribe(response => {
            const tuningOptionsList: string[] = [];
            const projectTypeList: string[] = [];

            response.forEach(reseller => {
                if (reseller.tuningOptions && !tuningOptionsList.includes(reseller.tuningOptions)) {
                    tuningOptionsList.push(reseller.tuningOptions);
                }
                if (reseller.resellerProjectType && !projectTypeList.includes(reseller.resellerProjectType)) {
                    projectTypeList.push(reseller.resellerProjectType);
                }
            });

            tuningOptionsList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            projectTypeList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

            this.resellerTuningList.set(tuningOptionsList);
            this.resellerProjectTypeList.set(projectTypeList);
            this.resellerTuningCombinations.set(response);
            this.isLoading.set(false);
        });
    }


    addNewResellerOptions(newResellerOptionRow: ResellerTuningCombinationsModel) {
        this.isWaitingForResponse.set(true);
        this.httpClient.post<ResellerTuningCombinationsModel>(`${this.url}/resellerTuningCombinations`, {
            id: newResellerOptionRow.id,
            newTuningCombinationOptions: newResellerOptionRow.tuningOptions,
            newResellerProjectType: newResellerOptionRow.resellerProjectType
        }, {observe: "response"}).subscribe(response => {
            if (response.status === 201) {
                this.resellerTuningCombinations.update((resellerOption) => [...resellerOption, response.body]);
                if (!this.resellerTuningList().includes(response.body.tuningOptions)) {
                    this.resellerTuningList.update(list => [...list, response.body.tuningOptions].sort());
                }

                if (!this.resellerProjectTypeList().includes(response.body.resellerProjectType)) {
                    this.resellerProjectTypeList.update(list => [...list, response.body.resellerProjectType].sort());
                }
                this.isWaitingForResponse.set(false);
                this.notificationService.showSuccessMessage('Successfully added.', `Successfully added new Reseller Tuning Combination.`)
            }
            this.showNewInputRow.set(false);
            this.resetNewResellerRowValue();
        }, (error: HttpErrorResponse) => {
            this.isWaitingForResponse.set(false);
            if (error.status === 403) {
                this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
            }
            if (error.error?.errors) {
                const errorMessage = error.error?.errors;
                for (const [key, value] of Object.entries(errorMessage)) {
                    this.notificationService.showErrorMessage(value as string, key['Error occurred']);
                }
            }
        })
    }


    editResellerOptions(id: number, resellerOptions: ResellerTuningCombinationsModel) {
        this.isWaitingForResponse.set(true);

        const requestBody = {
            newTuningCombinationOptions: resellerOptions.tuningOptions,
            newResellerProjectType: resellerOptions.resellerProjectType
        };

        this.httpClient.put<ResellerTuningCombinationsModel>(`${this.url}/resellerTuningCombinations/${id}`, requestBody, {observe: "response"})
            .subscribe((response) => {
                if (response.status === 200 && response.body) {
                    this.resellerTuningCombinations.update((resellers) =>
                        resellers.map((reseller) =>
                            reseller.id === id ? (response.body as ResellerTuningCombinationsModel) : reseller
                        )
                    );
                    this.notificationService.showSuccessMessage("Reseller Tuning Combination successfully updated", "Reseller Tuning updated");
                }
                this.refreshLists();
                this.isWaitingForResponse.set(false);
                this.closeEditedItem();
            }, (error: HttpErrorResponse) => {
                this.isWaitingForResponse.set(false);
                if (error.status === 400) {
                    this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
                }
                if (error.error?.errors) {
                    const errorMessage = error.error?.errors;
                    for (const [key, value] of Object.entries(errorMessage)) {
                        this.notificationService.showErrorMessage(value as string, key + ['Error occurred']);
                    }
                }
            });
    }


    deleteItemFromDatabase(id: number) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete Reseller Option?"
        })
        dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.httpClient.delete(`${this.url}/resellerTuningCombinations/${id}`, {
                        params: {resellerId: id},
                        observe: "response"
                    }).subscribe((response) => {
                        if (response.status === 200) {
                            this.resellerTuningCombinations.update((resellers) => resellers.filter(reseller => reseller.id !== id));
                            this.notificationService.showSuccessMessage('Successfully deleted', `Reseller Tuning Combination  ${id} deleted.`);
                            this.refreshLists()
                        } else {
                            this.notificationService.showErrorMessage('Something went wrong', 'Error',);
                        }

                    })
                }
            }
        )
    }

    refreshLists() {
        const tuningOptionsList = Array.from(new Set(this.resellerTuningCombinations().map(item => item.tuningOptions).filter(Boolean)));
        const projectTypeList = Array.from(new Set(this.resellerTuningCombinations().map(item => item.resellerProjectType).filter(Boolean)));

        this.resellerTuningList.set(tuningOptionsList.sort());
        this.resellerProjectTypeList.set(projectTypeList.sort());
    }


    resetNewResellerRowValue() {
        this.newResellerRow.set({} as ResellerTuningCombinationsModel);
    }

    toggleNewInputRow() {
        this.showNewInputRow.set(true);
    }

    toggleEditView(index: number) {
        this.resellerTuningIndex.set(index);
    }


    closeRow() {
        this.showNewInputRow.set(false);
    }

    closeEditedItem() {
        this.resellerTuningIndex.set(-1);
    }

}