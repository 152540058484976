import {Component, inject, OnInit} from '@angular/core';
import {PureOriginalsUploadService} from "../../service/pure-originals-upload.service";


@Component({
    selector: 'app-pure-originals-upload',
    templateUrl: './pure-originals-upload.component.html',
    styleUrls: ['./pure-originals-upload.component.css']
})
export class PureOriginalsUploadComponent implements OnInit {

    pureOriginalsUploadService = inject(PureOriginalsUploadService);

    ngOnInit() {
        this.refreshUpload();
        this.checkForChanges();
    }

    checkForChanges() {
        this.pureOriginalsUploadService.checkForChanges()
    }

    refreshUpload() {
        this.pureOriginalsUploadService.refreshUpload()
    }


    uploadBin($event: any) {
        this.pureOriginalsUploadService.resetSignal.set(false);
        const file = $event.valueOf()["fileToUpload"];
        this.pureOriginalsUploadService.uploadBin(file);
    }

    uploadIni($event: any) {
        this.pureOriginalsUploadService.resetSignal.set(false);
        const file = $event.valueOf()["fileToUpload"];
        this.pureOriginalsUploadService.uploadIni(file)
    }

    uploadAllFiles() {
        this.pureOriginalsUploadService.uploadAllFiles();
    }
}

export class CustomPureOriginalUploadValidations {
    message: string;
    hasError: boolean;
    pureOriginalId: number;
}
