<div class="content-title padding-block bg-secondary-clr">
    @if (isMobile()) {
        <div class="header-content">
            <div class="logo-wrapper">

                @if (PRODUCTION === false) {
                    <div class="line">STAGING</div>
                }

                @if (statisticService.isThemeChanged()) {
                    <a class="logo" (click)="redirectToMainPage()"><img
                            src="assets/logo-black.svg" alt=""/></a>
                }
                @if (statisticService.isThemeChanged() === false) {
                    <a class="logo" (click)="redirectToMainPage()"><img
                            src="assets/logo-white.png" alt=""/></a>
                }
            </div>

            <ng-container>
                <app-user-modal-mobile></app-user-modal-mobile>
            </ng-container>

            @if (!statisticService.isOpened()) {
                <button class="button" (click)="openNavigationMenu()" data-showed="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18">
                        <g fill="#242D52" fill-rule="evenodd">
                            <path d="M0 0h25v4H0zM0 7h25v4H0zM0 14h25v4H0z"/>
                        </g>
                    </svg>
                </button>
            }

            @if (statisticService.isOpened()) {
                <button class="button" data-mobile="true" (click)="closeNavigationMenu()">
                    <svg width="25" height="18" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#828FA3" fill-rule="evenodd">
                            <path d="m12.728 0 2.122 2.122L2.122 14.85 0 12.728z"/>
                            <path d="M0 2.122 2.122 0 14.85 12.728l-2.122 2.122z"/>
                        </g>
                    </svg>
                </button>
            }
        </div>
    }
    @if (!isMobile()) {
        <div class="header-content-desktop">
            <div class="user-info">
                @if (statisticService.isOpened()) {
                    <a class="logo" (click)="redirectToMainPage()">
                        @if (statisticService.isThemeChanged() == false) {
                            <img src="assets/logo-white.png" alt=""/>
                        }
                        @if (statisticService.isThemeChanged()) {
                            <img src="assets/logo-black.svg" alt=""/>
                        }
                    </a>
                }
                @if (isAdmin() && isLoggedIn()) {
                    <div class="global-search-placeholder">
                        <app-global-search></app-global-search>
                    </div>
                }
                @if (userService.loggedUser()) {
                    <div class="user-details-wrapper">
                        <ng-container>
                            <div class="server-stats-modal">
                                <app-server-stats-modal></app-server-stats-modal>
                            </div>

                            <div class="filesInQueue-container">
                                <app-number-of-files-in-queue></app-number-of-files-in-queue>
                            </div>
                        </ng-container>

                        <div class="weather-time-container">
                            <app-weather-and-time></app-weather-and-time>
                        </div>

                        <div class="img-container" (click)="openUserSettings()">
                            <div class="user-name-picture">
                                <p>{{ userService.getInitials() }}</p></div>
                        </div>
                        <app-user-modal-component
                                [isUserSettingsOpened]="userService.isUserSettingsOpened()"
                                (closeUserSettingsModal)="closeUserSettings($event)"></app-user-modal-component>
                    </div>
                }
            </div>
        </div>
    }
</div>



