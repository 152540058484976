<div class="card-wrapper">
  <div class="even-columns">
   <div class="statistic-card" (click)="openMostRequestedPartialTunings()">
    <div class="cf-container">
      <div class="text-container">
        <span class="s-num">{{pureOriginalResultsService.numberOfMissingTunings()?.numberOfPartialMissingTunings}}</span>
        <span class="title">Total Number of Partial Missing Tunings</span>
      </div>
      <div class="icon-container">
        <div class="icon-button">
          <i class="bi bi-folder"></i>

        </div>
      </div>
    </div>
   </div>
   <div class="statistic-card" (click)="openMostRequestedMissingTunings()">
     <div class="cf-container">
       <div class="text-container">
         <span class="s-num">{{pureOriginalResultsService.numberOfMissingTunings()?.numberOfMissingTunings}}</span>
         <span class="title">Total Number of Missing Tunings</span>
       </div>
       <div class="icon-container">
         <div class="icon-button">
           <i class="bi bi-folder"></i>
         </div>
       </div>
     </div>
   </div>
  </div>
</div>




