import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {PureOriginalHistoryModel} from "../../../models/PureOriginalHistory-model";

@Component({
  selector: 'app-pure-original-history-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle

  ],
  templateUrl: './pure-original-history-dialog.component.html',
  styleUrl: './pure-original-history-dialog.component.css'
})
export class PureOriginalHistoryDialogComponent implements OnInit  {

  constructor(public dialogRef: MatDialogRef<PureOriginalHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    id: string,
    text: string,
    content: PureOriginalHistoryModel[]
  }) {}

  ngOnInit () {
  }
}
