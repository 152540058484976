export class MasterStatsModel {
    public masterId: string;
    public actionDateTime: string;
    public dateUploaded: string;
    public winOLSReference: string;
    public ecuBuild: string
    public ecuProducer: string
    public vehicleProducer: string;
    public isDeleted: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public resellerProjectDetails: string;


    constructor() {
        this.masterId = "masterId";
        this.actionDateTime = "actionDateTime";
        this.dateUploaded = "dateUploaded";
        this.winOLSReference = "winOlsReference";
        this.ecuBuild = "ecuBuild";
        this.ecuProducer = "ecuProducer";
        this.vehicleProducer = "vehicleProducer";
        this.vehicleSeries = "vehicleSeries";
        this.vehicleModel = "vehicleModel";
        this.resellerProjectDetails = "resellerProjectDetails";
        this.isDeleted = "isDeleted";


    }
}
