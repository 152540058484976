import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocalAuthService} from "../../../service/localAuth.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
    selector: 'app-email-confirmation-form',
    templateUrl: './email-confirmation-form.component.html',
    styleUrls: ['./email-confirmation-form.component.css']
})
export class EmailConfirmationFormComponent {

    public isLoading: boolean = false;

    constructor(private localAuthService: LocalAuthService, private toastrService: ToastrService, private router: Router) {
    }

    forgotPasswordForm = new FormGroup({
        email: new FormControl("", Validators.required)
    })


    sendForgotPasswordRequest() {
        this.isLoading = true;
        this.localAuthService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(response => {
            if (response.status == 200) {
                this.isLoading = false;
                this.forgotPasswordForm.reset();
                this.router.navigate(["/forgot-password-verification"]);
                this.toastrService.success("Check your email for password reset.")
            }
        }, error => {
            if (error.status == 400) {
                this.toastrService.error(error.error.message);
                this.forgotPasswordForm.reset();
                this.isLoading = false;
            }
            if (error.status == 403) {
                this.toastrService.error(error.error.message);
                this.forgotPasswordForm.reset();
                this.isLoading = false;
            }
            if (error.status == 404) {
                this.toastrService.error(error.error.message);
                this.forgotPasswordForm.reset();
                this.isLoading = false;
            }
            if (error.status == 409) {
                this.toastrService.error(error.error.message);
                this.forgotPasswordForm.reset();
                this.isLoading = false;
            }

        })
    }

}
