import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ResellerRecordsModel} from "../models/Reseller-records-model";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";

@Injectable({
    providedIn: "root"
})

export class ResellerRecordsService {
    private url = `${this.API_URL}`;
    public allResellerRecords = signal<ResellerRecordsModel[]>([]);
    public allResellers = signal<ResellerRecordsModel>(new ResellerRecordsModel());
    public displayedResellerKeys = signal<(keyof ResellerRecordsModel)[]>([]);

    private pageNumber = signal<number>(0);
    private pageSize = signal<number>(50);
    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "resellerid", direction: "desc"});
    public isLoading = signal<boolean>(false);

    httpClient = inject(HttpClient);

    constructor(@Inject("API_URL") private API_URL: string) {}

    refreshTableOnInit() {
        this.displayedResellerKeys.set([]);
        this.allResellerRecords.set([]);
        this.pageNumber.set(0);
        this.pageSize.set(50);
    }

    getTableKeysForPureOriginals() {
        let objectKeys = Object.keys(this.allResellers())
        for (let key of objectKeys) {
            this.displayedResellerKeys().push(key as keyof ResellerRecordsModel);
        }
    }

    triggerResellerRecordsRequest() {
        return this.httpClient.get<ResellerRecordsModel[]>(`${this.url}/resellers/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        })
    }

    getResellerRecords() {
        this.isLoading.set(true);
        this.triggerResellerRecordsRequest().subscribe((response) => {
            this.allResellerRecords.set(response["content"]);
            this.isLoading.set(false);
        });
    }

    onScroll() {
        this.increasePageNumber();
        this.triggerResellerRecordsRequest().subscribe(response => {
            this.allResellerRecords.update((records) => [...records, ...response["content"]]);
        });
    }

    increasePageNumber() {
        this.pageNumber.update((pageNum) => pageNum + 1)
    }

    refreshResellersTable() {
        this.pageNumber.set(0);
        this.allResellerRecords.set([]);
        this.getResellerRecords();
    }

    changeResellersSortDirection(sortEvent: SortDirectionValuesModel) {
        this.sortDirectionValues.set(sortEvent);
        this.pageNumber.set(0);
        this.getResellerRecords();
    }

}
