<div class="main">
    @defer (when !masterService.isLoading()) {
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="masterService.onScrollFaulty()">
            <app-master-records-table [allMasters]="masterService.faultyMasters()"
                                      [sortDirectionValues]="masterService.sortDirectionValues()"
                                      [displayedColumns]="masterService.displayedColumns()"
                                      [expandedMaster]="masterService.expandedMaster()"
                                      [displayedTableHeaders]="masterService.displayedTableHeaders()"
                                      [allVersions]="tuningService.allVersions()"
                                      [displayedVersionColumns]="masterService.displayedVersionColumns()"
                                      (deleteOriginalItem)="deleteFaultyMaster($event)"
                                      (downloadOriginalFile)="downloadFileFromTable($event)"
                                      (deleteTuningRecord)="deleteTuningRecordFromTable($event)"
                                      (downloadTuningRecord)="downloadTuningRecordFromTable($event)"
                                      (editItemDetails)="editItemDetails($event)"
                                      (toggleExpandedRow)="toggleExpandedRow($event)"
                                      (changeSortDirection)="changeSortDirection($event)"
            ></app-master-records-table>
        </div>
    } @placeholder (minimum 150ms) {
        <div>
            <app-skeleton-table></app-skeleton-table>
        </div>
    }
</div>
