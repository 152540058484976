export class PureOriginalFilterHeadersModel {
    public dateUploaded: string;
    public winOlsReference: string;
    public winOlsDbName: string;
    public sha1: string;
    public sha256: string;
    public md5: string;
    public vehicleProducer: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public vehicleModelYear: string;
    public vehicleBuild: string;
    public vehicleCharacteristic: string;
    public ecuSoftwareVersion: string;
    public ecuUse: string;
    public ecuBuild: string;
    public ecuProducer: string;
    public ecuProdNr: string;
    public ecuStgNr: string;
    public engineDisplacement: string;
    public engineProducer: string;
    public engineType: string;
    public outputPs: string;
    public outputKw: string;
    public dateExported: string;
    public readingHardware: string;
    public dateUpdated: string;
    public tuningName: string;
    public hitRate: string;

    constructor(init?: Partial<PureOriginalFilterHeadersModel>) {
        Object.assign(this, init);
    }
}
