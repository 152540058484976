import {Component, HostListener, inject,  OnInit} from '@angular/core';
import {StatisticsService} from "../service/statistics.service";
import {AuthService} from "../service/auth.service";


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],

})
export class LayoutComponent implements OnInit {


    public statisticService = inject(StatisticsService);
    public authService = inject(AuthService);

    ngOnInit() {}

    @HostListener('window:resize', ['$event'])
    isMobile(): boolean {
        return window.innerWidth < 768
    }

    closeMFAModal ($event: any) {
        this.authService.isMFAModalOpened.set($event.valueOf());
    }

}
